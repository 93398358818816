<div class="pmssettings">
  <!-- buttons -->
  <span class="pull-right p-mr-3 clientform-btn-top">
    <button
      pButton
      pRipple
      type="button"
      icon="fal fa-check"
      class="p-button-sm p-pr-2 p-mr-2"
      label="{{ 'save_button' | translate }}"
      [disabled]="(!isFormValid)"
      (click)="saveSettingsData()"></button>
    <button
      pButton
      pRipple
      label="{{ 'dashboard' | translate }}"
      icon="fal fa-tachometer-alt-fastest"
      class="p-button-sm p-pr-2 p-mr-2"
      routerLink="/project-management/dashboard"></button>
    <button
      pButton
      pRipple
      label="{{ 'Projects' | translate }}/{{ 'Tasks' | translate }} List"
      icon="fal fa-building"
      class="p-button-sm p-pr-2 p-mr-2"
      routerLink="/project-management/projectList"></button>
    <button
      pButton
      pRipple
      label="{{ 'Clients' | translate }}"
      icon="fal fa-users"
      class="p-button-sm p-pr-2 p-mr-2"
      routerLink="/project-management/clientHistory"></button>
  </span>
  <div class="layout-content p-p-2">
    <form class="form-horizontal" role="form" [formGroup]="mySettingsForm" novalidate>
      <div class="p-grid nested-grid">
        <!-- Start Tags -->
        <div class="p-col-4">
          <p-panel header="{{ 'your_tags' | translate }}">
            <div class="settings-pref-mh">
              <div
                class="p-mb-3"
                *ngFor="let tagItem of tagArray; let index = index; let last = last;">
                <div *ngIf="tagItem.Index == 1 || tagItem.Index == 2">
                  <input
                    type="text"
                    [ngClass]="tagItem.Class"
                    [value]="tagItem.Tag"
                    [id]="tagItem.name"
                    pInputText
                    [style.color]="tagItem.textColor"
                    [style.background-color]="tagItem.color && tagItem.color != '' ? tagItem.color : null"
                    readonly />
                  <span>
                    <i class="fal fa-lock grey" pTooltip="{{ 'tag_read_only' | translate }}"></i
                  ></span>
                </div>
                <div *ngIf="(tagItem.Index != 1 && tagItem.Index != 2) && tagItem.isShowTag">
                  <input
                    type="text"
                    [ngClass]="tagItem.Class"
                    pInputText
                    [(ngModel)]="tagItem.Tag"
                    [ngModelOptions]="{standalone: true}"
                    [style.background-color]="tagItem.color && tagItem.color != '' ? tagItem.color : null"
                    [style.color]="tagItem.textColor"
                    (keyup)="isTouched=true;validateForm();validateForm()"
                    (ngModelChange)="onTagChange($event,tagItem.Index);"
                    (focus)="$event.target.select()" />
                  <p-colorPicker
                    [(ngModel)]="tagArray[index].color"
                    [ngModelOptions]="{standalone: true}"
                    format="hex"
                    appendTo="body"
                    (onChange)="onColorChange($event, tagItem.Index)"></p-colorPicker>
                  <!-- <p-colorPicker (onChange)="onColorChange($event, tagItem.Index)"  format="hex" appendTo="body" ></p-colorPicker> -->
                  <!-- <span (click)="onDeleteTag(tagItem.Index)"> <i class="fal fa-trash-alt ic-red" pTooltip="{{ 'tag_delete' | translate }}"></i></span> -->
                </div>
                <div *ngIf="last">
                  <button
                    pButton
                    type="button"
                    icon="fa fa-plus"
                    class="p-mt-3"
                    label="{{ 'add_new_tag' | translate }}"
                    (click)="onAddNewTag()"></button>
                </div>
              </div>
              <!-- <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag1" pInputText
                                    formControlName="Tag1" readonly />
                                <span> <i class="fal fa-lock grey"
                                        pTooltip="{{ 'tag_read_only' | translate }}"></i></span>
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag2" pInputText
                                    formControlName="Tag2" readonly />
                                <span> <i class="fal fa-lock grey"
                                        pTooltip="{{ 'tag_read_only' | translate }}"></i></span>
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag3" pInputText
                                    formControlName="Tag3" (keyup)="isTouched=true;validateForm();validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag4" pInputText
                                    formControlName="Tag4" (keyup)="isTouched=true;validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag5" pInputText
                                    formControlName="Tag5" (keyup)="isTouched=true;validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag6" pInputText
                                    formControlName="Tag6" (keyup)="isTouched=true;validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag7" pInputText
                                    formControlName="Tag7" (keyup)="isTouched=true;validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag8" pInputText
                                    formControlName="Tag8" (keyup)="isTouched=true;validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag9" pInputText
                                    formControlName="Tag9" (keyup)="isTouched=true;validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag10" pInputText
                                    formControlName="Tag10" (keyup)="isTouched=true;validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag11" pInputText
                                    formControlName="Tag11" (keyup)="isTouched=true;validateForm()" />
                            </div>
                            <div class="p-mb-3">
                                <input type="text" class="width-90p p-mr-2 form-control Tag12" pInputText
                                    formControlName="Tag12" (keyup)="isTouched=true;validateForm()" />
                            </div> -->
            </div>
          </p-panel>
        </div>
        <!-- End Tags -->

        <!-- Start Project Template-->
        <div class="p-col-8">
          <p-panel header="{{ 'company_template_library' | translate }}">
            <div class="settings-pref-mh">
              <div class="p-grid p-jc-between">
                <div class="p-col-6">
                  <p-splitButton
                    class="p-button-help p-pr-2"
                    styleClass="p-button-sm split-sm-button"
                    label="{{ 'pm.Duplicate_to_Clients' | translate }}"
                    [menuStyleClass]="'duplicate-menu'"
                    (onClick)="duplicateRecords()"
                    icon="fa fa-copy"
                    [model]="buttonsList"
                    [disabled]="selecteItems == 0">
                  </p-splitButton>
                </div>
                <div class="p-col-6 p-text-right">
                  <p-button
                    class="p-mr-2"
                    type="button"
                    icon="fal fa-building"
                    label="{{ 'pm.master-template-library' | translate }}"
                    routerLink="/project-management/masterTemplateLibrary"></p-button>
                  <p-button
                    type="button"
                    icon="fal fa-plus"
                    label="{{ 'create_project_template' | translate }}"
                    routerLink="/project-management/createTemplate"></p-button>
                </div>
              </div>

              <p-table
                [value]="companyDS"
                [rows]="10"
                [totalRecords]="companyDS.length"
                [pageLinks]="3"
                [scrollable]="true"
                [scrollHeight]="'calc(50vh - 15px)'"
                (onFilter)="onFilter($event)"
                [resizableColumns]="true"
                [responsive]="true"
                dataKey="CompanyTemplateHeaderID"
                [globalFilterFields]="['TemplateName', 'SearchField']"
                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                [(selection)]="selecteItems"
                [sortOrder]="1"
                rowExpandMode="single"
                #dt>
                <ng-template pTemplate="caption">
                  <div class="table-header">
                    <span class="p-input-icon-left">
                      <i class="fal fa-search"></i>
                      <input
                        #searchValue
                        pInputText
                        type="text"
                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{ 'globalsearch' | translate }}" />
                    </span>
                    <span>
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        (click)="dt.reset();clearSearchFilter()"
                        tooltipPosition="top"
                        pTooltip="{{ 'clear' | translate }}"></button>
                    </span>
                    <p-message
                      *ngIf="filteredItemsSize > -1"
                      severity="success"
                      text="{{filteredItemsSize}} Record(s) found."
                      styleClass="p-ml-2"></p-message>
                  </div>
                </ng-template>

                <!-- Start Table Header -->
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th class="p-text-center width-2p">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="p-text-center width-3p"></th>
                    <th class="p-text-center width-25p" pSortableColumn="TemplateName">
                      {{ 'Project-Name' | translate }}
                      <p-sortIcon field="TemplateName"></p-sortIcon>
                    </th>
                    <th class="p-text-center width-10p" pSortableColumn="detailcount">
                      {{ 'total_tasks' | translate }}
                      <p-sortIcon field="detailcount"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <!-- End Table Header -->

                <!-- Start Table Body -->
                <ng-template
                  pTemplate="body"
                  let-rowData
                  let-columns="columns"
                  let-expanded="expanded"
                  let-rowIndex="rowIndex">
                  <tr>
                    <td class="p-text-center width-2p">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="p-text-center width-3p">
                      <span [pRowToggler]="rowData">
                        <i
                          *ngIf="rowData.hasChildrens"
                          [ngClass]="expanded ? 'far fa-chevron-down cursor-hand' : 'far fa-chevron-right cursor-hand'"></i>
                      </span>
                    </td>
                    <td class="p-text-left p-text-nowrap width-25p">
                      <a
                        class="hyperlink-text"
                        routerLink="/project-management/{{rowData.CompanyTemplateHeaderID}}/createTemplate"
                        >{{rowData.TemplateName}}</a
                      >
                    </td>
                    <td class="p-text-center p-px-3 width-10p">
                      <span class="badge-number bg-blue">{{rowData.detailcount}}</span>
                    </td>
                  </tr>
                </ng-template>
                <!-- End Table Body -->

                <!-- Start Table Row Expansion -->
                <ng-template pTemplate="rowexpansion" let-item let-i="rowIndex">
                  <tr>
                    <td [attr.colspan]="4" class="expand-row-bg" *ngIf="item.childItems.length > 0">
                      <div class="p-p-2 bg-blue">
                        <p-table
                          [style]="{'margin':'10px'}"
                          [value]="item.childItems"
                          [rows]="5"
                          [pageLinks]="3"
                          [rowsPerPageOptions]="[5,10,20]"
                          [paginator]="item.childItems.length > 5"
                          [responsive]="true"
                          [resizableColumns]="true"
                          #dtchild>
                          <ng-template pTemplate="header" let-columns>
                            <tr>
                              <th class="width-8p p-text-center">
                                {{ 'task_number' | translate }}
                              </th>
                              <th class="width-35p p-text-left">
                                {{ 'Task-Description' | translate }}
                              </th>
                              <th class="width-15p p-text-left">{{ 'due-date' | translate }}</th>
                              <th class="width-15p p-text-left">
                                {{ 'user-assigned' | translate }}
                              </th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-childrowData let-columns="columns">
                            <tr>
                              <td class="nested-table-header p-text-center">
                                <strong>{{childrowData.TaskRow}}</strong>
                              </td>
                              <td class="nested-table-header p-text-left">
                                {{childrowData.TaskDescription}}
                              </td>
                              <td class="nested-table-header p-text-left p-text-nowrap">
                                {{childrowData.DueDate | date:'MM/dd/yyyy'}}
                              </td>
                              <td class="nested-table-header p-text-left p-text-nowrap">
                                {{childrowData.StaffName}}
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <!-- End Table Row Expansion -->
              </p-table>
            </div>
          </p-panel>
        </div>
        <!-- End Project Template-->
      </div>
      <div class="p-grid nested-grid">
        <!-- Start Email Notifications -->
        <div class="p-col-4">
          <p-panel header="{{ 'email_notifications' | translate }}">
            <div class="p-mb-3">
              <p-checkbox
                binary="true"
                class="label-text"
                label="{{ 'notify_project_assigned' | translate }}"
                formControlName="IsEmailProjectAssigned"
                (onChange)="isTouched=true;validateForm();onCheckboxChanged($event, 'IsEmailProjectAssigned')">
              </p-checkbox>
            </div>
            <div class="p-mb-3">
              <p-checkbox
                class="label-text"
                formControlName="IsEmailTaskAssigned"
                binary="true"
                label="{{ 'notify_task_assigned' | translate }}"
                (onChange)="isTouched=true;validateForm();onCheckboxChanged($event, 'IsEmailTaskAssigned')">
              </p-checkbox>
            </div>
            <div class="p-mb-3">
              <p-checkbox
                class="label-text"
                formControlName="IsEmailTaskReady"
                binary="true"
                label="{{ 'notify_task_ready' | translate }}"
                (onChange)="isTouched=true;validateForm();onCheckboxChanged($event, 'IsEmailTaskReady')">
              </p-checkbox>
            </div>
            <div class="p-mb-3">
              <p-checkbox
                class="label-text"
                name="group1"
                value="readyForReview"
                label="{{ 'notify_project_for_review' | translate }}"
                formControlName="IsManagerReadyReview"
                binary="true"
                (onChange)="isTouched=true;validateForm();onCheckboxChanged($event, 'IsManagerReadyReview')">
              </p-checkbox>
            </div>
            <div class="p-mb-3">
              <p-checkbox
                class="label-text"
                name="group1"
                value="inProgress"
                label="{{ 'notify_project_in_progress' | translate }}"
                formControlName="IsManagerProjectInProgress"
                binary="true"
                (onChange)="isTouched=true;validateForm();onCheckboxChanged($event, 'IsManagerProjectInProgress')">
              </p-checkbox>
            </div>
            <div class="p-mb-3">
              <p-checkbox
                class="label-text"
                name="group1"
                value="IsProjectRejected"
                label="{{ 'notify_project_rejected' | translate }}"
                formControlName="IsProjectRejected"
                binary="true"
                (onChange)="isTouched=true;validateForm();onCheckboxChanged($event, 'IsProjectRejected')">
              </p-checkbox>
            </div>
          </p-panel>
          <div class="p-mt-4">
            <p-panel header="{{ 'Budgets' | translate }}">
              <div>
                <div class="p-field p-fluid p-col-12">
                  <p-checkbox
                    binary="true"
                    class="label-text"
                    label="{{ 'pm.Enable_Track_Time_by_Projects' | translate }}"
                    formControlName="isTrackingTimebyProjects"
                    (onChange)="onRadioSettingsClicked($event, 'trackTime');
                                onCheckboxChanged($event, 'isTrackingTimebyProjects')">
                  </p-checkbox>
                </div>

                <div class="p-field p-fluid p-pb-0 p-col-12" *ngIf="(isCapacityPlanningAllowed)">
                  <p-checkbox
                    binary="true"
                    class="label-text"
                    label="{{ 'enable-capacity-planning' | translate }}"
                    formControlName="isCapacityPlanningEnabled"
                    (onChange)="onRadioSettingsClicked($event, 'capacityPlanning');
                                onCheckboxChanged($event, 'isCapacityPlanningEnabled')">
                  </p-checkbox>
                </div>

                <div
                  class="p-field p-fluid p-pb-0 p-col-12"
                  *ngIf="(subscriptionLevel == 'ENTERPRISE' || subscriptionLevel == 'FREE')">
                  <p-checkbox
                    binary="true"
                    class="label-text"
                    label="{{ 'pm.Enable_Project_Budgets' | translate }}"
                    formControlName="isBudgetingProjectLevel"
                    (onChange)="onRadioSettingsClicked($event, 'projectBudget');
                                onCheckboxChanged($event, 'isBudgetingProjectLevel')">
                  </p-checkbox>
                </div>

                <div
                  class="p-field p-fluid p-pb-0 p-col-12"
                  *ngIf="mySettingsForm.controls['isBudgetingProjectLevel'].value && 
                        (subscriptionLevel == 'ENTERPRISE' || subscriptionLevel == 'FREE')">
                  <p-checkbox
                    binary="true"
                    class="label-text"
                    label="{{ 'enable-task-budget' | translate }}"
                    formControlName="isTaskBudgetEnabled"
                    (onChange)="onRadioSettingsClicked($event, 'taskBudget');
                                onCheckboxChanged($event, 'isTaskBudgetEnabled')">
                  </p-checkbox>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
        <!-- End Email Notifications -->

        <!-- Start Default Assignments -->
        <div class="p-col-8">
          <p-panel header="{{ 'Email Alerts' | translate }}">
            <div class="p-d-flex p-jc-between">
              <div class="p-col-6">
                <h4 class="label-text">
                  {{'alerts.project_email_alerts_defaults' | translate}}
                  <p-checkbox
                    class="label-text p-ml-2 p-mb-1"
                    formControlName="EnableProjectEmailAlerts"
                    binary="true"
                    (onChange)="enableProjectEmailAlert($event);onCheckboxChanged($event, 'EnableProjectEmailAlerts')">
                  </p-checkbox>
                </h4>
                <!-- <div class="p-col-12">
                            <p-checkbox class="label-text" formControlName="EnableProjectEmailAlerts" binary="true"
                                label="{{ 'Enable' | translate }}" (onChange)="isTouched=true;validateForm()">
                            </p-checkbox>
                        </div>
                        <div class="p-col-12"></div> -->
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="DaysBeforePastDueDateHeaderBool"
                    binary="true"
                    label="{{ 'alerts.notify_days_before_due_date' | translate }}"
                    (onChange)="toggleCheckBoxWithInt($event, 'DaysBeforePastDueDateHeader');validateEnableFlag();onCheckboxChanged($event, 'DaysBeforePastDueDateHeaderBool')">
                  </p-checkbox>
                </div>
                <div class="p-field p-col-12 p-ml-5">
                  <label
                    class="p-col-12"
                    [ngClass]="!mySettingsForm.controls['DaysBeforePastDueDateHeaderBool'].value? 'label-text-disabled' : 'label-text'"
                    >{{'alerts.number_of_days_before_due_date' | translate}}</label
                  >
                  <p-inputNumber
                    maxlength="3"
                    (click)="$event.target.select()"
                    autocomplete="off"
                    class="p-ml-3"
                    (ngModelChange)="validateEnableFlag();onInputNumberChange($event, 'DaysBeforePastDueDateHeader')"
                    formControlName="DaysBeforePastDueDateHeader"
                    mode="decimal"
                    inputStyleClass="p-text-right"
                    placeholder="0"
                    [disabled]="!mySettingsForm.controls['DaysBeforePastDueDateHeaderBool'].value"></p-inputNumber>
                </div>
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="PastDueHeader"
                    binary="true"
                    label="{{ 'alerts.notify_when_past_due' | translate }}"
                    (onChange)="toggleCheckBoxWithInt($event, 'ProjectDaysToRemind');validateEnableFlag();onCheckboxChanged($event, 'PastDueHeader')">
                  </p-checkbox>
                </div>
                <div class="p-field p-col-12 p-ml-5">
                  <label
                    class="p-col-12"
                    [ngClass]="!mySettingsForm.controls['PastDueHeader'].value? 'label-text-disabled' : 'label-text'"
                    >{{'alerts.interval_days_after_due_date' | translate}}</label
                  >
                  <p-inputNumber
                    maxlength="3"
                    (click)="$event.target.select()"
                    autocomplete="off"
                    class="p-ml-3"
                    (ngModelChange)="validateEnableFlag();onInputNumberChange($event, 'ProjectDaysToRemind')"
                    formControlName="ProjectDaysToRemind"
                    mode="decimal"
                    inputStyleClass="p-text-right"
                    placeholder="0"
                    [disabled]="!mySettingsForm.controls['PastDueHeader'].value"></p-inputNumber>
                </div>
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="isNotifyManagerHeader"
                    binary="true"
                    label="{{ 'alerts.notify_manager' | translate }}"
                    (onChange)="validateEnableFlag();onCheckboxChanged($event, 'isNotifyManagerHeader')">
                  </p-checkbox>
                </div>
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="isNotifyBillingPartnerHeader"
                    binary="true"
                    label="{{ 'alerts.notify_billing_partner' | translate }}"
                    (onChange)="validateEnableFlag();onCheckboxChanged($event, 'isNotifyBillingPartnerHeader')">
                  </p-checkbox>
                </div>
              </div>
              <div class="p-col-6 p-pb-3">
                <h4 class="label-text">
                  {{'alerts.task_email_alerts_defaults' | translate}}
                  <p-checkbox
                    class="label-text p-ml-2 p-mb-1"
                    formControlName="EnableTaskEmailAlerts"
                    binary="true"
                    (onChange)="enableTaskEmailAlert($event);onCheckboxChanged($event, 'EnableTaskEmailAlerts')">
                  </p-checkbox>
                </h4>
                <!-- <div class="p-col-12">
                            <p-checkbox class="label-text" formControlName="EnableTaskEmailAlerts" binary="true"
                                label="{{ 'Enable' | translate }}" (onChange)="isTouched=true;validateForm()">
                            </p-checkbox>
                        </div> -->
                <!-- <div class="p-col-12"></div> -->
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="DaysBeforePastDueDateDetailBool"
                    binary="true"
                    label="{{ 'alerts.notify_days_before_due_date' | translate }}"
                    (onChange)="toggleCheckBoxWithInt($event, 'DaysBeforePastDueDateDetail');validateEnableFlag();onCheckboxChanged($event, 'DaysBeforePastDueDateDetailBool')">
                  </p-checkbox>
                </div>

                <div class="p-field p-col-12 p-ml-5">
                  <label
                    class="p-col-12"
                    [ngClass]="!mySettingsForm.controls['DaysBeforePastDueDateDetailBool'].value? 'label-text-disabled' : 'label-text'"
                    >{{'alerts.number_of_days_before_due_date' | translate}}</label
                  >
                  <p-inputNumber
                    maxlength="3"
                    (click)="$event.target.select()"
                    autocomplete="off"
                    class="p-ml-3"
                    formControlName="DaysBeforePastDueDateDetail"
                    mode="decimal"
                    inputStyleClass="p-text-right"
                    (ngModelChange)="validateEnableFlag();onInputNumberChange($event, 'DaysBeforePastDueDateDetail')"
                    placeholder="0"
                    [disabled]="!mySettingsForm.controls['DaysBeforePastDueDateDetailBool'].value"></p-inputNumber>
                </div>
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="PastDueDetail"
                    binary="true"
                    label="{{ 'alerts.notify_when_past_due' | translate }}"
                    (onChange)="toggleCheckBoxWithInt($event, 'TasksDaysToRemind');validateEnableFlag();onCheckboxChanged($event, 'PastDueDetail')">
                  </p-checkbox>
                </div>
                <div class="p-field p-col-12 p-ml-5">
                  <label
                    class="p-col-12"
                    [ngClass]="!mySettingsForm.controls['PastDueDetail'].value? 'label-text-disabled' : 'label-text'"
                    >{{'alerts.interval_days_after_due_date' | translate}}</label
                  >
                  <p-inputNumber
                    maxlength="3"
                    (click)="$event.target.select()"
                    autocomplete="off"
                    class="p-ml-3"
                    (ngModelChange)="validateEnableFlag();onInputNumberChange($event, 'TasksDaysToRemind')"
                    formControlName="TasksDaysToRemind"
                    mode="decimal"
                    inputStyleClass="p-text-right"
                    placeholder="0"
                    [disabled]="!mySettingsForm.controls['PastDueDetail'].value"></p-inputNumber>
                </div>
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="isNotifyManagerDetail"
                    binary="true"
                    label="{{ 'alerts.notify_manager' | translate }}"
                    (onChange)="validateEnableFlag();onCheckboxChanged($event, 'isNotifyManagerDetail')">
                  </p-checkbox>
                </div>
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="isNotifyBillingPartnerDetail"
                    binary="true"
                    label="{{ 'alerts.notify_billing_partner' | translate }}"
                    (onChange)="validateEnableFlag();onCheckboxChanged($event, 'isNotifyBillingPartnerDetail')">
                  </p-checkbox>
                </div>
                <div class="p-col-12">
                  <p-checkbox
                    class="label-text"
                    formControlName="isNotifyStaffAssignedDetail"
                    binary="true"
                    label="{{ 'alerts.notify_assigned_staff' | translate }}"
                    (onChange)="validateEnableFlag();onCheckboxChanged($event, 'isNotifyStaffAssignedDetail')">
                  </p-checkbox>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
        <div class="p-col-4" style="padding-bottom: 200px">
          <p-panel header="{{ 'pm.other_settings' | translate }}">
            <div class="p-d-flex p-jc-between">
              <div class="p-col-8 p-pb-3">
                <h4 class="label-text">
                  {{'pm.project_settings' | translate}}
                  <i
                    pTooltip="{{ 'pm.show_tags_dates' | translate }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap"
                    class="fa fa-question-circle ic-blue p-ml-1"></i>
                </h4>
                <div class="p-field p-fluid p-mb-1 p-col-12">
                  <label class="label-text">Show Project Tags/Due Dates</label>
                  <p-dropdown
                    [options]="dashboardTagsOptions"
                    formControlName="DashboardProjectTags"
                    (onChange)="onRadioSettingsClicked($event, 'dashboardTags');onInputNumberChange($event, 'DashboardProjectTags')"></p-dropdown>
                </div>
                <div class="p-field p-fluid p-mb-1 p-col-12">
                  <label class="label-text">Allow Project Completion</label>
                  <p-dropdown
                    [options]="completionOptions"
                    formControlName="CompletionPermission"
                    (onChange)="onRadioSettingsClicked($event, 'completion');onInputNumberChange($event, 'CompletionPermission')"></p-dropdown>
                </div>
                <div class="p-field p-fluid p-mb-1 p-col-12">
                  <label class="label-text">Dashboard: Projects View</label>
                  <p-checkbox
                    binary="true"
                    class="label-text"
                    label="{{ 'pm.isShowProjectsView' | translate }}"
                    formControlName="isShowProjectsView"
                    (onChange)="isTouched=true;validateForm();onCheckboxChanged($event, 'isShowProjectsView')">
                  </p-checkbox>
                </div>
              </div>
              <!-- <div class="p-col-6 p-ml-6 p-pl-6">
                    </div> -->
            </div>
          </p-panel>
        </div>
        <!-- End Default Assignments -->
      </div>
    </form>
  </div>
</div>
