import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import {
  AuthGuard,
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  RepeatEnum
} from '@app/_services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import WebViewer from '@pdftron/webviewer';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  RichTextEditorComponent,
  ToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';
import { Table } from 'primeng/table';
import { RecurrenceEditorChangeEventArgs } from '@syncfusion/ej2-angular-schedule';
import * as AWS from 'aws-sdk';
import $ from 'jquery';
import moment from 'moment';
import { MenuItem, TreeNode } from 'primeng/api';
import { RRule } from 'rrule';
import { forkJoin, Subject, timer } from 'rxjs';
import swal from 'sweetalert2';
import { SharedComponentsService } from '@app/shared/components';
import Swal from 'sweetalert2';
import { isNullOrUndefined } from '@syncfusion/ej2-base';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';
import {
  CARET_DAY_CARDINAL,
  CARET_DAY_ORDINAL,
  CARET_MONTH,
  CARET_QUARTER_CARDINAL,
  CARET_QUARTER_ORDINAL,
  CARET_YEAR
} from './constants';
import { RProjectDetailsService } from './rproject-details.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('preiewviewer') preiewviewer: ElementRef;
  @ViewChild('dummyViewer') dummyViewer: ElementRef;
  wvPreviewInstance: any;
  dummyPDFInstance: any;

  public isDisplayWildcardsTable: boolean = false;
  /* Start - For New Projects SplitButton */
  public isDisplayDetailsTable: boolean = false;
  public templateDataSource: any = [];
  public isRadioSelected: boolean = false;
  buttonsList: MenuItem[];
  companyId;
  selectedTemplate;
  /* End - For New Projects SplitButton*/

  /* Start - For Fetching Clients */
  public selClient: any = { ClientID: null, ClientName: '' };
  public selClientClone: any = { ClientID: null, ClientName: '' };
  public clientListDatasource: any = [];
  public filteredClients: any[];
  intervalid: any;
  /* End - For Fetching Clients */

  public buttonsDeleteList: MenuItem[];
  moreOptions: MenuItem[];
  showCloneModal: boolean = false;
  public note: boolean = false;
  public dueDateList: any = [];
  @ViewChild('dt') table: Table;
  @ViewChild('searchValue') searchValue;
  public resourceId;
  public projectEditForm: UntypedFormGroup;
  public awsBucket: any;
  public dateRangeList: any = [];
  public engagementListItems: any = [];
  public engagementList: any = [];
  public isShowBudgetsDialog = false;
  public budgetObject: any = {};
  public selectedItems: any = [];
  public tasksDataSource: any = [];
  public AllManagerTypes: any = [];
  public AllManagers: any = [];
  public StaffOptionsForTasks: any = [];
  public totalProcessLen: any = 0;
  public statusText: any = 'Pending';
  public statusClass: any = 'pending';
  public emailTemplate = { to: '', from: '', subject: '' };
  public emailEditorValue: any = null;
  public oldemailEditorValue: any = null;
  public projectsList: any = [];
  public companyTags;
  public ddmSettingsObj: any = {};
  public tagsList: any = [];
  public isShowIcon: boolean = false;
  public isTouched: boolean = false;
  public isLineItemsValid: boolean = false;
  public isValidForm: boolean = true;
  public senderObj: any = { toName: null, toEmail: null, companyName: null };
  public isCreateFlow: boolean = false;
  public selProject: any = { label: null };
  public selectedProjectDetails: any = {};
  public selectedProjectHeader: any = null;
  public projectDocuments: any = [];
  public notesDataSource: any = [];
  public isUserSettingsTouched: boolean = false;
  public noteHistory: any = '';
  public isEmailClient: boolean = false;
  public isEmailDialog: boolean = false;
  public selectedUsers: string[] = [];
  public isFormValid: boolean = false;
  public filteredProjects: any[];
  public isCreateProject: boolean = false;
  public isFailedReviewClicked: boolean = false;
  public isDisplayRuleDialog: boolean = false;
  public ruledateSource: any = null;
  public ruleTwodateSource: any = null;
  public currentRuleStr: any = '';
  public rule: any;
  public showAttachedFiles: boolean = false;
  public showProjectEmailAlerts: boolean = false;
  public showTaskEmailAlert: boolean = false;
  selectedTaskEmailAlert = null;
  projectEmailAlerts = {
    OverrideFirmDefaults: null,
    ProjectDaysToRemind: 0,
    DaysBeforePastDueDate: 0,
    DaysBeforePastDueDateBool: false,
    PastDue: null,
    isNotifyManager: null,
    isNotifyBillingPartner: null,
    IsChanged: false
  };
  public showClientFolders: boolean = false;
  public isEngagementChanged: boolean = false;
  public dmsParentIDToLink = null;
  public dmsYellowParentIDToLink = null;
  public parentSelected = false;
  public dmsParentIDsNewArray = [];
  public isShowfileName: boolean = false;
  public fileName = '';
  public selectedObj = null;
  public isFilePreview: boolean = false;
  public isPreviewViewerShow: boolean = false;
  files: File[] = [];
  userName;
  currentTaskRow;
  filteredItemsSize = -1;
  searchTextStr: any = '';
  IsFinishReview: boolean = false;
  newProjectHeaderID = null;
  // Start - For Scratchpad
  scratchPadEnabled: boolean = false;
  public editorValue: any = null;
  @ViewChild('clientEditor') clientEditor;
  public rteObj: RichTextEditorComponent;
  public isCheckTriggered: boolean = false;
  public isRowEdit: boolean = false;
  public lastRowIndex: number = -1;
  recEditor: any;
  EngagementTypeIDAsigned: any;
  projHeaderRule: string = '';
  
  public allStaffDetails: any = [];

  clientFiles: TreeNode[];

  previousEngagement: any = null;
  previousTemplateWildcards: string = '';
  lastIsPreviousPeriodYearPolicy: boolean = null;
  isDisplayTemplateWildcardsDialog: boolean = false;
  initialTemplateName: string = '';
  public tools: ToolbarModule = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      'SuperScript',
      'SubScript',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo'
    ]
  };
  // End - For Scratchpad

  public emailTools: object = {
    items: [
      'Undo',
      'Redo',
      '|',
      'Bold',
      'Italic',
      'Underline',
      '|',
      'FontName',
      'FontSize',
      'FontColor',
      'Alignments',
      '|',
      'OrderedList',
      'UnorderedList',
      'CreateLink',
      'SourceCode'
    ]
  };

  public selectedType: number = 0;

  public isShowProjectNotes: boolean = false;
  public isShowProjectDocs: boolean = false;
  public isEngagementNotSaved: boolean = false;
  subscriptionLevel;

  public isListView: boolean = false;

  companyData = null;
  isManaging: any;

  forbiddenWildcards = {
    YEAR: [
      CARET_QUARTER_ORDINAL,
      CARET_QUARTER_CARDINAL,
      CARET_MONTH,
      CARET_DAY_ORDINAL,
      CARET_DAY_CARDINAL
    ],
    QUARTER: [CARET_MONTH, CARET_DAY_ORDINAL, CARET_DAY_CARDINAL],
    MONTH: [CARET_DAY_ORDINAL, CARET_DAY_CARDINAL]
  };

  allowedWildcards = {
    YEAR: [CARET_YEAR],
    QUARTER: [CARET_QUARTER_ORDINAL, CARET_QUARTER_CARDINAL, CARET_YEAR],
    MONTH: [CARET_MONTH, CARET_YEAR, CARET_QUARTER_ORDINAL, CARET_QUARTER_CARDINAL],
    DAY: [CARET_DAY_ORDINAL, CARET_DAY_CARDINAL]
  };

  public projectNoteMailRecipientList: Record<string, any>[] = [] as Record<string, any>[];

  showCapacityPlanning: boolean = false;
  @ViewChild('fileUpload') fileUpload: any;
  constructor(
    private router: Router,
    private authGuard: AuthGuard,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private _fb: UntypedFormBuilder,
    private http: HttpClient,
    public mangoUtils: mangoUtils,
    private translate: TranslateService,
    private sharedService: SharedComponentsService,
    private activatedRoute: ActivatedRoute,
    private rProjectDetailsService: RProjectDetailsService
  ) {
    const parent = this;

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) {
        return;
      }

      clearInterval(interval);

      this.initTranslations();
    }, 300);

    parent.companyId = parent.encrDecSrvc.getObject(AppConstants.companyID);
    parent.getCompanyTemplateDetails();

    this.companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);

    parent.resourceId = parent.encrDecSrvc.getObject(AppConstants.resourceID);

    parent.initializeForm();

    parent.setAWSOBject();

    parent.getProjectNames(parent.companyId);

    parent.getStaffList();

    parent.fetchDdmSettings();

    /*;
    parent.prepareData( );

    parent.getfetchprojectsNotes( );

    parent.encrDecSrvc.removeObject( AppConstants.fromCompanyTemplate );
    */

    this.sharedService.leaveAndSaveSub = new Subject<any>();
    this.sharedService.leaveAndSaveSub.subscribe(() => {
      this.saveAllTasks();
    });
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management') },
      { label: this.translate.instant('Project-Details'), icon: 'ic-red' }
    ]);
    /* Start - For New Projects SplitButton */
    this.buttonsList = [
      {
        label: this.translate.instant('New-Project'),
        icon: 'fal fa-plus',
        routerLink: ['/project-management/projectDetails']
      },
      {
        label: this.translate.instant('create_company_template'),
        icon: 'fal fa-plus',
        command: () => {
          this.isDisplayDetailsTable = true;
          const interval = setInterval(() => {
            if (!this.searchValue) return;
            clearInterval(interval);
            this.searchValue.nativeElement?.focus();
          });
        }
      },
      {
        label: this.translate.instant('create_new_template'),
        icon: 'fal fa-plus',
        routerLink: ['/project-management/createTemplate']
      }
    ];
    this.dateRangeList = [
      { label: this.translate.instant('none'), value: 'none' },
      { label: this.translate.instant('yearly'), value: 'year' },
      { label: this.translate.instant('quarterly'), value: 'quarterly' },
      { label: this.translate.instant('user.monthly'), value: 'monthly' },
      { label: this.translate.instant('semi_monthly'), value: 'semi-monthly' },
      { label: this.translate.instant('user.weekly'), value: 'week' },
      { label: this.translate.instant('semi_weekly'), value: 'semi-weekly' },
      { label: this.translate.instant('user.daily'), value: 'daily' },
      { label: this.translate.instant('Custom'), value: 'custom' }
    ];
    this.dueDateList = [
      { label: this.translate.instant('Next-Week'), value: 'NextWeek' },
      { label: this.translate.instant('next_month'), value: 'NextMonth' },
      { label: `3 ${this.translate.instant('months')}`, value: 'threeMonths' },
      { label: `4 ${this.translate.instant('months')}`, value: 'fourMonths' },
      { label: `5 ${this.translate.instant('months')}`, value: 'fiveMonths' },
      { label: `6 ${this.translate.instant('months')}`, value: 'sixMonths' }
    ];

    const parent = this;
    this.buttonsDeleteList = [
      {
        label: parent.translate.instant('delete_selected_items'),
        icon: 'fal fa-trash-alt',
        command: () => {
          parent.deleteItems();
        }
      },
      {
        label: parent.translate.instant('complete_selected_items'),
        icon: 'fal fa-check',
        disabled: parent.selectedItems.length > 0,
        command: () => {
          parent.completeItems();
        }
      }
    ];
    this.updateMoreOptions();
  }

  updateMoreOptions() {
    this.mangoAPISrvc.getIsDMSIshareValue().subscribe((result: any) => {
      this.moreOptions = [
        {
          label: `${this.translate.instant('notes')} (${this.notesDataSource.length})`,
          icon: 'fal fa-sticky-note',
          command: () => {
            this.onCloseProjectNotesSidebar();
          }
        },
        {
          label: `${this.translate.instant('documents')} (${this.projectDocuments.length})`,
          icon: 'fal fa-folders',
          command: () => {
            this.onOpenProjectDocsSidebar();
          }
        },
        {
          label: this.translate.instant('Email Alerts'),
          icon: 'pi pi-cog',
          command: () => {
            this.openProjectEmailAlerts();
          }
        },
        {
          label: this.translate.instant('pm.Clone-Project'),
          icon: 'fal fa-copy',
          command: () => {
            this.showCloneModal = true;
          }
        }
      ];
      if (!result?.data?.isDMSIShare) {
        this.moreOptions.splice(2, 0, {
          label: this.translate.instant('Upload'),
          icon: 'fal fa-cloud-upload',
          disabled: this.selectedItems.length > 0,
          command: () => {
            this.showFiles();
          }
        });
      }
      if (this.ddmSettingsObj.isTrackingTimebyProjects) {
        this.moreOptions.push({
          label: this.translate.instant('Time'),
          icon: 'fal fa-plus',
          disabled: !this.selClient?.ClientID,
          command: () => {
            this.showTimeEntryDialog({ IsTimeRecord: 'T' });
          }
        });
      }
      if (
        this.ddmSettingsObj?.isBudgetingProjectLevel &&
        this.projectEditForm.value.ProjectMasterID &&
        !this.isEngagementNotSaved
      ) {
        this.moreOptions.push({
          label: this.translate.instant('Budgets'),
          icon: 'fal fa-ballot',
          disabled: !this.selClient?.ClientID,
          command: () => {
            this.showBudgetDialog();
          }
        });
      }
    });
  }

  toggleCheckBoxWithInt(event, data, type) {
    data[type] = 0;
  }

  redirectToProjectSettings() {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: this.translate.instant('alerts.redirect_project_settings_warning'),
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: this.translate.instant('Go to Project Settings'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then(result => {
      if (result.value) {
        this.router.navigate(['/project-management/settings']);
      }
    });
  }

  onOverrideProjectFirmDefaults(event) {
    if (event.checked) {
      this.projectEmailAlerts['DaysBeforePastDueDate'] = 0;
      this.projectEmailAlerts['DaysBeforePastDueDateBool'] = false;
      this.projectEmailAlerts['ProjectDaysToRemind'] = 0;
      this.projectEmailAlerts['PastDue'] = false;
      this.projectEmailAlerts['isNotifyManager'] = false;
      this.projectEmailAlerts['isNotifyBillingPartner'] = false;
      return;
    }
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: this.translate.instant('alerts.disable_and_use_firm'),
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('No')
    }).then(result => {
      if (result.value) {
        (this.projectEmailAlerts['DaysBeforePastDueDate'] =
          this.companyData['DaysBeforePastDueDateHeader']),
          (this.projectEmailAlerts['DaysBeforePastDueDateBool'] =
            this.companyData['DaysBeforePastDueDateHeader'] != null),
          (this.projectEmailAlerts['ProjectDaysToRemind'] =
            this.companyData['ProjectDaysToRemind']),
          (this.projectEmailAlerts['PastDue'] = this.companyData['PastDueHeader']);
        this.projectEmailAlerts['isNotifyManager'] = this.companyData['isNotifyManagerHeader'];
        this.projectEmailAlerts['isNotifyBillingPartner'] =
          this.companyData['isNotifyBillingPartnerHeader'];
      } else {
        this.projectEmailAlerts.OverrideFirmDefaults = true;
      }
    });
  }

  onOverrideTaskFirmDefaults(event) {
    if (event.checked) {
      this.selectedTaskEmailAlert['DaysBeforePastDueDate'] = 0;
      this.selectedTaskEmailAlert['DaysBeforePastDueDateBool'] = false;
      this.selectedTaskEmailAlert['TasksDaysToRemind'] = 0;
      this.selectedTaskEmailAlert['PastDue'] = false;
      this.selectedTaskEmailAlert['isNotifyManager'] = false;
      this.selectedTaskEmailAlert['isNotifyBillingPartner'] = false;
      this.selectedTaskEmailAlert['isNotifyStaffAssigned'] = false;
      return;
    }
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: this.translate.instant('alerts.disable_and_use_firm'),
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('No')
    }).then(result => {
      if (result.value) {
        (this.selectedTaskEmailAlert['DaysBeforePastDueDate'] =
          this.companyData['DaysBeforePastDueDateDetail']),
          (this.selectedTaskEmailAlert['DaysBeforePastDueDateBool'] =
            this.companyData['DaysBeforePastDueDateDetail'] != null),
          (this.selectedTaskEmailAlert['TasksDaysToRemind'] =
            this.companyData['TasksDaysToRemind']),
          (this.selectedTaskEmailAlert['PastDue'] = this.companyData['PastDueDetail']);
        this.selectedTaskEmailAlert['isNotifyManager'] = this.companyData['isNotifyManagerDetail'];
        this.selectedTaskEmailAlert['isNotifyBillingPartner'] =
          this.companyData['isNotifyBillingPartnerDetail'];
        this.selectedTaskEmailAlert['isNotifyStaffAssigned'] =
          this.companyData['isNotifyStaffAssignedDetail'];
      } else {
        this.selectedTaskEmailAlert.OverrideFirmDefaults = true;
      }
    });
  }

  saveProjectEmailAlerts() {
    const formObj = this.projectEditForm.value;
    let tempObj = {};
    if (!this.projectEmailAlerts['OverrideFirmDefaults']) {
      tempObj['DaysBeforePastDueDate'] =
        tempObj['ProjectDaysToRemind'] =
        tempObj['DaysBeforePastDueDateBool'] =
        tempObj['PastDue'] =
        tempObj['isNotifyManager'] =
        tempObj['isNotifyBillingPartner'] =
          null;
    } else {
      tempObj = this.projectEmailAlerts;
    }
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateProjectHeader(formObj.ProjectHeaderID, tempObj).subscribe(result => {
      const logdata = {};
      logdata['Action'] = 'Update Project Email Alerts';
      logdata['Description'] =
        formObj.ProjectHeaderID +
        ' - ' +
        formObj.TemplateName +
        ` --${this.selClient['ClientName']}`;
      logdata['Table'] = '';
      if (!this.isManaging) {
        this.mangoAPISrvc.addUserLogs(logdata).subscribe(
          res => {},
          err => {
            console.log(err);
          }
        );
      }

      this.mangoAPISrvc.showLoader(false);
      this.showProjectEmailAlerts = false;
      this.projectEmailAlerts['IsChanged'] = false;
      this.mangoAPISrvc.notify(
        'success',
        this.translate.instant('updated'),
        AppConstants.updateMsg
      );
    });
  }

  openProjectEmailAlerts() {
    const enableProjectEmailAlerts =
      this.companyData['DaysBeforePastDueDateHeader'] != null ||
      this.companyData['PastDueHeader'] ||
      this.companyData['isNotifyManagerHeader'] ||
      this.companyData['isNotifyBillingPartnerHeader'];
    if (!enableProjectEmailAlerts) {
      Swal.fire({
        title: this.translate.instant('warning'),
        html: this.translate.instant('alerts.email_alerts_not_set'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('Go to Project Settings'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          this.router.navigate(['/project-management/settings']);
        }
      });
      return;
    }
    this.showProjectEmailAlerts = true;
    if (this.selectedProjectDetails) {
      if (this.projectEmailAlerts['OverrideFirmDefaults'] == null) {
        this.projectEmailAlerts['OverrideFirmDefaults'] =
          this.selectedProjectDetails['DaysBeforePastDueDate'] ||
          this.selectedProjectDetails['ProjectDaysToRemind'] ||
          this.selectedProjectDetails['PastDue'] ||
          this.selectedProjectDetails['isNotifyManager'] ||
          this.selectedProjectDetails['isNotifyBillingPartner'];
      }
      if (this.projectEmailAlerts['OverrideFirmDefaults']) {
        // this.projectEmailAlerts['DaysBeforePastDueDate'] = this.selectedProjectDetails['DaysBeforePastDueDate'],
        // this.projectEmailAlerts['DaysBeforePastDueDateBool'] = this.selectedProjectDetails['DaysBeforePastDueDate'] != null,
        // this.projectEmailAlerts['ProjectDaysToRemind'] = this.selectedProjectDetails['ProjectDaysToRemind'],
        // this.projectEmailAlerts['PastDue'] = this.selectedProjectDetails['PastDue'];
        // this.projectEmailAlerts['isNotifyManager'] = this.selectedProjectDetails['isNotifyManager'];
        // this.projectEmailAlerts['isNotifyBillingPartner'] = this.selectedProjectDetails['isNotifyBillingPartner'];
      } else {
        (this.projectEmailAlerts['DaysBeforePastDueDate'] =
          this.companyData['DaysBeforePastDueDateHeader']),
          (this.projectEmailAlerts['DaysBeforePastDueDateBool'] =
            this.companyData['DaysBeforePastDueDateHeader'] != null),
          (this.projectEmailAlerts['ProjectDaysToRemind'] =
            this.companyData['ProjectDaysToRemind']),
          (this.projectEmailAlerts['PastDue'] = this.companyData['PastDueHeader']);
        this.projectEmailAlerts['isNotifyManager'] = this.companyData['isNotifyManagerHeader'];
        this.projectEmailAlerts['isNotifyBillingPartner'] =
          this.companyData['isNotifyBillingPartnerHeader'];
      }
    }
  }

  openTaskEmailAlert(rowData) {
    const enableTaskEmailAlerts =
      this.companyData['DaysBeforePastDueDateDetail'] != null ||
      this.companyData['PastDueDetail'] ||
      this.companyData['isNotifyManagerDetail'] ||
      this.companyData['isNotifyBillingPartnerDetail'] ||
      this.companyData['isNotifyStaffAssignedDetail'];
    if (!enableTaskEmailAlerts) {
      Swal.fire({
        title: this.translate.instant('warning'),
        html: this.translate.instant('alerts.email_alerts_not_set'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('Go to Project Settings'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          this.router.navigate(['/project-management/settings']);
        }
      });
      return;
    }
    this.showTaskEmailAlert = true;
    this.selectedTaskEmailAlert = rowData;
    if (this.selectedTaskEmailAlert['OverrideFirmDefaults'] == null) {
      this.selectedTaskEmailAlert['OverrideFirmDefaults'] =
        this.selectedTaskEmailAlert['DaysBeforePastDueDate'] ||
        this.selectedTaskEmailAlert['TasksDaysToRemind'] ||
        this.selectedTaskEmailAlert['PastDue'] ||
        this.selectedTaskEmailAlert['isNotifyManager'] ||
        this.selectedTaskEmailAlert['isNotifyBillingPartner'] ||
        this.selectedTaskEmailAlert['isNotifyStaffAssigned'] ||
        false;
      this.selectedTaskEmailAlert['OverrideFirmDefaults'] = Boolean(
        JSON.parse(this.selectedTaskEmailAlert['OverrideFirmDefaults'])
      );
    }
    if (this.selectedTaskEmailAlert['OverrideFirmDefaults']) {
      this.selectedTaskEmailAlert['DaysBeforePastDueDateBool'] =
        this.selectedTaskEmailAlert['DaysBeforePastDueDate'] != null;
    } else {
      (this.selectedTaskEmailAlert['DaysBeforePastDueDate'] =
        this.companyData['DaysBeforePastDueDateDetail']),
        (this.selectedTaskEmailAlert['DaysBeforePastDueDateBool'] =
          this.companyData['DaysBeforePastDueDateDetail'] != null),
        (this.selectedTaskEmailAlert['TasksDaysToRemind'] = this.companyData['TasksDaysToRemind']),
        (this.selectedTaskEmailAlert['PastDue'] = this.companyData['PastDueDetail']);
      this.selectedTaskEmailAlert['isNotifyManager'] = this.companyData['isNotifyManagerDetail'];
      this.selectedTaskEmailAlert['isNotifyBillingPartner'] =
        this.companyData['isNotifyBillingPartnerDetail'];
      this.selectedTaskEmailAlert['isNotifyStaffAssigned'] =
        this.companyData['isNotifyStaffAssignedDetail'];
    }
  }

  ngOnInit() {
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.searchTextStr = this.encrDecSrvc.getObject('projTemplate_' + AppConstants.SearchString);

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
    this.getShortCutLabels();
    this.emailTemplate['to'] = this.selClient['Email'] ? this.selClient['Email'] : '';
    this.emailTemplate['from'] = this.encrDecSrvc.getObject(AppConstants.userEmail);
    this.emailTemplate['subject'] = this.selClient['ClientName']
      ? this.selClient['ClientName'] + ' Note'
      : '';
    this.getEmailSignature();

    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);

    this.mangoAPISrvc.fetchDDMSettings(this.companyId).subscribe((dataSettings: any) => {
      this.showCapacityPlanning =
        dataSettings.length > 0 ? dataSettings[0].isCapacityPlanningEnabled : false;
    });
  }

  getEngagementsByCompanyID(companyID, engagementID) {
    this.engagementList = [];
    this.mangoAPISrvc.getEngagementTypes(companyID).subscribe((result: any) => {
      for (let i = 0; i < result.length; i++) {
        if (!result[i].Inactive) {
          this.engagementListItems.push({
            label: result[i].Description,
            value: result[i].EngagementTypeID,
          });
        }
      }
      this.engagementListItems.forEach((en => {
        if( en.value == engagementID) {
          console.log( en)
          this.projectEditForm.controls["EngagementTypeID"].setValue(
            en.value
          );
        }
      }));
    });
  }

  getEngagements(itemData, isClientChanged?: boolean) {
    this.engagementListItems = [];

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getProjectsByClientId(itemData.ClientID).subscribe(
      (data: any) => {
        this.mangoAPISrvc.showLoader(false);

        this.engagementListItems = data
          .filter(item => item.Inactive == false)
          .map(item => ({
            label: item.EngagementName,
            value: item.ProjectMasterID
          }));

        if (this.engagementListItems.length === 0) {
          Swal.fire({
            title: this.translate.instant('Client_Engagements_Required'),
            html: this.translate.instant('billing.there_are_no_engagements_setup'),
            icon: 'warning',
            showCancelButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: this.translate.instant('Go_to_Client'),
            cancelButtonText: this.translate.instant('no_cancel')
          }).then(result => {
            if (result.value) {
              this.encrDecSrvc.addObject(
                AppConstants.selectedClientRecord,
                this.clientListDatasource.filter(
                  client => client.ClientID === itemData['ClientID']
                )[0]
              );

              this.encrDecSrvc.addObject(AppConstants.clientID, itemData['ClientID']);
              this.encrDecSrvc.addObject(AppConstants.ClientName, itemData['ClientName']);
              this.mangoAPISrvc.showLoader(true);
              this.mangoAPISrvc.getAllDataCounts(itemData['ClientID']).subscribe(data => {
                this.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
                this.mangoAPISrvc.fireClientView(true);
                this.router.navigate([
                  AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath
                ]);
              });
            } else return;
          });
        }

        if (this.engagementListItems.length == 1 && isClientChanged) {
          this.projectEditForm.controls['ProjectMasterID'].setValue(
            this.engagementListItems[0].value
          );
          this.isEngagementNotSaved = true;
          this.isEngagementChanged = true;
        }
      },
      () => {
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject('projTemplate_' + AppConstants.SearchString, '');
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      'projTemplate_' + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  onScratchpadChange() {
    setTimeout(() => {
      this.tasksDataSource = this.tasksDataSource.map(task => {
        if (task.TaskRow === this.currentTaskRow) {
          if (task.TaskMemo != this.editorValue) {
            task.TaskMemo = this.editorValue;
            this.projectEditForm.markAsDirty();
            this.validateForm(true);
          }
        }
        return task;
      });
    }, 1);
  }

  onShowScratchpad(data) {
    this.currentTaskRow = data.TaskRow;
    this.editorValue = data.TaskMemo;
    this.scratchPadEnabled = !this.scratchPadEnabled;
  }

  /* Start - For Fetching Clients */
  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientListDatasource.length; i++) {
        const client = this.clientListDatasource[i];
        if (
          client['ClientName'].toLowerCase().indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] == false
        ) {
          filtered.push(client);
        } else if (
          client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] != true
        ) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientListDatasource.length == 0 || this.clientListDatasource.length !== list.length) {
      this.clientListDatasource = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientListDatasource.push(item);
        if (this.selClient.ClientID == item.ClientID) {
          this.selClient = item;
        }
      }
    } else {
      clearInterval(this.intervalid);
    }
  }
  /* End - For Fetching Clients */

  ngAfterViewInit(): void {
    this.createDummyPDFInstance('../../../../assets/dummyPDF.pdf');
    this.projectEditForm.valueChanges.subscribe(data => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.projectEditForm.pristine);
      this.validateForm();
    });
    this.searchValue?.nativeElement.focus();
  }

  ngOnDestroy() {
    this.sharedService.leaveAndSaveSub.unsubscribe();
    this.encrDecSrvc.removeObject(AppConstants.ddmProjectDetails);
    this.encrDecSrvc.removeObject(AppConstants.ddmClientName);
  }

  handleDropSelectClick(event: any, data: any) {
    if (event.value) {
      data['UserAssignedIDArray'] = event.value;
      this.tasksDataSource.map(item => {
        if (item.TaskRow === data.TaskRow) {
          item.Edited = true;
          item.AssignedChanged = true;
        }
      });
    }

    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.getStaffNames(event.value, data);
    this.validateForm(true);
  }

  getStaffName(staffID) {
    if (!staffID) return '';
    const allList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
    const labels = allList
      ?.filter(item => staffID === item['StaffID'])
      .map(item => item['StaffName']);

    if (labels.length > 0) {
      return labels[0];
    }
    return '';
  }

  getStaffNames(ids, data) {
    if (!ids || ids.length == 0) {
      data['StaffNames'] = 'Unassigned';
      return;
    }
    const allList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
    const labels = allList
      ?.filter(item => ids.includes(item['StaffID']))
      .map(item => (item.Inactive ? item['StaffName'] + ' (Inactive User)' : item['StaffName']));

    if (labels.length == 0) {
      // data['UserAssignedIDArray'] = null;
      data['StaffNames'] = 'Unassigned';
      return;
    }
    data['StaffNames'] = labels.join(', ');
  }

  onRowChange(event) {
    this.validateForm(true);
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.projectEditForm.markAsDirty();
    const completedTasks = this.tasksDataSource.filter(x => x.IsCompleted == true);
    if (this.projectEditForm.controls['IsCompleteInOrder'].value && completedTasks.length > 0) {
      this.tasksDataSource.sort((a, b) => a['TaskRow'] - b['TaskRow']);
      Swal.fire({
        title: this.translate.instant('Warning'),
        text: this.translate.instant('pm.manipulate_task'),
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: this.translate.instant('Ok'),
        timer: 6000
      });
    } else {
      this.tasksDataSource.forEach((task, index) => {
        task['TaskRow'] = index + 1;
      });

      this.computeDueDate(1, 0);
      this.saveAllTasks();
    }
  }

  saveFileName() {
    const parent = this;
    parent.selectedObj['FName'] = parent.fileName;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateDMSParentFolder(parent.selectedObj).subscribe(response => {
      parent.isShowfileName = false;
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify(
        'success',
        parent.translate.instant('Success'),
        AppConstants.updateMsg
      );
    });
  }

  async createDummyPDFInstance(docUrl) {
    const self = this;
    if (self.dummyPDFInstance) {
      self.dummyPDFInstance.loadDocument(docUrl).then(instance => {
        const docViewer = instance.docViewer;
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%');
        });
      });
    } else {
      WebViewer(
        {
          licenseKey: `Mango Billing, Inc.(mangobilling.com):OEM:Mango Billing - Document Management Module::B+:AMS(20210804):F8B577020427760AB360B13AC982737860614F3EB748F59ADD8FB49A142CA9D65AC2BEF5C7`,
          path: '../../../../wv-resources/lib',
          initialDoc: docUrl
        },
        self.dummyViewer.nativeElement
      ).then(instance => {
        self.dummyPDFInstance = instance;
        self.dummyPDFInstance.disableElements([
          'leftPanel',
          'leftPanelButton',
          'panToolButton',
          'toolsButton',
          'signatureToolButton',
          'freeHandToolGroupButton',
          ,
          'signatureTool',
          'freeTextToolButton',
          'eraserToolButton',
          'shapeToolGroupButton',
          'textToolGroupButton',
          'miscToolGroupButton',
          'stickyToolButton'
        ]);
        const docViewer = instance.docViewer;
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%');
        });
      });
    }
  }

  validateEmailForm() {
    this.isUserSettingsTouched = true;

    const dataValues = Object.keys(this.emailTemplate);

    for (let index = 0; index < dataValues.length; index++) {
      const key = dataValues[index];

      if (!this.emailTemplate[key] || !this.emailEditorValue) {
        this.isUserSettingsTouched = false;

        break;
      }
    }
  }

  batchCreateAllTasks(dataList, rowObj) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    const repeat = parent.projectEditForm.controls['Repeat'].value;
    dataList.forEach((selectedItem, key) => {
      if (selectedItem['IsRepeatTask']) {
        selectedItem['DueDate'] = selectedItem['DueDate']
          ? parent.calculateTaskRepeatData(selectedItem['DueDate'], repeat)
          : parent.calculateTaskRepeatData(moment().format('DD/MM/YYYY HH:mm:ss'), repeat);
      } else {
        selectedItem['DueDate'] = null;
      }

      if (selectedItem?.TaskRow !== 1) selectedItem['IsTaskReady'] = false;
      else selectedItem['IsTaskReady'] = true;

      selectedItem['IsCompleted'] = false;
      selectedItem['dueDateOffsetDaysIndicator'] = 1;
      selectedItem['IsRepeatTask'] = false;
      selectedItem['CompletionDate'] = null;
      selectedItem['CompanyID'] = rowObj.CompanyID;
      selectedItem['ClientID'] = rowObj.ClientID;
      selectedItem['ProjectHeaderID'] = rowObj.ProjectHeaderID;
      // selectedItem["DueDate"] = null;
      delete selectedItem.ProjectDetailsID;
      observableBatch.push(
        parent.http.post(`${environment.API_URL}/ddm/projectDetails/create`, selectedItem, {
          headers: headers,
          withCredentials: true
        })
      );
    });
    return forkJoin(observableBatch);
  }

  calculateTaskRepeatData(dueDate, repeat) {
    let newDate = null;
    if (repeat != 'none') {
      if (repeat == 'year') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'years').utc().toDate();
      } else if (repeat == 'quarterly') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(3, 'months').utc().toDate();
      } else if (repeat == 'monthly') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'months').utc().toDate();
      } else if (repeat == 'onetime') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'days').utc().toDate();
      } else if (repeat == 'week') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(7, 'days').toDate();
      } else if (repeat == 'semi-weekly') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(14, 'days').toDate();
      } else if (repeat == 'semi-monthly') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(15, 'days').utc().toDate();
      } else if (repeat == 'daily') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'days').toDate();
      }
    }

    return newDate;
  }

  calculateNewRepeatData(formData) {
    let newDate = null;
    formData['DueDate'] = formData.NextDueDate;
    formData['DueDate'] = moment(formData['DueDate']).startOf('day').toDate();
    formData['ActualDueDate'] = formData['DueDate'];
    if (formData.Repeat != 'none') {
      if (formData.Repeat == 'year') {
        newDate = moment(formData['DueDate'], 'DD-MM-YYYY').add(1, 'years').utc().toDate();
      } else if (formData.Repeat == 'quarterly') {
        newDate = moment(formData['DueDate'], 'DD-MM-YYYY').add(3, 'months').utc().toDate();
      } else if (formData.Repeat == 'monthly') {
        newDate = moment(formData['DueDate'], 'DD-MM-YYYY').add(1, 'months').utc().toDate();
      } else if (formData.Repeat == 'onetime') {
        newDate = moment(formData['DueDate'], 'DD-MM-YYYY').add(1, 'days').utc().toDate();
      } else if (formData.Repeat == 'week') {
        newDate = moment(formData['DueDate'], 'DD-MM-YYYY').add(7, 'days').toDate();
      } else if (formData.Repeat == 'semi-weekly') {
        newDate = moment(formData['DueDate'], 'DD-MM-YYYY').add(14, 'days').toDate();
      } else if (formData.Repeat == 'semi-monthly') {
        newDate = moment(formData['DueDate'], 'DD-MM-YYYY').add(15, 'days').utc().toDate();
      } else if (formData.Repeat == 'daily') {
        newDate = moment(formData['DueDate'], 'DD-MM-YYYY').add(1, 'days').toDate();
      } else if (formData.Repeat == 'custom') {
        this.genarateRules(formData['DueDate'], true);
        newDate = this.ruleTwodateSource ? new Date(this.ruleTwodateSource) : null;
      }
    }

    formData['NextDueDate'] = newDate;
    formData['CompletionDate'] = null;
    formData['ExtensionDate'] = null;
    formData['DateReceived'] = null;
    formData['isCompleted'] = false;
    formData['dueDateOffsetDaysIndicator'] = 1;
    formData['Status'] = 'Pending';
    formData['Tags'] = null;
    formData['isNewCreated'] = false;
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  toggleView() {
    this.isListView = !this.isListView;
    /*  setTimeout(()=>{
       $(`div[class ^= 'p-timeline-event-opposite']`).remove();
     }, 1000); */
  }

  getfetchprojectsNotes() {
    if (this.projectEditForm.value.ProjectHeaderID) {
      const parent = this;
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.fetchprojectsNotes(this.projectEditForm.value.ProjectHeaderID).subscribe(
        (data: any) => {
          data.forEach(item => {
            const selStaff = this.allStaffDetails.filter(staff => staff.StaffID === item.StaffID);
            if (selStaff.length > 0) {
              item.StaffImage = selStaff[0].ProfilePic;
            }
            item.NoteEdited = item.Note;
            item.color = this.getRandomColor();
          });
          parent.notesDataSource = data;
          parent.updateMoreOptions();
          /*  setTimeout(()=>{
               $(`div[class ^= 'p-timeline-event-opposite']`).remove();
             }, 1000); */

          parent.mangoAPISrvc
            .updateProjectHeader(parent.projectEditForm.value.ProjectHeaderID, {
              TotalNotes: data.length
            })
            .subscribe(
              data => {
                parent.mangoAPISrvc.showLoader(false);
              },
              err => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
        },
        err => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  updateChangedNotes() {
    const observableBatch = [];
    this.notesDataSource.forEach(item => {
      if (item.Note != item.NoteEdited) {
        this.projectEditForm.markAsDirty();
        item.Note = item.NoteEdited;
        observableBatch.push(this.mangoAPISrvc.updateProjectNote(item.ProjectNotesID, item));
      }
    });
    return forkJoin(observableBatch);
  }

  saveNote(item) {
    item.isEdit = false;
    if (item.Note != item.NoteEdited) {
      this.projectEditForm.markAsDirty();
    }
  }

  calculateRepeatData(formData) {
    const parent = this;
    //var formData = parent.projectEditForm.value;
    let newDate = null;
    formData['DueDate'] = moment(formData['DueDate']).startOf('day').toDate();
    if (formData.Repeat != 'none') {
      if (formData.Repeat == 'year') {
        newDate = moment(formData['DueDate']).add(1, 'years').utc().toDate();
      } else if (formData.Repeat == 'quarterly') {
        newDate = moment(formData['DueDate']).add(3, 'months').utc().toDate();
      } else if (formData.Repeat == 'monthly') {
        newDate = moment(formData['DueDate']).add(1, 'months').utc().toDate();
      } else if (formData.Repeat == 'onetime') {
        newDate = moment(formData['DueDate']).add(1, 'days').utc().toDate();
      } else if (formData.Repeat == 'week') {
        newDate = moment(formData['DueDate']).add(7, 'days').toDate();
      } else if (formData.Repeat == 'daily') {
        newDate = moment(formData['DueDate']).add(1, 'days').toDate();
      } else if (formData.Repeat == 'semi-weekly') {
        newDate = moment(formData['DueDate']).add(14, 'days').toDate();
      } else if (formData.Repeat == 'semi-monthly') {
        newDate = moment(formData['DueDate']).add(15, 'days').utc().toDate();
      }
    }
    parent.projectEditForm.controls['NextDueDate'].setValue(newDate);
    formData['NextDueDate'] = newDate;
  }

  createProjectHeader() {
    this.mangoAPISrvc.showLoader(true);

    this.isFormValid = false;
    const parent = this;
    const formObj = parent.projectEditForm.value;
    formObj['PreviousDueDate'] = formObj['DueDate'];
    formObj['Tags'] = null;
    formObj['isCompleted'] = false;
    formObj['CompletionDate'] = null;
    formObj['ExtensionDate'] = null;
    formObj['TurnAround'] = null;
    formObj['DateReceived'] = null;
    formObj['FinishDate'] = null;
    formObj['automaticComputeDuedate'] = false;
    formObj['Status'] = 'Pending';
    formObj['IsProject'] = 'P';
    formObj['NextDueDateForTemplate'] = formObj['NextDueDate'];
    formObj['DueDate'] = formObj.NextDueDate;
    //Note: Needs to update the form after completing the current if has repeat
    parent.projectEditForm.controls['DueDate'].setValue(formObj.NextDueDate);

    if (
      formObj.Repeat !== null &&
      formObj.Repeat !== '' &&
      formObj.Repeat !== 'none' &&
      formObj.Repeat !== 'custom'
    ) {
      if (formObj['isPreviousPeriodYearPolicy']) {
        formObj['TemplateName'] = formObj['TemplateWildcards']
          ? parent.mangoUtils.replacePMCaretTemplate(
              formObj['TemplateWildcards'],
              formObj.DueDate,
              formObj.isPreviousPeriodYearPolicy,
              formObj.Repeat
            )
          : formObj['TemplateName'];
      } else {
        formObj['TemplateName'] =
          formObj['TemplateWildcards'] && formObj.NextDueDateForTemplate
            ? parent.mangoUtils.replacePMCaretTemplate(
                formObj['TemplateWildcards'],
                formObj.NextDueDateForTemplate
              )
            : formObj['TemplateName'];
      }
    }

    formObj['ActualDueDate'] = formObj['DueDate'];

    //Note: Needs to update the form after completing the current if has repeat
    parent.projectEditForm.controls['TemplateName'].setValue(formObj.TemplateName);
    parent.projectEditForm.controls['ActualDueDate'].setValue(formObj.NextDueDate);
    parent.projectEditForm.controls['Tags'].setValue(null);
    parent.projectEditForm.controls['isCompleted'].setValue(false);
    parent.projectEditForm.controls['CompletionDate'].setValue(null);
    parent.projectEditForm.controls['DateReceived'].setValue(null);
    parent.projectEditForm.controls['ExtensionDate'].setValue(null);
    parent.projectEditForm.controls['TurnAround'].setValue(null);
    parent.projectEditForm.controls['FinishDate'].setValue(null);
    // Should clear tags upon creating new projects

    const valueStr =
      parent.selectedProjectDetails?.Tags && parent.selectedProjectDetails?.Tags.length > 0
        ? parent.selectedProjectDetails?.Tags
        : null;
    if (!valueStr) {
      parent.projectEditForm.controls['Tags'].setValue(null);
    }

    const total = parent.selectedProjectDetails?.Tags.length;
    if (parent.selectedProjectDetails?.Tags && total > 0) {
      for (let i = 0; i <= total; i++) {
        parent.deleteTag(i);
      }
    }
    formObj['IsProject'] = 'P';
    formObj['Status'] = 'Pending';
    parent.projectEditForm.controls['Status'].setValue('Pending');
    if (
      this.projectEditForm.get('Repeat').value != null &&
      this.projectEditForm.get('Repeat').value == 'custom'
    ) {
      formObj['RuleString'] = this.currentRuleStr;
      parent.calculateNewRepeatData(formObj);

      if (!formObj['NextDueDate'] || !formObj['DueDate']) {
        formObj['NextDueDate'] = null;
        formObj['Repeat'] = null;
        formObj['RuleString'] = null;
      }
    } else {
      formObj['RuleString'] = null;
      parent.calculateRepeatData(formObj);
    }
    //Note: Needs to update the form after completing the current if has repeat
    parent.projectEditForm.controls['NextDueDate'].setValue(formObj.NextDueDate);

    const oldProjectHeaderID = parent.projectEditForm.value.ProjectHeaderID;
    formObj['ExtensionDate'] = null;
    // delete parent.projectEditForm.value.ProjectHeaderID;
    // delete formObj.ProjectHeaderID;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.createProjectHeader(formObj).subscribe(
      (data: any) => {
        this.newProjectHeaderID = data.data['ProjectHeaderID'];

          parent.statusText = data.data['Status'];
          parent.statusClass = data.data['Status'].toLowerCase();
          parent.totalProcessLen = 0;
          parent.projectEditForm.controls['ProjectHeaderID'].setValue(data.data['ProjectHeaderID']);

        for (let index = 0; index < parent.tasksDataSource.length; index++) {
          const element = parent.tasksDataSource[index];
          element['UserAssignedID'] = element.UserAssigned
            ? element.UserAssigned
            : element['UserAssignedID'];
          element['CompanyID'] = parent.projectEditForm.value.CompanyID;
          element['ProjectHeaderID'] = this.newProjectHeaderID;
          element['ClientID'] = parent.projectEditForm.value.ClientID;
          element['IsNewRecord'] = true;
          element['IsCompleted'] = false;
          element['CompletionDate'] = null;
          if (!element['IsRepeatTask']) element['DueDate'] = null;
        }

        if (
          (!parent.tasksDataSource || parent.tasksDataSource.length == 0) &&
          !parent.isCreateFlow
        ) {
          parent.mangoAPISrvc
            .copyBudgetsToNewProject({
              oldProjectHeaderID: oldProjectHeaderID,
              newProjectHeaderID: data.data['ProjectHeaderID'],
              companyID: parent.companyId,
              newProjectYear: moment(data.data['DueDate']).format('YYYY')
            })
            .subscribe(
              result => {
                parent.mangoAPISrvc.showLoader(false);
              },
              err => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
        }
        parent.batchSaveAllTasks().subscribe(data2 => {
          if (parent.tasksDataSource?.length > 0 && !parent.isCreateFlow) {
            parent.mangoAPISrvc
              .copyBudgetsToNewProject({
                oldProjectHeaderID: oldProjectHeaderID,
                newProjectHeaderID: data.data['ProjectHeaderID'],
                companyID: parent.companyId,
                newProjectYear: moment(data.data['DueDate']).format('YYYY')
              })
              .subscribe(
                result => {
                  parent.statusText = data.data['Status'];
                  parent.mangoAPISrvc.showLoader(false);
                },
                err => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          }
          parent.IsFinishReview = false;
        });
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), data.message);
      },
      err => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), err.message);
      }
    );
  }

  updateSettings() {
    this.isFormValid = false;
    const parent = this;
    const formObj = parent.projectEditForm.value;
    const email = parent.AllManagerTypes.filter(item => item['value'] == formObj['ManagerID'])[0]
      ?.Email;
    const valueStr = formObj['Tags'] && formObj['Tags'].length > 0 ? formObj['Tags'] : null;
    if (!valueStr) {
      parent.projectEditForm.controls['Tags'].setValue(null);
    }
    formObj['IsProject'] = 'P';
    if (formObj['Status'] == 'Completed') {
      parent.projectEditForm.controls['isCompleted'].setValue(true);
      parent.projectEditForm.controls['CompletionDate'].setValue(
        moment().format('MM/DD/YYYY HH:mm:ss')
      );
    }

    if (
      this.projectEditForm.get('Repeat').value != null &&
      this.projectEditForm.get('Repeat').value == 'custom'
    ) {
      formObj['RuleString'] = this.currentRuleStr;
    } else {
      formObj['RuleString'] = null;
      parent.calculateRepeatData(formObj);
    }

    if (this.isCreateFlow) {
      delete parent.projectEditForm.value.ProjectHeaderID;
      parent.projectEditForm.value.ClientID = parent.projectEditForm.value.ClientID
        ? parent.projectEditForm.value.ClientID
        : (parent.selClient?.ClientID ?? null);

      this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);

      if (formObj['Status'] == 'Completed') {
        parent.projectEditForm.controls['isNewCreated'].setValue(true);
      }
      var completedRecords = parent.tasksDataSource.filter(item => item['IsCompleted'] === true);
      formObj['TasksCompleted'] = completedRecords.length;
      formObj['TotalNotes'] = parent.notesDataSource.length;
      formObj['TotalTasks'] = parent.tasksDataSource.length;
      formObj['ClientID'] =
        parent.projectEditForm.value.ClientID ?? parent.selClient.ClientID ?? null;
      //parent.mangoAPISrvc.showLoader(true);
      formObj.ActualDueDate = formObj.FinishDate || formObj.ExtensionDate || formObj.DueDate;
      if (
        parent.projectEditForm.get('Repeat').value != null &&
        parent.projectEditForm.get('Repeat').value == 'custom'
      ) {
        formObj['RuleString'] = parent.currentRuleStr;
      } else {
        formObj['RuleString'] = null;
      }
      // parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.createProjectHeader(formObj).subscribe(
        (data: any) => {
          data.data['Tags'] = data.data['Tags'] ? data.data['Tags'] : [];
          parent.encrDecSrvc.addObject('ddmProjectDetails', JSON.stringify(data.data));
          parent.selectedProjectDetails = data.data;
          parent.projectEditForm.controls['ProjectHeaderID'].setValue(data.data['ProjectHeaderID']);

          for (let index = 0; index < parent.tasksDataSource.length; index++) {
            const element = parent.tasksDataSource[index];
            element['UserAssignedID'] = element.UserAssigned
              ? element.UserAssigned
              : element['UserAssignedID'];
            element['CompanyID'] = parent.projectEditForm.value.CompanyID;
            element['ProjectHeaderID'] = parent.projectEditForm.value.ProjectHeaderID;
            element['ClientID'] = parent.projectEditForm.value.ClientID;
            element['IsNewRecord'] = true;
            element['isEditRecord'] = true;
          }
          setTimeout(() => {
            if (formObj['Status'] == 'Completed') {
              parent.createAllProjectDetails(parent.tasksDataSource);
            }
            parent.isCreateFlow = parent.tasksDataSource.length == 0 ? false : parent.isCreateFlow;
            this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
            parent.isValidForm = false;
            parent.validateForm(false);
            parent.projectEditForm.controls['IsAssignedSent'].setValue(true);

            parent.emailSendToUsers(formObj);
            this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
            parent.mangoAPISrvc.showLoader(false);
          }, 1000);
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), data.message);
        },
        err => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), err.message);
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      let isNewRecord = false;
      const formObj = parent.projectEditForm.value;
      formObj['ClientID'] =
        formObj['ClientID'] != '' ? formObj['ClientID'] : (parent.selClient?.ClientID ?? null);
      formObj['IsProject'] = 'P';

      if (
        parent.projectEditForm.get('Repeat').value != null &&
        parent.projectEditForm.get('Repeat').value == 'custom'
      ) {
        formObj['RuleString'] = parent.currentRuleStr;
      } else {
        formObj['RuleString'] = null;
      }

      if (formObj['Status'] == 'Completed' && parent.projectEditForm.value.isNewCreated == false) {
        parent.projectEditForm.controls['isNewCreated'].setValue(true);
        isNewRecord = true;
      }
      var completedRecords = parent.tasksDataSource.filter(item => item['IsCompleted'] === true);
      formObj['TasksCompleted'] = completedRecords.length;
      formObj['TotalNotes'] = parent.notesDataSource.length;
      formObj['TotalTasks'] = parent.tasksDataSource.length;
      formObj['TurnAround'] = formObj['TurnAround'] ? formObj['TurnAround'] : null;

      parent.encrDecSrvc.addObject('ddmProjectDetails', JSON.stringify(formObj));
      // parent.mangoAPISrvc.showLoader(true);
      formObj.ActualDueDate = formObj.FinishDate || formObj.ExtensionDate || formObj.DueDate;
      formObj['ProjectMemo'] = formObj['ProjectMemo'] ? formObj['ProjectMemo'] : '';
      formObj['DateReceived'] = moment(formObj['DateReceived']).toDate();
      formObj['isCompleted'] = formObj['Status'] == 'Completed' ? true : false;

      parent.mangoAPISrvc.updateProjectHeader(formObj.ProjectHeaderID, formObj).subscribe(
        (data: any) => {
          parent.emailSendTaskToAssignedUsers();
          if (parent.isEngagementChanged) {
            parent.isEngagementChanged = false;
            parent.mangoAPISrvc
              .updateTimeRecordsByDDMProjectHeaderID(
                {
                  ProjectMasterID: parent.projectEditForm.value.ProjectMasterID,
                  ClientID: parent.projectEditForm.value.ClientID
                },
                parent.projectEditForm.value.ProjectHeaderID,
                parent.companyId
              )
              .subscribe(
                (data: any) => {
                  parent.mangoAPISrvc.notify(
                    'success',
                    parent.translate.instant('Success'),
                    AppConstants.updateMsg
                  );
                },
                err => {
                  parent.mangoAPISrvc.showLoader(false);
                  parent.mangoAPISrvc.notify(
                    'error',
                    parent.translate.instant('Error'),
                    AppConstants.updateErrorMsg
                  );
                }
              );

            parent.mangoAPISrvc
              .updateBudgetsByDDMProjectHeaderID(
                {
                  ProjectMasterID: parent.projectEditForm.value.ProjectMasterID,
                  ClientID: parent.projectEditForm.value.ClientID
                },
                parent.projectEditForm.value.ProjectHeaderID,
                parent.companyId
              )
              .subscribe(
                data => {},
                err => {
                  // parent.mangoAPISrvc.showLoader(false);
                  parent.mangoAPISrvc.notify(
                    'error',
                    parent.translate.instant('Error'),
                    AppConstants.updateErrorMsg
                  );
                }
              );
          }
          const updatedFields = this.getChangedProperties();

          const logdata = {};
          if (updatedFields.length > 0) {
            logdata['Table'] = '';
            logdata['Action'] = 'Update Project #' + formObj.ProjectHeaderID;
            logdata['Description'] =
              'Updated ' + updatedFields.join(', ') + ` --${this.selClient['ClientName']}`;
            if (!this.isManaging) {
              this.mangoAPISrvc.addUserLogs(logdata).subscribe(
                res => {},
                err => {
                  console.log(err);
                }
              );
            }
          }
          // else {
          //   logdata['Action'] = "Update Project Status #" + formObj.ProjectHeaderID;

          //   logdata['Description'] = formObj.Status;
          // }
          this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
          // if (isNewRecord) {
          //   setTimeout(() => {
          //     debugger
          //     parent.createAllProjectDetails(parent.tasksDataSource);
          //   }, 3000);
          // }
          parent.isValidForm = false;
          parent.validateForm(false);
          parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), data.message);

          const manager = parent.AllManagerTypes.filter(
            item => item['value'] == formObj['ManagerID']
          );
          let manageremail = null;
          if (manager.length > 0) {
            manageremail = manager[0]['Email'];
          }

          if (
            parent.statusText == 'Ready for Review' &&
            parent.ddmSettingsObj.IsManagerReadyReview == true &&
            parent.selectedProjectDetails.IsReadyReviewSent == false
          ) {
            parent.selectedProjectDetails['IsReadyReviewSent'] = true;
            parent.projectEditForm.controls['IsReadyReviewSent'].setValue(true);
            parent.sendAnEmail(manageremail, 37, false);
          } else if (
            parent.statusText == 'In Progress' &&
            parent.ddmSettingsObj.IsManagerProjectInProgress == true &&
            parent.selectedProjectDetails.IsInProgressSent == false
          ) {
            parent.selectedProjectDetails['IsInProgressSent'] = true;
            parent.projectEditForm.controls['IsInProgressSent'].setValue(true);
            parent.sendAnEmail(manageremail, 35, false);
          }
          // parent.mangoAPISrvc.showLoader(false);
        },
        err => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), err.message);
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  emailSendToUsers(formObj) {
    const parent = this;

    const email = parent.AllManagerTypes.filter(item => item['value'] === formObj['ManagerID'])[0]
      ?.Email;

    if (email && parent.ddmSettingsObj.IsEmailProjectAssigned === true) {
      parent.sendAnEmail(email, 11, true);
    }

    if (parent.ddmSettingsObj.IsEmailTaskAssigned === true) {
      if (parent.tasksDataSource.length > 0) {
        parent.tasksDataSource
          .filter(task => task.IsCompleted !== true)
          .forEach(task => {
            if ((task.IsTaskReady && formObj.IsCompleteInOrder) || !formObj.IsCompleteInOrder) {
              const emails = this.AllManagerTypes.filter(item =>
                task['UserAssignedIDArray']?.includes(item['value'])
              )?.map(item => item['Email']);

              this.sendAnEmail(emails, 36, false, false, null, task, true);
            }
          });
      }
    }
  }

  emailSendTaskToAssignedUsers() {
    if (this.ddmSettingsObj.IsEmailTaskAssigned == true) {
      if (this.tasksDataSource.length > 0) {
        this.tasksDataSource
          .filter(t => t.IsCompleted != true)
          .forEach(task => {
            if (task?.AssignedChanged) {
              const emails = this.AllManagerTypes.filter(item =>
                task['UserAssignedIDArray']?.includes(item['value'])
              )?.map(item => item['Email']);
              this.sendAnEmail(emails, 67, false, false, null, null, true);
            }
          });
      }
    }
  }

  deleteTag(tag) {
    const index = this.selectedProjectDetails.Tags.indexOf(tag);
    this.selectedProjectDetails.Tags.splice(index, 1);
    if (this.projectEditForm.value.Tags && this.projectEditForm.value.Tags.length > 0) {
      this.projectEditForm.controls['Tags'].setValue(this.selectedProjectDetails.Tags);
    } else {
      this.projectEditForm.value.Tags = [];
    }

    this.isFormValid = true;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);

    if (tag == 2) {
      this.projectEditForm.controls['ExtensionDate'].setValue(null);
    }
    this.projectEditForm.markAsDirty();
    this.validateForm(true);
  }

  handleSelectClick(event) {
    this.selClient = event;

    this.emailTemplate['to'] = this.selClient['Email'] ? this.selClient['Email'] : '';

    this.emailTemplate['subject'] = this.selClient['ClientName']
      ? this.selClient['ClientName'] + ' Note'
      : '';

    this.projectEditForm.controls['ProjectMasterID'].setValue(null);

    this.previousEngagement = null;

    this.projectEditForm.controls['ClientID'].setValue(event['ClientID']);

    this.isEngagementChanged = true;

    this.getEngagements(event, true);

    this.emailSendToUsers(this.projectEditForm.value);

    this.validateForm(true);
  }

  filterProjects(event) {
    const self = this;
    self.filteredProjects = [];
    for (let i = 0; i < self.projectsList.length; i++) {
      const brand = self.projectsList[i];
      if (brand.label.toLowerCase().indexOf(event.query.toLowerCase()) == 0 || event.query == '') {
        self.filteredProjects.push(brand);
      }
    }
    self.isCreateProject = self.filteredProjects.length > 0 ? false : true;
  }

  createProjectNames() {
    const _this = this;
    if (!_this.selProject) return;

    if (_this.isCreateProject) {
      swal
        .fire({
          title: _this.translate.instant('confirmation'),
          text: _this.translate.instant('create_new_project_name'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: _this.translate.instant('yes_continue'),
          cancelButtonText: _this.translate.instant('no_cancel')
        })
        .then(result => {
          if (result.value) {
            const obj = {};
            obj['TemplateName'] = _this.selProject;
            obj['CompanyID'] = _this.companyId;
            _this.mangoAPISrvc.showLoader(true);
            _this.mangoAPISrvc.createProjectNames(obj).subscribe(
              (data: any) => {
                _this.isCreateProject = false;
                _this.getProjectNames(_this.companyId);
                _this.projectEditForm.controls['TemplateName'].setValue(_this.selProject);
                _this.validateForm(true);
                _this.mangoAPISrvc.showLoader(false);
              },
              err => {}
            );
          }
        });
    }
  }

  onIsCompleteInOrderChange(event) {
    const anchorTask = this.tasksDataSource.find(
      (item, index) =>
        !item['IsCompleted'] &&
        this.tasksDataSource[index + 1] &&
        this.tasksDataSource[index + 1]['IsCompleted']
    );
    if (event.checked && anchorTask) {
      Swal.fire({
        title: this.translate.instant('Warning'),
        text: this.translate.instant('Tasks are not in order!'),
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant('Rearrange'),
        denyButtonText: this.translate.instant('Reset'),
        cancelButtonText: this.translate.instant('Cancel')
      }).then(result => {
        if (result.value) {
          this.tasksDataSource.sort(
            (a, b) =>
              new Date(b['CompletionDate']).getTime() - new Date(a['CompletionDate']).getTime() ||
              a['TaskRow'] - b['TaskRow']
          );
          this.tasksDataSource = this.tasksDataSource.map((task, index) => {
            task['TaskRow'] = index + 1;
            return task;
          });
          this.correctTaskReady();
          this.mangoAPISrvc.showLoader(true);
          this.batchSaveAllTasks().subscribe(data => {
            this.mangoAPISrvc.showLoader(true);
            setTimeout(() => {
              this.getProjectDetailsByHeaderId();
            }, 1500);
          });
          // this.continueFunction(item, index)
        } else if (result.isDenied) {
          this.tasksDataSource = this.tasksDataSource.map(task => {
            task['IsCompleted'] = false;
            task['CompletionDate'] = null;
            return task;
          });
          this.correctTaskReady();
          this.mangoAPISrvc.showLoader(true);
          this.batchSaveAllTasks().subscribe(data => {
            setTimeout(() => {
              this.getProjectDetailsByHeaderId();
            }, 1500);
          });
        } else {
          this.projectEditForm.controls['IsCompleteInOrder'].setValue(false);
        }
      });
    } else {
      // if checked, but no problem with order. we must recheck the IsTaskReady
      this.correctTaskReady();
      this.mangoAPISrvc.showLoader(true);
      this.batchSaveAllTasks().subscribe(data => {
        this.mangoAPISrvc.showLoader(true);
        setTimeout(() => {
          this.getProjectDetailsByHeaderId();
        }, 1500);
      });
    }
  }

  onDueDateChange(evt, index) {
    this.tasksDataSource.map(item => {
      if (item.TaskRow === index + 1) {
        item.Edited = true;
        item.DueDateChanged = true;
      }
    });
    this.projectEditForm.markAsDirty();
    if (this.selectedProjectDetails['automaticComputeDuedate']) {
      if (evt) {
        if (!moment(evt, 'MM/DD/YYYY', true).isValid()) this.tasksDataSource[index].DueDate = null;
        // this.tasksDataSource[index].DueDate = moment(evt).toDate();

        //disable selecting of due date lesser than In Date
        if (
          (this.projectEditForm.get('DateReceived').value || index > 0) &&
          this.tasksDataSource[index].DueDate <=
            (index == 0
              ? this.projectEditForm.get('DateReceived').value
              : this.tasksDataSource[index - 1].DueDate)
        ) {
          this.mangoAPISrvc.notify(
            'error',
            this.translate.instant('error'),
            'Unable to select date lesser than previous date.'
          );
          const d =
            index == 0
              ? this.projectEditForm.get('DateReceived').value
              : this.tasksDataSource[index - 1].DueDate;
          this.tasksDataSource[index].DueDate = //new Date(moment(d).add(this.tasksDataSource[index].dueDateOffsetDaysIndicator ?? 1, "days").format("YYYY-MM-DD"));
            moment(
              moment(d)
                .add(this.tasksDataSource[index].dueDateOffsetDaysIndicator ?? 1, 'days')
                .format('YYYY-MM-DD')
            )
              .utc()
              .toDate();
          return;
        }

        // let hasNull = this.tasksDataSource.filter((res) => { return res.DueDate == null });
        // if (index > 0  && hasNull.length > 0) {
        //   let i = index;
        //   while (i != 0) {
        //     let d = this.tasksDataSource[i].DueDate;

        //     if (d && !this.tasksDataSource[i-1].DueDate)
        //     this.tasksDataSource[i-1].DueDate = moment(moment(d).subtract(this.tasksDataSource[i-1].dueDateOffsetDaysIndicator ?? 1, "days").format("YYYY-MM-DD")).utc().toDate();
        //     else {
        //       let a = (index == 0) ? this.projectEditForm.get('DateReceived').value :  this.tasksDataSource[i - 1].DueDate
        //       let b = evt;

        //       let dateDiff = moment(b).diff(a, "days") + 1;
        //       this.tasksDataSource[i].dueDateOffsetDaysIndicator = dateDiff;
        //     }
        //     i--;
        //   }
        //   this.computeDueDate(1, index+1);
        //   // this.saveAllTasks();
        //   return;
        // }

        if (!this.projectEditForm.get('DateReceived').value && index == 0) {
          this.computeDueDate(1, index + 1);
          return;
        } /*else if (!this.projectEditForm.get('DateReceived').value && index > 0) {
          let d = this.tasksDataSource[index].DueDate;
          this.tasksDataSource[index-1].DueDate =new Date(moment(d).subtract(this.tasksDataSource[index-1].dueDateOffsetDaysIndicator ?? 1, "days").format("YYYY-MM-DD"));
        }*/

        const a =
          index == 0
            ? this.projectEditForm.get('DateReceived').value
            : this.tasksDataSource[index - 1].DueDate;
        const b = evt;

        const dateDiff = moment(b).diff(a, 'days');
        this.tasksDataSource[index].dueDateOffsetDaysIndicator = dateDiff;

        this.computeDueDate(1, index);
        // this.saveAllTasks();
      } else {
        if (index == 0 && this.projectEditForm.get('DateReceived').value)
          this.tasksDataSource.map(r => {
            r.DueDate = null;
          });
        else if (index == 0) this.computeDueDate(1, index);
      }
    }
  }

  onOffsetDaysChange(evt, index) {
    if (!this.selectedProjectDetails['automaticComputeDuedate']) return;

    if (evt < 1 && index >= 0) this.tasksDataSource[index].dueDateOffsetDaysIndicator = 1;
    this.computeDueDate(evt, index);
    this.validateForm(true);
    this.projectEditForm.markAsDirty();
    // if (index == null) this.saveAllTasks(); //if in date is changed
  }

  onAutoComputeDueDate(evt) {
    this.projectEditForm.markAsDirty();
    this.selectedProjectDetails['automaticComputeDuedate'] = evt.checked;
    if (evt.checked) {
      this.tasksDataSource.map(r => {
        r.dueDateOffsetDaysIndicator = 1;
        return r;
      });
      this.computeDueDate(evt, 1);
    }
    // this.saveAllTasks();
  }

  computeDueDate(e, index) {
    if (
      e &&
      (e.checked || e > 0) &&
      this.selectedProjectDetails['automaticComputeDuedate'] == true
    ) {
      let inDate = this.projectEditForm.get('DateReceived').value;
      this.mangoAPISrvc.showLoader(true);
      this.tasksDataSource.forEach((e, i, a) => {
        if (i < index) {
          inDate = e.DueDate ? moment(e.DueDate).toDate() : null;
          return;
        }
        if (!inDate) {
          if (!this.tasksDataSource[i]?.DueDate || !this.tasksDataSource[i - 1]?.DueDate) return;
          else inDate = this.tasksDataSource[i - 1]?.DueDate ?? null;
        }

        if (!e.dueDateOffsetDaysIndicator) e.dueDateOffsetDaysIndicator = 1;
        e.DueDate = moment(
          moment(inDate).add(e.dueDateOffsetDaysIndicator, 'days').format('YYYY-MM-DD')
        )
          .utc()
          .toDate();

        inDate = e.DueDate;
      });
      this.mangoAPISrvc.showLoader(false);
    }
  }

  batchSaveAllTasks(isEditedTasksOnly: boolean = false) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    const formData = parent.projectEditForm.value;
    parent.tasksDataSource.map(function (lineObj, index) {
      lineObj['ProjectHeaderID'] = formData.ProjectHeaderID;
      lineObj['TaskRow'] = index + 1;
      lineObj.ClientID = formData.ClientID;
      if (lineObj['Status'] == 'Ready for Review') {
        lineObj['IsCompleted'] = false;
      }
    });

    const createRecords = parent.tasksDataSource.filter(item => item['IsNewRecord']);
    const repeat = parent.projectEditForm.controls['Repeat'].value;
    createRecords.forEach((selectedItem, key) => {
      delete selectedItem['StaffName'];
      delete selectedItem['IsNewRecord'];
      delete selectedItem['ProjectDetailsID'];

      if (parent.IsFinishReview && selectedItem['isReviewTask']) {
        //skip creating tasks for inserted Failed Task(s)
        return;
      }

      if (selectedItem['IsRepeatTask']) {
        selectedItem['DueDate'] = selectedItem['DueDate']
          ? parent.calculateTaskRepeatData(selectedItem['DueDate'], repeat)
          : parent.calculateTaskRepeatData(moment().format('DD/MM/YYYY HH:mm:ss'), repeat);
      } else {
        selectedItem['DueDate'] = null;
      }
      selectedItem['IsRepeatTask'] = false;

      const index = parent.tasksDataSource.findIndex(task => task.TaskRow == selectedItem.TaskRow);
      if (selectedItem?.TaskRow !== 1) {
        if (parent.tasksDataSource[index - 1].IsCompleted != true)
          selectedItem['IsTaskReady'] = false;
      } else selectedItem['IsTaskReady'] = true;
      selectedItem['isTask'] = 'T';
      selectedItem['IsCompleted'] = selectedItem['IsCompleted']
        ? selectedItem['IsCompleted']
        : false;
      selectedItem['ClientID'] = selectedItem['ClientID'] != '' ? selectedItem['ClientID'] : null;
      selectedItem['UserAssignedIDArray'] =
        selectedItem['UserAssignedIDArray']?.length > 0
          ? selectedItem['UserAssignedIDArray']
          : null;
      observableBatch.push(
        parent.http.post(
          `${environment.API_URL}/ddm/projectDetails/create`,
          JSON.parse(JSON.stringify(selectedItem)),
          { headers: headers, withCredentials: true }
        )
      );
    });
    const updateRecords = parent.tasksDataSource.filter(item => !item['IsNewRecord']);
    updateRecords.forEach((selectedItem, key) => {
      delete selectedItem['IsNewRecord'];
      if (isEditedTasksOnly) {
        if (!selectedItem.isUpdated) return;
        selectedItem.isUpdated = false;
      }
      selectedItem['isTask'] = 'T';
      selectedItem['ClientID'] = selectedItem['ClientID'] != '' ? selectedItem['ClientID'] : null;
      selectedItem['DueDate'] = selectedItem['DueDate']
        ? moment(selectedItem['DueDate']).toDate()
        : null;
      if (!selectedItem['OverrideFirmDefaults']) {
        selectedItem['DaysBeforePastDueDate'] =
          selectedItem['TasksDaysToRemind'] =
          selectedItem['PastDue'] =
          selectedItem['isNotifyManager'] =
          selectedItem['isNotifyStaffAssigned'] =
          selectedItem['isNotifyBillingPartner'] =
            null;
      }
      selectedItem['UserAssignedIDArray'] =
        selectedItem['UserAssignedIDArray']?.length > 0
          ? selectedItem['UserAssignedIDArray']
          : null;
      if (selectedItem['ProjectDetailsID']) {
        observableBatch.push(
          parent.http.put(
            `${environment.API_URL}/ddm/projectDetails/update/${selectedItem['ProjectDetailsID']}`,
            selectedItem,
            { headers: headers, withCredentials: true }
          )
        );
      }
    });

    observableBatch.push(
      parent.mangoAPISrvc.updateProjectHeader(parent.projectEditForm.value.ProjectHeaderID, {
        EngagementTypeID: this.projectEditForm.get("EngagementTypeID").value,
        type: true
      })
    );
    return forkJoin(observableBatch);
  }

  createAllProjectDetails(dataList) {
    const parent = this;
    // create project Header and project details
    const formObj = Object.assign({}, parent.projectEditForm.value);
    if (
      formObj.Repeat != null &&
      formObj.Repeat != '' &&
      formObj.Repeat != 'none' &&
      formObj.Status == 'Completed'
    ) {
      dataList = Object.assign([], dataList);
      formObj['PreviousDueDate'] = formObj['DueDate'];
      formObj['NextDueDateForTemplate'] = formObj['NextDueDate'];
      parent.calculateNewRepeatData(formObj);

      if (
        formObj.Repeat !== null &&
        formObj.Repeat !== '' &&
        formObj.Repeat !== 'none' &&
        formObj.Repeat !== 'custom'
      ) {
        if (formObj['isPreviousPeriodYearPolicy']) {
          formObj['TemplateName'] = formObj['TemplateWildcards']
            ? parent.mangoUtils.replacePMCaretTemplate(
                formObj['TemplateWildcards'],
                formObj.DueDate,
                formObj.isPreviousPeriodYearPolicy,
                formObj.Repeat
              )
            : formObj['TemplateName'];
        } else {
          formObj['TemplateName'] =
            formObj['TemplateWildcards'] && formObj.NextDueDateForTemplate
              ? parent.mangoUtils.replacePMCaretTemplate(
                  formObj['TemplateWildcards'],
                  formObj.NextDueDateForTemplate
                )
              : formObj['TemplateName'];
        }
      }

      formObj['TasksCompleted'] = 0;
      formObj['TotalNotes'] = 0;
      formObj['FinishDate'] = null;
      formObj['TotalTasks'] = parent.tasksDataSource.length;
      formObj['TurnAround'] = parent.projectEditForm.value.TurnAround;

      if (
        parent.projectEditForm.get('Repeat').value != null &&
        parent.projectEditForm.get('Repeat').value == 'custom'
      ) {
        formObj['RuleString'] = parent.currentRuleStr;
        if (!formObj['NextDueDate'] || !formObj['DueDate']) {
          formObj['NextDueDate'] = null;
          formObj['Repeat'] = null;
          formObj['RuleString'] = null;
        }
      } else {
        formObj['RuleString'] = null;
      }
      parent.mangoAPISrvc.showLoader(true);

      parent.mangoAPISrvc.createProjectHeader(formObj).subscribe(
        (Item: any) => {
          const selectedItem = {};
          selectedItem['CompanyID'] = formObj.CompanyID;
          selectedItem['ClientID'] = formObj.ClientID;
          selectedItem['ProjectHeaderID'] = Item.data['ProjectHeaderID'];
          parent.updateNewProjectHeaderIDProperty(Item.data['ProjectHeaderID']);
          if ((!dataList || dataList.length == 0) && !parent.isCreateFlow) {
            parent.mangoAPISrvc
              .copyBudgetsToNewProject({
                oldProjectHeaderID: parent.projectEditForm.value.ProjectHeaderID,
                newProjectHeaderID: Item.data['ProjectHeaderID'],
                companyID: parent.companyId,
                newProjectYear: moment(Item.data['DueDate']).format('YYYY')
              })
              .subscribe(
                result => {},
                err => {}
              );
          }
          parent.batchCreateAllTasks(dataList, selectedItem).subscribe(data => {
            if (dataList?.length > 0 && !parent.isCreateFlow) {
              parent.mangoAPISrvc
                .copyBudgetsToNewProject({
                  oldProjectHeaderID: parent.projectEditForm.value.ProjectHeaderID,
                  newProjectHeaderID: Item.data['ProjectHeaderID'],
                  companyID: parent.companyId,
                  newProjectYear: moment(Item.data['DueDate']).format('YYYY')
                })
                .subscribe(
                  result => {},
                  err => {}
                );
            }
            parent.projectEditForm.controls['isNewCreated'].setValue(true);
            parent.mangoAPISrvc.notify(
              'success',
              parent.translate.instant('Success'),
              AppConstants.updateMsg
            );
            parent.mangoAPISrvc.showLoader(false);
          });
        },
        err => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  async prepareData() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.selClient = { ClientID: null, ClientName: '' };
    parent.selProject = { label: null };
    let clientObj = parent.encrDecSrvc.getObject(AppConstants.ddmClientName);
    clientObj = clientObj
      ? JSON.parse(parent.encrDecSrvc.getObject(AppConstants.ddmClientName))
      : null;

    const tempObj = parent.encrDecSrvc.getObject(AppConstants.fromCompanyTemplate);
    parent.selectedProjectHeader = tempObj
      ? JSON.parse(parent.encrDecSrvc.getObject(AppConstants.fromCompanyTemplate))
      : null;
    //created from company template
    if (parent.selectedProjectHeader) {
      parent.isCreateFlow = true;
      parent.selectedProjectDetails = parent.selectedProjectHeader;
      // parent.selProject["label"] =
      //   parent.selectedProjectDetails["TemplateName"];
      parent.projectEditForm.controls['TemplateName'].setValue(
        parent.selectedProjectDetails['TemplateName']
      );
      parent.getProjectTemplates(parent.selectedProjectHeader.CompanyTemplateHeaderID);
      parent.updateTemplateForm();
      parent.isValidForm = false;
      const ClientName = parent.selectedProjectDetails['ClientName'] || '';
      parent.selClient = {
        ClientID: parent.selectedProjectDetails.ClientID,
        ClientName
      };
      if(this.selectedProjectDetails?.CompanyID && this.selectedProjectDetails?.EngagementTypeID)  parent.getEngagementsByCompanyID(this.selectedProjectDetails?.CompanyID, this.selectedProjectDetails?.EngagementTypeID);
      if (parent.selClient?.ClientID) parent.getEngagements(parent.selClient);

      if (parent.selectedProjectDetails && !parent.selectedProjectDetails.DueDate) {
        parent.selectedProjectDetails.Repeat = null;
        this.projectEditForm.controls['Repeat'].setValue(null);
      }
    }
    //created from client > projects / client history
    else if (clientObj && clientObj['ClientID'] != null) {
      parent.isCreateFlow = true;
      parent.selectedProjectDetails = { Tags: [] };
      parent.projectEditForm.controls['Tags'].setValue([]);
      parent.projectEditForm.controls['UserAssignedID'].setValue(parent.resourceId);
      parent.selClient = clientObj;
      parent.emailTemplate['to'] =
        parent.selClient && parent.selClient['Email'] ? parent.selClient['Email'] : '';
      parent.emailTemplate['subject'] =
        parent.selClient && parent.selClient['ClientName']
          ? parent.selClient['ClientName'] + ' Note'
          : '';
      if (parent.selClient?.ClientID) parent.getEngagements(parent.selClient);
    } else {
      let proDetails = parent.encrDecSrvc.getObject(AppConstants.ddmProjectDetails);

      proDetails = proDetails
        ? JSON.parse(parent.encrDecSrvc.getObject(AppConstants.ddmProjectDetails))
        : null;

      //if Client or Engagement is empty, fetch projectdetails from ProjectHeaderID
      if (proDetails && (!proDetails?.ClientID || !proDetails?.ProjectMasterID)) {
        let projectId = parent.activatedRoute.snapshot.params.projectHeaderID;
        projectId = parseInt(projectId);

        if (projectId) {
          await parent.mangoAPISrvc
            .fetchGetProjectHeader(projectId)
            .toPromise()
            .then((data: any) => {
              if (data[0].ClientID && data[0].ClientID != proDetails.ClientID)
                proDetails.ClientID = data[0].ClientID;
              if (data[0].ProjectMasterID && data[0].ProjectMasterID != proDetails.ProjectMasterID)
                proDetails.ProjectMasterID = data[0].ProjectMasterID;
            });
        }
      }

      parent.selectedProjectDetails = proDetails;
      if (parent.selectedProjectDetails) {
        //set Repeat as null if due date is empty
        if (!parent.selectedProjectDetails.DueDate) {
          parent.selectedProjectDetails.Repeat = null;
          this.projectEditForm.controls['Repeat'].setValue(null);
        }

        //get from client list
        if (parent.clientListDatasource?.length > 0) {
          parent.selClient = parent.clientListDatasource.find(
            client => client.ClientID === parent.selectedProjectDetails['ClientID']
          );
        } else {
          //if client is not yet loaded
          if (parent.selectedProjectDetails['ClientID']) {
            parent.selClient = await parent.mangoAPISrvc
              .getClientFullinformation(parent.selectedProjectDetails['ClientID'])
              .toPromise();
          }
        }

        parent.emailTemplate['to'] =
          parent.selClient && parent.selClient['Email'] ? parent.selClient['Email'] : '';
        parent.emailTemplate['subject'] =
          parent.selClient && parent.selClient['ClientName']
            ? parent.selClient['ClientName'] + ' Note'
            : '';

        (this.projectEmailAlerts['DaysBeforePastDueDate'] =
          parent.selectedProjectDetails['DaysBeforePastDueDate']),
          (this.projectEmailAlerts['DaysBeforePastDueDateBool'] =
            parent.selectedProjectDetails['DaysBeforePastDueDate'] != null),
          (this.projectEmailAlerts['ProjectDaysToRemind'] =
            parent.selectedProjectDetails['ProjectDaysToRemind']),
          (this.projectEmailAlerts['PastDue'] = parent.selectedProjectDetails['PastDue']);
        this.projectEmailAlerts['isNotifyManager'] =
          parent.selectedProjectDetails['isNotifyManager'];
        this.projectEmailAlerts['isNotifyBillingPartner'] =
          parent.selectedProjectDetails['isNotifyBillingPartner'];

        parent.projectEditForm.controls['TemplateName'].setValue(
          parent.selectedProjectDetails['TemplateName']
        );

        parent.updateTemplateForm();
        if (parent.selClient?.ClientID) parent.getEngagements(parent.selClient);
        parent.getProjectDetailsByHeaderId();
      } else {
        parent.isCreateFlow = true;
        parent.selectedProjectDetails = { Tags: [] };
        parent.projectEditForm.controls['Tags'].setValue([]);
        parent.projectEditForm.controls['UserAssignedID'].setValue(parent.resourceId);
        parent.projectEditForm.controls['ManagerID'].setValue(parent.resourceId);
      }
    }
    parent.projectEditForm.controls['ProjectMemo'].setValue(parent.selectedProjectDetails['ProjectMemo']);
    parent.initialTemplateName =
      parent.projectEditForm.controls['TemplateName'].value + parent.mangoAPISrvc.showLoader(false);
    if (parent.projectEditForm.value.ProjectHeaderID) {
      parent.fetchProjectDocuments(parent.projectEditForm.value.ProjectHeaderID);
      parent.getfetchprojectsNotes();
    }

    setTimeout(() => {
      this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);
    }, 300);
  }

  changeExtensionDate(dateType) {
    const formData = this.projectEditForm.value;
    let considerDate = null;
    if (formData.ExtensionDate) {
      considerDate = formData.ExtensionDate;
    } else {
      considerDate = formData.DueDate;
    }
    if (dateType == 'NextWeek') {
      considerDate = moment(considerDate).add(7, 'days').utc().toDate();
    } else if (dateType == 'NextMonth') {
      considerDate = moment(considerDate).add(1, 'months').utc().toDate();
    } else if (dateType == 'threeMonths') {
      considerDate = moment(considerDate).add(3, 'months').utc().toDate();
    } else if (dateType == 'fourMonths') {
      considerDate = moment(considerDate).add(4, 'months').utc().toDate();
    } else if (dateType == 'fiveMonths') {
      considerDate = moment(considerDate).add(5, 'months').utc().toDate();
    } else if (dateType == 'sixMonths') {
      considerDate = moment(considerDate).add(6, 'months').utc().toDate();
    }
    this.projectEditForm.controls['ExtensionDate'].setValue(considerDate);
    if (this.projectEditForm.controls['ExtensionDate'].value) {
      this.onDatesChange(true);
    }
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.projectEditForm.markAsDirty();
    this.validateForm(true);
  }

  getProjectTemplates(id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyTemplateHeader(id).subscribe(
      (data: any) => {
        data.map(function (lineObj, index) {
          parent.getStaffNames(lineObj['UserAssignedIDArray'], lineObj);
          lineObj.IsNewRecord = false;
          lineObj.isEditRecord = false;
          lineObj.DueDate = lineObj.DueDate ? moment(lineObj.DueDate).toDate() : null;
          lineObj.CompletionDate = lineObj.CompletionDate
            ? moment(lineObj.CompletionDate).toDate()
            : null;
          lineObj.IsTaskReady = lineObj.IsTaskReady ? lineObj.IsTaskReady : false;
          lineObj['TaskRow'] = lineObj['TaskRow'] ? lineObj['TaskRow'] : index + 1;
        });
        data.sort(function (a, b) {
          return a.TaskRow - b.TaskRow;
        });
        const totalReadyTask = data.filter(item => item['IsTaskReady'] == true);
        if (data.length > 1) {
          if (totalReadyTask.length == 0) {
            data[0]['IsTaskReady'] = true;
          }
          // Removed unnecessary codes with regards to isTaskReady
        }
        parent.tasksDataSource = data;
        if (parent.tasksDataSource.length == 0) {
        } else {
          parent.validateLineItems();
        }
        parent.mangoAPISrvc.showLoader(false);

        parent.saveAllTasks();
      },
      (data: any) => {
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), data.message);
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  addRow(desc?: any, dueDate?: any, dt?: any) {
    if (!this.validateLineItems()) {
      setTimeout(() => {
        this.scrollDownToNewRow('new-row');
      }, 500);
      return;
    }
    const lineObj = {};
    lineObj['UserAssignedID'] = this.projectEditForm.value.UserAssignedID;
    lineObj['UserAssignedIDArray'] = this.projectEditForm.value.UserAssignedID
      ? [this.projectEditForm.value.UserAssignedID]
      : null;
    lineObj['CompanyID'] = this.projectEditForm.value.CompanyID;
    lineObj['TaskDescription'] = desc;
    lineObj['TaskMemo'] = '';
    lineObj['DueDate'] = null;
    lineObj['CompletionDate'] = null;
    lineObj['ProjectHeaderID'] = this.projectEditForm.value.ProjectHeaderID;
    lineObj['ClientID'] = this.projectEditForm.value.ClientID;
    lineObj['TaskRow'] = this.tasksDataSource.length + 1;
    lineObj['IsCompleted'] = false;
    lineObj['dueDateOffsetDaysIndicator'] = 1;
    lineObj['isTask'] = 'T';
    if (this.tasksDataSource.length > 0) {
      lineObj['IsRepeatTask'] =
        this.tasksDataSource[this.tasksDataSource.length - 1]['IsRepeatTask'];
    } else {
      lineObj['IsRepeatTask'] = false;
    }

    lineObj['IsNewRecord'] = true;
    lineObj['isEditRecord'] = true;
    lineObj['isTouched'] = true;
    lineObj['NextDueDate'] = dueDate;
    lineObj['OverrideFirmDefaults'] = null;

    if (desc == 'Failed Review') lineObj['isReviewTask'] = true;

    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);

    this.getStaffNames(lineObj['UserAssignedIDArray'], lineObj);
    this.tasksDataSource.push(lineObj);
    // this.tasksDataSource = [...this.tasksDataSource];
    this.projectEditForm.markAsDirty();
    this.validateForm(true);

    const prevTask = this.tasksDataSource.find(prev => prev['TaskRow'] == lineObj['TaskRow'] - 1);
    if ((prevTask && prevTask['IsCompleted']) || !prevTask) {
      lineObj['IsTaskReady'] = true;
    }

    if (dt) {
      dt.editingRowKeys = {};
      dt.editingRowKeys[this.tasksDataSource.length] = true;
    } else this.table.editingRowKeys[this.tasksDataSource.length] = true;

    this.correctStatus();
    if (this.selectedProjectDetails['automaticComputeDuedate'] == true) {
      const i = this.projectEditForm.get('DateReceived').value ? 0 : this.tasksDataSource.length;
      this.computeDueDate(1, i - 1);
    }

    setTimeout(() => {
      this.scrollDownToNewRow('new-row');
    }, 100);
  }

  correctStatus() {
    const self = this;
    //self.correctTaskReady();
    if (!self.isCreateFlow) {
      const totalValidTasks = self.tasksDataSource;
      const totalProcessedTasks = totalValidTasks.filter(
        item => item['IsCompleted'] == true
      ).length;

      const objToSave = {
        ProjectHeaderID: self.projectEditForm.controls['ProjectHeaderID'].value,
        Status: self.getStatus(totalValidTasks, totalProcessedTasks),
        TasksCompleted: totalProcessedTasks,
        TotalTasks: totalValidTasks.length
      };

      if (objToSave.ProjectHeaderID) {
        self.mangoAPISrvc.updateProjectHeader(objToSave.ProjectHeaderID, objToSave).subscribe(
          (data: any) => {
            //save task row number...
            self.batchSaveAllTasks().subscribe(data => {
              self.getProjectDetailsByHeaderId();
            });
          },
          err => {}
        );
      }
    }
  }

  saveNotes() {
    const parent = this;

    const obj = {};

    obj['ProjectHeaderID'] = this.projectEditForm.value.ProjectHeaderID;

    obj['StaffID'] = this.resourceId;

    obj['ClientID'] = this.selClient.ClientID;

    obj['CompanyID'] = this.companyId;

    obj['Note'] = this.noteHistory;

    obj['DateCreated'] = new Date();

    this.emailTemplate['to'] = this.selClient['Email'] ? this.selClient['Email'] : '';

    this.emailTemplate['from'] = parent.encrDecSrvc.getObject('userEmail');

    this.emailTemplate['subject'] = this.selClient['ClientName']
      ? this.selClient['ClientName'] + ' Note'
      : '';

    this.isEmailClient = this.selectedUsers.some(userValue => userValue === 'client');

    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.createProjectNote(obj).subscribe(
      (data: any) => {
        if (parent.isEmailClient) {
          parent.isEmailDialog = true;

          parent.emailEditorValue = parent.noteHistory || '';
        }

        if (parent.selectedUsers.filter(userValue => userValue !== 'client').length > 0) {
          parent.sendAnEmailForNote();
        } else {
          parent.noteHistory = '';
        }

        parent.getfetchprojectsNotes();

        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), data.message);

        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  deleteProject(project) {
    const parent = this;
    swal
      .fire({
        title: parent.translate.instant('confirmation'),
        text: parent.translate.instant('delete_project_message'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: parent.translate.instant('yes_delete'),
        cancelButtonText: parent.translate.instant('no_delete')
      })
      .then(result => {
        if (result.value) {
          parent.mangoAPISrvc.showLoader(true);
          //API call for deleting project
          parent.mangoAPISrvc.deleteProjectTemplateHeader(project.id).subscribe(
            (data: any) => {
              parent.getProjectNames(parent.companyId);
              if (project.id == this.selProject.id) {
                this.selProject = { label: null };
              }
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.deleteMessage
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            err => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.deleteErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
        }
      });
  }

  deleteNotes(Id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.deleteProjectNote(Id).subscribe(
      (data: any) => {
        parent.getfetchprojectsNotes();
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), data.message);
        parent.mangoAPISrvc.showLoader(false);
      },
      err => {
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), err.message);
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }
  async sendAnEmailForNote() {
    const parent = this;

    const formData = parent.projectEditForm.value;
    const managerObj = parent.AllManagers.filter(
      item => item['value'] === formData['ManagerID']
    )[0];

    //if selClient is not loaded, get client details again
    if (!parent.selClient['ClientName'] && formData.ClientID) {
      if (parent.clientListDatasource.length > 0) {
        const client = parent.clientListDatasource.find(x => x.ClientID == formData.ClientID);
        parent.selClient = { ClientID: formData.ClientID, ClientName: client.ClientName };
      } else {
        //if client is not yet loaded
        if (parent.selectedProjectDetails['ClientID']) {
          parent.selClient = await parent.mangoAPISrvc
            .getClientFullinformation(parent.selectedProjectDetails['ClientID'])
            .toPromise();
        }
      }
    }

    const sendInBlueObj = {
      sender: {
        name: 'Mango Billing',
        email: environment.EMAIL_RETURN_SENDER
      },
      to: [],
      replyTo: {
        email: environment.EMAIL_RETURN_SENDER
      },
      templateId: 39,
      params: {
        manager: managerObj ? managerObj['label'] : '',
        user:
          formData['UserAssignedID'] != -1
            ? parent.AllManagers.filter(item => item['value'] === formData['UserAssignedID'])[0][
                'label'
              ]
            : '',
        clientname: parent.selClient['ClientName'] ?? '',
        projectname: formData.TemplateName ?? '',
        duedate: formData.DueDate ? moment(formData.DueDate).format('MM-DD-YYYY') : '-',
        projectnote: parent.noteHistory,
        COMPANYNAME: parent.senderObj.companyName
      }
    };

    parent.selectedUsers.forEach(u => {
      const user = parent.projectNoteMailRecipientList.filter(x => x.value == u)[0];
      if (user) sendInBlueObj.to.push({ email: u, name: user.name });
    });

    parent.isCreateFlow = false;
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(
      data => {
        parent.selectedUsers = [];
        parent.noteHistory = '';

        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.emailSentMsg
        );

        parent.mangoAPISrvc.showLoader(false);
      },
      err => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateTemplateForm() {
    const parent = this;
    const itemData = parent.selectedProjectDetails;

    if (Object.keys(itemData).length > 0) {
      this.newProjectHeaderID = itemData['newProjectHeaderID'];
      parent.projectEditForm.controls['ProjectHeaderID'].setValue(itemData['ProjectHeaderID']);
      parent.projectEditForm.controls['ImagineShareSyncID'].setValue(
        itemData['ImagineShareSyncID']
      );
      parent.projectEditForm.controls['CompanyID'].setValue(itemData['CompanyID']);
      itemData['DateReceived'] = itemData['DateReceived']
        ? moment(itemData['DateReceived']).toDate()
        : null;

      parent.projectEditForm.controls['DateReceived'].setValue(itemData['DateReceived']);

      itemData['DueDate'] = itemData['DueDate'] ? moment(itemData['DueDate']).toDate() : null;
      parent.projectEditForm.controls['DueDate'].setValue(itemData['DueDate']);

      parent.currentRuleStr = itemData['RuleString'] ? itemData['RuleString'] : '';
      parent.projHeaderRule = parent.currentRuleStr.replace('RRULE:', '').replace('COUNT=2', '');

      if (parent.projHeaderRule) {
        parent.ruledateSource = moment(itemData['DueDate']).format('YYYY/MM/DD');
        parent.ruleTwodateSource = moment(itemData['NextDueDate']).format('YYYY/MM/DD');
      }

      itemData['FinishDate'] = itemData['FinishDate']
        ? moment(itemData['FinishDate']).toDate()
        : null;
      parent.projectEditForm.controls['FinishDate'].setValue(itemData['FinishDate']);

      itemData['TurnAround'] = itemData['TurnAround'] ? itemData['TurnAround'] : null;
      parent.projectEditForm.controls['TurnAround'].setValue(itemData['TurnAround']);

      itemData['NextDueDate'] = itemData['NextDueDate']
        ? moment(itemData['NextDueDate']).toDate()
        : null;
      parent.projectEditForm.controls['NextDueDate'].setValue(itemData['NextDueDate']);

      itemData['ExtensionDate'] = itemData['ExtensionDate']
        ? moment(itemData['ExtensionDate']).toDate()
        : null;
      parent.projectEditForm.controls['ExtensionDate'].setValue(itemData['ExtensionDate']);

      itemData['CompletionDate'] = itemData['CompletionDate']
        ? moment(itemData['CompletionDate']).toDate()
        : null;
      parent.projectEditForm.controls['CompletionDate'].setValue(itemData['CompletionDate']);

      itemData['ManagerID'] = itemData['ManagerID'] ? itemData['ManagerID'] : null;
      parent.projectEditForm.controls['ManagerID'].setValue(itemData['ManagerID']);

      itemData['ProjectMemo'] = itemData['ProjectMemo'] ? itemData['ProjectMemo'] : null;
      parent.projectEditForm.controls['ProjectMemo'].setValue(itemData['ProjectMemo']);
      itemData['Repeat'] = itemData['Repeat'] ? itemData['Repeat'] : null;
      parent.projectEditForm.controls['Repeat'].setValue(itemData['Repeat']);
      itemData['TemplateName'] = itemData['TemplateName'] ? itemData['TemplateName'] : null;
      parent.projectEditForm.controls['TemplateName'].setValue(itemData['TemplateName']);

      itemData['TemplateWildcards'] = itemData['TemplateWildcards']
        ? itemData['TemplateWildcards']
        : itemData['TemplateName'];
      parent.projectEditForm.controls['TemplateWildcards'].setValue(itemData['TemplateWildcards']);

      itemData['isPreviousPeriodYearPolicy'] = itemData['isPreviousPeriodYearPolicy']
        ? itemData['isPreviousPeriodYearPolicy']
        : null;
      parent.projectEditForm.controls['isPreviousPeriodYearPolicy'].setValue(
        itemData['isPreviousPeriodYearPolicy']
      );

      itemData.ActualDueDate = itemData.FinishDate || itemData.ExtensionDate || itemData.DueDate;

      itemData['Status'] = itemData['Status'] ? itemData['Status'] : 'Pending';
      parent.projectEditForm.controls['Status'].setValue(itemData['Status']);

      itemData['isCompleted'] = itemData['isCompleted'] ? itemData['isCompleted'] : false;
      parent.projectEditForm.controls['isCompleted'].setValue(itemData['isCompleted']);

      itemData['isProject'] = itemData['isProject'] ? itemData['isProject'] : 'P';
      parent.projectEditForm.controls['isProject'].setValue(itemData['isProject']);

      itemData['ClientID'] = itemData['ClientID'] ? itemData['ClientID'] : null;
      parent.projectEditForm.controls['ClientID'].setValue(itemData['ClientID']);

      itemData['isReviewRequired'] = itemData['isReviewRequired']
        ? itemData['isReviewRequired']
        : false;
      parent.projectEditForm.controls['isReviewRequired'].setValue(itemData['isReviewRequired']);

      itemData['UserAssignedID'] = itemData['UserAssignedID']
        ? itemData['UserAssignedID']
        : parent.resourceId;

      parent.projectEditForm.controls['UserAssignedID'].setValue(itemData['UserAssignedID']);

      itemData['IsAssignedSent'] = itemData['IsAssignedSent'] ? itemData['IsAssignedSent'] : false;
      parent.projectEditForm.controls['IsAssignedSent'].setValue(itemData['IsAssignedSent']);

      itemData['IsFailedSent'] = itemData['IsFailedSent'] ? itemData['IsFailedSent'] : false;
      parent.projectEditForm.controls['IsFailedSent'].setValue(itemData['IsFailedSent']);

      itemData['IsInProgressSent'] = itemData['IsInProgressSent']
        ? itemData['IsInProgressSent']
        : false;
      parent.projectEditForm.controls['IsInProgressSent'].setValue(itemData['IsInProgressSent']);

      itemData['IsReadyReviewSent'] = itemData['IsReadyReviewSent']
        ? itemData['IsReadyReviewSent']
        : false;
      parent.projectEditForm.controls['IsReadyReviewSent'].setValue(itemData['IsReadyReviewSent']);

      itemData['IsTaskAssignedSent'] = itemData['IsTaskAssignedSent']
        ? itemData['IsTaskAssignedSent']
        : false;
      parent.projectEditForm.controls['IsTaskAssignedSent'].setValue(
        itemData['IsTaskAssignedSent']
      );

      itemData['isNewCreated'] = itemData['isNewCreated'] ? itemData['isNewCreated'] : false;
      parent.projectEditForm.controls['isNewCreated'].setValue(itemData['isNewCreated']);

      itemData['IsCompleteInOrder'] = itemData['IsCompleteInOrder']
        ? itemData['IsCompleteInOrder']
        : false;

      parent.projectEditForm.controls['IsCompleteInOrder'].setValue(itemData['IsCompleteInOrder']);

      itemData['automaticComputeDuedate'] = itemData['automaticComputeDuedate']
        ? itemData['automaticComputeDuedate']
        : false;

      parent.projectEditForm.controls['automaticComputeDuedate'].setValue(
        itemData['automaticComputeDuedate']
        // false
      );

      parent.projectEditForm.controls['ProjectMasterID'].setValue(itemData['ProjectMasterID']);

      itemData['Tags'] = itemData['Tags'] ? itemData['Tags'] : [];

      const temp = [];
      for (let index = 0; index < itemData['Tags'].length; index++) {
        temp.push(parseInt(itemData['Tags'][index]));
      }
      parent.projectEditForm.controls['Tags'].setValue(temp);

      parent.senderObj['toName'] = itemData.username;
      parent.senderObj['toEmail'] = itemData.useremail;
      parent.senderObj['companyName'] = parent.encrDecSrvc.getObject(AppConstants.companyName);
    }
  }

  fetchProjectDocuments(id) {
    const parent = this;
    parent.projectDocuments = [];
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.fetchProjectDocuments(id).subscribe(
      (response: any) => {
        parent.projectDocuments = response;
        parent.updateMoreOptions();
        parent.mangoAPISrvc.showLoader(false);
      },
      error => parent.mangoAPISrvc.showLoader(false)
    );
  }

  deleteDocRow(obj) {
    swal
      .fire({
        title: this.translate.instant('confirmation'),
        text: this.translate.instant('imports.Do_you_really_want_to_delete_file'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes_delete'),
        cancelButtonText: this.translate.instant('no_delete')
      })
      .then(result => {
        if (result.value) {
          const params = {
            Bucket: 'mangobillings3',
            Key: 'documents/' + obj.UniqueName
          };
          const self = this;
          self.awsBucket.deleteObject(
            params,

            function (err, data) {
              if (err) {
                return;
              }
              self.mangoAPISrvc.showLoader(true);
              self.mangoAPISrvc.deleteDMSParentFolder(obj.dmsParentID).subscribe(
                (objItem: any) => {
                  const index = self.projectDocuments.indexOf(obj);
                  self.projectDocuments.splice(index, 1);
                  self.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.deleteMessage);
                  self.mangoAPISrvc.showLoader(false);
                },
                error => self.mangoAPISrvc.showLoader(false)
              );
            },

            self.mangoAPISrvc
          );
        }
      });
  }

  previewRow(obj) {
    const params = {
      Bucket: environment.AWS_BUCKET_NAME,
      Key: 'documents/' + obj['UniqueName']
    };
    const self = this;
    self.isFilePreview = true;
    self.isPreviewViewerShow = true;
    self.awsBucket.getSignedUrl(
      'getObject',

      params,

      function (err, docUrl) {
        $('#overlay').hide();
        if (err) {
          console.log('error while saving file on s3 server', err);
          return;
        }
        self.createWebPreviewViewer(docUrl);
      },

      self.mangoAPISrvc
    );
  }

  createWebPreviewViewer(docUrl) {
    const self = this;
    self.isPreviewViewerShow = true;

    if (self.wvPreviewInstance) {
      self.wvPreviewInstance.loadDocument(docUrl).then(instance => {
        const docViewer = instance.docViewer;
        // you must have a document loaded when calling this api
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    } else {
      WebViewer(
        {
          licenseKey: environment.PDF_WebViewer_KEY,
          path: '../../../../wv-resources/lib',
          initialDoc: docUrl
        },
        self.preiewviewer.nativeElement
      ).then(instance => {
        self.wvPreviewInstance = instance;
        self.wvPreviewInstance.disableElements([
          'leftPanel',
          'leftPanelButton',
          'panToolButton',
          'toolsButton',
          'signatureToolButton',
          'freeHandToolGroupButton',
          ,
          'signatureTool',
          'freeTextToolButton',
          'eraserToolButton',
          'shapeToolGroupButton',
          'textToolGroupButton',
          'miscToolGroupButton',
          'stickyToolButton'
        ]);
        const docViewer = instance.docViewer;
        docViewer.on('documentLoaded', function () {
          instance.setZoomLevel('100%'); // or setZoomLevel(1.5)
        });
      });
    }
  }

  closePreviewDialog() {
    this.isFilePreview = false;
    this.isPreviewViewerShow = false;
  }

  editRow(obj) {
    this.isShowfileName = true;
    this.fileName = obj.FName;
    this.selectedObj = obj;
  }

  completeItems() {
    this.selectedItems.forEach(selectedOneObj => {
      if (
        !selectedOneObj.IsCompleted &&
        !selectedOneObj.IsTaskReady &&
        this.projectEditForm.get('IsCompleteInOrder').value
      )
        return;
      this.onChecked({ checked: true }, selectedOneObj, selectedOneObj.TaskRow - 1);
    });
  }

  resetTasks() {
    const self = this;
    for (let i = 0; i < self.selectedItems.length; i++) {
      const selectedOneObj = self.selectedItems[i];
      self.onChecked({ checked: false }, selectedOneObj, -1);
    }
  }

  removeForbiddenWildcards(str: string, repeat: RepeatEnum): string {
    let word = str;
    switch (repeat) {
      case RepeatEnum.YEAR:
        this.forbiddenWildcards.YEAR.forEach(val => {
          word = this.mangoUtils.replaceAll(word, val, '');
        });
        return word;
      case RepeatEnum.QUARTERLY:
        this.forbiddenWildcards.QUARTER.forEach(val => {
          word = this.mangoUtils.replaceAll(word, val, '');
        });
        return word;
      case RepeatEnum.MONTHLY:
      case RepeatEnum.SEMIMONTHLY:
        this.forbiddenWildcards.MONTH.forEach(val => {
          word = this.mangoUtils.replaceAll(word, val, '');
        });
        return word;
      default:
        return word;
    }
  }

  verifyCustomDialog() {
    const formData = this.projectEditForm.value;
    const newTemplateWildcards = this.removeForbiddenWildcards(
      formData['TemplateWildcards'],
      formData.Repeat
    );
    this.projectEditForm.controls['TemplateWildcards'].setValue(newTemplateWildcards);
    if (formData.DueDate) {
      if (
        formData.Repeat !== null &&
        formData.Repeat !== '' &&
        formData.Repeat !== 'none' &&
        formData.Repeat !== 'custom' &&
        !!formData['TemplateWildcards']
      ) {
        if (newTemplateWildcards) {
          this.projectEditForm.controls['TemplateName'].setValue(
            this.mangoUtils.replacePMCaretTemplate(
              newTemplateWildcards,
              formData.DueDate,
              formData.isPreviousPeriodYearPolicy,
              formData['Repeat']
            )
          );
        }
      }
    }

    if (formData.Repeat == 'custom') {
      this.isDisplayRuleDialog = true;
    }
  }

  onChange(args: RecurrenceEditorChangeEventArgs): void {
    if (!this.isDisplayRuleDialog) return;

    if (!isNullOrUndefined(args.value)) {
      if (args.value == '') {
        this.projHeaderRule = '';
      } else {
        this.projHeaderRule = args.value;
        setTimeout(() => {
          const formObj = Object.assign({}, this.projectEditForm.value);
          this.currentRuleStr = `RRULE:${this.projHeaderRule}COUNT=2`;
          this.genarateRules(formObj['DueDate']);
        }, 300);
      }
    }
  }

  showFiles() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getIsDMSIshareValue().subscribe((result: any) => {
      this.mangoAPISrvc.showLoader(false);
      if (result?.data?.isDMSIShare) {
        this.redirectToImagineShare();
      } else {
        this.showAttachedFiles = !this.showAttachedFiles;
        this.dmsParentIDToLink = null;
        this.dmsYellowParentIDToLink = null;
        this.parentSelected = false;
        this.dmsParentIDsNewArray = [];
      }
    });
  }

  nodeSelect(event) {
    this.dmsParentIDToLink = event.node.dmsParentID;
    this.dmsYellowParentIDToLink = event.node.YellowParentID || event.node.dmsParentID;
    this.parentSelected = true;
  }

  linkToClientDMSFolder() {
    this.mangoAPISrvc.showLoader(true);
    const obj = {
      dmsParentIds: this.dmsParentIDsNewArray,
      newParentId: this.dmsParentIDToLink,
      newYellowParentId: this.dmsYellowParentIDToLink
    };
    this.mangoAPISrvc.changeClientDMSParent(obj).subscribe(res => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify(
        'success',
        this.translate.instant('Sucess'),
        this.translate.instant('client_dms_linked')
      );
      this.showClientFolders = false;
    });
  }

  closeFiles() {
    this.showAttachedFiles = false;
  }

  async onSelect(event) {
    if (this.selClient.ClientID == null) {
      return false;
    }
    const listOfAcceptedFiles = [
      'pdf',
      'txt',
      'xls',
      'xlsx',
      'doc',
      'docx',
      'csv',
      'jpg',
      'jpeg',
      'png',
      'tif',
      'rtf'
    ].toString();
    const filePos = event.files[0].name.lastIndexOf('.');
    const fileType = event.files[0].name.substring(filePos + 1, event.files[0].name.length);
    const fileSize = event.files[0].size / 1048576; //size in mb
    if (event.files && listOfAcceptedFiles.indexOf(fileType) > -1 && fileSize <= 30) {
      this.files.push(...event.files);
      //this.file = event.files[0];
      for (let index = 0; index < this.files.length; index++) {
        const file = this.files[index];
        setTimeout(() => {
          this.uploadFile(file);
        }, 200);
      }
    } else {
      swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Accepted Files are PDF/Excel/Images/txt/RTF',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok',
        timer: 2000
      });
      this.fileUpload.clear();
    }
  }

  async uploadFile(fileObj) {
    const self = this;
    const UniqueName = this.mangoUtils.generateUUID() + '_' + fileObj.name;
    const filePos = fileObj.name.lastIndexOf('.');
    const fileType = fileObj.name.substring(filePos + 1, fileObj.name.length);
    const params = {
      Bucket: 'mangobillings3',
      Key: 'documents/' + UniqueName,
      // ACL: "public-read",
      Body: fileObj
    };
    self.mangoAPISrvc.showLoader(true);
    $('#overlay').show();
    self.awsBucket.upload(
      params,
      function (err, data) {
        self.fileUpload.clear();
        self.mangoAPISrvc.showLoader(false);
        self.closeFiles();
        $('#overlay').hide();

        if (err) {
          console.log('error while saving file on s3 server', err);
          return;
        }
        self.onRemove(fileObj);
        setTimeout(() => {
          const cloneObj = {};
          cloneObj['FName'] = fileObj.name;
          cloneObj['UniqueName'] = UniqueName;
          cloneObj['Size'] = self.mangoUtils.formatBytes(fileObj.size, 0);
          cloneObj['ModifyOn'] = new Date();
          cloneObj['FileType'] = fileType;
          cloneObj['LineType'] = 'F';
          cloneObj['ShowInPortal'] = false;
          cloneObj['IsDDM'] = true;
          cloneObj['ProjectHeaderID'] = self.projectEditForm.value.ProjectHeaderID;
          cloneObj['ClientID'] = self.selClient.ClientID;
          cloneObj['CompanyID'] = self.companyId;
          cloneObj['CreatedOn'] = new Date();
          cloneObj['ModifyBy'] = self.userName;
          cloneObj['isLastRecord'] = self.files.length == 0 ? true : false;
          self.addDMSParentFolder(cloneObj, cloneObj['isLastRecord']);
        }, 200);
      },
      self.mangoAPISrvc
    );
  }

  getClientFolders() {
    this.showClientFolders = true;
  }

  addDMSParentFolder(obj, isLastRecord?: any) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.addDMSParentFolder(obj).subscribe(
      (response: any) => {
        this.dmsParentIDsNewArray.push(response.data.dmsParentID);
        if (isLastRecord) {
          parent.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.getClientFolders(obj['ClientID']).subscribe((result: any) => {
            this.clientFiles = <TreeNode[]>result.data;
            if (
              this.clientFiles &&
              this.clientFiles[0] &&
              this.clientFiles[0]['children'].length > 0
            ) {
              Swal.fire({
                icon: 'success',
                title: this.translate.instant('Success'),
                html: this.translate.instant('link_file_client_dms_confirmation'),
                allowEscapeKey: false,
                showCancelButton: true,
                confirmButtonText: parent.translate.instant('yes_continue'),
                cancelButtonText: parent.translate.instant('no_leave')
              }).then(result => {
                if (result.value) {
                  this.getClientFolders();
                }
              });
            }
          });
          parent.fetchProjectDocuments(obj['ProjectHeaderID']);
          parent.mangoAPISrvc.notify(
            'success',
            parent.translate.instant('Success'),
            'Successfully Uploaded.'
          );
        }
        parent.projectEditForm.markAsDirty();
      },
      error => parent.mangoAPISrvc.showLoader(false)
    );
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  genarateRules(date: any, isNextDueDate?: boolean) {
    if (this.currentRuleStr == '' || !this.currentRuleStr) {
      this.ruledateSource = null;
      this.ruleTwodateSource = null;
      return false;
    }
    const dateStart: any = date ? moment(date).format('YYYYMMDDThhmmss') : null;

    this.rule = RRule.fromString(
      dateStart ? `${this.currentRuleStr};DTSTART=${dateStart}` : this.currentRuleStr
    );

    // console.log("last date:", this.rule.before(date))

    this.rule.noCache = true;
    if (!isNextDueDate || this.compareDates(date, this.rule.all()[0])) {
      this.ruledateSource = this.rule.all()[0] ? this.convertUTCDate(this.rule.all()[0]) : null;
      this.ruleTwodateSource = this.rule.all()[1] ? this.convertUTCDate(this.rule.all()[1]) : null;
      this.ruledateSource = this.ruledateSource
        ? this.getNearestWeekday(this.ruledateSource)
        : null;
      this.ruleTwodateSource = this.ruleTwodateSource
        ? this.getNearestWeekday(this.ruleTwodateSource)
        : null;
    } else {
      this.ruleTwodateSource = this.rule.all()[0] ? this.convertUTCDate(this.rule.all()[0]) : null;
      this.ruleTwodateSource = this.ruleTwodateSource
        ? this.getNearestWeekday(this.ruleTwodateSource)
        : null;
    }
  }

  compareDates(date1, date2) {
    return moment(date1).format('YYYY/MM/DD') == moment(date2).format('YYYY/MM/DD');
  }

  getNearestWeekday(dateString: string) {
    const dateMoment = moment(dateString?.substring(0, 8));
    if (
      (this.currentRuleStr?.toLowerCase()?.indexOf('freq=yearly') == -1 &&
        this.currentRuleStr?.toLowerCase()?.indexOf('freq=monthly') == -1) ||
      this.currentRuleStr?.toLowerCase()?.indexOf('byday=') > -1
    )
      return dateMoment.format('YYYY/MM/DD');

    if (dateMoment.format('dddd') === 'Sunday' || dateMoment.format('dddd') === 'Saturday') {
      dateMoment.add(1, 'days');
      return this.getNearestWeekday(dateMoment.format('YYYYMMDD'));
    } else return dateMoment.format('YYYY/MM/DD');
  }

  convertUTCDate(date) {
    const check = moment(date, 'YYYY/MM/DD');
    const month = parseInt(check.format('M'));
    const day = parseInt(check.format('D'));
    const year = parseInt(check.format('YYYY'));
    return new RRule({
      dtstart: new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
    })
      .toString()
      .split('DTSTART:')[1];
  }

  onCheckedRepeat(event, item, index) {
    this.validateForm(true);
  }

  updateNewProjectHeaderIDProperty(newProjectHeaderID, newStatus?) {
    const formUpdate = Object.assign({}, this.projectEditForm.value);
    const obj = { newProjectHeaderID };
    if (!newProjectHeaderID) {
      obj['isCompleted'] = false;
      obj['isNewCreated'] = false;
      obj['isNewCreated'] = false;
      obj['Status'] = newStatus;
      const completedRecords = this.tasksDataSource.filter(item => item['IsCompleted'] === true);
      obj['TasksCompleted'] = completedRecords.length;
      obj['TotalTasks'] = this.tasksDataSource.length;
    }
    this.mangoAPISrvc.updateProjectHeader(formUpdate.ProjectHeaderID, obj).subscribe(data => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      this.projectEditForm.markAsPristine();
      this.isFormValid = false;
      this.isLineItemsValid = false;
      this.isValidForm = false;
      this.validateForm(false);
      this.newProjectHeaderID = newProjectHeaderID;
    });
  }

  getStatus(totalValidTasks, totalProcessedTasks): string {
    let status: string = 'Pending';

    if (totalProcessedTasks > 0 && totalValidTasks.length > totalProcessedTasks) {
      status = 'In Progress';
    } else if (totalProcessedTasks > 0 && totalValidTasks.length == totalProcessedTasks) {
      if (
        this.projectEditForm.value.isReviewRequired == true &&
        this.projectEditForm.value.Status !== 'Completed'
      ) {
        status = 'Ready for Review';
      } else {
        status = 'Completed';
      }
    } else if (totalProcessedTasks == 0) {
      status = 'Pending';
    } else if (this.projectEditForm.value.Status == 'Completed') {
      status = 'Completed';
    }
    if (
      this.projectEditForm.value.Status == 'Ready for Review' &&
      totalValidTasks.length == totalProcessedTasks
    ) {
      status = 'Ready for Review';
    }

    return status;
  }

  continueFunction(item, index) {
    this.mangoAPISrvc.showLoader(true);
    const nextTask = this.tasksDataSource.find(task => task['TaskRow'] == item['TaskRow'] + 1);
    if (nextTask) {
      nextTask['IsTaskReady'] = item.IsCompleted;
      nextTask.isUpdated = true;
    }
    item.IsTaskAssignedSent = item.IsTaskAssignedSent ? item.IsTaskAssignedSent : false;
    this.totalProcessLen = this.tasksDataSource.filter(item => item['IsCompleted'] == true).length;
    this.validateForm(true);

    this.checkRepeat();
    this.saveAllTasks(null, true);

    if (item.IsCompleted == true) {
      const logdata = {};
      logdata['Action'] = 'Completed Project Task from #' + item.ProjectHeaderID;
      logdata['Description'] =
        'Task: ' +
        item.ProjectDetailsID +
        ' - Completion Date: ' +
        moment(item.CompletionDate).format('MM/DD/YYYY') +
        ` --${this.selClient?.ClientName}]}`;
      logdata['Table'] = '';
      if (!this.isManaging && item.ProjectDetailsID) {
        this.mangoAPISrvc.addUserLogs(logdata).subscribe(
          res => {},
          err => {
            console.log(err);
          }
        );
      }
    }

    this.mangoAPISrvc.showLoader(false);

    if (!this.isCreateFlow) {
      const totalValidTasks = this.tasksDataSource.filter(
        task => task.ProjectDetailsID !== null && task.ProjectDetailsID !== undefined
      );
      const totalProcessedTasks = totalValidTasks.filter(
        item => item['IsCompleted'] == true
      ).length;
      const status = this.getStatus(totalValidTasks, totalProcessedTasks);
      const objToSave = {
        ProjectHeaderID: this.projectEditForm.controls['ProjectHeaderID'].value,
        Status: status,
        isCompleted: status === 'Completed' ? true : false,
        TasksCompleted: totalProcessedTasks,
        TotalTasks: totalValidTasks.length
      };

      if (status !== 'Completed') {
        objToSave['CompletionDate'] = null;
      }

      this.mangoAPISrvc.updateProjectHeader(objToSave.ProjectHeaderID, objToSave).subscribe(
        (data: any) => {},
        err => {}
      );
    }

    this.applyDueDates(item, index);
    if (
      index >= 0 &&
      this.tasksDataSource.length > 1 &&
      index !== this.tasksDataSource.length - 1 &&
      this.ddmSettingsObj.IsEmailTaskReady == true &&
      item.IsCompleted
    ) {
      const nextRecord = this.tasksDataSource[index + 1];
      //nextRecord.IsTaskReady = item.IsCompleted == false ? false : nextRecord.IsTaskReady;
      if (nextRecord.IsCompleted == false && !this.isCreateFlow) {
        if (nextRecord['UserAssignedIDArray'] && nextRecord['UserAssignedIDArray'].length > 0) {
          this.selectedProjectDetails['IsTaskAssignedSent'] = true;
          this.projectEditForm.controls['IsTaskAssignedSent'].setValue(true);
          item.IsTaskAssignedSent = true;
          const emails = this.AllManagerTypes.filter(item =>
            nextRecord['UserAssignedIDArray'].includes(item['value'])
          ).map(item => item['Email']);
          this.sendAnEmail(emails, 36, false, false, item, nextRecord);
        } else {
          this.saveProjDetails(item);
        }
      } else {
        this.saveProjDetails(item);
        this.mangoAPISrvc.showLoader(false);
      }
      this.selectedItems = [];
    } else {
      this.saveProjDetails(item);
      this.mangoAPISrvc.showLoader(false);
    }
  }

  get isAllowCreateProject() {
    return this.authGuard.isAllowAccess(41);
  }

  get isAllowChangeClient() {
    return this.authGuard.isAllowAccess(47) || this.projectEditForm.value.Status == 'Pending';
  }

  checkRepeat() {
    const prevStatus = this.projectEditForm.value.Status;
    this.applyStatus(true);
    const newStatus = this.projectEditForm.value.Status;
    if (
      this.newProjectHeaderID &&
      prevStatus == 'Completed' &&
      (newStatus == 'In Progress' || newStatus == 'Pending' || newStatus == 'Ready for Review')
    ) {
      Swal.fire({
        title: this.translate.instant('warning'),
        html: this.translate.instant(
          `Status has changed from Completed to ${newStatus}.  Do you want to delete the NEW project that was created?`
        ),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          this.mangoAPISrvc.ddmDeleteProjectHeader(this.newProjectHeaderID).subscribe(data => {
            this.mangoAPISrvc.notify(
              'success',
              this.translate.instant('Success'),
              this.translate.instant('delete-message')
            );
            this.newProjectHeaderID = null;
            this.updateNewProjectHeaderIDProperty(null, newStatus);
          });
        }
      });
    }
    if (newStatus == 'Completed') {
      const formObj = Object.assign({}, this.projectEditForm.value);
      if (
        // !this.newProjectHeaderID &&
        formObj.Repeat != null &&
        formObj.Repeat != '' &&
        formObj.Repeat != 'none' &&
        formObj.Status == 'Completed'
      ) {
        setTimeout(() => {
          this.createProjectHeader();
        }, 1500);
      }
    }
  }

  onChecked(event, item, index, completionDate?) {
    item.isUpdated = true;
    if (event.checked != undefined) {
      item.IsCompleted = event.checked;
    } else {
      item.IsCompleted = event;
    }
    if (item.IsCompleted) {
      item.CompletionDate = completionDate
        ? completionDate
        : moment().format('MM/DD/YYYY HH:mm:ss');
      item.IsCompleted = true;
      item.CompletedByStaffID = this.resourceId;
      item.CompletedByStaffName = this.getStaffName(this.resourceId);
    } else {
      item.CompletionDate = null;
      item.IsCompleted = false;
      item.CompletedByStaffID = null;
      item.CompletedByStaffName = '';
    }
    if (this.projectEditForm.get('IsCompleteInOrder').value && !item.IsCompleted) {
      const nextTask = this.tasksDataSource.find(next => next['TaskRow'] == item['TaskRow'] + 1);
      if (nextTask && nextTask['IsCompleted']) {
        Swal.fire({
          title: this.translate.instant('Warning'),
          text: this.translate.instant('Incompleting a task not in order!'),
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: this.translate.instant('Rearrange'),
          denyButtonText: this.translate.instant('Reset'),
          cancelButtonText: this.translate.instant('Cancel')
        }).then(result => {
          if (result.value) {
            item.CompletionDate = null;
            this.tasksDataSource.sort(
              (a, b) =>
                new Date(b['CompletionDate']).getTime() - new Date(a['CompletionDate']).getTime() ||
                a['TaskRow'] - b['TaskRow']
            );
            this.tasksDataSource = this.tasksDataSource.map((task, index) => {
              task['TaskRow'] = index + 1;
              return task;
            });
            this.correctTaskReady();
            this.mangoAPISrvc.showLoader(true);
            this.batchSaveAllTasks().subscribe(data => {
              this.mangoAPISrvc.showLoader(true);
              setTimeout(() => {
                this.getProjectDetailsByHeaderId();
              }, 1500);
            });
            // this.continueFunction(item, index)
          } else if (result.isDenied) {
            this.tasksDataSource = this.tasksDataSource.map(task => {
              task['IsCompleted'] = false;
              task['CompletionDate'] = null;
              return task;
            });
            this.correctTaskReady();
            this.mangoAPISrvc.showLoader(true);
            this.batchSaveAllTasks().subscribe(data => {
              this.mangoAPISrvc.showLoader(true);
              setTimeout(() => {
                this.getProjectDetailsByHeaderId();
              }, 1500);
            });
          } else {
            item.IsCompleted = true;
          }
        });
      } else {
        item.CompletionDate = null;
        this.continueFunction(item, index);
      }
    } else {
      this.continueFunction(item, index);
    }
    this.mangoAPISrvc.showLoader(false);
  }

  saveProjDetails(item) {
    item.IsTaskAssignedSent = true;
    if (!this.isLineItemsValid) {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Project task description must not be empty.'
      );
      return;
    }
    if (!item.ProjectDetailsID) {
      return false;
    }
    // this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateProjectDetails(item.ProjectDetailsID, item).subscribe(
      (data: any) => {
        // this.mangoAPISrvc.showLoader(false);
        // this.selectedItems = [];
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  applyDueDate() {
    this.validateForm(true);
    const formData = this.projectEditForm.value;
    if (this.tasksDataSource.length > 0 && formData.DueDate) {
      this.tasksDataSource.map(item => {
        item['DueDate'] = item['DueDate']
          ? moment(item['DueDate'], 'DD/MM/YYYY HH:mm:ss').toDate()
          : moment(formData.DueDate, 'DD/MM/YYYY HH:mm:ss').toDate();
      });
    }

    if (formData && !formData.DueDate) {
      this.projectEditForm.controls['Repeat'].setValue(null);
    }
    if (formData.DueDate) this.calculateRepeatData(formData);

    if (formData.DueDate) {
      if (
        formData.Repeat !== null &&
        formData.Repeat !== '' &&
        formData.Repeat !== 'none' &&
        formData.Repeat !== 'custom' &&
        !!formData['TemplateWildcards']
      ) {
        this.projectEditForm.controls['TemplateName'].setValue(
          this.mangoUtils.replacePMCaretTemplate(
            formData['TemplateWildcards'],
            formData.DueDate,
            formData.isPreviousPeriodYearPolicy,
            formData['Repeat']
          )
        );
      }
    }
    this.onDatesChange();
  }

  applyDueDates(item, rowindex) {
    if (
      this.tasksDataSource.length > 1 &&
      this.tasksDataSource.length != rowindex + 1 &&
      this.tasksDataSource.length > rowindex &&
      item.IsCompleted
    ) {
      const element = this.tasksDataSource[rowindex + 1];
      if (!element['DueDate']) {
        element['DueDate'] = moment(item['CompletionDate']).add(1, 'days').utc().toDate();
      }

      element['IsTaskReady'] = true;
    }
  }

  sendAnEmailForClient() {
    const parent = this;

    const senderObj = {
      name: '',
      email: ''
    };

    const sendInBlueObj = {};

    const toArr = [];

    const companyEmail = environment.EMAIL_RETURN_SENDER;

    const companyName = localStorage.getItem('CompanyName');

    senderObj['name'] = parent.selClient['ClientName'] ? parent.selClient['ClientName'] : '';

    senderObj['email'] = environment.EMAIL_RETURN_SENDER;

    if (!parent.emailTemplate['to']) {
      swal.fire({
        icon: parent.translate.instant('error'),
        title: 'Error',
        text: parent.translate.instant('customer_no_email'),
        showConfirmButton: false,
        timer: 1500
      });

      return false;
    } else {
      const obj = {
        email: '',
        name: ''
      };

      obj['email'] = parent.emailTemplate['to'];

      obj['name'] = parent.selClient['ClientName'];

      toArr.push(obj);

      sendInBlueObj['to'] = toArr;

      sendInBlueObj['replyTo.email'] = environment.EMAIL_RETURN_SENDER;

      sendInBlueObj['sender'] = senderObj;

      sendInBlueObj['sender.name'] = companyName;

      sendInBlueObj['sender.email'] = environment.EMAIL_RETURN_SENDER;

      sendInBlueObj['subject'] = parent.emailTemplate['subject'];

      sendInBlueObj['htmlContent'] = parent.emailEditorValue;

      parent.mangoAPISrvc.showLoader(true);

      parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(
        data => {
          parent.closeEmail();

          parent.mangoAPISrvc.showLoader(false);

          parent.mangoAPISrvc.notify(
            'success',
            parent.translate.instant('Success'),
            'Email sent successfully.'
          );
        },
        () => {
          parent.mangoAPISrvc.showLoader(false);

          parent.mangoAPISrvc.notify(
            'failed',
            parent.translate.instant('Failed'),
            'Email was not sent properly.'
          );
        }
      );
    }
  }

  initializeSendClientProjectNoteMail() {
    this.validateEmailForm();
  }

  closeEmail() {
    this.isEmailDialog = this.isUserSettingsTouched = this.isEmailClient = false;

    this.emailTemplate = {
      to: '',
      from: '',
      subject: ''
    };

    this.emailEditorValue = this.oldemailEditorValue;
  }

  async sendAnEmail(
    email,
    templeteId,
    isToRequired,
    isLineItem?: boolean,
    taskobj?: any,
    nextObj?: any,
    emailOnly?: boolean
  ) {
    // console.log('sending email to ', email)
    const parent = this;

    const formData = parent.projectEditForm.value;
    if (!formData.ProjectHeaderID) return;
    const sendInBlueObj = {
      sender: { name: 'Mango Billing', email: environment.EMAIL_RETURN_SENDER },
      to: [],
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: null,
      params: {}
    };

    if (isToRequired) {
      const userObj = parent.AllManagerTypes.filter(
        item => item['value'] == formData['UserAssignedID']
      )[0];
      if (userObj) {
        const obj = { email: '', name: '' };
        obj['email'] = userObj['Email'];
        obj['name'] = userObj['label'];
        sendInBlueObj.to.push(obj);
      }
    }

    if (isLineItem && isLineItem == true) {
      const taskUser = parent.tasksDataSource[0];
      const userObj = parent.AllManagerTypes.filter(
        item => item['value'] == taskUser['UserAssigned']
      )[0];
      const obj = { email: '', name: '' };
      obj['email'] = userObj['Email'];
      obj['name'] = userObj['label'];
      if (userObj && obj['name'] != 'Unassigned') {
        sendInBlueObj.to.push(obj);
      }
      sendInBlueObj.params['taskduedate'] = taskUser.DueDate
        ? moment(taskUser.DueDate).format('MM-DD-YYYY')
        : '-';
      sendInBlueObj.params['task'] = taskUser.TaskMemo ? taskUser.TaskMemo : '-';
    }

    if (nextObj) {
      sendInBlueObj.params['taskduedate'] = nextObj.DueDate
        ? moment(nextObj.DueDate).format('MM-DD-YYYY')
        : '-';
      sendInBlueObj.params['task'] = nextObj.TaskDescription ? nextObj.TaskDescription : '-';

      sendInBlueObj.params['user'] = '';
      if (nextObj['UserAssignedIDArray'] && nextObj['UserAssignedIDArray'].length == 1) {
        sendInBlueObj.params['user'] = parent.AllManagerTypes.filter(item =>
          nextObj['UserAssignedIDArray'].includes(item['value'])
        ).map(item => item['StaffName'])[0];
      }
    } else {
      sendInBlueObj.params['user'] = parent.AllManagerTypes.filter(
        item => item['value'] == formData['UserAssignedID']
      )[0]['label'];
    }

    const managerObj = parent.AllManagerTypes.filter(
      item => item['value'] == formData['ManagerID']
    )[0];
    if (email) {
      //These values will change based on type of email sent
      if (email instanceof Array) {
        sendInBlueObj.to = [];
        email.forEach(e => {
          const user = parent.AllManagerTypes.find(item => item.Email === e);
          const name = user && user.label ? user.label : e; // Use email as the name if label is not found
          const obj = { email: e, name };
          sendInBlueObj.to.push(obj);
        });
      } else {
        const user = parent.AllManagerTypes.find(item => item.Email === email);
        const name = user && user.label ? user.label : email; // Use email as the name if label is not found
        if (email) {
          sendInBlueObj.to.push({ email, name });
        }
      }
    }

    sendInBlueObj.templateId = templeteId;

    // user can change based on the Header or the TaskDetail row items

    // manager is static from dropdown
    sendInBlueObj.params['manager'] = managerObj ? managerObj['label'] : '';

    sendInBlueObj.params['clientname'] = parent.selClient?.ClientName
      ? parent.selClient['ClientName']
      : '';

    //if selClient is not loaded, get client details again
    if (!parent.selClient['ClientName'] && formData.ClientID) {
      if (parent.clientListDatasource.length > 0) {
        const client = parent.clientListDatasource.find(x => x.ClientID == formData.ClientID);
        parent.selClient = { ClientID: formData.ClientID, ClientName: client.ClientName };
      } else {
        //if client is not yet loaded
        if (formData.ClientID) {
          parent.selClient = await parent.mangoAPISrvc
            .getClientFullinformation(formData.ClientID)
            .toPromise();
        }
      }
    }

    // sendInBlueObj.params["projectname"] = parent.selProject["label"]
    //   ? parent.selProject["label"]
    //   : "";
    sendInBlueObj.params['projectname'] = formData.TemplateName ? formData.TemplateName : '';
    sendInBlueObj.params['duedate'] = formData.DueDate
      ? moment(formData.DueDate).format('MM-DD-YYYY')
      : '-';
    sendInBlueObj.params['memo'] = formData.ProjectMemo ? formData.ProjectMemo : '-';
    sendInBlueObj.params['COMPANYNAME'] = parent.encrDecSrvc.getObject(AppConstants.companyName);

    parent.isCreateFlow = false;
    formData['ClientID'] =
      formData['ClientID'] != '' ? formData['ClientID'] : (parent.selClient?.ClientID ?? null);

    // parent.mangoAPISrvc.showLoader(true);
    if (sendInBlueObj?.to.length > 0) {
      if (templeteId === 67) {
        for (const objEmail of email) {
          const currentRecipient = sendInBlueObj.to.find(toItem => toItem.email === objEmail);
          if (currentRecipient) {
            const emailObjToSend = {
              ...sendInBlueObj,
              to: [currentRecipient],
              params: {
                ...sendInBlueObj.params,
                user: currentRecipient.name
              }
            };
            parent.mangoAPISrvc.sendSMTPEmail(emailObjToSend).subscribe(data => {
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.emailSentMsg
              );
              err => {
                parent.mangoAPISrvc.showLoader(false);
              };
              parent.mangoAPISrvc.showLoader(false);
            });
          }
        }
      } else {
        parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(
          data => {
            parent.mangoAPISrvc.notify(
              'success',
              parent.translate.instant('Success'),
              AppConstants.emailSentMsg
            );
            if (!emailOnly) {
              if (templeteId == 36) {
                taskobj['IsTaskAssignedSent'] = true;
                taskobj.IsCompleted = true;
                taskobj.CompletionDate = moment().format('MM/DD/YYYY HH:mm:ss');
                parent.mangoAPISrvc
                  .updateProjectDetails(taskobj.ProjectDetailsID, taskobj)
                  .subscribe(
                    (data: any) => {},
                    err => {}
                  );
              } else {
                formData['Tags'] =
                  formData['Tags'] && formData['Tags'].length > 0 ? formData['Tags'] : null;
                parent.mangoAPISrvc
                  .updateProjectHeader(formData.ProjectHeaderID, formData)
                  .subscribe(
                    (data: any) => {},
                    err => {}
                  );
              }
            }
            // parent.mangoAPISrvc.showLoader(false);
          },
          err => {
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      }
    }
  }

  clearAddTag(action, option) {
    const formdata = this.projectEditForm.value;
    if (action == 'Add') {
      if (option == 'Edate' && formdata.ExtensionDate) {
        this.addTagItem(2);
      } else if (formdata.DateReceived) {
        this.addTagItem(1);
      }
      this.projectEditForm.markAsDirty();
    }
    if (action == 'delete') {
    }
  }

  deleteRow(event: any, itemData: any) {
    const self = this;
    event.stopPropagation();
    if (itemData.IsNewRecord || !itemData.ProjectDetailsID) {
      const index = this.tasksDataSource.indexOf(itemData);
      this.tasksDataSource.splice(index, 1);
    } else {
      swal
        .fire({
          title: self.translate.instant('confirmation'),
          text: self.translate.instant('pm.do-you-want-to-delete-this-record'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: self.translate.instant('yes_delete'),
          cancelButtonText: self.translate.instant('no_delete')
        })
        .then(result => {
          if (result.value) {
            self.mangoAPISrvc.showLoader(true);
            self.mangoAPISrvc.deleteProjectDetails(itemData.ProjectDetailsID).subscribe(
              (data: any) => {
                const index = self.tasksDataSource.indexOf(itemData);
                self.tasksDataSource.splice(index, 1);
                self.tasksDataSource = self.tasksDataSource.map((task, index) => {
                  task['TaskRow'] = index + 1;
                  return task;
                });
                self.correctTaskReady();
                if (!self.isCreateFlow) {
                  const totalValidTasks = self.tasksDataSource.filter(
                    task => task.ProjectDetailsID !== null && task.ProjectDetailsID !== undefined
                  );
                  const totalProcessedTasks = totalValidTasks.filter(
                    item => item['IsCompleted'] == true
                  ).length;

                  const objToSave = {
                    ProjectHeaderID: self.projectEditForm.controls['ProjectHeaderID'].value,
                    Status: self.getStatus(totalValidTasks, totalProcessedTasks),
                    TasksCompleted: totalProcessedTasks,
                    TotalTasks: totalValidTasks.length
                  };

                  self.mangoAPISrvc
                    .updateProjectHeader(objToSave.ProjectHeaderID, objToSave)
                    .subscribe(
                      (data: any) => {
                        const logdata = {};
                        logdata['Action'] =
                          'Delete Project Task from #' + objToSave.ProjectHeaderID;
                        logdata['Description'] =
                          'Task: ' +
                          itemData.ProjectDetailsID +
                          ' - ' +
                          itemData.TaskDescription +
                          ` --${this.selClient['ClientName']}`;
                        logdata['Table'] = '';
                        if (!this.isManaging) {
                          this.mangoAPISrvc.addUserLogs(logdata).subscribe(
                            res => {},
                            err => {
                              console.log(err);
                            }
                          );
                        }

                        //save task row number...
                        self.batchSaveAllTasks().subscribe(data => {
                          self.getProjectDetailsByHeaderId();
                        });
                      },
                      err => {}
                    );
                }
                self.mangoAPISrvc.notify(
                  'success',
                  self.translate.instant('Success'),
                  AppConstants.deleteMessage
                );
                // self.mangoAPISrvc.showLoader(false);
              },
              err => {
                const res = err.json();
                self.mangoAPISrvc.notify(
                  'error',
                  self.translate.instant('error'),
                  AppConstants.deleteErrorMsg
                );
                self.mangoAPISrvc.showLoader(false);
              }
            );
          }
        });
    }
  }

  onCompletionDateChange(rowData, rowIndex) {
    this.onChecked(rowData.CompletionDate != null, rowData, rowIndex, rowData.CompletionDate);
  }

  onDatesChange(onExtensionDateChange?) {
    const formData = this.projectEditForm.value;
    const actualDueDate =
      formData['FinishDate'] || formData['ExtensionDate'] || formData['DueDate'];
    this.projectEditForm.controls['ActualDueDate'].setValue(actualDueDate);
    if (onExtensionDateChange && formData['ExtensionDate'] && formData['FinishDate']) {
      swal
        .fire({
          title: this.translate.instant('confirmation'),
          text: this.translate.instant('pm.adjust_outdate'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('clear_out_date'),
          cancelButtonText: this.translate.instant('no_delete')
        })
        .then(result => {
          if (result.value) {
            this.projectEditForm.controls['FinishDate'].setValue(null);
            this.projectEditForm.controls['TurnAround'].setValue(null);
          }
        });
    }
  }

  validateForm(isChanged?: boolean) {
    const parent = this;
    const formData = this.projectEditForm.value;
    parent.validateLineItems();
    if (formData['ExtensionDate'] || formData['DueDate']) {
      this.isShowIcon = true;
    }
    parent.isTouched = typeof isChanged !== 'undefined' ? isChanged : parent.isTouched;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, parent.isTouched);
    if (
      parent.isTouched &&
      (parent.projectEditForm.value.TemplateName == null ||
        parent.projectEditForm.value.TemplateName == '' ||
        !parent.validateLineItems())
    ) {
      parent.isValidForm = true;
    } else {
      parent.isValidForm = !parent.isTouched ? true : false;
    }
  }

  getMinHeight() {
    return this.tasksDataSource.length == 0 ? 60 : 175;
  }

  finishReview() {
    this.removeRowEditMode();
    this.IsFinishReview = true;
    this.statusText = 'Completed';
    this.statusClass = 'completed';
    this.projectEditForm.controls['isCompleted'].setValue(true);
    this.projectEditForm.controls['isReviewRequired'].setValue(true);
    this.projectEditForm.controls['CompletionDate'].setValue(
      moment(new Date()).format('MM/DD/YYYY HH:mm:ss')
    );
    this.projectEditForm.controls['Status'].setValue(this.statusText);
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    // this.applyStatus(true);
    this.checkRepeat();

    this.saveAllTasks();

    const logdata = {};
    logdata['Action'] = 'Update Project #' + this.projectEditForm.value.ProjectHeaderID;
    logdata['Description'] = 'Finish Review' + ` --${this.selClient['ClientName']}`;
    logdata['Table'] = '';
    if (!this.isManaging) {
      this.mangoAPISrvc.addUserLogs(logdata).subscribe(
        res => {},
        err => {
          console.log(err);
        }
      );
    }
  }

  failFinishReview() {
    this.removeRowEditMode();
    const formData = this.projectEditForm.value;
    this.isFailedReviewClicked = true;
    this.addRow('Failed Review', null);
    this.projectEditForm.controls['isCompleted'].setValue(false);
    this.projectEditForm.controls['isReviewRequired'].setValue(true);
    this.projectEditForm.controls['CompletionDate'].setValue(null);
    this.applyStatus(true);
    const manageremail = this.AllManagerTypes.filter(
      item => item['value'] == formData['ManagerID']
    )[0]?.Email;
    if (this.ddmSettingsObj.IsProjectRejected == true) {
      this.sendAnEmail(manageremail, 38, false);
    }
    this.saveAllTasks();

    const logdata = {};
    logdata['Action'] = 'Update Project #' + this.projectEditForm.value.ProjectHeaderID;
    logdata['Description'] = 'Fail Finish Review' + ` --${this.selClient['ClientName']}`;
    logdata['Table'] = '';
    if (!this.isManaging) {
      this.mangoAPISrvc.addUserLogs(logdata).subscribe(
        res => {},
        err => {
          console.log(err);
        }
      );
    }
  }

  finalReviewRequired(evt) {
    const status = this.projectEditForm.value.Status;

    if (status == 'Ready for Review' || !evt.checked) {
      // this.applyStatus(true);
      this.checkRepeat();
      this.saveAllTasks();
    } else if (status == 'Completed') {
      Swal.fire({
        title: this.translate.instant('Warning'),
        text: this.translate.instant('revert-ready-for-review'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes_continue'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          this.projectEditForm.controls['isCompleted'].setValue(false);
          // this.applyStatus(true);
          this.checkRepeat();
          this.saveAllTasks();
        } else {
          this.projectEditForm.controls['isReviewRequired'].setValue(false);
        }
      });
    }
  }

  saveAllTasks(isOpenBudget?: boolean, isEditedTasksOnly?: boolean) {
    console.log('xxx', this.projectEditForm.value.isCapacityPlanningEnabled);

    console.log(this.previousEngagement);

    this.isEngagementNotSaved = false;
    this.removeRowEditMode();
    const parent = this;
    this.onDatesChange();
    const formData = parent.projectEditForm.value;
    parent.updateSettings();
    parent.updateChangedNotes().subscribe();
    if (parent.tasksDataSource.length > 0) {
      const intervalid = setInterval(() => {
        if (!parent.projectEditForm.value.ProjectHeaderID) return;
        clearInterval(intervalid);
        parent.batchSaveAllTasks(isEditedTasksOnly).subscribe((data: any) => {
          this.mangoAPISrvc.showLoader(false);

          const updatedRow = parent.tasksDataSource;
          updatedRow.forEach((selectedItem, key) => {
            if (!selectedItem.ProjectDetailsID) {
              // @NOTE: If ProjectDetailsID is undefined, we should get it from data returned from saving all tasks.
              const taskProjectDetailsID = data.find(
                (item: any) => item?.data?.TaskRow === selectedItem?.TaskRow
              )?.data?.ProjectDetailsID;
              selectedItem.ProjectDetailsID = taskProjectDetailsID;
            }
            if (selectedItem['ProjectDetailsID'] && selectedItem['Edited'] === true) {
              const logdata = {};
              logdata['Action'] = 'Update Project Task from #' + selectedItem.ProjectHeaderID;
              logdata['Description'] = 'TaskID: ' + selectedItem.ProjectDetailsID;

              if (selectedItem.TaskDescriptionChanged) {
                logdata['Description'] += ' - Description: ' + selectedItem.TaskDescription;
              }

              if (selectedItem.DueDateChanged) {
                logdata['Description'] +=
                  ' - Due Date: ' + moment(selectedItem.DueDate).format('MM/DD/YYYY');
              }

              if (selectedItem.AssignedChanged) {
                logdata['Description'] += ' - Assigned: ' + selectedItem.UserAssignedIDArray;
              }

              logdata['Description'] += ` --${this.selClient['ClientName']}`;
              logdata['Table'] = '';

              if (!this.isManaging && selectedItem.ProjectDetailsID) {
                this.mangoAPISrvc.addUserLogs(logdata).subscribe(
                  res => {},
                  err => {
                    console.log(err);
                  }
                );
              }
              delete selectedItem['Edited'];
              delete selectedItem['TaskDescriptionChanged'];
              delete selectedItem['DueDateChanged'];
              delete selectedItem['AssignedChanged'];
            }
          });
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
          parent.projectEditForm.markAsPristine();
          parent.isFormValid = false;
          parent.isLineItemsValid = false;
          parent.isValidForm = false;
          parent.validateForm(false);
          formData.Repeat = formData.Repeat;
          parent.isCreateFlow = false;
          if (isOpenBudget) {
            parent.openBudgetsDialog();
          }
        });
      }, 500);
    } else {
      this.mangoAPISrvc.showLoader(false);
      parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      parent.projectEditForm.markAsPristine();
      parent.isFormValid = false;
      parent.isLineItemsValid = false;
      parent.isValidForm = false;
      parent.validateForm(false);
      formData.Repeat = formData.Repeat;
      parent.isCreateFlow = false;
      if (isOpenBudget) {
        parent.openBudgetsDialog();
      }
    }
    setTimeout(() => {
      this.updateMoreOptions();
    }, 500);
  }

  validateLineItems() {
    this.isLineItemsValid = true;
    this.isTouched = true;
    let validData = true;
    for (let i = 0; i < this.tasksDataSource.length; ++i) {
      const obj = this.tasksDataSource[i];
      if (obj['TaskDescription'] == null || obj['TaskDescription'] == '') {
        validData = false;
        this.isLineItemsValid = false;
        break;
      }
    }

    const formData = this.projectEditForm.value;
    if (formData['ExtensionDate'] || formData['DueDate']) {
      this.isShowIcon = true;
    }
    return validData;
  }

  findTagByValue(tagValue, property) {
    const temp = this.tagsList.filter(item => item['value'] == tagValue);
    return temp[0] ? temp[0][property] : '';
  }

  setProject(event) {
    this.selProject = event;
    this.projectEditForm.controls['TemplateName'].setValue(event['value']);
    this.isTouched = true;
  }

  onSelectExtensionDate(select?) {
    this.clearAddTag('Add', 'Edate');
    this.validateForm(true);
    const formData = this.projectEditForm.value;
    if (formData['ExtensionDate'] == null) {
      const tags = this.projectEditForm.value.Tags;
      // tags.forEach((tag, index) => {
      //   if (tag == 2) tags.splice(index, 1)
      // });
      this.addTagItem(2);
      this.projectEditForm.controls['Tags'].setValue(tags);
    }
    this.onDatesChange(select);
  }

  calculateFinishDate(isFinishDateChangesRequired) {
    this.clearAddTag('Add', 'Ddate');
    this.validateForm(true);
    const parent = this;
    const formData = parent.projectEditForm.value;

    if (formData.DateReceived) {
      // if (isFinishDateChangesRequired && this.projectEditForm.value.Status != "Ready for Review" && this.projectEditForm.value.Status != "Completed" ) {
      //   this.statusText = "In Progress";
      //   this.statusClass = "Inprogress";
      // }
      if (isFinishDateChangesRequired && formData.TurnAround == 0) {
        parent.projectEditForm.controls['FinishDate'].setValue(null);
      } else if (isFinishDateChangesRequired && formData.TurnAround > 0) {
        formData['FinishDate'] = moment(formData['DateReceived'], 'DD-MM-YYYY')
          .add(formData.TurnAround, 'days')
          .utc()
          .toDate();
        parent.projectEditForm.controls['FinishDate'].setValue(formData['FinishDate']);
      } else if (formData.FinishDate) {
        const startDate = moment(formData.FinishDate, 'DD.MM.YYYY');
        const endDate = moment(formData.DateReceived, 'DD.MM.YYYY');
        //@NOTE: There has been issue with moment duration for some machines which returnes a float instead of integer
        // See: https://github.com/moment/moment/issues/3637
        const days = endDate.diff(startDate, 'days');
        const turnAroundDays = moment.duration(days, 'days').asDays();
        parent.projectEditForm.controls['TurnAround'].setValue(
          isNaN(Math.abs(turnAroundDays)) ? 0 : Math.abs(turnAroundDays)
        );
      }
    } else {
      parent.projectEditForm.controls['FinishDate'].setValue(null);
    }
    this.onDatesChange();
  }

  isDisableTag(value) {
    let temp = -1;
    if (
      this.selectedProjectDetails &&
      this.selectedProjectDetails.Tags &&
      this.selectedProjectDetails.Tags.length > 0
    ) {
      temp = this.selectedProjectDetails.Tags.indexOf(parseInt(value));
    }
    return temp >= 0 ? true : false;
  }

  addTagItem(value) {
    value = parseInt(value);
    //this.projectEditForm.markAsPristine()
    if (!this.isDisableTag(value)) {
      this.isFormValid = true;
      if (this.selectedProjectDetails.Tags && this.selectedProjectDetails.Tags.length > 0) {
        this.selectedProjectDetails.Tags.push(value);
        this.projectEditForm.value.Tags = this.selectedProjectDetails.Tags;
      } else if (this.projectEditForm.value.Tags && this.projectEditForm.value.Tags.length > 0) {
        this.projectEditForm.value.Tags.push(value);
      } else {
        this.selectedProjectDetails.Tags = [];
        this.selectedProjectDetails.Tags.push(value);
      }
      if (this.selectedProjectDetails.Tags.length > 0) {
        this.projectEditForm.controls['Tags'].setValue(this.selectedProjectDetails.Tags);
      } else {
        this.projectEditForm.controls['Tags'].setValue(null);
      }

      this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
      this.projectEditForm.markAsDirty();
      this.validateForm(true);
    }
  }

  fetchDdmSettings() {
    const parent = this;

    parent.companyTags = parent.encrDecSrvc.getObject(AppConstants.companyTags);

    if (!parent.companyTags) {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.fetchDDMSettings(parent.companyId).subscribe(
        (res: any) => {
          if (res.length > 0) parent.companyTags = res[0];
          else {
            parent.companyTags = {
              CompanyID: '',
              IsEmailProjectAssigned: false,
              IsEmailTaskAssigned: false,
              IsEmailTaskReady: false,
              IsManagerProjectInProgress: false,
              IsManagerReadyReview: false,
              IsProjectRejected: false,
              Tag1: 'Available for Work',
              Tag2: 'Extended',
              Tag3: 'Urgent',
              Tag4: 'Missing Information',
              Tag5: 'Waiting on Client',
              Tag6: 'Notice',
              Tag7: 'Telephone Call',
              Tag8: 'Tag 8',
              Tag9: 'Tag 9 ',
              ddmSettingsID: null,
              DefaultUserID: null,
              DefaultManagerID: null,
              isTrackingTimebyProjects: [false],
              isBudgetingProjectLevel: [false],
              TagArray2: []
            };
          }

          parent.encrDecSrvc.addObject(AppConstants.companyTags, parent.companyTags);
          parent.mangoAPISrvc.showLoader(false);
        },
        error => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
    parent.ddmSettingsObj = parent.companyTags;
    if (parent.ddmSettingsObj && Object.keys(parent.ddmSettingsObj).length > 0) {
      const tagArray = parent.ddmSettingsObj.TagArray2;
      tagArray?.map((item, i) => {
        if (item[0] != '') {
          const obj = {};
          obj['label'] = item[0];
          obj['value'] = (i + 1).toString();
          obj['color'] = item[1];
          obj['textColor'] =
            item[1] != ''
              ? this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb(item[1]))
              : this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb('#ffffff'));
          parent.tagsList.push(obj);
        }
      });

      /* for (
        let index = 0;
        index < Object.keys(parent.ddmSettingsObj).length;
        index++
      ) {
         const element = Object.keys(parent.ddmSettingsObj)[index];
        if (element.indexOf("DashboardProjectTags") == -1 && element.indexOf("Tag") > -1 && parent.ddmSettingsObj[element]) {
          let obj = {};
          obj["label"] = parent.ddmSettingsObj[element];
          obj["value"] = element.substring(3);
          obj["color"] = element;
          parent.tagsList.push(obj);
        }
      } */
    }
  }

  getStaffList() {
    const _this = this;

    this.AllManagerTypes.unshift({
      label: 'Unassigned',
      StaffName: 'Unassigned',
      value: -1,
      StaffID: -1,
      Email: null
    });

    this.StaffOptionsForTasks = [];

    _this.mangoAPISrvc.showLoader(true);

    _this.mangoAPISrvc.getPMAllStaffList().subscribe(
      (item: any) => {
        for (let i = 0; i < item.length; ++i) {
          if (item[i].Inactive) {
            continue;
          }

          const obj = {};

          obj['label'] = item[i]['StaffName'];
          obj['value'] = item[i]['StaffID'];
          obj['StaffID'] = item[i]['StaffID'];
          obj['StaffName'] = item[i]['StaffName'];
          obj['StaffNumber'] = item[i]['StaffNumber'];
          obj['Email'] = item[i]['Email'];

          this.AllManagers.push(obj);

          if (!this.isAllowLoadOtherUser && this.resourceId !== item[i]['StaffID']) {
            continue;
          }

          this.AllManagerTypes.push(obj);

          this.StaffOptionsForTasks.push(obj);
        }

        _this.mangoAPISrvc.showLoader(false);

        this.allStaffDetails = item;

        this.prepareData();
      },
      err => {
        _this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getProjectNames(cId) {
    const _this = this;
    _this.projectsList = [];
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getProjectNames(cId).subscribe(
      (data: any) => {
        for (let i = 0; i < data.length; ++i) {
          if (data[i]['TemplateName']) {
            const obj = {};
            obj['label'] = data[i]['TemplateName'];
            obj['value'] = data[i]['TemplateName'];
            obj['id'] = data[i]['ProjectNamesID'];
            _this.projectsList.push(obj);
          }
        }
        _this.mangoAPISrvc.showLoader(false);
      },
      err => {
        _this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getEmailSignature() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailSignature(parent.resourceId).subscribe(
      (response: any) => {
        parent.emailEditorValue = response.HtmlBodySignature ? response.HtmlBodySignature : null;
        parent.oldemailEditorValue = response.HtmlBodySignature ? response.HtmlBodySignature : null;

        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  deleteItems() {
    const self = this;
    event.stopPropagation();
    swal
      .fire({
        title: self.translate.instant('confirmation'),
        text: self.translate.instant('pm.do-you-want-to-delete-this-record'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: self.translate.instant('yes_delete'),
        cancelButtonText: self.translate.instant('no_delete')
      })
      .then(result => {
        if (result.value) {
          // static removing
          self.selectedItems.forEach((selectedItem, index) => {
            if (selectedItem.IsNewRecord) {
              const index = self.tasksDataSource.indexOf(selectedItem);
              self.tasksDataSource.splice(index, 1);
            }
          });
          if (self.tasksDataSource.length == 0) {
            self.selectedItems = [];
            return false;
          }
          self.tasksDataSource.forEach((selectedItem, index) => {
            if (selectedItem.IsNewRecord) {
              const index = self.selectedItems.indexOf(selectedItem);
              self.selectedItems.splice(index, 1);
            }
          });

          if (self.selectedItems.length > 0) {
            self.bulkDelete().subscribe(data => {
              const logdata = {};
              logdata['Action'] = 'Deleted Project Task/s';
              logdata['Description'] =
                'Task: ' +
                self.selectedItems
                  .map(x => {
                    return x.ProjectDetailsID;
                  })
                  .join(', ') +
                ` --${self.selClient['ClientName']}`;
              logdata['Table'] = '';

              if (!self.isManaging) {
                self.mangoAPISrvc.addUserLogs(logdata).subscribe(
                  res => {},
                  err => {
                    console.log(err);
                  }
                );
              }
              self.getProjectDetailsByHeaderId();
              self.mangoAPISrvc.notify(
                'success',
                self.translate.instant('Success'),
                AppConstants.deleteMessage
              );
            });
          }
        }
      });
  }

  correctTaskReady() {
    if (this.projectEditForm.get('IsCompleteInOrder').value) {
      const firstReadyTask = this.tasksDataSource.find(item => {
        const prevTask = this.tasksDataSource.find(prev => prev['TaskRow'] == item['TaskRow'] - 1);
        if (prevTask && prevTask['IsCompleted'] && item && !item['IsCompleted']) {
          return item;
        } else if (!prevTask && item && !item['IsCompleted']) {
          return item;
        }
      });
      if (firstReadyTask) firstReadyTask['IsTaskReady'] = true;
      if (firstReadyTask) {
        this.tasksDataSource
          .filter(item => item['TaskRow'] > firstReadyTask['TaskRow'])
          .forEach(item => {
            item['IsTaskReady'] = false;
          });
      }
    } else {
      this.tasksDataSource.map(x => {
        if (!x.IsCompleted) x.IsTaskReady = true;
      });
    }
  }

  rowReordered() {}

  getProjectDetailsByHeaderId() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc
      .fetchprojectDetailsByHeaderId(parent.projectEditForm.value.ProjectHeaderID)
      .subscribe(
        (responseData: any) => {
          const taskList = responseData.taskList;
          const data = taskList;

          data.map(function (lineObj, index) {
            parent.getStaffNames(lineObj['UserAssignedIDArray'], lineObj);
            lineObj.IsNewRecord = false;
            lineObj.isEditRecord = false;
            lineObj.IsCompleted = lineObj.IsCompleted ? lineObj.IsCompleted : false;
            lineObj.IsTaskReady = lineObj.IsTaskReady ? lineObj.IsTaskReady : false;
            lineObj['TaskRow'] = lineObj['TaskRow'] ? lineObj['TaskRow'] : index + 1;
            lineObj['IsRepeatTask'] = lineObj['IsRepeatTask'] ? lineObj['IsRepeatTask'] : false;
            lineObj.DueDate = lineObj.DueDate ? moment(lineObj.DueDate).toDate() : null;

            lineObj.ScheduledDate = lineObj.ScheduledDate
              ? moment(lineObj.ScheduledDate).toDate()
              : null;

            lineObj.CompletionDate = lineObj.CompletionDate
              ? moment(lineObj.CompletionDate).toDate()
              : null;

            lineObj['OverrideFirmDefaults'] = null;

            lineObj['dueDateOffsetDaysIndicator'] = lineObj['dueDateOffsetDaysIndicator'] ?? 1;
            // lineObj['DueDate'] = lineObj['DueDate'] ? moment(lineObj['DueDate']).toDate() : null;
          });
          data.sort(function (a, b) {
            return a.TaskRow - b.TaskRow;
          });
          const totalReadyTask = data.filter(item => item['IsTaskReady'] == true);
          if (data.length > 1) {
            if (totalReadyTask.length == 0) {
              data[0]['IsTaskReady'] = true;
            }
            // else {
            //   let lastObj = data[totalReadyTask.length - 1];
            //   let index = data.findIndex(
            //     (item) => item.ProjectDetailsID == lastObj.ProjectDetailsID
            //   );
            //   if (data.length > index + 1) {
            //     data[index + 1]["IsTaskReady"] = true;
            //   }
            // }
          }
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
          // if(parent.tasksDataSource.length != data.length) {
          //   let newRows = data.length - parent.tasksDataSource.length;
          //   for(var i = 0; i < newRows; i++) {
          //     parent.tasksDataSource.push(data[parent.tasksDataSource.length+i]);
          //   }
          // } else {
          //   parent.tasksDataSource = data;
          // }

          const editedRows = parent.tasksDataSource.filter(result => result.Edited === true);
          data.map(item => {
            editedRows.forEach(task => {
              if (item.ProjectDetailsID === task.ProjectDetailsID) {
                task.Edited === true ? (item['Edited'] = true) : delete item.Edited;
                task.TaskDescriptionChanged = true
                  ? (item['TaskDescriptionChanged'] = true)
                  : delete item.TaskDescriptionChanged;
                task.DueDateChanged === true
                  ? (item['DueDateChanged'] = true)
                  : delete item.DueDateChanged;
                task.AssignedChanged === true
                  ? (item['AssignedChanged'] = true)
                  : delete item.AssignedChanged;
              }
            });
          });

          parent.tasksDataSource = data;

          if (
            parent.selectedProjectDetails['DateReceived'] &&
            parent.selectedProjectDetails['automaticComputeDuedate'] == true
          ) {
            parent.computeDueDate(1, 0);
          }

          parent.correctTaskReady();
          parent.totalProcessLen = parent.tasksDataSource.filter(
            item => item['IsCompleted'] == true
          ).length;
          const prevStatus = this.projectEditForm.value.Status;
          parent.applyStatus(false);
          if (prevStatus != this.projectEditForm.value.Status) this.saveAllTasks();
          parent.mangoAPISrvc.showLoader(false);
        },
        err => {
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), err.message);
          parent.mangoAPISrvc.showLoader(false);
        }
      );
  }

  changetoUncomplete() {
    this.statusText = 'Pending';
    this.statusClass = 'pending';
    this.projectEditForm.controls['Status'].setValue(this.statusText);
    this.projectEditForm.controls['isCompleted'].setValue(false);
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
    this.saveAllTasks();
  }

  applyStatus(isModified) {
    if (this.totalProcessLen > 0 && this.tasksDataSource.length > this.totalProcessLen) {
      this.statusText = 'In Progress';
      this.statusClass = 'Inprogress';
    } else if (this.totalProcessLen > 0 && this.tasksDataSource.length == this.totalProcessLen) {
      if (
        this.projectEditForm.value.Status == 'Ready for Review' ||
        this.projectEditForm.value.Status == 'Completed'
      ) {
        if (
          this.projectEditForm.value.isReviewRequired == true &&
          this.projectEditForm.controls['isCompleted'].value == false
        ) {
          this.statusText = 'Ready for Review';
          this.statusClass = 'review';
          this.projectEditForm.controls['isCompleted'].setValue(false);
        } else {
          this.statusText = 'Completed';
          this.statusClass = 'completed';
        }
      } else if (
        this.projectEditForm.value.isReviewRequired == true &&
        this.projectEditForm.value.Status !== 'Completed'
      ) {
        this.statusText = 'Ready for Review';
        this.statusClass = 'review';
        this.projectEditForm.controls['isCompleted'].setValue(false);
      } else {
        this.statusText = 'Completed';
        this.statusClass = 'completed';
      }
    } else if (this.totalProcessLen == 0) {
      this.statusText = 'Pending';
      this.statusClass = 'pending';
    } else if (this.projectEditForm.value.Status == 'Completed') {
      this.statusText = 'Completed';
      this.statusClass = 'completed';
    }

    this.projectEditForm.controls['Status'].setValue(this.statusText);
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, isModified);
  }

  bulkDelete() {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    parent.selectedItems.forEach((selectedItem, index) => {
      observableBatch.push(
        this.http.delete(
          `${environment.API_URL}/ddm/projectDetails/delete/${selectedItem.ProjectDetailsID}`,
          { headers: headers, withCredentials: true }
        )
      );
    });
    return forkJoin(observableBatch);
  }

  setAWSOBject() {
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = environment.AWS_REGION;
    this.awsBucket = new AWS.S3({
      params: { Bucket: environment.AWS_BUCKET_NAME },
    });
    */
    this.awsBucket = AWSBucketMask;
  }

  /* Start - For New Projects SplitButton */
  createProjectScreen() {
    this.isDisplayDetailsTable = false;
    this.isRadioSelected = false;
    this.encrDecSrvc.getObject(AppConstants.ddmProjectDetails);
    this.encrDecSrvc.getObject(AppConstants.ddmClientName);
    this.initializeForm();
    this.prepareData();
    this.router.navigate(['project-management/projectDetails']);
  }

  getCompanyTemplateDetails() {
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.getCompanyTemplateHeaderByCompanyId(this.companyId).subscribe(
      (data: any) => {
        _this.templateDataSource = data;
        _this.mangoAPISrvc.showLoader(false);
      },
      error => {
        _this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  redirectProject() {
    this.tasksDataSource = [];
    this.encrDecSrvc.removeObject(AppConstants.ddmProjectDetails);
    this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);
    this.encrDecSrvc.removeObject(AppConstants.ddmClientName);
    this.initializeForm();
    this.prepareData();
    this.router.navigate(['project-management/projectDetails']);
  }

  radioCompanyClicked(event, item) {
    this.isRadioSelected = true;
    this.encrDecSrvc.addObject(AppConstants.fromCompanyTemplate, JSON.stringify(item));
  }
  /* End - For New Projects SplitButton */

  getShortCutLabels() {
    const parent = this;

    parent.projectEditForm.valueChanges.subscribe(data => {
      data.ProjectMemo = parent.replaceShortcuts(data.ProjectMemo);
      parent.projectEditForm.controls['ProjectMemo'].setValue(data.ProjectMemo, {
        emitEvent: false
      });
    });
  }

  replaceShortcuts(value, property?, item?) {
    if (!value) {
      return;
    }
    const valueArr = value.split(' ');
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut['Inactive']) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }

      valueArr[i] = label;
    }
    if (property === 'ProjectNotes') {
      this.noteHistory = valueArr.join(' ');
      return;
    } else if (property === 'editNotes') {
      item.NoteEdited = valueArr.join(' ');
    } else return valueArr.join(' ');
  }

  findChoices(searchText: string) {
    return this['viewContainerRef']['parentInjector']['view']['component'][
      'rawshortcutLabels'
    ].filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
  }

  getChoiceLabel(choice: string) {
    return `${choice} `;
  }
  /* End - For Shortcut labels */

  initializeForm() {
    const projectDetailsEncrypted = this.encrDecSrvc.getObject(AppConstants.ddmProjectDetails);
    
    if (projectDetailsEncrypted) {
      try {
        const projectDetailsValues = JSON.parse(projectDetailsEncrypted);
        this.EngagementTypeIDAsigned = projectDetailsValues.EngagementTypeID;
      } catch (error) {
        console.error('We got an error JSON:', error);
      }
    } else {
      console.warn('null value');
    }

    this.projectEditForm = this._fb.group({
      ProjectHeaderID: [''],
      EngagementTypeID: [this.EngagementTypeIDAsigned ?? ''],
      CompanyID: [this.companyId],
      UserAssignedID: [''],
      DateReceived: [null],
      DueDate: [null],
      FinishDate: [null],
      TurnAround: [null],
      ActualDueDate: [null],
      CompletionDate: [null],
      ExtensionDate: [null],
      ManagerID: [this.resourceId],
      ProjectMemo: [''],
      isReviewRequired: [false],
      Repeat: [''],
      Tags: [''],
      isCompleted: [false],
      Status: ['Pending'],
      isProject: ['P'],
      ClientID: [''],
      NextDueDate: [null],
      TemplateName: ['', [<any>Validators.required]],
      IsAssignedSent: [false],
      IsFailedSent: [false],
      IsInProgressSent: [false],
      IsReadyReviewSent: [false],
      IsTaskAssignedSent: [false],
      IsCompleteInOrder: [false],
      automaticComputeDuedate: [false],
      isNewCreated: [false],
      IsRepeatTask: [false],
      ProjectMasterID: [null],
      TemplateWildcards: [''],
      isPreviousPeriodYearPolicy: [true],
      ImagineShareSyncID: [null],
      isCapacityPlanningEnabled: [false],
      isTaskBudgetEnabled: [false],
      isShowProjectsView: [false]
    });

    this.projectEditForm.valueChanges.subscribe(val => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.projectEditForm.pristine);
    });

    console.log(this.projectEditForm);
  }

  createRule() {
    this.projectEditForm.markAsDirty();
    this.isDisplayRuleDialog = false;
    this.projectEditForm.controls['DueDate'].setValue(
      this.ruledateSource ? new Date(this.ruledateSource) : null
    );
    this.projectEditForm.controls['NextDueDate'].setValue(
      this.ruleTwodateSource ? new Date(this.ruleTwodateSource) : null
    );
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`;
      colClass += xtraEl ? ` > ${xtraEl}` : '';
      if (clss == 'staff-name') $(colClass).click();
      else $(colClass).select();
    }, 50);
  }

  onRowEditInit(data, column?, index?) {
    this.removeRowEditMode(true);
    this.table.editingRowKeys[data.TaskRow] = true;

    if (this.isRowEdit && this.lastRowIndex == index) return;

    this.lastRowIndex = index;

    if (column === 'TaskDescription') {
      this.selectCell('task-desc', index);
    } else if (column === 'CompletionDate') {
      this.selectCell('complete-date', index);
    } else if (column === 'DueDate') {
      this.selectCell('due-date', index);
    } else if (column === 'StaffName') {
      this.selectCell('staff-name', index, '.p-multiselect-trigger');
    } else if (column === 'OffsetDays') {
      this.selectCell('offset-days', index);
    } else if (column === 'ScheduledDate') {
      this.selectCell('scheduled-date', index);
    }
    this.isRowEdit = true;
  }

  checkIfRemoveEdit() {
    if (this.isCheckTriggered) return;

    this.isCheckTriggered = true;

    setTimeout(() => {
      const matches =
        document.activeElement.className.match(/tasks-row-edit/) ||
        document.activeElement.className.match(/p-datepicker/) ||
        document.activeElement.className.match(/p-multiselect/) ||
        document.activeElement?.tagName == 'INPUT'
          ? [1]
          : [];
      if (!matches || matches?.length == 0) {
        this.removeRowEditMode();
      }
      this.isCheckTriggered = false;
    }, 150);
  }

  removeRowEditMode(doNotRemoveFlag?) {
    this.table.editingRowKeys = {};

    if (!doNotRemoveFlag) this.isRowEdit = false;
  }

  scrollDownToNewRow(id) {
    if (id) {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        const el1 = document.querySelectorAll('.new-row');
        el1[0].querySelectorAll('input')[0]?.focus();
      }, 100);
    }
  }

  initializeProjectNoteSidebar() {
    this.projectNoteMailRecipientList = [];

    //adding client email
    if (this.selClient?.ClientName?.length > 0 && this.selClient.Email) {
      this.projectNoteMailRecipientList.push({
        label: `${this.selClient.ClientName} <${this.selClient.Email}>`,
        name: this.selClient.ClientName,
        value: this.selClient.Email
      });
    }

    this.selClient?.ContactsList?.forEach(x => {
      if (
        x.Email &&
        this.projectNoteMailRecipientList.filter(r => r.value == x.Email).length == 0
      ) {
        this.projectNoteMailRecipientList.push({
          label: `${x.ContactName} <${x.Email}>`,
          name: x.ContactName,
          value: x.Email
        });
      }
    });

    //adding company users
    this.AllManagers.forEach(x => {
      if (
        x.Email &&
        this.projectNoteMailRecipientList.filter(r => r.value == x.Email).length == 0
      ) {
        this.projectNoteMailRecipientList.push({
          label: `${x.label} <${x.Email}>`,
          name: x.label,
          value: x.Email
        });
      }
    });

    //sort
    this.projectNoteMailRecipientList.sort((a, b) => {
      const fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();
      if (fa < fb) return -1;
      if (fa > fb) return 1;
      return 0;
    });
  }

  onCloseProjectNotesSidebar() {
    this.isShowProjectNotes = !this.isShowProjectNotes;
  }

  redirectToImagineShare() {
    const clientID = this.projectEditForm.value.ClientID;
    this.mangoAPISrvc.getClientFullinformation(clientID).subscribe(
      (resultClient: any) => {
        if (resultClient) {
          const { IShareClientID, IShareCompanyID } = resultClient;
          this.mangoAPISrvc.getIShareUserApiToken().subscribe(
            (result: any) => {
              this.mangoAPISrvc.showLoader(false);
              let url = '';
              if (this.projectEditForm.value.ImagineShareSyncID) {
                url = `
                    ${environment.BASE_IMAGINETIME}/firm/${result.data?.firmID}/workspaces/${IShareClientID}/files?userapitoken=${result.data?.userapitoken}
                  `;
                window.open(url, '_blank').focus();
              } else {
                this.mangoAPISrvc.showLoader(true);
                this.mangoAPISrvc
                  .createISFolder({
                    ddmProjectHeaderID: this.projectEditForm.get('ProjectHeaderID').value,
                    clientID: this.projectEditForm.get('ClientID').value,
                    projectName: this.projectEditForm.get('TemplateName').value
                  })
                  .subscribe(
                    (resultCreate: any) => {
                      this.mangoAPISrvc.showLoader(false);
                      this.projectEditForm.controls['ImagineShareSyncID'].setValue(
                        resultCreate.data.ImagineShareSyncID
                      );
                      url = `
                      ${environment.BASE_IMAGINETIME}/firm/${result.data?.firmID}/files/${IShareClientID}/workspace/${resultCreate.data.ImagineShareSyncID}/folder?userapitoken=${result.data?.userapitoken}
                    `;
                      window.open(url, '_blank').focus();
                    },
                    err => {
                      this.mangoAPISrvc.showLoader(false);
                      console.log(err);
                      this.mangoAPISrvc.notify(
                        'error',
                        'Error',
                        'Error occurred while creating Imagine Share folder'
                      );
                    }
                  );
              }
            },
            error => {
              this.mangoAPISrvc.showLoader(false);
              this.mangoAPISrvc.notify('error', 'Error', AppConstants.fetchErrorMsg);
            }
          );
        }
      },
      error => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', 'Error', AppConstants.fetchErrorMsg);
      }
    );
  }

  onOpenProjectDocsSidebar() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getIsDMSIshareValue().subscribe((result: any) => {
      this.mangoAPISrvc.showLoader(false);
      if (result?.data?.isDMSIShare) {
        this.redirectToImagineShare();
      } else {
        this.isShowProjectDocs = !this.isShowProjectDocs;
      }
    });
  }

  onCloseProjectDocsSidebar() {
    this.isShowProjectDocs = !this.isShowProjectDocs;
  }

  onClickRepeatBtn() {
    if (this.projectEditForm.get('DueDate').value == null) {
      swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: this.translate.instant('pm.due_date_required'),
        showConfirmButton: true
      });
    }
  }

  showBudgetDialog() {
    if (this.subscriptionLevel !== 'ENTERPRISE' && this.subscriptionLevel !== 'FREE') {
      swal.fire({
        title: this.translate.instant('access_denied'),
        html: '<div>This feature is only available for <strong>PRO</strong> plan.</div>', //ENTERPRISE is now labeled as PRO
        icon: 'warning',
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (!this.authGuard.isAllowAccess(28)) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: 'Your permission level does not allow access to this feature.'
      });
      return;
    }

    if (this.encrDecSrvc.getObject(AppConstants.isFormChanged)) {
      Swal.fire({
        title: this.translate.instant('Warning'),
        html: this.translate.instant('pm.unsave_for_budgets'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('Yes_Proceed'),
        cancelButtonText: this.translate.instant('No')
      }).then(result => {
        if (result.value) {
          this.saveAllTasks(true);
        } else {
          this.openBudgetsDialog();
        }
      });
    } else {
      this.openBudgetsDialog();
    }
  }

  get isAllowLoadOtherUser() {
    return this.authGuard.isAllowAccess(23);
  }

  openBudgetsDialog() {
    this.budgetObject = {
      ClientID: this.projectEditForm.get('ClientID').value,
      TemplateName: this.projectEditForm.get('TemplateName').value,
      ProjectMasterID: this.projectEditForm.get('ProjectMasterID').value,
      ProjectMasterLabel: this.engagementListItems?.find(
        item => item.value == this.projectEditForm.get('ProjectMasterID').value
      )?.label,
      ddmProjectHeaderID: this.projectEditForm.get('ProjectHeaderID').value
    };
    this.isShowBudgetsDialog = true;
  }

  onCloseBudgetDialog() {
    this.isShowBudgetsDialog = false;
  }

  showTimeEntryDialog(data) {
    const selectedClient = this.clientListDatasource.filter(
      client => client.ClientID == this.projectEditForm.get('ClientID').value
    )[0];
    data['isEditFlow'] = false;
    data['isFromProject'] = true;
    data['projectObject'] = {
      ClientID: this.projectEditForm.get('ClientID').value,
      ClientName: selectedClient['ClientName'],
      MarkSlipsBilled: selectedClient['MarkSlipsBilled'],
      Billable: selectedClient['Billable'],
      ProjectMasterID: this.projectEditForm.get('ProjectMasterID').value,
      ddmProjectHeaderID: this.projectEditForm.get('ProjectHeaderID').value
    };
    data['selectedDate'] = new Date();
    this.sharedService.openTimeEntryDialog(data);
  }

  openTimerDialog(rowData) {
    if (!rowData.ProjectDetailsID) {
      swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'The project should be saved first before you can create a timer using this task.',
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok'
      });
      return false;
    }

    const selectedClient = this.clientListDatasource.filter(
      client => client.ClientID == this.projectEditForm.get('ClientID').value
    )[0];
    const data = {
      display: true,
      isEditFlow: false,
      isFromProject: true,
      projectObject: {
        ClientID: this.projectEditForm.get('ClientID').value,
        ClientName: selectedClient['ClientName'],
        MarkSlipsBilled: selectedClient['MarkSlipsBilled'],
        Billable: selectedClient['Billable'],
        ProjectMasterID: this.projectEditForm.get('ProjectMasterID').value,
        ddmProjectHeaderID: this.projectEditForm.get('ProjectHeaderID').value,
        ddmProjectDetailID: rowData['ProjectDetailsID'],
        Memo: rowData.TaskDescription
      },
      selectedDate: new Date()
    };
    this.sharedService.openTimerDialog(data);
  }

  updateTimeRecords(event) {
    if (!this.isCreateFlow) {
      if (this.previousEngagement) {
        if (!this.ddmSettingsObj.isTrackingTimebyProjects) {
          this.validateForm(true);
          this.previousEngagement = null;
          this.isEngagementChanged = true;
          this.isEngagementNotSaved = true;
        } else {
          Swal.fire({
            title: this.translate.instant('Warning'),
            html: this.translate.instant('pm.on_update_engagement'),
            icon: 'warning',
            showCancelButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: this.translate.instant('Yes_Proceed'),
            cancelButtonText: this.translate.instant('no_cancel')
          }).then(result => {
            if (result.value) {
              this.validateForm(true);
              this.previousEngagement = null;
              this.isEngagementChanged = true;
              this.isEngagementNotSaved = true;
            } else {
              this.projectEditForm.controls['ProjectMasterID'].setValue(this.previousEngagement);
              this.previousEngagement = null;
            }
          });
        }
      } else {
        this.isEngagementNotSaved = true;
        this.isEngagementChanged = true;
        this.validateForm(true);
      }
    } else {
      this.validateForm(true);
    }
  }

  setPreviousEngagement() {
    if (!this.previousEngagement)
      this.previousEngagement = this.projectEditForm.get('ProjectMasterID').value;
  }

  openClient() {
    this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, this.selClient);
    this.encrDecSrvc.addObject(AppConstants.clientID, this.selClient?.ClientID);
    this.encrDecSrvc.addObject(AppConstants.ClientName, this.selClient?.ClientName);
    this.sharedService.selClient = this.selClient;
    this.router.navigate([`/client/view`]);
  }

  private getChangedProperties(): string[] {
    const changedProperties = [];

    Object.keys(this.projectEditForm.controls).forEach(name => {
      const currentControl = this.projectEditForm.controls[name];

      if (currentControl.dirty) {
        changedProperties.push(name);
      }
    });

    return changedProperties;
  }

  handleSelectCloneClient(event) {
    this.selClientClone = event;
  }

  handleCloneProject() {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: this.translate.instant('pm.clone_question'),
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: this.translate.instant('Yes_Proceed'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then(result => {
      if (result.value) {
        if (!!this.projectEditForm.value.ProjectHeaderID && !!this.selClientClone?.ClientID) {
          this.mangoAPISrvc.showLoader(true);
          this.mangoAPISrvc
            .cloneProjectToAnotherClient({
              ProjectHeaderID: this.projectEditForm.value.ProjectHeaderID,
              ClientID: this.selClientClone.ClientID
            })
            .subscribe(result => {
              this.mangoAPISrvc.notify(
                'success',
                this.translate.instant('Success'),
                this.translate.instant('pm.clone_successful')
              );
              this.selClientClone = { ClientID: null, ClientName: '' };
              this.showCloneModal = false;
              this.mangoAPISrvc.showLoader(false);
            });
        }
      }
    });
  }

  onCloseTemplateDialog() {
    this.isDisplayDetailsTable = false;
  }

  onCloseTemplateWildcardsDialog() {
    this.projectEditForm.controls['TemplateWildcards'].setValue(this.previousTemplateWildcards);
    this.projectEditForm.controls['isPreviousPeriodYearPolicy'].setValue(
      this.lastIsPreviousPeriodYearPolicy
    );

    this.previousTemplateWildcards = '';
    this.lastIsPreviousPeriodYearPolicy = null;
    this.isDisplayTemplateWildcardsDialog = false;
    this.projectEditForm.controls['TemplateWildcards'].markAsPristine();
    this.projectEditForm.controls['isPreviousPeriodYearPolicy'].markAsPristine();
  }

  validateEnteredWildcards(str: string, repeat: RepeatEnum): boolean {
    switch (repeat) {
      case RepeatEnum.YEAR:
        if (this.forbiddenWildcards.YEAR.some(v => str.includes(v))) {
          this.showSwalForbiddenWildcards(this.allowedWildcards.YEAR, repeat);
          return false;
        }
        return true;
      case RepeatEnum.QUARTERLY:
        if (this.forbiddenWildcards.QUARTER.some(v => str.includes(v))) {
          this.showSwalForbiddenWildcards(this.allowedWildcards.QUARTER, repeat);
          return false;
        }
        return true;
      case RepeatEnum.MONTHLY:
      case RepeatEnum.SEMIMONTHLY:
        if (this.forbiddenWildcards.MONTH.some(v => str.includes(v))) {
          this.showSwalForbiddenWildcards(this.allowedWildcards.MONTH, repeat);
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  showSwalForbiddenWildcards(allowedWildcardsArr: string[], repeat: RepeatEnum) {
    Swal.fire({
      title: this.translate.instant('Warning'),
      text: `The repeat value is ${repeat} and you can only use these wildcards: ${allowedWildcardsArr.join(
        ', '
      )}`,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonText: this.translate.instant('Ok')
    });
  }

  onSaveTemplateWildcards() {
    const formObj = this.projectEditForm.value;

    const isValid = this.validateEnteredWildcards(formObj['TemplateWildcards'], formObj['Repeat']);
    if (!isValid) return;

    this.projectEditForm.controls['TemplateName'].setValue(
      this.mangoUtils.replacePMCaretTemplate(
        formObj['TemplateWildcards'],
        formObj.DueDate,
        formObj.isPreviousPeriodYearPolicy,
        formObj['Repeat']
      )
    );
    formObj['TemplateName'] = this.projectEditForm.value['TemplateName'];

    if (this.isCreateFlow) {
      this.projectEditForm.controls['TemplateWildcards'].markAsPristine();
      this.projectEditForm.controls['TemplateName'].markAsPristine();
      this.projectEditForm.controls['isPreviousPeriodYearPolicy'].markAsPristine();
      this.projectEditForm.controls['Repeat'].markAsPristine();
      this.isDisplayTemplateWildcardsDialog = false;
      return;
    }

    const itemToSave = {
      TemplateWildcards: formObj.TemplateWildcards,
      TemplateName: formObj.TemplateName,
      Repeat: formObj.Repeat
    };

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateProjectHeader(formObj.ProjectHeaderID, itemToSave).subscribe(
      result => {
        this.isTouched = false;
        this.mangoAPISrvc.showLoader(false);
        this.projectEditForm.controls['TemplateWildcards'].markAsPristine();
        this.projectEditForm.controls['TemplateName'].markAsPristine();
        this.projectEditForm.controls['isPreviousPeriodYearPolicy'].markAsPristine();
        this.projectEditForm.controls['Repeat'].markAsPristine();
        this.isDisplayTemplateWildcardsDialog = false;
      },
      error => {
        this.isTouched = false;
        this.projectEditForm.controls['TemplateWildcards'].markAsPristine();
        this.projectEditForm.controls['TemplateName'].markAsPristine();
        this.projectEditForm.controls['isPreviousPeriodYearPolicy'].markAsPristine();
        this.projectEditForm.controls['Repeat'].markAsPristine();
        this.mangoAPISrvc.showLoader(false);
        this.isDisplayTemplateWildcardsDialog = false;
      }
    );
  }

  onOpenDisplayTemplateWildcardsDialog() {
    const formObj = this.projectEditForm.value;

    if (
      formObj.Repeat === null ||
      formObj.Repeat === '' ||
      formObj.Repeat === 'none' ||
      formObj.Repeat === 'custom'
    ) {
      Swal.fire({
        title: this.translate.instant('Warning'),
        text: this.translate.instant(`pm.wildcards_not_allowed_on_empty_repeat`),
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: this.translate.instant('Ok')
      });
      return;
    }

    if (!formObj['TemplateWildcards']) {
      this.projectEditForm.controls['TemplateWildcards'].setValue(formObj['TemplateName']);
    }

    this.isDisplayTemplateWildcardsDialog = true;
    this.previousTemplateWildcards = this.projectEditForm.value['TemplateWildcards'];
    this.lastIsPreviousPeriodYearPolicy = this.projectEditForm.value['isPreviousPeriodYearPolicy'];
  }

  onTaskDescriptionChange(data, index) {
    this.tasksDataSource.map(item => {
      if (item.TaskRow === index + 1) {
        item.Edited = true;
        item.TaskDescriptionChanged = true;
      }
    });
  }

  onTemplateNameChanged(event) {
    if (this.isCreateFlow) return;

    this.projectEditForm.controls['TemplateWildcards'].setValue(event.target.value);
  }

  onFocusTemplateName() {
    if (this.isCreateFlow) return;
    if (this.projectEditForm.value['TemplateWildcards']?.includes('^')) {
      Swal.fire({
        title: this.translate.instant('Warning'),
        text: this.translate.instant('pm.template_wildcards_warning'),
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: this.translate.instant('Ok')
      });
    }
  }

  disableSave(){
    if (this.note)
      return false;
    else
    return (this.projectEditForm.invalid || this.projectEditForm.pristine || !this.isLineItemsValid)
  }

  inputChanged(){
    this.note = true;
    this.validateForm(true); 
  }

}
