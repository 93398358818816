<div class="card">
  <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
    <div class="p-col-12 p-py-2" *ngIf="isBatch">
      <div class="p-col-12  required-field">
        <label class="label-text" style="width: 100%;">
          {{'engagement.type' | translate}}
        </label>
      </div>
      <div class="p-py-2 p-col-12">
        <p-dropdown [options]="engagementList" placeholder="Select Engagement" [(ngModel)]="engagementTypeId"
            name="EngagementTypeID" (onChange)="onEngagementChanged()">
        </p-dropdown>
      </div>
    </div>
    <div class="p-col-12 p-py-2 required-field">
      <label class="label-text">Proposal Name</label>
    </div>
    <div class="p-py-2" [ngClass]="(isBatch)?'p-col-12':'p-col-4'">
      <input
        class="width-100p"
        type="text"
       
        pInputText
        [(ngModel)]="generalObj.ProposalName"
        (ngModelChange)="onChangeObject()"
        (focus)="$event.target.select()"
        [ngModelOptions]="{ standalone: true }" />
    </div>
    <div class="p-col-12 p-py-2 required-field" *ngIf="!isBatch">
      <label class="label-text">Client Name</label>
    </div>
    <div class="p-col-4 p-py-2" *ngIf="!isBatch">
      <p-autoComplete [(ngModel)]="generalObj.Client" [ngModelOptions]="{ standalone: true }"
          panelStyleClass="client-dropdown-autocomplete" autocomplete="off" [suggestions]="filteredClients"
          (completeMethod)="filterClients($event)" field="ClientName" (onSelect)="handleSelectClick($event.value)"
          ngDefaultControl placeholder="{{ 'Search-Clients' | translate }}" (onFocus)="$event.target.select()"
          [dropdown]="true" required>
        <ng-template let-client pTemplate="item">
          <div>
            {{ client.ClientName }}{{ client.ClientNumber ? " - " + client.ClientNumber : "" }}
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
    <div class="p-col-12 p-py-2">
      <label class="label-text">Proposal start date</label>
    </div>
    <div class="p-py-2" [ngClass]="(isBatch)?'p-col-8':'p-col-3'">
      <p-selectButton
        [options]="effectiveFromOptions"
        [(ngModel)]="generalObj.EffectiveFrom"
        (ngModelChange)="onChangeObject();onChangeContractMonth();"
        optionLabel="label"
        optionValue="value"></p-selectButton>
    </div>
    <div *ngIf="generalObj.EffectiveFrom === 'On a specific date'" class="p-col p-py-2">
      <p-calendar
        [monthNavigator]="true"
        [yearNavigator]="true"
        yearRange="2000:2090"
        [(ngModel)]="generalObj.SpecificStartDate"
        (ngModelChange)="onChangeObject();onChangeContractMonth();"></p-calendar>
    </div>
    <div class="p-col-12 p-py-2 required-field">
      <label class="label-text">Minimum Contract Length</label>
    </div>
    <div class="p-py-2 " [ngClass]="(isBatch)?'p-col-12':'p-col-4'">
      <div class="p-formgroup-inline">
        <div class="p-field p-col-6">
          <p-inputNumber
            [(ngModel)]="generalObj.MinContractMonths"
            (ngModelChange)="onChangeObject();onChangeContractMonth();"
            inputStyleClass="p-text-right"
            [min]="1"
            [max]="36"
            autocomplete="off"
          >
          </p-inputNumber>
        </div>
        <div class="p-field p-col-6 p-my-auto">
          <label>month/s</label>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-py-2">
      <label class="label-text">Minimum contract term</label>
    </div>
    <div class="p-col-12 p-py-2">
      <label>{{minContractTerm}}</label>
    </div>
    <div class="p-col-12 p-py-2">
      <p-checkbox
        [(ngModel)]="generalObj.isEmailReminderEnabled"
        (onChange)="toggleEmailReminderInterval($event)"
        label="Turn on Email Reminder Interval"
        binary="true"></p-checkbox>
    </div>
    <div class="p-col-1 p-py-2" *ngIf="generalObj.isEmailReminderEnabled">
      <label class="label-text">Every</label>
      <p-inputNumber
        [(ngModel)]="generalObj.ProposalReminderInterval"
        (ngModelChange)="onChangeObject()"
        inputStyleClass="p-text-right"
        [min]="1"
        autocomplete="off"></p-inputNumber>
      <label class="label-text">day(s)</label>
    </div>
  </div>
</div>
