import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppConstants } from "@app/_helpers/api-constants";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  AuthGuard,
} from "@app/_services";
import { Table } from "primeng/table";
import moment from "moment";
import { SelectItem } from "primeng/api";
import Swal from "sweetalert2";
import $ from "jquery";
import { timer } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Dropdown } from "primeng/dropdown";
declare let numeral: any;
enum BillableActualEnum {
  BILLABLE = "billable",
  ACTUAL = "actual",
}

@Component({
  selector: "app-time-card",
  templateUrl: "./time-card.component.html",
})
export class TimeCardComponent implements OnInit, AfterViewInit {
  public readonly billableTimeColumn = "totalTime";
  public readonly actualTimeColumn = "ElaspedTime";
  public readonly EngagementName = "EngagementName";
  public readonly ServiceCode = "ServiceCode";
  isDropdownClicked: boolean = false;
  serviceCodeDropdown: Dropdown;
  isServiceCodeOpen: boolean = false;
  isEngagementNameOpen: boolean = false;
  public staffID: any = null;
  public targetElement = null;
  public staffList: SelectItem[];
  public dateRangeList: SelectItem[];
  public selectedWeekDate = new Date();
  public momentWeek: moment.unitOfTime.StartOf = "isoWeek";
  public startDate = moment().startOf(this.momentWeek).toDate();
  public endDate = moment().endOf(this.momentWeek).toDate();
  public dateList: any[] = [];
  public clients: any = [];
  public activityList: any = [];
  public engagementsList: any = [];
  public companyGlobalSetting: any = {};
  public isFormChanged: boolean = false;
  protected billingProfiles: any[] = [];
  public showAddMemo: boolean = false;
  public isValidForm: boolean = false;
  public rowIndex: number = null;
  public selField: string = null;
  public staffDetails: any = {};
  public timeCardCols: any[];
  public workLocationsList: any = [];
  public mainClientList: any = [];
  public clientList: any = [];
  public filteredClients: any[];
  public selClient: any = null;
  public companyLocationObj: any = null;
  companyId;
  billableActual: BillableActualEnum = BillableActualEnum.BILLABLE;
  isRoundEntries: boolean = false;

  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dataTableComponent: Table;
  filteredItemsSize = -1;
  searchTextStr: any = "";
  timeMemoRequired: boolean = false;
  isValidMemo: boolean = true;
  intervalid;

  public cols: any[];
  public _selectedColumns: any[];
  public globalFilterFields: any = [];

  constructor(
    private ref: ChangeDetectorRef,
    private mangoAPISrvc: MangoApiService,
    public mangoUtils: mangoUtils,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public auth: AuthGuard,
    public translate: TranslateService
  ) {
    this.mangoUtils.shouldFetchCode.next(true);
    this.translate.reloadLang(this.translate.currentLang).subscribe((data) => {
      this.breadcrumbService.setItems([
        { label: this.translate.instant("Time_&_Expense") },
        { label: this.translate.instant("time-card-entry"), icon: "ic-red" },
      ]);
      this.initializeColumn();
    });
    this.companyGlobalSetting = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this.companyGlobalSetting.isWorkLocationRequired = this.companyGlobalSetting
      .isWorkLocationRequired
      ? this.companyGlobalSetting.isWorkLocationRequired
      : false;
    this.timeMemoRequired = this.companyGlobalSetting.TimeMemoRequired
      ? this.companyGlobalSetting.TimeMemoRequired
      : false;
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.momentWeek = this.companyGlobalSetting?.TimeEntryFirstDayofWeek
      ? this.companyGlobalSetting.TimeEntryFirstDayofWeek == "monday"
        ? "isoWeek"
        : "week"
      : "isoWeek";
    this.startDate = moment().startOf(this.momentWeek).toDate();
    this.endDate = moment().endOf(this.momentWeek).toDate();
    this.getWorkLocations();
    this.getAllStaffList();
    this.getActivityGroups();
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  ngOnInit(): void {
    this.getDates();
  }

  @HostListener("document:keydown", ["$event"])
  onKeydownHandler(evt: KeyboardEvent) {
    if (evt.ctrlKey && evt.key == "i") {
      evt.preventDefault();
      this.addRow(this.clients.length);
    } else if (evt.key == "Escape") {
      this.removeRowEditMode();
    }
  }

  initializeColumn() {
    const allCols = [
      {
        field: "SlipMasterID",
        header: this.translate.instant("sys_id"),
        class: "width-7p p-text-left",
        editable: false,
      },
    ];

    let selectedCols = [];

    const defaultCols = [
      {
        field: "SlipMasterID",
        header: this.translate.instant("sys_id"),
        class: "width-7p p-text-left",
        editable: false,
      },
    ];

    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe(
        (data: any) => {
          if (data.TimeCardCols) {
            if (data.TimeCardCols?.length > 0) {
              selectedCols = allCols.filter((col) => data.TimeCardCols?.includes(col.field));
            }
          } else {
            selectedCols = [...defaultCols];
          }

          this.cols = [...allCols];

          this._selectedColumns = selectedCols;
          this.globalFilterFields = [...this.selectedColumns];
        },
        (err) => {
          selectedCols = [...defaultCols];

          this.cols = [...allCols];

          this.globalFilterFields = [...selectedCols];
        }
      );
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map((col) => col.field);
    this._selectedColumns = this.cols.filter((col) => arr.includes(col.field));
    this.globalFilterFields = [...this.selectedColumns];
  }

  isLocked(ddate) {
    if (!(this.auth.isAllowAccess(32) || this.auth.isSuperAdmin)) {
      if (
        this.companyGlobalSetting.isLockTime &&
        parseInt(this.companyGlobalSetting.LockTimeDays) > 0
      ) {
        const dDate = moment(ddate).format("MM/DD/YYYY");
        const dateNow = moment().format("MM/DD/YYYY");
        return moment(dateNow).diff(moment(dDate), "days") > this.companyGlobalSetting.LockTimeDays;
      }
    }
    return false;
  }

  saveAll(event) {
    const self = this;
    const rowsToInsert = [];
    const rowsToUpdate = [];
    const rowsToDelete = [];

    const callback = (data, i) => {
      const rowIndex = i + 1;
      if (!data["IsColumnChanges"]) {
        if (rowIndex == self.clients.length) {
          bulkSave();
        }
        return;
      }

      for (let i = 1; i <= 7; i++) {
        const weekday = data[`weekday${i}`];
        weekday["color"] = "";
        if (!weekday["isModified"]) {
          continue;
        }
        weekday["isModified"] = false;

        if (
          !self.staffID ||
          !data.ClientID ||
          !data.ServiceCodeID ||
          (weekday["totalTime"] == 0 && !weekday["SlipMasterID"])
        ) {
          console.log("not all parameters are selected");
          continue;
        }
        let isMarked = false;
        if (data.MarkSlipsBilled == true) {
          isMarked = true;
        }
        const tempDDate = moment(weekday["Ddate"]).format("MM/DD/YYYY");
        data.GroupDescriptionIDArray =
          data.GroupDescriptionIDArray && data.GroupDescriptionIDArray.length > 0
            ? data.GroupDescriptionIDArray
            : null;
        const item: any = {
          StaffID: numeral(self.staffID).value(),
          StaffDeptID: self.staffDetails.StaffDeptID,
          StaffPayRate: Number(self.staffDetails.AverageCostPerHour),
          StaffCost: Number(weekday["totalTime"]) * Number(self.staffDetails.AverageCostPerHour),
          ClientID: data.ClientID,
          CompanyID: self.companyId,
          ServiceCodeID: data.ServiceCodeID,
          ProjectMasterID: data.ProjectMasterID,
          EngagementTypeID: data.EngagementTypeID,
          Description: data.Description,
          Memo: weekday["Memo"],
          PrivateMemo: weekday["PrivateMemo"],
          TotalTime: weekday["totalTime"],
          ElaspedTime: weekday["ElaspedTime"],
          BillingRate: weekday["BillingRate"],
          Billable: weekday.Billable,
          Approved: data.Approved,
          Ddate: tempDDate,
          WorkCodeID: data.WorkCodeID,
          OriginatingPartnerID: data.OriginatingPartnerID,
          BillingPartnerID: data.BillingPartnerID,
          GroupDescriptionID: data.GroupDescriptionID,
          GroupDescriptionIDArray: data.GroupDescriptionIDArray,
          ClientTypeID: data.ClientTypeID,
          WorkLocation: weekday["WorkLocation"],
          Billed: isMarked,
          Tax: 0,
          IsTaxable: false,
          IsTimeRecord: "T",
        };

        const billingProfile = self.billingProfiles.filter((row) => {
          return (
            row.StaffID == item.StaffID &&
            row.ClientID == item.ClientID &&
            row.ServiceCodeID == item.ServiceCodeID
          );
        });

        if (data["isNew"] && billingProfile.length == 0) {
          continue;
        }

        if (!item.BillingRate) {
          item.BillingRate = billingProfile[0].BillingRate
            ? `${billingProfile[0].BillingRate}`
            : "0.00";
        }

        if (numeral(item.BillingRate).value() == 0) {
          self.mangoAPISrvc.notify(
            "warn",
            self.translate.instant("warning"),
            self.translate.instant("invoice.there_is_no_billing_rate")
          );
        }
        const billingRate =
          typeof item.BillingRate == "string"
            ? item.BillingRate.replace("$", "")
            : `${item.BillingRate}`;
        if (billingRate == 0) {
          item.StandardAmount = item.BillingRate;
        } else {
          if (isMarked && self.companyGlobalSetting?.isAdminTimeZero) item.StandardAmount = "0.00";
          else item.StandardAmount = (item["TotalTime"] * Number(billingRate)).toFixed(2);
        }

        self.calculateSalesTax(item);

        if (weekday["SlipMasterID"]) {
          item["SlipMasterID"] = weekday["SlipMasterID"];
          if (Number(item["TotalTime"]) > 0) {
            rowsToUpdate.push(item);
          } else {
            rowsToDelete.push(item);
          }
        } else {
          if (isMarked) {
            item["InvoiceDate"] = tempDDate;
          }
          rowsToInsert.push(item);
        }
      }
      data["isNew"] = false;
      data["IsColumnChanges"] = false;

      if (rowIndex == self.clients.length) {
        bulkSave();
      }
    };

    const bulkSave = () => {
      self.mangoAPISrvc.showLoader(true);
      self.mangoAPISrvc
        .bulkTimeSheetSave(self.companyId, { rowsToInsert, rowsToUpdate, rowsToDelete })
        .subscribe(
          (result) => {
            self.isValidTimeSheets();
            self.isFormChanged = false;
            this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
            self.refresh();
            this.mangoAPISrvc.notify(
              "success",
              this.translate.instant("Success_notify"),
              "Changes are saved."
            );
            self.mangoAPISrvc.showLoader(false);
          },
          (error) => {
            self.mangoAPISrvc.notify(
              "error",
              self.translate.instant("error"),
              AppConstants.fetchErrorMsg
            );
            self.mangoAPISrvc.showLoader(false);
          }
        );
    };

    const startProc = (num) => {
      if (num != self.clients.length) {
        return;
      }

      for (let i = 0; i < self.clients.length; i++) {
        const data = self.clients[i];
        callback(data, i);
      }
    };

    let callbackCount = 0;
    for (let i = 0; i < self.clients.length; i++) {
      const data = self.clients[i];
      if (!data["IsColumnChanges"]) {
        startProc(++callbackCount);
        continue;
      }
      self.mangoAPISrvc.showLoader(true);
      data["ClientID"] = data.ClientID.ClientID || data.ClientID;
      self.mangoAPISrvc
        .getBillingRateFromRateTemplate(data.ClientID, data.ServiceCodeID, self.staffID)
        .subscribe(
          (result: any) => {
            self.billingProfiles.push({
              StaffID: self.staffID,
              ClientID: data.ClientID,
              ServiceCodeID: data.ServiceCodeID,
              BillingRate: result.BillingRate,
            });
            self.mangoAPISrvc.showLoader(false);
            startProc(++callbackCount);
          },
          (error) => {
            self.mangoAPISrvc.notify(
              "error",
              self.translate.instant("error"),
              AppConstants.fetchErrorMsg
            );
            self.mangoAPISrvc.showLoader(false);
          }
        );
    }
  }

  reloadData() {
    const self = this;
    const startDate = moment(self.startDate).format("MM-DD-YYYY");
    const endDate = moment(self.endDate).format("MM-DD-YYYY");
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc
      .getTimeCard(self.companyId, "timecard", self.staffID, startDate, endDate)
      .subscribe(
        function (data: any) {
          self.getDates();
          self.clients = []; // YYYY-MMM-DD HH:mm A
          data.map((item) => {
            (item["engagementsList"] = []), (item["isClientBillable"] = item.Billable);
            item["isEngagementBillable"] = item.isBillable ? item.isBillable : false;
            item["isReadyOnly"] = true;
            item["isNew"] = false;
            item["Ddate"] = moment.utc(Date.parse(item["Ddate"])).format("YYYY-MM-DD HH:mm");
            item["isMerged"] = false;
            return item;
          });
          self.mergeData(data);
          self.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          self.mangoAPISrvc.notify(
            "error",
            self.translate.instant("error"),
            AppConstants.updateErrorMsg
          );
          self.mangoAPISrvc.showLoader(false);
        }
      );
  }

  mergeData(data) {
    const self = this;
    self.clients = [];
    this.mangoUtils.customSortFun(data, "Ddate", 1);
    for (let i = 0; i < data.length; i++) {
      const rowObj = data[i];
      if (rowObj["isMerged"]) {
        continue;
      }
      const weekdayTotal = 0;
      let dayIndex = 1;
      let isDuplicatesFound = false;
      let currentDate = moment(self.startDate).toDate();
      const stopDate = moment(self.endDate).toDate();
      // get duplicate records
      const duplicateRecords = data.filter((item) => {
        return (
          item.ClientID == rowObj.ClientID &&
          item.ServiceCodeID == rowObj.ServiceCodeID &&
          item.ProjectMasterID == rowObj.ProjectMasterID &&
          item.isMerged == false
        );
      });

      // Itarate One Week (7 days)
      while (currentDate <= stopDate) {
        const today = self.removeOffset(currentDate).toISOString();
        let timeStart: any = new Date(
          moment.utc(today).year(),
          moment.utc(today).month(),
          moment.utc(today).date(),
          moment().hour(),
          moment().minute(),
          0
        );
        timeStart = self.removeOffset(timeStart).toISOString();
        const cDate = moment(currentDate).format("MM/DD/YYYY");
        const nDate = moment(currentDate).format("MM/DD/YYYY");

        const defualtDayCellObj = {
          SlipMasterID: null,
          totalTime: 0,
          ElaspedTime: 0,
          isElapsedTimeChanged: false,
          isTotalTimeChanged: false,
          Ddate: cDate,
          Billed: false,
          Description: null,
          Memo: null,
          PrivateMemo: null,
          WorkCodeID: null,
          OriginatingPartnerID: null,
          BillingPartnerID: null,
          GroupDescriptionID: null,
          ClientTypeID: null,
          GroupDescriptionIDArray: null,
          BillingRate: null,
          Billable: false,
        };

        if (moment(currentDate).format("D-M-Y") == moment.utc(rowObj.Ddate).format("D-M-Y")) {
          defualtDayCellObj["SlipMasterID"] = rowObj.SlipMasterID;
          defualtDayCellObj["totalTime"] = rowObj.TotalTime ? numeral(rowObj.TotalTime).value() : 0;
          defualtDayCellObj["ElaspedTime"] = rowObj.ElaspedTime
            ? numeral(rowObj.ElaspedTime).value()
            : 0;
          defualtDayCellObj["isElapsedTimeChanged"] = false;
          defualtDayCellObj["isTotalTimeChanged"] = false;
          defualtDayCellObj["Ddate"] = nDate;
          defualtDayCellObj["Billed"] = rowObj.Billed;
          defualtDayCellObj["Billable"] = rowObj.Billable;
          defualtDayCellObj["Description"] = rowObj.Description;
          defualtDayCellObj["IsTimeRecord"] = "T";
          defualtDayCellObj["Memo"] = this.mangoUtils.replaceCaretTemplate(rowObj.Memo);
          defualtDayCellObj["PrivateMemo"] = this.mangoUtils.replaceCaretTemplate(
            rowObj.PrivateMemo
          );
          defualtDayCellObj["WorkCodeID"] = rowObj.WorkCodeID;
          defualtDayCellObj["OriginatingPartnerID"] = rowObj.OriginatingPartnerID;
          defualtDayCellObj["BillingPartnerID"] = rowObj.BillingPartnerID;
          defualtDayCellObj["GroupDescriptionID"] = rowObj.GroupDescriptionID;
          defualtDayCellObj["GroupDescriptionIDArray"] = rowObj.GroupDescriptionIDArray;
          defualtDayCellObj["ClientTypeID"] = rowObj.ClientTypeID;
          defualtDayCellObj["BillingRate"] = rowObj.BillingRate;
          defualtDayCellObj["WorkLocation"] = rowObj.WorkLocation ? rowObj.WorkLocation : null;
        }
        // if clientId/ProjectId/ServiceCodeId is Same Then we need to check Days
        rowObj[`weekday${dayIndex}`] = defualtDayCellObj;
        rowObj.weekdayTotal = numeral(weekdayTotal).value() || 0;
        currentDate = moment(currentDate).add("days", 1).toDate();
        //currentDate.setDate(currentDate.getDate() + 1);
        dayIndex++;
      }
      isDuplicatesFound = duplicateRecords.length > 1 ? true : false;
      if (isDuplicatesFound) {
        // sort by dates like mon/tue/web ... etc
        this.mangoUtils.customSortFun(duplicateRecords, "Ddate", 1);
        // checking the diff dates in duplicate records
        for (let duplicateIndex = 0; duplicateIndex < duplicateRecords.length; ++duplicateIndex) {
          // checking Index for Last record
          const nextIndex = duplicateRecords.length - 1 == duplicateIndex ? -1 : duplicateIndex + 1;
          if (nextIndex < 0) {
            break;
          }
          const currentDateObj = moment(duplicateRecords[duplicateIndex]["Ddate"]).toDate();
          const nextDateObj = moment(duplicateRecords[nextIndex]["Ddate"]).toDate();
          let currDayIndex = new Date(nextDateObj).getDay();

          if (this.companyGlobalSetting.TimeEntryFirstDayofWeek == "sunday") {
            currDayIndex = currDayIndex == 0 ? 1 : currDayIndex + 1; // sunday as Zero
          } else {
            currDayIndex = currDayIndex == 0 ? 7 : currDayIndex; // sunday as Zero
          }

          if (
            moment.utc(currentDateObj).format("D-M-Y") != moment.utc(nextDateObj).format("D-M-Y") &&
            !(rowObj[`weekday${currDayIndex}`]["totalTime"] > 0)
          ) {
            // TODO other days can exist
            if (!(rowObj[`weekday${currDayIndex}`]["totalTime"] > 0)) {
              const changeObj = duplicateRecords[nextIndex];

              const today = self.removeOffset(nextDateObj).toISOString();
              let timeStart: any = new Date(
                moment.utc(today).year(),
                moment.utc(today).month(),
                moment.utc(today).date(),
                moment().hour(),
                moment().minute(),
                0
              );
              timeStart = self.removeOffset(timeStart).toISOString();
              data.map((item) => {
                if (item.SlipMasterID == duplicateRecords[nextIndex].SlipMasterID) {
                  item["isMerged"] = true;
                }
                return item;
              });
              rowObj[`weekday${currDayIndex}`]["SlipMasterID"] = changeObj.SlipMasterID;
              rowObj[`weekday${currDayIndex}`]["totalTime"] =
                numeral(changeObj.TotalTime).value() || 0;
              rowObj[`weekday${currDayIndex}`]["ElaspedTime"] = changeObj.ElaspedTime
                ? numeral(changeObj.ElaspedTime).value()
                : 0;
              rowObj[`weekday${currDayIndex}`]["isElapsedTimeChanged"] = false;
              rowObj[`weekday${currDayIndex}`]["isTotalTimeChanged"] = false;
              rowObj[`weekday${currDayIndex}`]["Ddate"] = moment(nextDateObj).format("MM/DD/YYYY");
              rowObj[`weekday${currDayIndex}`]["Billed"] = changeObj.Billed;
              rowObj[`weekday${currDayIndex}`]["Billable"] = changeObj.Billable;
              rowObj[`weekday${currDayIndex}`]["Description"] = changeObj.Description;
              rowObj[`weekday${currDayIndex}`]["Memo"] = this.mangoUtils.replaceCaretTemplate(
                changeObj.Memo
              );
              rowObj[`weekday${currDayIndex}`]["IsTimeRecord"] = "T";
              rowObj[`weekday${currDayIndex}`]["PrivateMemo"] =
                this.mangoUtils.replaceCaretTemplate(changeObj.PrivateMemo);
              rowObj[`weekday${currDayIndex}`]["WorkCodeID"] = changeObj.WorkCodeID;
              rowObj[`weekday${currDayIndex}`]["OriginatingPartnerID"] =
                changeObj.OriginatingPartnerID;
              rowObj[`weekday${currDayIndex}`]["BillingPartnerID"] = changeObj.BillingPartnerID;
              rowObj[`weekday${currDayIndex}`]["GroupDescriptionID"] = changeObj.GroupDescriptionID;
              rowObj[`weekday${currDayIndex}`]["GroupDescriptionIDArray"] =
                changeObj.GroupDescriptionIDArray;
              rowObj[`weekday${currDayIndex}`]["ClientTypeID"] = changeObj.ClientTypeID;
              rowObj[`weekday${currDayIndex}`]["BillingRate"] = changeObj.BillingRate;
              rowObj[`weekday${currDayIndex}`]["WorkLocation"] = changeObj.WorkLocation
                ? changeObj.WorkLocation
                : null;
            }
          }
        }
      }
      for (let totalIndex = 1; totalIndex < 8; ++totalIndex) {
        rowObj["weekday" + totalIndex]["totalTime"] =
          rowObj["weekday" + totalIndex]["totalTime"] || 0;
        rowObj["weekday" + totalIndex]["isValidMemo"] = true;
        if (this.timeMemoRequired) {
          if (
            !rowObj["weekday" + totalIndex]["Memo"] &&
            rowObj["weekday" + totalIndex]["totalTime"] > 0
          ) {
            rowObj["weekday" + totalIndex]["isValidMemo"] = false;
          } else {
            rowObj["weekday" + totalIndex]["isValidMemo"] = true;
          }
        }
        rowObj.weekdayTotal += numeral(rowObj["weekday" + totalIndex]["totalTime"]).value();
      }
      self.clients.push(rowObj);
      //console.log(self.clients);
    }
  }

  selectStaff(event) {
    if (event.value == null) {
      return;
    }
    const self = this;
    self.staffID = event.value;
    self.staffDetails = {};
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getUserById(self.staffID).subscribe(
      (data) => {
        self.staffDetails = data;
        self.search();
        self.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        self.mangoAPISrvc.notify(
          "error",
          self.translate.instant("error"),
          AppConstants.fetchErrorMsg
        );
        self.mangoAPISrvc.showLoader(false);
        self.staffID = null;
      }
    );
  }

  getActivityGroups() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getActivityList().subscribe(
      function (data: any) {
        parent.activityList = data.sort(parent.mangoUtils.compareValues("ServiceCode", "asc"));
        for (let i = 0; i < parent.activityList.length; i++) {
          parent.activityList[i].label =
            parent.activityList[i].ServiceCode + " - " + parent.activityList[i].Description;
          parent.activityList[i].value = parent.activityList[i].ServiceCode;
          parent.activityList[i].BillRate = parent.activityList[i].BillRate;
          parent.activityList[i].Billable = parent.activityList[i].Billable;
          parent.activityList[i].WorkCodeID = parent.activityList[i].WorkCodeID;
          parent.activityList[i].DefaultMemo = parent.activityList[i].DefaultMemo;
        }
        parent.activityList.splice(0, 0, { label: "Select Activity", value: null });
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        parent.mangoAPISrvc.notify(
          "error",
          parent.translate.instant("error"),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientList.length == 0 || this.clientList.length !== list.length) {
      this.clientList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        item["Billable"] = item["Billable"] ? item["Billable"] : false;
        this.clientList.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (
          client.ClientName.toLowerCase().indexOf(query.toLowerCase()) > -1 &&
          client["ContactRecord"] != true &&
          client["Inactive"] == false
        ) {
          filtered.push(client);
        } else if (
          client["ClientNumber"]?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 &&
          client["ContactRecord"] != true &&
          client["Inactive"] != true
        ) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  handleActivitySelectClick(event, itemData, isLoading) {
    itemData["Approved"] = this.companyGlobalSetting.AutoApproveSlips;
    const activityCode = this.activityList.filter((item) => {
      return event.value && item.value == event.value;
    });

    if (activityCode.length == 0) {
      itemData["ServiceCode"] = null;
      itemData["ServiceCodeID"] = null;
      itemData["Description"] = "";
      itemData["Billable"] = false;
      itemData["WorkCodeID"] = null;
      return false;
    }
    if (activityCode.length > 0 && activityCode[0].value == null) {
      itemData["ServiceCode"] = null;
      itemData["ServiceCodeID"] = null;
      itemData["Description"] = "";
      itemData["Billable"] = false;
      itemData["WorkCodeID"] = null;
      return false;
    }
    itemData["ServiceCode"] = activityCode[0].ServiceCode;
    itemData["ServiceCodeID"] = activityCode[0].ServiceCodeID;
    itemData["Description"] = this.mangoUtils.replaceCaretTemplate(activityCode[0].Description);
    //itemData["Billable"] = activityCode[0].Billable;
    itemData["WorkCodeID"] = activityCode[0].WorkCodeID;
    itemData["Approved"] = this.companyGlobalSetting.AutoApproveSlips;

    itemData.MarkSlipsBilled = itemData.MarkSlipsBilled ? itemData.MarkSlipsBilled : false;
    itemData.Billable = itemData.Billable ? itemData.Billable : false;
    itemData["isactivitybillable"] = activityCode[0].Billable;

    if (
      itemData.MarkSlipsBilled == true ||
      itemData.Billable == false ||
      itemData.isBillable == false
    ) {
      itemData.Billable = false;
    } else {
      itemData.Billable = activityCode[0].Billable;
    }
    for (let totalIndex = 1; totalIndex < 8; ++totalIndex) {
      itemData["weekday" + totalIndex]["Billable"] = itemData.Billable;
    }
    this.targetElement?.focus();
    setTimeout(() => {
      this.targetElement = null;
    }, 20);
    this.isValidTimeSheets();
  }

  handleEngagementSelectClick(event, itemData, isLoading) {
    const selectedProjectMasterID = itemData.engagementsList.filter((item) => {
      return event.value && item.value == event.value;
    });

    if (selectedProjectMasterID.length == 0) {
      itemData["EngagementName"] = null;
      itemData["ProjectMasterID"] = null;
      itemData["EngagementTypeID"] = null;
      itemData["isBillable"] = true;
      return false;
    }
    if (selectedProjectMasterID.length > 0 && selectedProjectMasterID[0].value == null) {
      itemData["EngagementName"] = null;
      itemData["ProjectMasterID"] = null;
      itemData["EngagementTypeID"] = null;
      itemData["isBillable"] = true;
      return false;
    }
    itemData["EngagementName"] = selectedProjectMasterID[0].label;
    itemData["ProjectMasterID"] = selectedProjectMasterID[0].value;
    itemData["EngagementTypeID"] = selectedProjectMasterID[0].EngagementTypeID;
    itemData["isBillable"] = selectedProjectMasterID[0].isBillable
      ? selectedProjectMasterID[0].isBillable
      : false;
    itemData["isEngagementBillable"] = itemData["isBillable"];
    itemData.MarkSlipsBilled = itemData.MarkSlipsBilled ? itemData.MarkSlipsBilled : false;
    itemData.Billable = this.selClient.Billable ?? false;

    if (
      itemData.MarkSlipsBilled == true ||
      itemData.Billable == false ||
      itemData.isBillable == false
    ) {
      itemData["Billable"] = false;
    }
    for (let totalIndex = 1; totalIndex < 8; ++totalIndex) {
      itemData["weekday" + totalIndex]["Billable"] = itemData["Billable"];
    }
    this.targetElement?.focus();
    setTimeout(() => {
      this.targetElement = null;
    }, 20);
    this.isValidTimeSheets();
  }

  deleteRow(event, index) {
    const self = this;
    Swal.fire({
      title: self.translate.instant("confirmation"),
      text: self.translate.instant("delete_alert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: self.translate.instant("yes_delete"),
      cancelButtonText: self.translate.instant("no_delete"),
    }).then((result) => {
      if (result.value) {
        self.clients.splice(index, 1).slice(0);
        self.clients = self.clients.concat(self.clients.splice(index, 1).slice(0));
        self.isValidTimeSheets();
      }
    });
  }

  changeSelectedWeekDate(date) {
    // console.log(this.selectedWeekDate);
    this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
    this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
    this.search();
  }

  getCompanyLocation(id) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocation(id).subscribe(
      (data: any) => {
        this.companyLocationObj = data;
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  calculateSalesTax(item) {
    // See if Company has Sales Tax Activated
    const lRates = this.companyGlobalSetting.ActivateLaborRates;
    item["IsTaxable"] = false;

    let actualTaxRate = 0;
    if (lRates && lRates == true) {
      // 1st Check - Where to get labor rates by checking SalesTaxLevel Values, FirmRate, CompanyLocationRate, ClientRate
      if (item && item.SalesTaxLevel == "ClientRate") {
        actualTaxRate = item.Tax1ID;
      } else if (item && item.SalesTaxLevel == "CompanyLocationRate") {
        actualTaxRate = this.companyLocationObj.TaxRateLaborL;
      } else {
        actualTaxRate = this.companyGlobalSetting.LaborRate1;
      }
      item["Tax"] = numeral(item.StandardAmount).value() * (numeral(actualTaxRate).value() / 100);
      item["IsTaxable"] = true;
    }
  }

  handleClientSelectClick(value, itemData) {
    const parent = this;
    if (value.BlockTimeExpenseEntry) {
      Swal.fire({
        icon: "warning",
        title: `${parent.translate.instant("Warning")}`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: "OK",
        text: `${parent.translate.instant("block-time-entry")}!`,
      });
      itemData.ClientID = null;
      itemData["ProjectMasterID"] = null;
      itemData["ServiceCode"] = null;
      this.isValidTimeSheets();
      return false;
    } else {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.getClientFullinformation(value.ClientID).subscribe((clientdata: any) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.selClient = clientdata;
        // itemData["ClientID"] = clientdata.ClientID;
        itemData["ClientName"] = clientdata.ClientName;
        //check client id &&
        itemData["OriginatingPartnerID"] = value.OriginatingPartnerID;
        itemData["BillingPartnerID"] = value.BillingPartnerID;
        itemData["GroupDescriptionID"] = value.GroupDescriptionID;
        itemData["GroupDescriptionIDArray"] = value.GroupDescriptionIDArray;
        itemData["ClientTypeID"] = value.ClientTypeID;
        itemData["EngagementName"] = null;
        itemData["ProjectMasterID"] = null;
        itemData["EngagementTypeID"] = null;
        itemData["SalesTaxLevel"] = parent.selClient["SalesTaxLevel"];
        itemData["ServiceCode"] = null;
        itemData["Billable"] = value.Billable ? value.Billable : false;
        this.selClient["Billable"] = this.selClient["Billable"]
          ? this.selClient["Billable"]
          : false;
        itemData["isClientBillable"] = this.selClient["Billable"];
        value.MarkSlipsBilled = value.MarkSlipsBilled ? value.MarkSlipsBilled : false;
        itemData["MarkSlipsBilled"] = value.MarkSlipsBilled;
        // this.editCellComplete(itemData);
        this.getProjects(clientdata.ClientID, itemData);
        this.isValidTimeSheets();
      });
    }
  }

  convertHoursToNumericHours(time) {
    const arrTime = time.split(":");
    let minute: any = Math.round((arrTime[1] * 100) / 60);
    if (minute < 9) minute = "0" + minute;
    return arrTime[0] + "." + minute;
  }

  async updateTimeTotal(evt, field, data, rowIndex) {
    if (evt == null) {
      data[field]["totalTime"] = 0;
      data[field]["ElaspedTime"] = 0;
      return false;
    } else if (evt == ".") {
      return false;
    }

    if (this.billableActual === BillableActualEnum.BILLABLE) {
      let isOverwriteBillableOnly = false;
      if (data[field]["isElapsedTimeChanged"]) {
        const answer = await Swal.fire({
          title: "Confirmation",
          text: this.translate.instant("time_card.update_billable_message"),
          icon: "warning",
          showDenyButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: this.translate.instant("time_card.overwrite_billable_message"),
          denyButtonText: this.translate.instant("time_card.update_both_time"),
        });
        if (answer.isDenied) {
          data[field]["isElapsedTimeChanged"] = false;
        }
        isOverwriteBillableOnly = answer.isConfirmed;
      }
      data[field]["isTotalTimeChanged"] = true;

      const totalTimeValue = data[field]["totalTime"]
        ? numeral(data[field]["totalTime"]).value()
        : 0;
      data[field]["totalTime"] = 0; //@NOTE: this is to trick ngModelChange method that the value was changed to render correct value.
      this.ref.detectChanges();
      data[field]["totalTime"] = this.roundEntries(totalTimeValue);
      data[field]["ElaspedTime"] = isOverwriteBillableOnly
        ? data[field]["ElaspedTime"]
        : totalTimeValue;
    } else {
      let isOverwriteActualOnly = false;
      if (data[field]["isTotalTimeChanged"]) {
        const answer = await Swal.fire({
          title: "Confirmation",
          text: this.translate.instant("time_card.update_actual_message"),
          icon: "warning",
          allowEscapeKey: false,
          allowEnterKey: false,
          showDenyButton: true,
          confirmButtonText: this.translate.instant("time_card.overwrite_actual_message"),
          denyButtonText: this.translate.instant("time_card.update_both_time"),
        });
        if (answer.isDenied) {
          data[field]["isTotalTimeChanged"] = false;
        }
        isOverwriteActualOnly = answer.isConfirmed;
      }
      data[field]["isElapsedTimeChanged"] = true;
      const elapsedTimeValue = data[field]["ElaspedTime"]
        ? numeral(data[field]["ElaspedTime"]).value()
        : 0;
      data[field]["ElaspedTime"] = elapsedTimeValue;
      data[field]["totalTime"] = isOverwriteActualOnly
        ? data[field]["totalTime"]
        : this.roundEntries(data[field]["ElaspedTime"]);
    }

    const activityCode = this.activityList.filter((item) => {
      return item.value == data.ServiceCode;
    });

    data[field]["isModified"] = true;
    data[field]["color"] = "#5e81ac42";
    data[field]["Billable"] = true;

    if (!data.isEngagementBillable || !data.isClientBillable || !data.isactivitybillable) {
      data[field]["Billable"] = false;
    }

    data["IsColumnChanges"] = true;
    this.isFormChanged = true;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);

    data[field]["Memo"] = data[field]["Memo"]
      ? data[field]["Memo"]
      : activityCode[0]["DefaultMemo"];
    data[field]["Memo"] = this.mangoUtils.replaceCaretTemplate(data[field]["Memo"]);
    if (this.timeMemoRequired) {
      if (!data[field]["Memo"] && data[field]["totalTime"] > 0) {
        data[field]["isValidMemo"] = false;
      } else {
        data[field]["isValidMemo"] = true;
      }
    }

    if (field.substr(0, 7) != "weekday") {
      return;
    }
    rowIndex = rowIndex + 1;
    this.calculateWeekTotalTime();
    this.isValidTimeSheets();
  }

  roundEntries(value) {
    if (this.isRoundEntries && this.companyGlobalSetting.BillingIncrements) {
      let globalMinute = 0;
      globalMinute = parseInt(this.companyGlobalSetting.BillingIncrements.split(" ")[0]);
      let realmin = (Math.round((numeral(value).value() * 60) % 60) * 100) / 100;
      let hours = Math.floor((numeral(value).value() * 60) / 60);
      if (realmin <= globalMinute)
        if (realmin == 0) realmin = <any>"00";
        else realmin = globalMinute;
      else {
        let diffGlobalMin = 0;
        // infinity issue
        if (globalMinute != 0) {
          diffGlobalMin = Math.floor(realmin / globalMinute);
        }
        if (realmin % globalMinute === 0) realmin = diffGlobalMin * globalMinute;
        else realmin = (diffGlobalMin + 1) * globalMinute;
      }
      if (realmin >= 60) {
        hours = hours + Math.floor(realmin / 60);
        realmin = realmin % 60;
        if (realmin < 9) realmin = <any>"0" + realmin;
      }
      return this.convertHoursToNumericHours(hours + ":" + realmin);
    } else {
      return value;
    }
  }

  calculateWeekTotalTime() {
    let columnNameAffected = this.billableTimeColumn;
    if (this.billableActual === BillableActualEnum.ACTUAL)
      columnNameAffected = this.actualTimeColumn;

    for (let i = 0; i < this.clients.length; i++) {
      const data = this.clients[i];
      const weekday1Value = data[`weekday1`][columnNameAffected]
        ? numeral(data[`weekday1`][columnNameAffected]).value()
        : 0;
      const weekday2Value = data[`weekday2`][columnNameAffected]
        ? numeral(data[`weekday2`][columnNameAffected]).value()
        : 0;
      const weekday3Value = data[`weekday3`][columnNameAffected]
        ? numeral(data[`weekday3`][columnNameAffected]).value()
        : 0;
      const weekday4Value = data[`weekday4`][columnNameAffected]
        ? numeral(data[`weekday4`][columnNameAffected]).value()
        : 0;
      const weekday5Value = data[`weekday5`][columnNameAffected]
        ? numeral(data[`weekday5`][columnNameAffected]).value()
        : 0;
      const weekday6Value = data[`weekday6`][columnNameAffected]
        ? numeral(data[`weekday6`][columnNameAffected]).value()
        : 0;
      const weekday7Value = data[`weekday7`][columnNameAffected]
        ? numeral(data[`weekday7`][columnNameAffected]).value()
        : 0;

      data.weekdayTotal =
        weekday1Value +
        weekday2Value +
        weekday3Value +
        weekday4Value +
        weekday5Value +
        weekday6Value +
        weekday7Value;
      data.weekdayTotal = numeral(data.weekdayTotal).value() || 0;
    }
  }

  addMemo(field, data, rowIndex) {
    this.selField = field;
    this.rowIndex = rowIndex;
    data.MarkSlipsBilled = data.MarkSlipsBilled ? data.MarkSlipsBilled : false;
    data.Billable = data.Billable ? data.Billable : false;
    const savedWorkLocation = localStorage.getItem("WorkLocation")
      ? localStorage.getItem("WorkLocation")
      : null;
    this.clients[this.rowIndex][this.selField]["WorkLocation"] = this.clients[this.rowIndex][
      this.selField
    ]["WorkLocation"]
      ? this.clients[this.rowIndex][this.selField]["WorkLocation"]
      : savedWorkLocation;

    if (!this.clients[this.rowIndex][this.selField]["isModified"]) {
      this.clients[this.rowIndex][this.selField]["Billable"] = true;

      if (data.MarkSlipsBilled == true || data.Billable == false || data.isBillable == false) {
        this.clients[this.rowIndex][this.selField]["Billable"] = false;
      } else {
        if (!this.clients[this.rowIndex][this.selField]["SlipMasterID"]) {
          const activityCode = this.activityList.filter((item) => {
            return item.value == data.ServiceCode;
          });
          if (activityCode.length > 0 && activityCode[0]["Billable"]) {
            this.clients[this.rowIndex][this.selField]["Billable"] = activityCode[0]["Billable"];
          }
        }
      }
    }

    this.showAddMemo = true;
    this.isValidTimeSheets();
  }

  closeMemoDialog(field, data, rowIndex) {
    this.showAddMemo = false;
    // if (!this.clients[this.rowIndex][this.selField]['SlipMasterID']) {
    //   this.clients[this.rowIndex][this.selField]['Billable'] = false;
    // }
  }

  getDates() {
    const self = this;
    const currentDate = moment(self.startDate).toDate();
    const stopDate = moment(self.endDate).toDate();
    self.dateList = [];
    $(`table > thead > tr > th`).removeClass("bg-darker-blue");
    $(`table > thead > tr > th > span`).removeClass("ic-white");
    while (currentDate <= stopDate) {
      self.dateList.push(moment(currentDate).format("ddd MM/D"));
      if (moment().format("MM/DD/Y") == moment(currentDate).format("MM/DD/Y")) {
        const colIndex =
          self.dateList.length + (self.selectedColumns ? self.selectedColumns.length : 0) + 4;
        const element = $(`table > thead > tr > th:nth-child(${colIndex})`);
        element.addClass("bg-darker-blue");
        element.find("span").addClass("ic-white");
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  editCellComplete(data) {
    data["IsColumnChanges"] = true;
    this.isFormChanged = true;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }

  saveMemo(field, data, rowIndex) {
    this.isFormChanged = true;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.showAddMemo = false;
    data["IsColumnChanges"] = true;
    data[field]["isModified"] = true;
    this.isMemoRequired(data[field]);
    this.encrDecSrvc.addObject(AppConstants.savedWorkLocation, data[field]["WorkLocation"]);
    this.isValidTimeSheets();
  }

  addOffset(date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() + offset * 60 * 1000;
    return new Date(time);
  }

  removeOffset(date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  getAllStaffList() {
    const parent = this;
    parent.staffList = [{ label: "Select Staff", value: -1 }];
    let tempList = [];
    if (this.auth.isAllowAccess(36)) {
      tempList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
    } else {
      tempList = this.encrDecSrvc.getObject(AppConstants.staffList);
      tempList = tempList?.filter(
        (staff) => staff?.StaffID == this.encrDecSrvc.getObject(AppConstants.staffID)
      );
    }
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive) continue;

      parent.staffList.push({ label: tempList[i].StaffName, value: tempList[i].StaffID });
    }
    parent.selectStaff({ value: parent.encrDecSrvc.getObject(AppConstants.resourceID) });
  }

  getProjects(clientId, itemData) {
    const parent = this;
    // itemData.engagementsList = [];
    if (!clientId) {
      return false;
    }
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getProjectsByClientId(clientId).subscribe(
      function (data: any) {
        const filterDataValue = data.filter((note) => note.Inactive == false);
        const filterData = filterDataValue.sort(
          parent.mangoUtils.compareValues("EngagementName", "asc")
        );
        itemData.engagementsList = [];
        for (let i = 0; i < filterData.length; i++) {
          filterData[i].isBillable = filterData[i].isBillable ? filterData[i].isBillable : false;
          itemData.engagementsList.push({
            label: filterData[i].EngagementName,
            value: filterData[i].ProjectMasterID,
            EngagementTypeID: filterData[i].EngagementTypeID,
            isBillable: filterData[i].isBillable,
          });
        }
        if (itemData.engagementsList.length == 1) {
          itemData["EngagementName"] = itemData.engagementsList[0].label;
          itemData["ProjectMasterID"] = itemData.engagementsList[0].value;
          itemData["EngagementTypeID"] = itemData.engagementsList[0].EngagementTypeID;
          itemData["isBillable"] = itemData.engagementsList[0].isBillable;
          itemData["isEngagementBillable"] = itemData.engagementsList[0].isBillable;
        }
        itemData.engagementsList.splice(0, 0, { label: "Select Engagement", value: null });
        parent.mangoAPISrvc.showLoader(false);
        parent.onRowEditInit1(itemData["SlipMasterID"]);
      },
      (error) => {
        parent.mangoAPISrvc.notify(
          "error",
          parent.translate.instant("error"),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  search() {
    const self = this;
    if (!self.staffID) {
      return;
    }

    if (!self.isFormChanged) {
      return self.reloadData();
    }
    Swal.fire({
      title: self.translate.instant("confirmation"),
      text: self.translate.instant("unsaved_changes_warning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: self.translate.instant("yes_leave"),
      cancelButtonText: self.translate.instant("no_stay"),
    }).then((result) => {
      if (result.value) {
        self.isFormChanged = false;
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        self.reloadData();
      }
    });
  }

  isValidTimeSheets() {
    this.isValidForm = false;
    this.isValidMemo = true;
    const srchClients = this.clients.filter((item) => {
      if (item.isNew) {
        return (
          item.ClientID == null ||
          item.ServiceCode == null ||
          item.ProjectMasterID == null ||
          item.weekdayTotal == 0
        );
      } else {
        return false;
      }
    });
    if (srchClients && srchClients.length > 0) {
      this.isValidForm = true;
    }

    let isInvalidData = false;
    for (let index = 0; index < this.clients.length; index++) {
      const element = this.clients[index];
      if (isInvalidData) {
        this.isValidForm = true;
        this.isValidMemo = false;
        break;
      }
      for (let totalIndex = 1; totalIndex < 8; ++totalIndex) {
        if (!element["weekday" + totalIndex]["isValidMemo"]) {
          isInvalidData = true;
          this.isValidForm = true;
          this.isValidMemo = false;
          break;
        }
      }
    }
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }

  getWorkLocations() {
    const parent = this;
    if (parent.workLocationsList.length > 0) {
      return false;
    }
    parent.workLocationsList = [{ label: "Select Location", value: null }];
    const tempList = parent.encrDecSrvc.getObject(AppConstants.workLocations);
    for (let index = 0; index < tempList.length; index++) {
      const element = tempList[index];
      element["label"] = element.ZipCode;
      element["value"] = element.WorkLocationID;
      parent.workLocationsList.push(element);
    }
  }

  previous() {
    this.selectedWeekDate = moment(this.selectedWeekDate).subtract(1, "weeks").toDate();
    this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
    this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
    this.search();
  }

  today() {
    this.selectedWeekDate = new Date();
    this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
    this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
    this.search();
  }

  next() {
    this.selectedWeekDate = moment(this.selectedWeekDate).add(1, "weeks").toDate();
    this.startDate = moment(this.selectedWeekDate).startOf(this.momentWeek).toDate();
    this.endDate = moment(this.selectedWeekDate).endOf(this.momentWeek).toDate();
    this.search();
  }

  refresh() {
    this.search();
  }

  weekdayTotal(dayNum: number) {
    let retVal = 0;
    const clients = this.clients;
    let selectedColumnName = this.billableTimeColumn;
    if (this.billableActual === BillableActualEnum.ACTUAL)
      selectedColumnName = this.actualTimeColumn;

    for (let i = 0; i < clients.length; i++) {
      if (dayNum >= 1 && dayNum <= 7) {
        retVal += Number(clients[i][`weekday${dayNum}`][selectedColumnName]);
      } else {
        retVal +=
          Number(clients[i][`weekday1`][selectedColumnName]) +
          Number(clients[i][`weekday2`][selectedColumnName]) +
          Number(clients[i][`weekday3`][selectedColumnName]) +
          Number(clients[i][`weekday4`][selectedColumnName]) +
          Number(clients[i][`weekday5`][selectedColumnName]) +
          Number(clients[i][`weekday6`][selectedColumnName]) +
          Number(clients[i][`weekday7`][selectedColumnName]);
      }
    }
    return retVal.toFixed(2);
  }

  scrollDownToNewRow(id) {
    if (id) {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const el1 = document.querySelectorAll(".new-row");
        el1[0].querySelectorAll("input")[0]?.focus();
      }, 500);
    }
  }

  onRowEditInit1(index) {
    this.removeRowEditMode();
    if (this.dataTableComponent) this.dataTableComponent.editingRowKeys[index] = true;
  }

  removeRowEditMode() {
    if (this.dataTableComponent) this.dataTableComponent.editingRowKeys = {};
  }

  addRow(index) {
    if (
      this.clients.filter(
        (item) =>
          item["isNew"] &&
          (item["ClientID"] == null ||
            item["ProjectMasterID"] == null ||
            item["ServiceCodeID"] == null)
      ).length > 0
    ) {
      this.mangoAPISrvc.notify(
        "error",
        this.translate.instant("error"),
        this.translate.instant("cant-add-please-save")
      );
      return;
    }
    const self = this;
    const newRow = {
      ClientID: null,
      ClientName: null,
      ServiceCodeID: null,
      ProjectMasterID: null,
      SlipMasterID: (this.clients.filter((item) => item["isNew"]).length + 1) * -1,
      ServiceCode: null,
      isReadyOnly: false,
      isClientBillable: false,
      isEngagementBillable: false,
      isactivitybillable: false,
      engagementsList: [],
      weekdayTotal: 0,
      IsTimeRecord: "T",
      isNew: true,
    };
    let currentDate = moment(self.startDate).toDate();
    const stopDate = moment(self.endDate).toDate();
    let i2 = 1;
    while (currentDate <= stopDate) {
      const today = self.removeOffset(currentDate).toISOString();
      newRow[`weekday${i2}`] = {
        SlipMasterID: null,
        totalTime: 0,
        ElaspedTime: 0,
        isElapsedTimeChanged: false,
        isTotalTimeChanged: false,
        WorkLocation: null,
        Ddate: moment(currentDate).format("MM/DD/YYYY"),
        IsTimeRecord: "T",
        Billed: false,
        Description: null,
        Memo: null,
        BillingRate: null,
        isValidMemo: true,
      };
      //currentDate.setDate(currentDate.getDate() + 1);
      currentDate = moment(currentDate).add("days", 1).toDate();
      i2++;
    }

    this.clients.splice(index + 1, 0, newRow);
    this.dataTableComponent._value = this.clients;
    this.onRowEditInit1(newRow["SlipMasterID"]);
    setTimeout(() => {
      this.scrollDownToNewRow("new-row");
    }, 1000);
    // let colneObj = Object.assign([], self.clients);
    // self.clients = colneObj;

    // self.clients = self.clients.splice((index + 1), 0, newRow);
    //self.clients[index + 1] = newRow;

    //self.clients.push(newRow);

    self.isValidTimeSheets();
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
  }

  ngAfterViewInit() {
    this.searchValue.nativeElement.focus();
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  replaceCaret(desc, type) {
    desc[type] = this.mangoUtils.replaceCaretTemplate(desc[type]);
    this.isMemoRequired(desc);
  }

  isMemoRequired(desc) {
    if (this.timeMemoRequired) {
      if (!desc["Memo"] && desc["totalTime"] > 0) {
        desc["isValidMemo"] = false;
      } else {
        desc["isValidMemo"] = true;
      }
    }
  }

  replaceShortcuts(value, desc, isMemo) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }

    if (isMemo) {
      desc["Memo"] = valueArr.join(" ");
    } else {
      desc["PrivateMemo"] = valueArr.join(" ");
    }
  }

  onChangeSelectedCols(event) {
    let columnsToSave = "";
    event.value.map((cols, index) => {
      if (index > 0) columnsToSave += `, ${cols.field}`;
      else columnsToSave += cols.field;
    });
    const objToSave = { TimeCardCols: `{${columnsToSave}}` };
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        (data) => {},
        (err) => {}
      );
  }

  onFocusDropdown(event, dropdownType) {
    if (this.targetElement) return null;
    try {
      const triggerSpaceEvent = new KeyboardEvent("keydown", { keyCode: 32, key: "Space" });
      this.targetElement = event.target;
      this.targetElement.dispatchEvent(triggerSpaceEvent);
      setTimeout(() => {
        if (
          (dropdownType == this.ServiceCode && !this.isServiceCodeOpen) ||
          (dropdownType == this.EngagementName && !this.isEngagementNameOpen)
        ) {
          this.targetElement.dispatchEvent(triggerSpaceEvent);
        }
        this.targetElement?.focus();
        this.targetElement = null;
      }, 30);
    } catch (err) {
      console.error("Unable to trigger space programatically.");
    }
  }

  onChangeBillableOrACtual(event) {
    if (event == BillableActualEnum.ACTUAL) this.isRoundEntries = true;
    else this.isRoundEntries = false;
    this.calculateWeekTotalTime();
  }
}
