export const instructions = [
    {
      title: "Download the .bat file:",
      steps: [
        "Ensure you have received the .bat file from the appropriate source. This file will typically have a .bat extension, and you may have received it as an attachment or a download link."
      ]
    },
    {
      title: "Save the .bat file:",
      steps: [
        "Save the downloaded .bat file to a location on your computer where you can easily access it. For example, you can save it to your Desktop or Documents folder."
      ]
    },
    {
      title: "Double-click the .bat file:",
      steps: [
        "Once the .bat file is saved to your computer, navigate to the location where you saved it.",
        "Double-click on the .bat file to run it. This action will start the script execution process."
      ]
    },
    {
      title: "Enter the base folder path:",
      steps: [
        "After double-clicking the .bat file, you'll be prompted to enter the base folder path where you want to search for .qbw files.",
        "Type the path of the folder you want to search (e.g., C:\\, D:\\, \\\\server\\share\\) and press Enter."
      ]
    },
    {
      title: "Wait for the search process:",
      steps: [
        "The script will start searching for .qbw files in the specified base folder and its subfolders. Wait for the search process to complete."
      ]
    },
    {
      title: "Select the file to synchronize:",
      steps: [
        "If the script finds more than one .qbw file in the specified folder, you'll be prompted to select the file you want to synchronize.",
        "Enter the corresponding number next to the file you want to select and press Enter."
      ]
    },
    {
      title: "Copy the file path:",
      steps: [
        "Once you've selected the file, its path will be copied to your clipboard automatically.",
        "You can paste the copied path into the QuickBooks configuration in Mango or any other relevant application."
      ]
    },
    {
      title: "Confirmation and exit:",
      steps: [
        "A confirmation message will indicate that the file path has been copied to the clipboard.",
        "If you need to exit the script, press Enter, or wait for 10 seconds for it to close automatically."
      ]
    }
  ];
  