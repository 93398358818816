import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { EncrDecrService, MangoApiService } from "@app/_services";
import { Checkbox } from "primeng/checkbox";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { SelectItem } from "primeng/api";
import { AppConstants } from "@app/_helpers/api-constants";

@Component({
  selector: "app-inbox",
  styleUrls: ["inbox.style.css"],
  templateUrl: "./inbox.component.html",
})
export class InboxComponent implements OnInit {
  public selectedbillingpartnerItem: any;
  public AllStaffsTypes: any[] = [];
  public Allbillingpartners: any[] = [];

  public selectedEngagementsItem: any;
  public AllEngagementsTypes: any[] = [];
  public AllEngagements: any[] = [];
  @ViewChild("boxMail") boxMail: ElementRef;
  filter1040Checkbox: boolean = false;
  filterbillingidCheckbox: boolean = false;
  emailWindows: Array<{
    isExpanded: boolean;
    isMinimized: boolean;
    emailForm: UntypedFormGroup;
  }> = [];

  staticThreads: Thread[] = [];
  ThreadsFromApi: Thread[] = [];
  private isTextAdded = false;
  showBackButton = false;
  showMailBox = true;
  saveMailBox: any;

  thread_id: string;
  message: EmailMessage | null;
  menuItems: any[];
  emailItems: any[] = [];
  currentEmail: any;

  selectedEmail: any = [];
  defaultValue: any = [];

  selectedItem: string = "Inbox";

  pageSize: number = 100;
  totalRecords: number = 0;
  currentPage: number = 1;

  showComposer = false;

  currentAccountId: string;

  setupMailBox: boolean = true;
  isLoading: boolean = false;

  filter1040: any = "";
  filterbillingid: any = "";
  hasFilter: boolean = false;
  loginCompanyId:any;
  engagementsTypes: any = [];
  noResultFilters: boolean = false;

  cardStyle = {
    "background-color": "#007bff",
    color: "white",
    width: "500px",
    "text-align": "center",
    padding: "15px",
    "font-size": "18px",
  };

  composeEmail() {}

  constructor(
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    private renderer: Renderer2,
    private el: ElementRef,
    private formBuilder: UntypedFormBuilder,
    private changeDet: ChangeDetectorRef
  ) {
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.menuItems = [
      { label: "Inbox", icon: "pi pi-fw pi-inbox", value: "inbox" },
      { label: "Starred", icon: "pi pi-fw pi-star", value: "starred" },
      { label: "Sent", icon: "pi pi-fw pi-envelope", value: "sent" },
    ];
    this.getAllStaffList();
  }

  ngOnInit(): void {
    this.getNylasContacts();
    this.getEngagements();
  }

  getAllStaffList() {
    const parent = this;
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    for (let i = 0; i < item.length; ++i) {
      if (item[i].Inactive)
        continue;

      parent.Allbillingpartners.push({
        name: item[i].StaffName,
        code: item[i].StaffID.toString(),
      });
    }
  }

  async getNylasContacts() {
    this.mangoAPISrvc.showLoader(true);
    this.isLoading = true;
    const allContacts: any = await this.getAllContacts();
    const staffContacts = await this.getStaffContacts();
    const emailList = allContacts.length > 0 ? allContacts : staffContacts;
    const enabledEmails = emailList.filter((email) => email.is_enabled);
    if (enabledEmails.length > 0) {
      this.setupMailBox = false;
      this.emailItems = [];
      this.emailItems = enabledEmails.map((email) => {
        return { label: email.email, value: email.nylas_id };
      });

      this.emailItems.sort((a, b) => a.label.localeCompare(b.label));
      this.defaultValue = this.emailItems[0].value;
      this.selectedEmail = [this.emailItems[0].value];
      this.getEmailsByCustomer(this.selectedEmail);
    } else {
      this.emailItems = [];
    }
    this.isLoading = false;
    this.mangoAPISrvc.showLoader(false);
  }

  updateFilterBillingId(event) {
    if (event.checked) {
      this.filterbillingid = 2864;
      this.hasFilter = true;
    } else {
      this.filterbillingid = "";
    }
  }

  updateFilter1040(event) {
    if (event.checked) {
      this.filter1040 = "1040";
      this.hasFilter = true;
    } else {
      this.filter1040 = "";
    }
  }

  getEmailsByFilter() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getEmailByFilter(this.selectedbillingpartnerItem?.code, this.selectedEngagementsItem?.name).pipe(finalize(() => {
      this.mangoAPISrvc.showLoader(false)
    })).subscribe({
      next: (data: []) => {
        this.hasFilter = true;
        this.emailItems = [];
        this.emailItems = data.map((email: any) => {
          return { label: email.Email, value: email.nylas_id };
        });
        if(this.emailItems.length > 0) {
          this.emailItems.sort((a, b) => a.label.localeCompare(b.label));
          this.defaultValue = this.emailItems[0].value;
          this.selectedEmail = [this.emailItems[0].value];
          this.getEmailsByCustomer(this.selectedEmail);
        }else {
          this.showMailBox = false;
          this.selectedEmail = [];
          this.noResultFilters = true;
          this.changeDet.detectChanges();
        }
      },
      error: (err) => console.log(err),
    });
  }

  getEngagements() {
    const parent = this;
    parent.mangoAPISrvc.getEngagementTypes(this.loginCompanyId).subscribe(function (data: any) {
      for (let i = 0; i <= data.length; i++) {
        const item = data[i];
        if(item){
          parent.AllEngagements.push({
            name: item.Description,
            code: item.EngagementTypeID.toString(),
          });
        }

      }
    }, error => {
     console.log(error)
    });
  }

  removeFilters() {

    this.selectedEngagementsItem =  null;
    this.selectedbillingpartnerItem = null;
    this.hasFilter = false;
    this.noResultFilters = false;

    this.filter1040Checkbox = false;
    this.filterbillingidCheckbox = false;

    this.getNylasContacts();
  }

  async getStaffContacts() {
    try {
      const staffEmail = await this.mangoAPISrvc.getNylasReadContactsByStaff();
      return staffEmail;
    } catch (error) {
      return [];
    }
  }

  async getAllContacts() {
    try {
      const allStaffEmails = await this.mangoAPISrvc.getNylasAllContactsEnableAndRead();
      return allStaffEmails;
    } catch (error) {
      return [];
    }
  }

  selectMenuEmail(email: any) {
    this.currentEmail = email;
  }

  onPageChange(event: any) {
    this.currentPage = event.page + 1;
    // this.getEmailsByCustomer();
  }
  toggleComposer() {
    const newEmailForm = this.createEmailForm();
    this.emailWindows.push({
      isExpanded: true,
      isMinimized: false,
      emailForm: newEmailForm,
    });
  }

  minimizeWindow(index: number) {
    this.emailWindows[index].isMinimized = true;
  }

  removeWindow(index: number) {
    this.emailWindows.splice(index, 1);
  }

  restoreWindow(index: number) {
    this.emailWindows[index].isMinimized = false;
  }
  createEmailForm(): UntypedFormGroup {
    return this.formBuilder.group({
      to: ["", [Validators.required, Validators.email]],
      cc: [[]],
      bcc: [[]],
      subject: [""],
      body: ["", Validators.required],
    });
  }
  ngAfterViewInit(): void {}
  onEmailSelected(event: any): void {
    if (event.value.length == 0) {
      this.getEmailsByCustomer([this.defaultValue]);
    }
    this.getEmailsByCustomer(this.selectedEmail);
  }

  onManifestLoaded(manifest: any) {}

  onError(error: any) {}

  onThreadClicked(event: any) {
    // Handle threadClicked event
    this.showMailBox = false;
    this.mangoAPISrvc.showLoader(true);
    setTimeout(() => {
      const { message_ids, account_id } = event.detail.thread;
      if (!this.isTextAdded) {
        this.isTextAdded = true;
        this.currentAccountId = account_id;
        this.readEmailByMessageId(message_ids[0], account_id);
      }
    }, 1000);
  }

  goToMailBox() {
    this.isTextAdded = false;
    this.showBackButton = false;
    this.message = null;
    setTimeout(() => {
      this.getEmailsByCustomer(this.selectedEmail);
    }, 100);

    this.staticThreads = this.ThreadsFromApi;
    this.changeDet.detectChanges();
  }

  onChangeSelectedReadStatus(event: any) {
    // Handle onChangeSelectedReadStatus event
    // console.log('Selected Read Status Changed:', event);
  }

  onDeleteSelected(event: any) {
    // Handle onDeleteSelected event
    // console.log('Selected Emails/Threads Deleted:', event);
  }

  onStarSelected(event: any) {}

  onRefreshClicked(event: any) {}

  onSelectOneClicked(event: any) {}

  onSelectAllClicked(event: any) {}

  onReturnToMailbox(event: any) {
    this.isTextAdded = false;
  }

  readEmailByMessageId(messageId: string, id) {
    this.mangoAPISrvc.readNylasEmailByMessageID(messageId, id).subscribe((res: EmailMessage) => {
      if (res) {
        this.message = res;
        this.showBackButton = true;
        this.mangoAPISrvc.showLoader(false);
      }
    });
  }

  getEmailsByCustomer(emailadressId: string[]) {
    this.mangoAPISrvc.showLoader(true);
    const requests = emailadressId.map((id) =>
      this.mangoAPISrvc.getNylasEmails(this.currentPage, this.pageSize, this.selectedItem, id)
    );

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.mangoAPISrvc.showLoader(false);
        })
      )
      .subscribe((responses: any[]) => {
        const allThreads = [];
        for (let i = 0; i < responses.length; i++) {
          const res = responses[i];
          allThreads.push(...res.threads);
        }
        this.processEmailThreads(allThreads);
        this.showMailBox = true;
        this.saveMailBox = this.el.nativeElement.querySelector("nylas-mailbox").cloneNode(true);
      });

    // this.mangoAPISrvc
    //   .getNylasEmails(this.currentPage, this.pageSize, this.selectedItem, emailadressId)
    //   .pipe(finalize(() => {
    //     this.mangoAPISrvc.showLoader(false);
    //   }))
    //   .subscribe((res: any) => {
    //     this.processEmailThreads(res.threads);
    //     this.showMailBox = true;
    //     this.saveMailBox = this.el.nativeElement.querySelector("nylas-mailbox").cloneNode(true);
    //     this.totalRecords = res.totalPages;
    //   });
  }

  private mapToThread(apiThread: any) {
    return {
      message_ids: apiThread.message_ids,
      account_id: apiThread.account_id,
      drafts: apiThread.drafts,
      first_message_timestamp: apiThread.first_message_timestamp,
      has_attachments: apiThread.has_attachments,
      id: apiThread.id,
      labels: apiThread.labels,
      last_message_received_timestamp: apiThread.last_message_received_timestamp,
      last_message_sent_timestamp: apiThread.last_message_sent_timestamp,
      last_message_timestamp: apiThread.last_message_timestamp,
      messages: apiThread.messages,
      object: apiThread.object,
      participants: apiThread.participants,
      snippet: apiThread.snippet,
      starred: apiThread.starred,
      subject: apiThread.subject,
      unread: apiThread.unread,
      version: apiThread.version,
    };
  }

  private processEmailThreads(apiThreads: any[]) {
    this.mangoAPISrvc.showLoader(true);
    this.staticThreads = apiThreads.map(this.mapToThread);
    this.staticThreads.forEach((thread) => this.addDefaultMessage(thread));
    this.ThreadsFromApi = this.staticThreads;
    this.mangoAPISrvc.showLoader(false);
  }

  private addDefaultMessage(thread: any) {
    const message: EmailMessage = {
      account_id: thread.account_id,
      bcc: [],
      cc: [],
      date: 1,
      files: [],
      from: [{ email: "", name: "" }],
      id: thread.id,
      labels: thread.labels,
      object: thread.subject,
      reply_to: [{ email: "", name: "" }],
      snippet: thread.snippet,
      starred: thread.starred,
      subject: thread.subject,
      thread_id: thread.id,
      to: [{ email: "", name: "" }],
      unread: thread.unread,
    };
    thread.messages.push(message);
  }

  private hideLoadingIndicator() {
    const loadingTag = this.getLoadingTag();
    if (loadingTag) {
      this.renderer.setStyle(loadingTag, "display", "none");
    }
  }

  private getLoadingTag(): HTMLElement | null {
    const nylasMailbox = this.el.nativeElement.querySelector("nylas-mailbox");
    const mainTag = nylasMailbox.shadowRoot.querySelector("main");
    const nylasEmail = mainTag?.querySelector("nylas-email");
    return nylasEmail?.shadowRoot.querySelector(".email-loader");
  }

  private removeDefaultEmailBox() {
    setTimeout(() => {
      const nylasMailbox = this.el.nativeElement.querySelector("nylas-mailbox");
      const mainTag = nylasMailbox.shadowRoot.querySelector("main");
      const nylasEmail = mainTag?.querySelector("nylas-email");
      if (nylasEmail) {
        nylasEmail.remove();
      }
    }, 1000);
  }

  private addMessageBody(snippet: string) {
    const loadingTag = this.getLoadingTag();
    if (loadingTag) {
      const messageBody = this.renderer.createElement("div");
      this.renderer.addClass(messageBody, "message-body");
      const messageText = this.renderer.createText(snippet);
      this.renderer.appendChild(messageBody, messageText);

      this.renderer.appendChild(loadingTag.parentElement, messageBody);
    }
  }
  selectItem(item: string) {
    this.selectedItem = item;
  }
}
