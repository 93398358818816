import { Component, OnInit } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService, AuthGuard } from '@app/_services';
import { Router } from '@angular/router';
import { AppConstants } from "@app/_helpers/api-constants";
import { environment } from '@environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import moment from "moment";

export interface IStaxEnum {
  StaxEnumID: number, 
  Value: string
}
@Component({
  selector: 'app-s-tax',
  templateUrl: './s-tax.component.html',
  styleUrls: ['./s-tax.component.css']
})
export class STaxComponent implements OnInit {
  public companyId;
  public subscriptionLevel;
  public isErolled: boolean = false;
  public isunderwritingDialog: boolean = false;
  public isapiLoaded: boolean = false;
  public isSurchargeDialog: boolean = false;
  public isSurchargeConfirmationDialog = false;
  public enableContinueBtn = false;
  public isSurchargeTerms = false;

  public url;
  public mangoCompanyData: any;
  public enrollStatus: string = "";
  public serviceProvider: string = "";
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  public StaxUnderwriting: any = {};
  public Staxassumedata: any = {};
  public surchargeOptions: any = [];
  public nativeWindow: any;
  public selectedSurchargeOption: any = null;
  public queryStr: any = "?";
  public processingDataSet: any = [{ label: 'Never accepted cards before', value: 'NOT_NOW_PROC' },
  { label: 'Processor change', value: "PROC_CHANGE" }];

  public sTaxObj: any = { "skip_account_page": true, "merchant": { "plan": "api", "company_name": "", "contact_name": "", "contact_email": "", "contact_phone": "", "address_1": "", "address_2": "", "address_city": "", "address_state": "", "address_zip": "" }, "registration": { "pricing_plan": "", "brand": environment.STAX_brand, "first_name": "", "last_name": "", "email": "", "title": "", "company_type": "", "entity_ownership_type": "", "phone_number": null, "business_legal_name": null, "business_dba": "", "business_website": "", "business_fax": "", "business_email": "", "owner_address_1": "", "owner_address_2": "", "owner_address_city": "", "owner_address_state": "", "owner_address_zip": "", "owner_address_country": "", "business_tax_id": "", "business_open_date": "", "job_title": "", "service_you_provide": "", "annual_volume": "", "highest_trans_amount": "", "card_not_present_percent": "", "bank_routing_number": null, "bank_account_number": null, "bank_account_owner_name": "", "secondary_bank_routing_number": "", "secondary_bank_account_number": "", "secondary_bank_account_purpose": "", "principal_owners_name": "", "chosen_processing_method": "api" }, "user": { "name": null, "email": null, "team_role": "staff", "team_enabled": true, "password": "", "password_confirmation": "" } };

  staxEnums: IStaxEnum[] = [];
  constructor(private authGuard: AuthGuard, private router: Router, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, private sanitizer: DomSanitizer, private translate: TranslateService) {
    this.translate.reloadLang(this.translate.currentLang).subscribe(() => {
      this.initTranslations();
    })
    this.mangoAPISrvc.notifyLogging(this.encrDecSrvc.getObject(AppConstants.isLoggingUser));
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);


    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID).toString();
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
    this.getCompanyInfo();

    this.surchargeOptions = [
      { name: 'Yes', code: 1 },
      { name: 'No', code: 0 }
    ];
  }

  ngOnInit(): void {
    this.mangoAPISrvc.getStaxEnums().subscribe((result: IStaxEnum[]) => {
      this.staxEnums = result;
      this.sTaxObj.registration.pricing_plan = this.getStaxEnumValue(1)
    })
  }

  getStaxEnumValue (id: number) {
    return this.staxEnums.find((item) => item.StaxEnumID === id)?.Value ?? "";
  }

  isFormChanged() {
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Accounting') },
      { label: 'Electronic Payments', icon: 'ic-red' }
    ]);
  }

  getCompanyInfo() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe(response => {
      parent.mangoCompanyData = response[0];
      parent.sTaxObj['merchant']['company_name'] = parent.mangoCompanyData.CompanyName;
      parent.sTaxObj.merchant.contact_name = parent.mangoCompanyData.CompanyContact;
      parent.sTaxObj.merchant.contact_email = parent.mangoCompanyData.EmailCompany;
      parent.mangoCompanyData['mangoCompanyID'] = this.companyId;
      if (parent.mangoCompanyData.isStMIdPresents) {
        //parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.getTimeAndExpenses().subscribe((mangoresponse: any) => {
          parent.encrDecSrvc.addObject(AppConstants.isStaxEnrollmentStarted, mangoresponse.isStaxEnrollmentStarted);
          parent.checkMerchantStatus();
        });
      } else {
        parent.prepareDefaultData();
        parent.isapiLoaded = true;
        parent.mangoAPISrvc.showLoader(false);
      }
    }, (error: any) => {
      parent.isapiLoaded = true;
      parent.mangoAPISrvc.notify(
        "error",
        parent.translate.instant("error"),
        parent.translate.instant("error")
      );
      this.mangoAPISrvc.showLoader(false);
    });
  }

  logintoStax(isOld) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.performStaxActions({ queryStr: this.queryStr }, this.mangoCompanyData.CompanyID, 'eph').subscribe((mangores: any) => {
      this.mangoAPISrvc.showLoader(false);
      const url = `https://omni.fattmerchant.com/#/sso?jwt[]=${mangores.token}`;
      const newWindow = this.nativeWindow.open(decodeURI(url));
      newWindow.location = url;
    }, error => {
      this.mangoAPISrvc.showLoader(false);
    });
  }


  logintoStaxReports(link) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.performStaxActions({ queryStr: this.queryStr }, this.mangoCompanyData.CompanyID, 'eph').subscribe((mangores: any) => {
      this.mangoAPISrvc.showLoader(false);
      const url = `https://omni.fattmerchant.com/#/sso?jwt[]=${mangores.token}&loginRedirect=${link}`;
      const newWindow = this.nativeWindow.open(decodeURI(url));
      newWindow.location = url;
    }, error => {
      this.mangoAPISrvc.showLoader(false);
    });
  }

  logOutApp() {
    this.router.navigate(['/login']).then((result) => {
      this.authGuard.logout();
      setTimeout(() => {
        this.mangoAPISrvc.notifyLogging(false);
      }, 100);
    })
  }

  prepareDefaultData() {
    const parent = this;
    parent.mangoCompanyData.Zip = parent.mangoCompanyData.Zip ? parent.mangoCompanyData.Zip.trim() : "";
    parent.mangoCompanyData.State = parent.mangoCompanyData.State ? parent.mangoCompanyData.State.trim() : "";
    parent.mangoCompanyData.City = parent.mangoCompanyData.City ? parent.mangoCompanyData.City.trim() : "";
    parent.mangoCompanyData.Street1 = parent.mangoCompanyData.Street1 ? parent.mangoCompanyData.Street1.trim() : "";
    parent.mangoCompanyData.Street2 = parent.mangoCompanyData.Street2 ? parent.mangoCompanyData.Street2.trim() : "";
    parent.mangoCompanyData.Telephone = parent.mangoCompanyData.Telephone ? parent.mangoCompanyData.Telephone.trim() : "";

    /*setting up merchant data
    @ write means Editable
    @ read means Readonly
    @ null means Hide
    */
    parent.sTaxObj.merchant.company_name = parent.mangoCompanyData.CompanyName;
    parent.sTaxObj.merchant.contact_name = parent.mangoCompanyData.CompanyContact;
    parent.sTaxObj.merchant.contact_email = parent.mangoCompanyData.EmailCompany;
    parent.sTaxObj.merchant.contact_phone = parent.mangoCompanyData.Telephone.replace(/[-()\s]/g, '');
    parent.sTaxObj.merchant.address_2 = parent.mangoCompanyData.Street2 ? parent.mangoCompanyData.Street2 : "";
    parent.sTaxObj.merchant.address_1 = parent.mangoCompanyData.Street1;
    parent.sTaxObj.merchant.address_city = parent.mangoCompanyData.City;
    parent.sTaxObj.merchant.address_state = parent.mangoCompanyData.State;
    parent.sTaxObj.merchant.address_zip = parent.mangoCompanyData.Zip;

    parent.sTaxObj.registration.business_legal_name = parent.mangoCompanyData.CompanyName;
    parent.sTaxObj.registration.business_dba = parent.mangoCompanyData.CompanyName;
    parent.sTaxObj.registration.email = parent.mangoCompanyData.EmailCompany;
    parent.sTaxObj.registration.business_email = parent.mangoCompanyData.EmailCompany;
    parent.sTaxObj.registration.business_phone_number = parent.mangoCompanyData.Telephone.replace(/[-()\s]/g, '');
    parent.sTaxObj.registration.business_location_phone_number = parent.mangoCompanyData.Telephone.replace(/[-()\s]/g, '');
    parent.sTaxObj.registration.business_address_1 = parent.mangoCompanyData.Street1;
    parent.sTaxObj.registration.business_address_2 = parent.mangoCompanyData.Street2;
    parent.sTaxObj.registration.business_address_city = parent.mangoCompanyData.City;
    parent.sTaxObj.registration.business_address_state = parent.mangoCompanyData.State;
    parent.sTaxObj.registration.business_address_zip = parent.mangoCompanyData.Zip;
    parent.sTaxObj.registration.business_location_address_1 = parent.mangoCompanyData.Street1;
    parent.sTaxObj.registration.business_location_address_2 = parent.mangoCompanyData.Street2;
    parent.sTaxObj.registration.business_location_address_city = parent.mangoCompanyData.City;
    parent.sTaxObj.registration.business_location_address_state = parent.mangoCompanyData.State;
    parent.sTaxObj.registration.business_location_address_zip = parent.mangoCompanyData.Zip;
    parent.sTaxObj.registration.business_location_email = parent.mangoCompanyData.EmailCompany;

    // Default Values
    parent.sTaxObj.registration.entity_ownership_type = 'PRIVATE';
    parent.sTaxObj.registration.card_swiped_percent = '0';
    parent.sTaxObj.registration.b2b_percent = '50';
    parent.sTaxObj.registration.international = '0';
    parent.sTaxObj.registration.card_present_percent = '0';
    parent.sTaxObj.registration.card_not_present_percent = '100';
    parent.sTaxObj.registration.moto_percent = '100';
    parent.sTaxObj.registration.internet = '100';
    parent.sTaxObj.registration.bus_type = '18';
    parent.sTaxObj.registration.reason_for_applying = 'PROC_CHANGE'; // NOT_NOW_PROC
    parent.sTaxObj.registration.refund_policy = 'O';
    parent.sTaxObj.registration.service_you_provide = 'Accounting';
    parent.sTaxObj.registration.mcc = '8999';
    parent.sTaxObj.registration.business_location_address_country = 'USA';

    // Defauly ACH Values
    parent.sTaxObj.registration.annual_gross_ach_revenue = '275000';
    parent.sTaxObj.registration.largest_ach_transaction = '3500';
    parent.sTaxObj.registration.avg_ach_transaction = '600';

    // Defauly Credit Card Values
    parent.sTaxObj.registration.annual_volume = '27500';
    parent.sTaxObj.registration.highest_trans_amount = '3500';
    parent.sTaxObj.registration.avg_trans_size = '600';



    parent.sTaxObj.merchant['field_permissions'] = {};
    parent.sTaxObj.registration['field_permissions'] = {};

    parent.sTaxObj.registration['field_permissions']['entity_ownership_type'] = null;
    parent.sTaxObj.registration['field_permissions']['business_fax'] = null;
    parent.sTaxObj.registration['field_permissions']['pricing_plan'] = null;
    parent.sTaxObj.registration['field_permissions']['reason_for_applying'] = null;
    parent.sTaxObj.registration['field_permissions']['refund_policy'] = null;
    parent.sTaxObj.registration['field_permissions']['service_you_provide'] = null;
    parent.sTaxObj.registration['field_permissions']['annual_volume'] = null;

    parent.sTaxObj.registration['field_permissions'].avg_trans_size = null;
    parent.sTaxObj.registration['field_permissions'].highest_trans_amount = null;
    parent.sTaxObj.registration['field_permissions'].card_swiped_percent = null;
    parent.sTaxObj.registration['field_permissions'].b2b_percent = null;
    parent.sTaxObj.registration['field_permissions'].international = null;

    parent.sTaxObj.registration['field_permissions']['business_location_address_country'] = null;
    parent.sTaxObj.registration['field_permissions']['business_address_country'] = null;
    parent.sTaxObj.registration['field_permissions']['business_address_phone_number'] = null;
    parent.sTaxObj.registration['field_permissions']['business_phone_number'] = null;
    parent.sTaxObj.registration['field_permissions']['entity_ownership_type'] = 'write';

    if (parent.mangoCompanyData.Street1) {
      parent.sTaxObj.registration['field_permissions']['business_address_1'] = 'write';
      parent.sTaxObj.registration['field_permissions']['business_location_address_1'] = 'write';
      parent.sTaxObj.merchant['field_permissions']['address_1'] = 'write';
    }

    if (parent.mangoCompanyData.Street2) {
      parent.sTaxObj.registration['field_permissions']['business_address_2'] = 'write';
      parent.sTaxObj.registration['field_permissions']['business_location_address_2'] = 'write';
    }

    if (parent.mangoCompanyData.City) {
      parent.sTaxObj.registration['field_permissions']['business_address_city'] = 'write';
      parent.sTaxObj.registration['field_permissions']['business_location_address_city'] = 'write';
      parent.sTaxObj.merchant['field_permissions']['address_city'] = 'write';
    }

    if (parent.mangoCompanyData.State) {
      parent.sTaxObj.registration['field_permissions']['business_address_state'] = 'write';
      parent.sTaxObj.registration['field_permissions']['business_location_address_state'] = 'write';
      parent.sTaxObj.merchant['field_permissions']['address_state'] = 'write';
    }

    if (parent.mangoCompanyData.Zip) {
      parent.sTaxObj.registration['field_permissions']['business_address_zip'] = 'write';
      parent.sTaxObj.registration['field_permissions']['business_location_address_zip'] = 'write';
      parent.sTaxObj.merchant['field_permissions']['address_zip'] = 'write';
    }

    if (parent.mangoCompanyData.EmailCompany) {
      parent.sTaxObj.registration['field_permissions']['email'] = 'write';
      parent.sTaxObj.registration['field_permissions']['business_email'] = 'write';
      parent.sTaxObj.registration['field_permissions']['business_location_email'] = 'write';
      parent.sTaxObj.merchant['field_permissions']['contact_email'] = 'write';
    }

    if (parent.mangoCompanyData.Telephone) {
      parent.sTaxObj.registration['field_permissions']['business_location_phone_number'] = 'write';
      parent.sTaxObj.merchant['field_permissions']['contact_phone'] = 'write';
    }

    if (parent.mangoCompanyData.CompanyName) {
      parent.sTaxObj.registration['field_permissions']['business_legal_name'] = 'write';
      parent.sTaxObj.registration['field_permissions']['business_dba'] = 'write';
      parent.sTaxObj.merchant['field_permissions']['company_name'] = 'write';
    }

    if (parent.mangoCompanyData.CompanyContact) {
      const names = parent.mangoCompanyData.CompanyContact.trim().split(' ');
      if (names.length > 1) {
        // parent.sTaxObj.registration['first_name'] = names[0];
        // parent.sTaxObj.registration['last_name'] = names[1];
        // parent.sTaxObj.registration['field_permissions']['last_name'] = 'write';
        // parent.sTaxObj.registration['field_permissions']['first_name'] = 'write';
      }
      parent.sTaxObj.merchant['field_permissions']['contact_name'] = 'write';
    }
  }

  processHybridEnroll() {
    const parent = this;

    if (!parent.mangoCompanyData.Zip || !parent.mangoCompanyData.State || !parent.mangoCompanyData.City || !parent.mangoCompanyData.Street1 || !parent.mangoCompanyData.Telephone) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: "Company Address information is required to proceed with Mango Payments Enrollment.",
        showConfirmButton: false,
        timer: 3000
      })
      return false
    }
    parent.sTaxObj.registration.business_email = parent.sTaxObj.merchant.contact_email;
    parent.sTaxObj.registration.business_location_email = parent.sTaxObj.merchant.contact_email;
    parent.sTaxObj.registration.business_legal_name = parent.sTaxObj.merchant.company_name;
    parent.sTaxObj.registration.business_dba = parent.sTaxObj.merchant.company_name;
    parent.sTaxObj.registration['field_permissions']['pricing_plan'] = null;
    parent.sTaxObj.registration['email'] = parent.sTaxObj.merchant.contact_email;
    parent.sTaxObj.user['email'] = parent.sTaxObj.merchant.contact_email;
    parent.sTaxObj.user['name'] = parent.sTaxObj.merchant.contact_name;
    parent.sTaxObj.skip_account_page = true;

    //
    parent.sTaxObj.registration['meta'] = {};
    parent.sTaxObj.registration['meta']['mango_netnew'] = true;

    parent.sTaxObj.registration['meta']['mango_created_at'] = parent.mangoCompanyData.DatePlanActivated;
    parent.sTaxObj.registration['meta']['mango_months_processing'] = 0;

    if (parent.mangoCompanyData.DatePlanActivated) {
      const noOfDays = moment().diff(moment(parent.mangoCompanyData.DatePlanActivated), 'days');
      parent.sTaxObj.registration['meta']['mango_netnew'] = noOfDays > 15 ? false : true;
    }

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.performStaxActions({ sTaxObj: parent.sTaxObj, isHybrid: true }, parent.mangoCompanyData.CompanyID, 'enh').subscribe((response: any) => {
      //enrollHybridStax(parent.sTaxObj).subscribe((response: any) => {
      parent.mangoAPISrvc.showLoader(false);
      Swal.fire({
        title: parent.translate.instant('confirmation'),
        html: `<div>${parent.translate.instant('stax.msg')}?</div>`,
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: parent.translate.instant('Yes'),
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          parent.isSurchargeConfirmationDialog = false;
          parent.isSurchargeDialog = false;
          const url = `https://signup.fattmerchant.com/#/sso?jwt=${response.token}`;
          const newWindow = parent.nativeWindow.open(decodeURI(url));
          newWindow.location = url;

          setTimeout(() => {
            parent.nativeWindow.location.reload();
            // parent.logOutApp();
            // parent.encrDecSrvc.addObject(AppConstants.clientID, "");
            // parent.mangoAPISrvc.fireClientView(true);
            // parent.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.listRoutePath]);
          }, 100);

        }
      })
      // if (response && Object.keys(response).length > 0) {
      //   parent.mangoCompanyData.StaxToken = response.token;
      //   parent.mangoCompanyData.StaxMerchantID = response.merchant.id;
      //   parent.mangoCompanyData.StaxPublicKey = response.merchant.hosted_payments_token;
      //   parent.mangoCompanyData.StaxStatus = response.merchant.status;
      //   parent.mangoAPISrvc.getStaxMerchantapiKey(response.merchant.id, { team_role: 'admin', "name": parent.sTaxObj.company_name }).subscribe(apiKeyResponse => {
      //     parent.mangoCompanyData.StaxMerchantAPIKey = apiKeyResponse['api_key'];
      //     parent.mangoCompanyData.isStaxEnrollmentStarted = true;

      //     parent.mangoAPISrvc.updateVendorAPIKeys(parent.mangoCompanyData).subscribe(mangores => {
      //       parent.mangoAPISrvc.showLoader(false);
      //       Swal.fire({
      //         title: parent.translate.instant('confirmation'),
      //         html: `<div>${parent.translate.instant('stax.msg')}?</div>`,
      //         icon: 'warning',
      //         showCancelButton: true,
      //         allowEscapeKey: false,
      //         allowEnterKey: false,
      //         confirmButtonText: parent.translate.instant('Yes'),
      //         cancelButtonText: 'No'
      //       }).then((result) => {
      //         if (result.value) {
      //           parent.isSurchargeConfirmationDialog = false;
      //           parent.isSurchargeDialog = false;
      //           let url = `https://signup.fattmerchant.com/#/sso?jwt=${response.token}`;
      //           var newWindow = parent.nativeWindow.open(decodeURI(url));
      //           newWindow.location = url;

      //           setTimeout(() => {
      //             parent.nativeWindow.location.reload();
      //             // parent.logOutApp();
      //             // parent.encrDecSrvc.addObject(AppConstants.clientID, "");
      //             // parent.mangoAPISrvc.fireClientView(true);
      //             // parent.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.listRoutePath]);
      //           }, 100);

      //         }
      //       })

      //     }), error => {
      //       parent.mangoAPISrvc.showLoader(false);
      //     };

      //   }), error => {
      //     parent.mangoAPISrvc.showLoader(false);
      //   };
      // } else {
      //   parent.mangoAPISrvc.showLoader(false);
      //   Swal.fire({
      //     icon: "info",
      //     title: "This email id is already registered.Try another one",
      //     showConfirmButton: false,
      //     timer: 7000,
      //   });
      // }
      //parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', this.translate.instant('error'), 'There is a problem with the information submitted.  Email must be unique and Passwords must match.');
      parent.mangoAPISrvc.showLoader(false);
    });

  }

  processCard() {
    const parent = this;

    parent.sTaxObj.contact_email = parent.sTaxObj.email;
    parent.sTaxObj.business_email = parent.sTaxObj.email;
    parent.sTaxObj.skip_account_page = true;
    parent.sTaxObj.business_legal_name = parent.sTaxObj['company_name'];


    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.performStaxActions({ sTaxObj: parent.sTaxObj, isHybrid: false }, parent.mangoCompanyData.CompanyID, 'enh').subscribe((response: any) => {
      Swal.fire({
        title: parent.translate.instant('confirmation'),
        html: `<div>${parent.translate.instant('stax.msg')}?</div>`,
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: parent.translate.instant('Yes'),
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          setTimeout(() => {
            parent.logOutApp();
            parent.encrDecSrvc.addObject(AppConstants.clientID, "");
            parent.mangoAPISrvc.fireClientView(true);
            parent.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.listRoutePath]);
          }, 100);
          const url = `https://signup.fattmerchant.com/#/sso?jwt=${response[0].token}`;
          const newWindow = parent.nativeWindow.open(decodeURI(url));
          newWindow.location = url;

        }
      })
      //enrollStax(parent.sTaxObj).subscribe((response: any) => {
      // if (response && response.length > 0) {
      //   parent.mangoCompanyData.StaxToken = response[0].token;
      //   parent.mangoCompanyData.StaxMerchantID = response[0].merchant.id;
      //   parent.mangoCompanyData.StaxPublicKey = response[0].merchant.hosted_payments_token;
      //   parent.mangoCompanyData.StaxStatus = response[0].merchant.status;
      //   parent.mangoAPISrvc.getStaxMerchantapiKey(response[0].merchant.id, { team_role: 'admin', "name": parent.sTaxObj.company_name }).subscribe(apiKeyResponse => {
      //     parent.mangoCompanyData.StaxMerchantAPIKey = apiKeyResponse['api_key'];
      //     parent.mangoCompanyData.isStaxEnrollmentStarted = true;

      //     parent.mangoAPISrvc.updateVendorAPIKeys(parent.mangoCompanyData).subscribe(mangores => {
      //       parent.mangoAPISrvc.showLoader(false);
      //       Swal.fire({
      //         title: parent.translate.instant('confirmation'),
      //         html: `<div>${parent.translate.instant('stax.msg')}?</div>`,
      //         icon: 'warning',
      //         showCancelButton: true,
      //         allowEscapeKey: false,
      //         allowEnterKey: false,
      //         confirmButtonText: parent.translate.instant('Yes'),
      //         cancelButtonText: 'No'
      //       }).then((result) => {
      //         if (result.value) {
      //           setTimeout(() => {
      //             parent.logOutApp();
      //             parent.encrDecSrvc.addObject(AppConstants.clientID, "");
      //             parent.mangoAPISrvc.fireClientView(true);
      //             parent.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.listRoutePath]);
      //           }, 100);
      //           let url = `https://signup.fattmerchant.com/#/sso?jwt=${response[0].token}`;
      //           var newWindow = parent.nativeWindow.open(decodeURI(url));
      //           newWindow.location = url;

      //         }
      //       })

      //     }), error => {
      //       parent.mangoAPISrvc.showLoader(false);
      //     };

      //   }), error => {
      //     parent.mangoAPISrvc.showLoader(false);
      //   };
      // } else {
      //   parent.mangoAPISrvc.showLoader(false);
      //   Swal.fire({
      //     icon: "info",
      //     title: "This email id is already registered.Try another one",
      //     showConfirmButton: false,
      //     timer: 7000,
      //   });
      // }
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', this.translate.instant('error'), 'There is a problem with the information submitted.  Email must be unique and Passwords must match.');
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  checkMerchantStatus() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.performStaxActions({ isFullData: true }, parent.mangoCompanyData.CompanyID, 'lrv').subscribe((lrvresponse: any) => {
      parent.StaxUnderwriting = lrvresponse;
      parent.queryStr += `user_id=${lrvresponse.user_id}&user_email=${lrvresponse.email}`;
      parent.mangoAPISrvc.performStaxActions({}, parent.mangoCompanyData.CompanyID, 'gmd').subscribe((gmdresponse: any) => {
        parent.Staxassumedata = gmdresponse;
        parent.mangoCompanyData.StaxStatus = gmdresponse['status'];
        parent.mangoCompanyData.isSurchargeEnabled = gmdresponse['is_surcharge_enabled'] ? gmdresponse['is_surcharge_enabled'] : false;
        if (!parent.mangoCompanyData.spkuid) {
          parent.mangoCompanyData.spkuid = gmdresponse['hpt'];
        }
        parent.mangoAPISrvc.updateVendorAPIKeys(parent.mangoCompanyData).subscribe(mangores => { });
        parent.mangoAPISrvc.showLoader(false);
        parent.isapiLoaded = true;
      }, (error: any) => {
        parent.isapiLoaded = true;
        parent.mangoAPISrvc.notify(
          "error",
          parent.translate.instant("error"),
          parent.translate.instant("error")
        );
        this.mangoAPISrvc.showLoader(false);
      });
    });
  }

  finishEnrollment() {
    this.mangoAPISrvc.performStaxActions({ queryStr: this.queryStr }, this.mangoCompanyData.CompanyID, 'eph').subscribe((mangores: any) => {
      this.mangoAPISrvc.showLoader(false);
      const url = `https://omni.fattmerchant.com/#/sso?jwt=${mangores.token}`;
      const newWindow = this.nativeWindow.open(decodeURI(url));
      newWindow.location = url;
    }, error => {
      this.mangoAPISrvc.showLoader(false);
    });
    // let url = `https://signup.fattmerchant.com/#/sso?jwt=${token}`;
    // var newWindow = this.nativeWindow.open(decodeURI(url));
    // newWindow.location = url;
  }

  onClearClick() {
    this.sTaxObj = { "skip_account_page": true, "merchant": { "plan": "api", "company_name": "", "contact_name": "", "contact_email": "", "contact_phone": "", "address_1": "", "address_2": "", "address_city": "", "address_state": "", "address_zip": "" }, "registration": { "pricing_plan": this.getStaxEnumValue(1), "brand": environment.STAX_brand, "first_name": "", "last_name": "", "email": "", "title": "", "company_type": "", "entity_ownership_type": "", "phone_number": "", "business_legal_name": "", "business_dba": "", "business_website": "", "business_fax": "", "business_email": "", "owner_address_1": "", "owner_address_2": "", "owner_address_city": "", "owner_address_state": "", "owner_address_zip": "", "owner_address_country": "", "business_tax_id": "", "business_open_date": null, "job_title": "", "service_you_provide": "", "annual_volume": "", "highest_trans_amount": "", "card_not_present_percent": "", "bank_routing_number": "", "bank_account_number": null, "bank_account_owner_name": "", "secondary_bank_routing_number": "", "secondary_bank_account_number": "", "secondary_bank_account_purpose": "", "principal_owners_name": "", "chosen_processing_method": "api" }, "user": { "name": "", "email": "", "team_role": "staff", "team_enabled": true, "password": "", "password_confirmation": "" } };    
    this.prepareDefaultData();
  }

  redirectTransation() {
    this.router.navigate([`/accounting/stax-transactions`]);
  }

  openStaxSurchargeConditions() {
    const parent = this;
    if (!parent.mangoCompanyData.spkuid) {
      parent.isSurchargeDialog = true;
      parent.enableContinueBtn = false;
      parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    } else {
      Swal.fire({
        title: parent.translate.instant('confirmation'),
        html: `<div>Surcharging requests are processed on each Wednesday. Electronic Payments Processing will be on hold until the following Monday to allow for provisioning of the merchant account</div>`,
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: parent.translate.instant('OK'),
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          parent.isSurchargeDialog = true;
          parent.enableContinueBtn = false;
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
        }
      })
    }

  }

  onChangeAcceptance() {
    this.enableContinueBtn = false;

    if (this.selectedSurchargeOption.code == 0 && !this.mangoCompanyData.isStMIdPresents) {
      this.enableContinueBtn = true;
      this.isSurchargeTerms = false;
    } else if (this.selectedSurchargeOption.code == 1 && this.isSurchargeTerms) {
      this.enableContinueBtn = true;
    }

  }

  processStaxCard() {
    this.isSurchargeDialog = false;
    this.mangoCompanyData.isSurchargeEnabled = (this.selectedSurchargeOption.code == 1) ? true : false;
    this.mangoCompanyData.AcknowledgedVisaMC = (this.selectedSurchargeOption.code == 1) ? true : false;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
    if (!this.mangoCompanyData.isStaxEnrollmentStarted) {
      this.sTaxObj.registration.pricing_plan =
        this.selectedSurchargeOption.code == 1
          ? this.getStaxEnumValue(7)
          : this.getStaxEnumValue(1);
      //this.processCard();
      this.processHybridEnroll();
    } else {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.updateVendorAPIKeys(this.mangoCompanyData).subscribe(mangores => {
        this.sendMail();
      }, error => {
        this.mangoAPISrvc.showLoader(false);
      });
    }

  }

  sendMail() {
    const parent = this;
    const toArr = [];
    const companysendInBlueObj = {
      sender: { name: "Mango Billing", email: "noreply@mangobilling.com" },
      to: [{ email: "", name: "" }],
      replyTo: { email: "noreply@mangobilling.com" },
      templateId: 60,
      params: {},
    };
    const toObj = { email: '', name: '' };

    if (toArr.length == 0) {
      toObj['email'] = "partnersupport@fattmerchant.com";
      toObj['name'] = "Partner support";
      toArr.push(toObj)
    }
    companysendInBlueObj.to = toArr;

    companysendInBlueObj.params["COMPANYNAME"] = parent.mangoCompanyData.CompanyName;

    this.mangoAPISrvc.sendSMTPEmail(companysendInBlueObj).subscribe(
      (data) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.isSurchargeDialog = false;
        parent.isSurchargeDialog = false;
        parent.isSurchargeTerms = false;
        parent.isSurchargeConfirmationDialog = false;
        parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
      },
      (err) => {
        parent.mangoAPISrvc.showLoader(false);
      })
  }

  processSurchargeTerms() {
    this.isSurchargeDialog = false;
    if (this.selectedSurchargeOption.code == 0) {
      this.processStaxCard();
    } else {
      this.isSurchargeConfirmationDialog = true;
    }
  }

  showUnderWritingConditions() {
    this.isunderwritingDialog = true;
  }


}
