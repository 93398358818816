import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { Table } from 'primeng/table';
import { MegaMenuItem } from 'primeng/api';
declare let numeral: any;
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html'
})
export class ListComponent implements OnInit {
  items: MegaMenuItem[];
  noteList: any = [];
  eventTypeList: any = [];
  contactTypeList: any = [];
  customerTypeList: any = [];
  termList: any = [];
  staffList: any = [];
  staffDeptList: any = [];
  groupList: any = [];
  invoiceGroupList: any = [];
  engagementsTypeList: any = [];
  workLocationsList: any = [];
  paymentMethodsList: any = [];
  cols: any[];
  companyId;

  addIndex: any = 1;
  editIndex;
  staffTitlesGB;
  displayAddModal: boolean = false;
  obj: any = {};
  searchNote: any;
  searchContact: any;
  searchCustomerType: any;
  searchStaff: any;
  searchTerm: any;
  searchInvoiceGroup: any;
  searchStaffDept: any;
  searchGroup: any;
  searchEvent: any;

  noteOrderBy = 'NoteCategory';
  staffOrderBy = 'StaffTitleDescription';
  staffDeptOrderBy = 'DeptDescription';
  contactTypeOrderBy = 'ContactType';
  termOrderBy = 'TermsDescription';
  groupOrderBy = 'GroupDescription';
  customerTypeOrderBy = 'CustomerTypeDescription';
  invoiceGroupOrderBy = 'RecurringGroupDescription';

  contactTypeInactive = false;
  staffInactive = false;
  noteInactive = false;
  eventInactive = false;
  termInactive = false;
  groupInactive = false;
  invoiceGroupInactive = false;
  engagementsInactive = false;
  staffDeptInactive = false;
  customerTypeInactive = false;
  workLocationInactive = false;
  paymentMethodsRequired = false;
  @ViewChild('searchNoteGB') searchValue1;
  @ViewChild('searchContactGB') searchValue2;
  @ViewChild('customerTypesGB') searchValue3;
  @ViewChild('staffTitlesGB') searchValue4;
  @ViewChild('termsGB') searchValue5;
  @ViewChild('staffDeptGB') searchValue6;
  @ViewChild('customerGroupsGB') searchValue7;
  @ViewChild('invoiceGroupsGB') searchValue8;
  @ViewChild('engagementGB') searchValue9;
  @ViewChild('engagementGB') searchValue10;
  @ViewChild('PaymentMethodsGB') searchValue11;
  @ViewChild('dt') dataTableCategories: Table;
  @ViewChild('dtcontact') dataTableContact: Table;
  @ViewChild('typelist') dataTableTypelist: Table;
  @ViewChild('staffsList') dataTableStafflist: Table;
  @ViewChild('custmorTerms') dataTablecustmorTerms: Table;
  @ViewChild('staffDept') dataTablestaffDept: Table;
  @ViewChild('customerGroups') dataTablecustomerGroups: Table;
  @ViewChild('customerGroupslist') dataTablecustomerGroupsList: Table;
  @ViewChild('engagementlist') dataTableengagementGroupsList: Table;
  @ViewChild('workLocationList') dataTableworkLocationslistList: Table;
  @ViewChild('paymentMethodList') dataTablepaymentMethodList: Table;

  tmpNoteList = [];
  tmpEventTypeList = [];
  tmpStaffList = [];
  tmpStaffDeptList: any = [];
  tmpContactTypeList: any = [];
  tmpInvoiceGroupList: any = [];
  tmpEngagementsTypeList: any = [];
  tmpWorkLocationsList: any = [];
  tmpTermList: any = [];
  tmpGroupList: any = [];
  tmpCustomerTypeList: any = [];
  tmpPaymentMethodsList: any = [];
  subscriptionLevel: string;
  tmpPaymentMethodsRequired: any = [
    'Check',
    'Cash',
    'Credit Card',
    'ACH',
    'Write-Off',
    'Credit Memo',
    'Refund Advance Payment'
  ];

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public mangoUtils: mangoUtils,
    private translate: TranslateService
  ) {
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
  }

  ngOnInit() {
    this.LoadDefaults();
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('list'), icon: 'ic-red' }
    ]);
    if (this.subscriptionLevel.toUpperCase() == 'PM') {
      this.items = [
        {
          label: this.translate.instant('settings.Client-Note-Categories'),
          tabindex: '1',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('settings.Client-Contact-Types'),
          tabindex: '2',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('Client_Types'),
          tabindex: '3',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('settings.Staff-Titles'),
          tabindex: '4',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('client_terms'),
          tabindex: '5',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('settings.Staff-Department'),
          tabindex: '6',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('Client_Groups'),
          tabindex: '7',
          command: event => {
            this.handleChange(event);
          }
        }
      ];
    } else {
      this.items = [
        {
          label: this.translate.instant('settings.Client-Note-Categories'),
          tabindex: '1',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('settings.Client-Contact-Types'),
          tabindex: '2',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('Client_Types'),
          tabindex: '3',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('settings.Staff-Titles'),
          tabindex: '4',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('client_terms'),
          tabindex: '5',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('settings.Staff-Department'),
          tabindex: '6',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('list.groups'),
          tabindex: '7',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('settings.Recurring-Invoice-Groups'),
          tabindex: '8',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('Engagement-Types'),
          tabindex: '9',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('Work-Locations'),
          tabindex: '10',
          command: event => {
            this.handleChange(event);
          }
        },
        {
          label: this.translate.instant('settings.Company-Payment-Methods'),
          tabindex: '11',
          command: event => {
            this.handleChange(event);
          }
        }
      ];
    }
  }

  handleChange(event) {
    this.clearSearchFilter(parseInt(event.item.tabindex));
  }

  LoadDefaults() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.loadListDefaults(self.companyId).subscribe(
      (results: any) => {
        self.getTerms(results[0]);
        self.getNoteCategories(results[1]);
        self.getStaffTitles(results[2]);
        self.getStaffDept(results[3]);
        self.getContactType(results[4]);
        self.getGroups(results[5]);
        self.getInvoiceGroups(results[6]);
        self.getEngagements(results[7]);
        self.getWorkLocations(results[8]);
        self.getCustomerType(results[9]);
        self.getPaymentMethods(results[10]);
        setTimeout(() => {
          self.mangoAPISrvc.showLoader(false);
        }, 100);
      },
      error => {
        self.mangoAPISrvc.notify(
          'error',
          self.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getNoteCategories(data) {
    const parent = this;
    parent.tmpNoteList = [];
    parent.noteList = data;
    for (let i = 0; i < data.length; i++) {
      parent.noteList[i].index = i;
      parent.noteList[i].NoteColor = parent.noteList[i].NoteColor || '#5d5f61';
    }
    parent.onStatusChange(1);
  }

  getContactType(data) {
    const parent = this;
    parent.tmpContactTypeList = [];
    parent.contactTypeList = data;
    for (let i = 0; i < data.length; i++) {
      parent.contactTypeList[i].index = i;
    }
    parent.onStatusChange(2);
  }

  getCustomerType(data) {
    const parent = this;
    parent.tmpCustomerTypeList = [];
    parent.customerTypeList = data;
    for (let i = 0; i < data.length; i++) {
      parent.customerTypeList[i].index = i;
    }
    parent.onStatusChange(3);
  }

  getStaffTitles(data) {
    const parent = this;
    parent.tmpStaffList = [];
    parent.staffList = data;
    for (let i = 0; i < data.length; i++) {
      parent.staffList[i].index = i;
    }
    parent.onStatusChange(4);
  }

  getTerms(data) {
    const parent = this;
    parent.tmpTermList = [];
    parent.termList = data;
    for (let i = 0; i < data.length; i++) {
      parent.termList[i].index = i;
      parent.termList[i].GraceDays = numeral(parent.termList[i].GraceDays).value();
    }
    parent.onStatusChange(5);
  }

  getStaffDept(data) {
    const parent = this;
    parent.tmpStaffDeptList = [];
    parent.staffDeptList = data;
    for (let i = 0; i < data.length; i++) {
      parent.staffDeptList[i].index = i;
    }
    parent.onStatusChange(6);
  }

  getGroups(data) {
    const parent = this;
    parent.tmpGroupList = [];
    parent.groupList = data;
    for (let i = 0; i < data.length; i++) {
      parent.groupList[i].index = i;
    }
    parent.onStatusChange(7);
  }

  getInvoiceGroups(data) {
    const parent = this;
    parent.tmpInvoiceGroupList = [];
    parent.invoiceGroupList = data;
    for (let i = 0; i < data.length; i++) {
      parent.invoiceGroupList[i].index = i;
    }
    parent.onStatusChange(8);
  }

  getEngagements(data) {
    const parent = this;
    parent.tmpEngagementsTypeList = [];
    parent.engagementsTypeList = data;
    for (let i = 0; i < data.length; i++) {
      parent.engagementsTypeList[i].index = i;
    }
    parent.onStatusChange(9);
  }

  getWorkLocations(data) {
    const parent = this;
    parent.tmpWorkLocationsList = [];
    parent.workLocationsList = data;
    for (let i = 0; i < data.length; i++) {
      parent.workLocationsList[i].index = i;
    }
    parent.onStatusChange(10);
  }

  getPaymentMethods(data){
    const parent = this;
    parent.tmpPaymentMethodsList = [];
    parent.paymentMethodsList = data;
    for (let i = 0; i < data.length; i++) {
      parent.paymentMethodsList[i].index = i;
    }
    parent.onStatusChange(11);
  }

  onStatusChange(index) {
    switch (index) {
      case 1:
        if (this.tmpNoteList.length < 1) this.tmpNoteList = this.noteList;
        if (this.noteInactive)
          this.noteList = this.tmpNoteList.filter(note => note.Inactive == true);
        else this.noteList = this.tmpNoteList.filter(note => note.Inactive == false);

        break;

      case 2:
        if (this.tmpContactTypeList.length < 1) this.tmpContactTypeList = this.contactTypeList;
        if (this.contactTypeInactive)
          this.contactTypeList = this.tmpContactTypeList.filter(
            contactType => contactType.Inactive == true
          );
        else
          this.contactTypeList = this.tmpContactTypeList.filter(
            contactType => contactType.Inactive == false
          );

        break;

      case 3:
        if (this.tmpCustomerTypeList.length < 1) this.tmpCustomerTypeList = this.customerTypeList;
        if (this.customerTypeInactive)
          this.customerTypeList = this.tmpCustomerTypeList.filter(
            customerType => customerType.Inactive == true
          );
        else
          this.customerTypeList = this.tmpCustomerTypeList.filter(
            customerType => customerType.Inactive == false
          );

        break;

      case 4:
        if (this.tmpStaffList.length < 1) this.tmpStaffList = this.staffList;
        if (this.staffInactive)
          this.staffList = this.tmpStaffList.filter(staff => staff.Inactive == true);
        else this.staffList = this.tmpStaffList.filter(staff => staff.Inactive == false);

        break;

      case 5:
        if (this.tmpTermList.length < 1) this.tmpTermList = this.termList;
        if (this.termInactive)
          this.termList = this.tmpTermList.filter(term => term.Inactive == true);
        else this.termList = this.tmpTermList.filter(term => term.Inactive == false);

        break;

      case 6:
        if (this.tmpStaffDeptList.length < 1) this.tmpStaffDeptList = this.staffDeptList;
        if (this.staffDeptInactive)
          this.staffDeptList = this.tmpStaffDeptList.filter(
            staffDept => staffDept.Inactive == true
          );
        else
          this.staffDeptList = this.tmpStaffDeptList.filter(
            staffDept => staffDept.Inactive == false
          );
        break;

      case 7:
        if (this.tmpGroupList.length < 1) this.tmpGroupList = this.groupList;
        if (this.groupInactive)
          this.groupList = this.tmpGroupList.filter(group => group.Inactive == true);
        else this.groupList = this.tmpGroupList.filter(group => group.Inactive == false);

        break;

      case 8:
        if (this.tmpInvoiceGroupList.length < 1) this.tmpInvoiceGroupList = this.invoiceGroupList;
        if (this.invoiceGroupInactive)
          this.invoiceGroupList = this.tmpInvoiceGroupList.filter(
            invoiceGroup => invoiceGroup.Inactive == true
          );
        else
          this.invoiceGroupList = this.tmpInvoiceGroupList.filter(
            invoiceGroup => invoiceGroup.Inactive == false
          );
        break;

      case 9:
        if (this.tmpEngagementsTypeList.length < 1)
          this.tmpEngagementsTypeList = this.engagementsTypeList;
        if (this.engagementsInactive)
          this.engagementsTypeList = this.tmpEngagementsTypeList.filter(
            invoiceGroup => invoiceGroup.Inactive == true
          );
        else
          this.engagementsTypeList = this.tmpEngagementsTypeList.filter(
            invoiceGroup => invoiceGroup.Inactive == false
          );
        break;

      case 10:
        if (this.tmpWorkLocationsList.length < 1)
          this.tmpWorkLocationsList = this.workLocationsList;
        if (this.workLocationInactive)
          this.workLocationsList = this.tmpWorkLocationsList.filter(
            invoiceGroup => invoiceGroup.Inactive == true
          );
        else
          this.workLocationsList = this.tmpWorkLocationsList.filter(
            invoiceGroup => invoiceGroup.Inactive == false
          );
        break;

      case 11:
        if (this.tmpPaymentMethodsList.length < 1)
          this.tmpPaymentMethodsList = this.paymentMethodsList;
        if (this.paymentMethodsRequired)
          this.paymentMethodsList = this.tmpPaymentMethodsList.filter(
            item => item.Required == true
          );
        else this.paymentMethodsList = this.tmpPaymentMethodsList;
        break;
    }
  }

  replaceShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    this.obj.customlableValue = valueArr.join(' ');
  }

  openEditWindow(data, id) {
    const index = data.index;

    this.addIndex = id;
    this.editIndex = index;
    this.obj = {};
    this.obj.isEdit = true;
    this.obj.maxlength = 40;
    switch (id) {
      case 1:
        this.obj.title = 'Note Categories';
        this.obj.lable = 'Categories';
        this.obj.value = data.NoteCategory;
        this.obj.Inactive = data.Inactive;
        this.obj.NoteColor = data.NoteColor || '#5d5f61';
        break;
      case 2:
        this.obj.title = 'Contact Types';
        this.obj.lable = 'Type';
        this.obj.value = data.ContactType;
        this.obj.Inactive = data.Inactive;
        break;

      case 3:
        this.obj.title = 'Customer Type';
        this.obj.lable = 'Description';
        this.obj.value = data.CustomerTypeDescription;
        this.obj.Inactive = data.Inactive;
        break;

      case 4:
        this.obj.title = 'Staff Titles';
        this.obj.lable = 'Titles';
        this.obj.value = data.StaffTitleDescription;
        this.obj.Inactive = data.Inactive;
        break;

      case 5:
        this.obj.title = 'Customer Terms';
        this.obj.lable = 'Terms Description';
        this.obj.value = data.TermsDescription;
        this.obj.lable1 = 'Grace Days';
        this.obj.value1 = data.GraceDays;
        this.obj.Inactive = data.Inactive;
        break;

      case 6:
        this.obj.title = 'Staff Department';
        this.obj.lable = 'Department';
        this.obj.value = data.DeptDescription;
        this.obj.Inactive = data.Inactive;
        break;

      case 7:
        this.obj.title = 'Customer Groups';
        this.obj.lable = 'Group Description';
        this.obj.value = data.GroupDescription;
        this.obj.Inactive = data.Inactive;
        break;

      case 8:
        this.obj.title = 'Recurring Invoice Groups';
        this.obj.lable = 'Description';
        this.obj.value = data.RecurringGroupDescription;
        this.obj.maxlength = 255;
        this.obj.Inactive = data.Inactive;
        break;
      case 9:
        this.obj.title = 'Engagement Types';
        this.obj.lable = 'Description';
        this.obj.value = data.Description;
        this.obj.customlableValue = data.InvoiceDescription;
        this.obj.maxlength = 400;
        this.obj.Inactive = data.Inactive;
        break;

      case 10:
        this.obj.title = 'Work Location';
        this.obj.lable = 'Zip Code';
        this.obj.value = data.ZipCode;
        this.obj.Inactive = data.Inactive;
        break;

      case 11:
        this.obj.title = 'Payment Methods';
        this.obj.lable = 'Payment Description';
        this.obj.value = data.PaymentDescription;
        this.obj.Inactive = data.Required;
    }
    this.displayAddModal = true;
  }

  clearSearchFilter(index) {
    this.addIndex = index;
    if (this.searchValue1 && this.searchValue1.nativeElement && index == 1) {
      this.searchValue1.nativeElement.value = '';
      this.dataTableCategories.reset();
    } else if (this.searchValue2 && this.searchValue2.nativeElement && index == 2) {
      this.searchValue2.nativeElement.value = '';
      this.dataTableContact.reset();
    } else if (this.searchValue3 && this.searchValue3.nativeElement && index == 3) {
      this.searchValue3.nativeElement.value = '';
      this.dataTableTypelist.reset();
    } else if (this.searchValue4 && this.searchValue4.nativeElement && index == 4) {
      this.searchValue4.nativeElement.value = '';
      this.dataTableStafflist.reset();
    } else if (this.searchValue5 && this.searchValue5.nativeElement && index == 5) {
      this.searchValue5.nativeElement.value = '';
      this.dataTablecustmorTerms.reset();
    } else if (this.searchValue6 && this.searchValue6.nativeElement && index == 6) {
      this.searchValue6.nativeElement.value = '';
      this.dataTablestaffDept.reset();
    } else if (this.searchValue7 && this.searchValue7.nativeElement && index == 7) {
      this.searchValue7.nativeElement.value = '';
      this.dataTablecustomerGroups.reset();
    } else if (this.searchValue8 && this.searchValue8.nativeElement && index == 8) {
      this.searchValue8.nativeElement.value = '';
      this.dataTablecustomerGroupsList.reset();
    } else if (this.searchValue9 && this.searchValue9.nativeElement && index == 9) {
      this.searchValue9.nativeElement.value = '';
      this.dataTableengagementGroupsList.reset();
    } else if (this.searchValue10 && this.searchValue10.nativeElement && index == 10) {
      this.searchValue10.nativeElement.value = '';
      this.dataTableworkLocationslistList.reset();
    } else if (this.searchValue11 && this.searchValue11.nativeElement && index == 11) {
      this.searchValue11.nativeElement.value = '';
      this.dataTablepaymentMethodList.reset();
    }
  }

  save(flag, isDialogClose) {
    const parent = this;
    if (flag) {
      const index = this.addIndex;
      switch (index) {
        case 1:
          const note = { "NoteCategory": parent.obj.value, "Inactive": false, "NoteColor": parent.obj.NoteColor };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveNoteCategories(note).subscribe(
            function (data) {
              parent.refreshNoteCategories();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;
        case 2:
          const contactType = { "ContactType": parent.obj.value, "Inactive": false };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveContactType(contactType).subscribe(
            function (data) {
              parent.refreshContactType();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;
        case 3:
          const customerType = { "CustomerTypeDescription": parent.obj.value, "Inactive": false };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveCustomerType(customerType).subscribe(
            function (data) {
              parent.refreshCustomerType();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          sessionStorage.removeItem('clientType');
          break;
        case 4:
          const staffTitles = { "StaffTitleDescription": parent.obj.value, "Inactive": false };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveStaffTitles(staffTitles).subscribe(
            function (data) {
              parent.refreshStaffTitles();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;
        case 5:
          const term = { "TermsDescription": parent.obj.value, "GraceDays": numeral(parent.obj.value1).value(), "Inactive": false };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveTerm(term).subscribe(
            function (data) {
              parent.refreshTerms();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;
        case 6:
          const staffDept = { "DeptDescription": parent.obj.value, "Inactive": false };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveStaffDept(staffDept).subscribe(
            function (data) {
              parent.refreshStaffDept();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;
        case 7:
          const group = { "GroupDescription": parent.obj.value, "Inactive": false };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveGroup(group).subscribe(
            function (data) {
              parent.addUserLog(
                'Create Billing Group',
                'Billing Group Description: ' + group.GroupDescription
              );

              parent.refreshGroups();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          //sessionStorage.removeItem("clientGroupCategory");
          break;

        case 8:
          const invoiceGroup = { "RecurringGroupDescription": parent.obj.value, "Inactive": false };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveInvoiceGroup(invoiceGroup).subscribe(
            function (data) {
              parent.refreshInvoiceGroups();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;
        case 9:
          const engagementType = { "Description": parent.obj.value, "Inactive": false, "InvoiceDescription": parent.obj.customlableValue };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.saveEngagements(engagementType).subscribe(
            function (data) {
              parent.refreshEngagements();
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.createMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.createErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;

        case 10:
          const worklocationObj = { "ZipCode": parent.obj.value, "Inactive": false, "CompanyID": parent.companyId };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.addWorkLocations(worklocationObj).subscribe(
            function (data) {
              parent.refreshWorkLocations();
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.updateMsg
              );
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.updateErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;

        case 11:
          const paymentMethodObj = { "PaymentDescription": parent.obj.value, "Required": parent.obj.Inactive, "CompanyID": parent.companyId };
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.addPaymentMethods(paymentMethodObj).subscribe(
            function (data) {
              parent.refreshPaymentMethods();
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify(
                'success',
                parent.translate.instant('Success'),
                AppConstants.updateMsg
              );
            },
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                parent.translate.instant('error'),
                AppConstants.updateErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            }
          );
          break;
      }
    } else {
      const id = parent.addIndex;
      const index = parent.editIndex;
      switch (id) {
        case 1:
          parent.tmpNoteList[index].NoteCategory = parent.obj.value;
          parent.tmpNoteList[index].Inactive = parent.obj.Inactive;
          parent.tmpNoteList[index].NoteColor = parent.obj.NoteColor;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateNoteCategories(
              parent.tmpNoteList[index].NoteCategoryID,
              parent.tmpNoteList[index]
            )
            .subscribe(
              function (data) {
                parent.refreshNoteCategories();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
        case 2:
          parent.tmpContactTypeList[index].ContactType = parent.obj.value;
          parent.tmpContactTypeList[index].Inactive = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateContactType(
              parent.tmpContactTypeList[index].CustomerContactTypeID,
              parent.tmpContactTypeList[index]
            )
            .subscribe(
              function (data) {
                parent.refreshContactType();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
        case 3:
          parent.tmpCustomerTypeList[index].CustomerTypeDescription = parent.obj.value;
          parent.tmpCustomerTypeList[index].Inactive = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateCustomerType(
              parent.tmpCustomerTypeList[index].CustomerTypeID,
              parent.tmpCustomerTypeList[index]
            )
            .subscribe(
              function (data) {
                parent.refreshCustomerType();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
        case 4:
          parent.tmpStaffList[index].StaffTitleDescription = parent.obj.value;
          parent.tmpStaffList[index].Inactive = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateStaffTitles(parent.tmpStaffList[index].StaffTitleID, parent.tmpStaffList[index])
            .subscribe(
              function (data) {
                parent.refreshStaffTitles();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
        case 5:
          parent.tmpTermList[index].TermsDescription = parent.obj.value;
          parent.tmpTermList[index].GraceDays = numeral(parent.obj.value1).value();
          parent.tmpTermList[index].Inactive = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateTerm(parent.tmpTermList[index].TermsID, parent.tmpTermList[index])
            .subscribe(
              function (data) {
                parent.refreshTerms();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
        case 6:
          parent.tmpStaffDeptList[index].DeptDescription = parent.obj.value;
          parent.tmpStaffDeptList[index].Inactive = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateStaffDept(
              parent.tmpStaffDeptList[index].StaffDeptID,
              parent.tmpStaffDeptList[index]
            )
            .subscribe(
              function (data) {
                parent.refreshStaffDept();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
        case 7:
          parent.tmpGroupList[index].GroupDescription = parent.obj.value;
          parent.tmpGroupList[index].Inactive = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.updateGroup(parent.tmpGroupList[index].CustomerGroupCategoryID, parent.tmpGroupList[index]).subscribe(function (data) {
            const obj = parent.tmpGroupList[index];
            parent.addUserLog("Updated Billing Group "+ parent.tmpGroupList[index].CustomerGroupCategoryID, "Billing Group Description: "+obj.GroupDescription+ " - Inactive: "+ obj.Inactive);
            parent.refreshGroups();
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.updateMsg);
          }, error => {
            parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          });
          break;
        case 8:
          parent.tmpInvoiceGroupList[index].RecurringGroupDescription = parent.obj.value;
          parent.tmpInvoiceGroupList[index].Inactive = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateInvoiceGroup(
              parent.tmpInvoiceGroupList[index].RecurringInvoiceGroupID,
              parent.tmpInvoiceGroupList[index]
            )
            .subscribe(
              function (data) {
                parent.refreshInvoiceGroups();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
        case 9:
          parent.tmpEngagementsTypeList[index].Description = parent.obj.value;
          parent.tmpEngagementsTypeList[index].Inactive = parent.obj.Inactive;
          parent.tmpEngagementsTypeList[index].InvoiceDescription = parent.obj.customlableValue;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateEngagements(
              parent.tmpEngagementsTypeList[index].EngagementTypeID,
              parent.tmpEngagementsTypeList[index]
            )
            .subscribe(
              function (data) {
                parent.refreshEngagements();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
        case 10:
          parent.tmpWorkLocationsList[index].ZipCode = parent.obj.value;
          parent.tmpWorkLocationsList[index].Inactive = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updateWorkLocations(
              this.tmpWorkLocationsList[index].WorkLocationID,
              this.tmpWorkLocationsList[index]
            )
            .subscribe(
              function (data) {
                parent.refreshWorkLocations();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;

        case 11:
          parent.tmpPaymentMethodsList[index].PaymentDescription = parent.obj.value;
          parent.tmpPaymentMethodsList[index].Required = parent.obj.Inactive;
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .updatePaymentMethods(
              parent.tmpPaymentMethodsList[index].CompanyPaymentMethodID,
              parent.tmpPaymentMethodsList[index]
            )
            .subscribe(
              function (data) {
                parent.refreshPaymentMethods();
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.updateMsg
                );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.updateErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          break;
      }
    }
    if (isDialogClose) {
      parent.displayAddModal = false;
    } else {
      parent.obj.isEdit = false;
      parent.obj.value1 = '';
      parent.obj.value = '';
    }
  }

  close(flag?: any) {
    this.displayAddModal = false;
  }

  openAddWindow(index) {
    this.addIndex = index;
    this.obj = {};
    this.obj.isEdit = false;
    this.obj.maxlength = 40;
    switch (index) {
      case 1:
        this.obj.title = 'Note Categories';
        this.obj.lable = 'Categories';
        this.obj.value = '';
        this.obj.NoteColor = '#5d5f61';
        this.obj.Inactive = false;
        break;
      case 2:
        this.obj.title = 'Contact Types';
        this.obj.lable = 'Type';
        this.obj.value = '';
        this.obj.Inactive = false;
        break;
      case 3:
        this.obj.title = 'Customer Types';
        this.obj.lable = 'Description';
        this.obj.value = '';
        this.obj.Inactive = false;
        break;
      case 4:
        this.obj.title = 'Staff Titles';
        this.obj.lable = 'Titles';
        this.obj.value = '';
        this.obj.Inactive = false;
        break;
      case 5:
        this.obj.title = 'Customer Terms';
        this.obj.lable = 'Terms Description';
        this.obj.value = '';
        this.obj.lable1 = 'Grace Days';
        this.obj.value1 = '0';
        this.obj.Inactive = false;
        break;
      case 6:
        this.obj.title = 'Staff Department';
        this.obj.lable = 'Department';
        this.obj.value = '';
        this.obj.Inactive = false;
        break;
      case 7:
        this.obj.title = 'Customer Groups';
        this.obj.lable = 'Group Description';
        this.obj.value = '';
        this.obj.Inactive = false;
        break;
      case 8:
        this.obj.title = 'Recurring Invoice Groups';
        this.obj.lable = 'Description';
        this.obj.value = '';
        this.obj.maxlength = 255;
        this.obj.Inactive = false;
        break;
      case 9:
        this.obj.title = 'Engagement Types';
        this.obj.lable = 'Description';
        this.obj.customlable = 'Invoice Description';
        this.obj.value = '';
        this.obj.customlableValue = '';
        this.obj.maxlength = 400;
        this.obj.Inactive = false;
        break;
      case 10:
        this.obj.title = 'Work Locations';
        this.obj.lable = 'Zip Code';
        this.obj.customlable = 'Invoice Description';
        this.obj.value = '';
        this.obj.customlableValue = '';
        this.obj.Inactive = false;
        break;
      case 11:
        this.obj.title = 'Payment Methods';
        this.obj.lable = 'Payment Description';
        this.obj.value = '';
        this.obj.customlableValue = '';
        this.obj.Inactive = false;
    }
    this.displayAddModal = true;
  }

  refreshNoteCategories() {
    const parent = this;
    parent.tmpNoteList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.encrDecSrvc.removeObject(AppConstants.noteCategories);
    parent.mangoAPISrvc.getNoteCategories().subscribe(
      function (data: any) {
        parent.noteList = data;
        parent.encrDecSrvc.addObject(AppConstants.noteCategories, data);
        for (let i = 0; i < data.length; i++) {
          parent.noteList[i].index = i;
          parent.noteList[i].NoteColor = parent.noteList[i].NoteColor || '#5d5f61';
        }
        parent.onStatusChange(1);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  refreshPaymentMethods(){
    const parent = this;
    parent.tmpPaymentMethodsList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.encrDecSrvc.removeObject(AppConstants.paymentMethods);
    parent.mangoAPISrvc.getPaymentMethods(this.companyId).subscribe(function (data: any) {
      parent.paymentMethodsList = data;
      parent.encrDecSrvc.addObject(AppConstants.paymentMethods, data);
      for (let i = 0; i < data.length; i++) {
        parent.paymentMethodsList[i].index = i;
      }
      parent.onStatusChange(11);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  refreshStaffTitles() {
    const parent = this;
    parent.tmpStaffList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getStaffTitles().subscribe(
      function (data: any) {
        parent.staffList = data;
        for (let i = 0; i < data.length; i++) {
          parent.staffList[i].index = i;
        }
        parent.onStatusChange(4);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  refreshStaffDept() {
    const parent = this;
    parent.tmpStaffDeptList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getStaffDept().subscribe(
      function (data: any) {
        parent.staffDeptList = data;
        for (let i = 0; i < data.length; i++) {
          parent.staffDeptList[i].index = i;
        }
        parent.onStatusChange(6);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  refreshContactType() {
    const parent = this;
    parent.tmpContactTypeList = [];
    parent.mangoAPISrvc.showLoader(true);
    // parent.encrDecSrvc.removeObject(AppConstants.customerType);
    parent.mangoAPISrvc.getContactType().subscribe(
      function (data: any) {
        parent.contactTypeList = data;
        // parent.encrDecSrvc.addObject(AppConstants.customerType, data);
        for (let i = 0; i < data.length; i++) {
          parent.contactTypeList[i].index = i;
        }
        parent.onStatusChange(2);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }
  refreshTerms() {
    const parent = this;
    parent.tmpTermList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getTerms().subscribe(
      function (data: any) {
        parent.termList = data;
        for (let i = 0; i < data.length; i++) {
          parent.termList[i].index = i;
          parent.termList[i].GraceDays = numeral(parent.termList[i].GraceDays).value();
        }
        parent.onStatusChange(5);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  refreshGroups() {
    const parent = this;
    parent.tmpGroupList = [];
    parent.encrDecSrvc.removeObject(AppConstants.clientGroupCategory);
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getGroups().subscribe(function (data:any) {
      parent.groupList = data;
      parent.encrDecSrvc.addObject(AppConstants.clientGroupCategory, data);
      for (let i = 0; i < data.length; i++) {
        parent.groupList[i].index = i;
      }
    });
  }

  refreshInvoiceGroups() {
    const parent = this;
    parent.tmpInvoiceGroupList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getInvoiceGroups().subscribe(
      function (data: any) {
        parent.invoiceGroupList = data;
        for (let i = 0; i < data.length; i++) {
          parent.invoiceGroupList[i].index = i;
        }
        parent.onStatusChange(8);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  refreshCustomerType() {
    const parent = this;
    parent.tmpCustomerTypeList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCustomerType().subscribe(
      function (data: any) {
        parent.customerTypeList = data;
        for (let i = 0; i < data.length; i++) {
          parent.customerTypeList[i].index = i;
        }
        parent.encrDecSrvc.addObject(AppConstants.customerType, data);
        parent.onStatusChange(3);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  refreshEngagements() {
    const parent = this;
    parent.tmpEngagementsTypeList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEngagements(parent.companyId).subscribe(
      function (data: any) {
        parent.engagementsTypeList = data;
        for (let i = 0; i < data.length; i++) {
          parent.engagementsTypeList[i].index = i;
        }
        parent.onStatusChange(9);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  refreshWorkLocations() {
    const parent = this;
    parent.tmpWorkLocationsList = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.encrDecSrvc.removeObject(AppConstants.workLocations);
    parent.mangoAPISrvc.getWorkLocations().subscribe(
      function (data: any) {
        parent.workLocationsList = data;
        parent.encrDecSrvc.addObject(AppConstants.workLocations, data);
        for (let i = 0; i < data.length; i++) {
          parent.workLocationsList[i].index = i;
        }
        parent.onStatusChange(10);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateWorkLocations(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateWorkLocations(obj.WorkLocationID, obj).subscribe(
      function (data) {
        parent.refreshWorkLocations();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateContactType(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateContactType(obj.CustomerContactTypeID, obj).subscribe(
      function (data) {
        parent.refreshContactType();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateEngagement(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateEngagements(obj.EngagementTypeID, obj).subscribe(
      function (data) {
        parent.refreshEngagements();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateNote(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateNoteCategories(obj.NoteCategoryID, obj).subscribe(
      function (data) {
        parent.refreshNoteCategories();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateStaff(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateStaffTitles(obj.StaffTitleID, obj).subscribe(
      function (data) {
        parent.refreshStaffTitles();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateStaffDept(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateStaffDept(obj.StaffDeptID, obj).subscribe(
      function (data) {
        parent.refreshStaffDept();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateTerm(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateTerm(obj.TermsID, obj).subscribe(
      function (data) {
        parent.refreshTerms();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }
  addUserLog(action, description) {
    const logdata = {}
    logdata['Action'] = action;
    logdata['Description'] = description;
    logdata['Table'] = '';

    this.mangoAPISrvc.addUserLogs(logdata).subscribe(
      res => {},
      err => {
        console.log(err);
      }
    );
  }

  updateGroup(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateGroup(obj.CustomerGroupCategoryID, obj).subscribe(
      function (data) {
        parent.addUserLog(
          'Updated Billing Group ' + obj.CustomerGroupCategoryID,
          'Billing Group Description: ' + obj.GroupDescription + ' - Inactive: ' + obj.Inactive
        );
        parent.refreshGroups();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateInvoiceGroup(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateInvoiceGroup(obj.RecurringInvoiceGroupID, obj).subscribe(
      function (data) {
        parent.refreshInvoiceGroups();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  updateCustomerType(data) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateCustomerType(data.CustomerTypeID, data).subscribe(
      function (data) {
        parent.refreshCustomerType();
        parent.mangoAPISrvc.notify(
          'success',
          parent.translate.instant('Success'),
          AppConstants.updateMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  delete(id, index) {
    const parent = this;
    switch (index) {
      case 1:
        parent.mangoAPISrvc.getCustomerNotesById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteNoteCategories(id).subscribe(
                  function (data) {
                    parent.refreshNoteCategories();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 4:
        parent.mangoAPISrvc.getCustomerTitlesById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteStaffTitles(id).subscribe(
                  function (data) {
                    parent.refreshStaffTitles();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 6:
        parent.mangoAPISrvc.getCustomerDeptById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteStaffDept(id).subscribe(
                  function (data) {
                    parent.refreshStaffDept();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 2:
        parent.mangoAPISrvc.getCustomerContactsById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteContactType(id).subscribe(
                  function (data) {
                    parent.refreshContactType();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 5:
        parent.mangoAPISrvc.getCustomerClientTermsById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteTerm(id).subscribe(
                  function (data) {
                    parent.refreshTerms();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 7:
        parent.mangoAPISrvc.getCustomerGroupsById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteGroup(id).subscribe(
                  function (data) {
                    parent.addUserLog(
                      'Delete Billing Group ' + id,
                      'Billing Group Description: ' +
                        parent.groupList.find(x => x.CustomerGroupCategoryID == id)
                          ?.GroupDescription
                    );
                    parent.refreshGroups();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
                //sessionStorage.removeItem("clientGroupCategory");
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 3:
        parent.mangoAPISrvc.getCustomerClientTypesById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteCustomerType(id).subscribe(
                  function (data) {
                    parent.refreshCustomerType();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
                //sessionStorage.removeItem("clientType");
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 8:
        parent.mangoAPISrvc.getCustomerRecurringInvoiceGroupById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteInvoiceGroup(id).subscribe(
                  function (data) {
                    parent.refreshInvoiceGroups();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 9:
        parent.mangoAPISrvc.getCustomerEngagementTypesById(id).subscribe(function (result) {
          if (result[0].count == 0) {
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('delete_alert'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.mangoAPISrvc.deleteEngagement(id).subscribe(
                  function (data) {
                    parent.refreshEngagements();
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      AppConstants.deleteMessage
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  },
                  error => {
                    parent.mangoAPISrvc.notify(
                      'error',
                      parent.translate.instant('error'),
                      AppConstants.deleteErrorMsg
                    );
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: parent.translate.instant('delete_not_allowed_message'),
              text: parent.translate.instant('record_is_in_use_message'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK'
            });
          }
        });
        break;
      case 10:
        Swal.fire({
          title: parent.translate.instant('confirmation'),
          text: parent.translate.instant('delete_alert'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: parent.translate.instant('yes_delete'),
          cancelButtonText: parent.translate.instant('no_cancel')
        }).then(result => {
          if (result.value) {
            parent.mangoAPISrvc.showLoader(true);
            parent.mangoAPISrvc.deleteWorkLocations(id).subscribe(
              function (data) {
                parent.refreshWorkLocations();
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.deleteMessage
                );
                parent.mangoAPISrvc.showLoader(false);
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.deleteErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          }
        });
        break;
      case 11:
        Swal.fire({
          title: parent.translate.instant('confirmation'),
          text: parent.translate.instant('delete_alert'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: parent.translate.instant('yes_delete'),
          cancelButtonText: parent.translate.instant('no_cancel')
        }).then(result => {
          if (result.value) {
            parent.mangoAPISrvc.showLoader(true);
            parent.mangoAPISrvc.deletePaymentMethods(id).subscribe(
              function (data) {
                parent.refreshPaymentMethods();
                parent.mangoAPISrvc.notify(
                  'success',
                  parent.translate.instant('Success'),
                  AppConstants.deleteMessage
                );
                parent.mangoAPISrvc.showLoader(false);
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.deleteErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          }
        });
        break;
    }
  }

  isRequiredPayment(dataRow) {
    if (this.tmpPaymentMethodsRequired.includes(dataRow['PaymentDescription'])) {
      return false;
    }
    return true;
  }
}
