import { Component, OnInit, ViewChild } from '@angular/core';
import { MangoApiService, EncrDecrService, BreadcrumbService, mangoUtils } from '@app/_services';
import { ConfirmationService } from 'primeng/api';
import { AppConstants } from '@app/_helpers/api-constants';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html'
})
export class ListComponent implements OnInit {
  isUserList: boolean = true;
  isApiLoaded: boolean = false;
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  public selectedUser: any = null;
  public userTabs: any[];
  usersCount: any = { "all": 0, "active": 0, "inactive": 0 };
  companyId;
  filteredItemsSize = -1;
  filteredUsers: any = [];
  resourceID: any;
  totalUsersDataSource: any = [];
  searchTextStr = localStorage.getItem('usersearchTextStr');
  planInfo: any;
  activeTab: any;
  userPermissionType: string;
  superAdmin: boolean = false;
  showAdminButtons: boolean = false;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService, 
    private confirmationSrvc: ConfirmationService, 
    private breadcrumbService: BreadcrumbService,
    public translate: TranslateService,
    public mangoUtils: mangoUtils
  ) {
    this.encrDecSrvc.addObject(AppConstants.usersID, "");
    this.encrDecSrvc.addObject(AppConstants.selectedUserRecord, "");
    this.encrDecSrvc.addObject(AppConstants.staffRoleID, "");
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.resourceID = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.mangoAPISrvc.fireUserView(true);
  }

  getPlanInfo() {
    let parent = this;
    this.mangoAPISrvc.getPlanInformation().subscribe((planInfo: any) => {
      parent.encrDecSrvc.removeObject(AppConstants.planInfo);
      parent.encrDecSrvc.addObject(AppConstants.planInfo, planInfo);
      parent.planInfo = planInfo;
      parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  ngOnInit(): void {
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslationsBreadcrumbs();
    }, 300);
    this.getPlanInfo();
    this.getAllUsersDataSource();
    this.userPermissionType = this.encrDecSrvc.getObject(AppConstants.userPermissions)?.Title;
    
    this.showAdminButtons = this.userPermissionType === 'Administrator' && AppConstants.superAdminVisible.includes(this.companyId);

    AppConstants.superAdminVisible.forEach((e) => {
      if (e === this.companyId) {
        this.superAdmin = true;
      }
    });
  }

  initTranslationsBreadcrumbs() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('user.user_list') }
    ]);
  }

  initTranslationTabs() {
    this.updateUserTabs();
  }

  updateUserTabs() {
    let parent = this;
    this.usersCount.all = this.totalUsersDataSource.length;
    this.usersCount.inactive = this.totalUsersDataSource.filter((user) => user.Inactive == true).length;
    this.usersCount.active = this.usersCount.all - this.usersCount.inactive;
    this.userTabs = [
      {
        label: `${this.translate.instant('active_users')} (${this.usersCount.active})`, icon: 'ic-size-14 ic-orange fb fas fa-star', command: (event) => {
          parent.filterUsers(0);
        }
      },
      {
        label: `${this.translate.instant('inactive_users')} (${this.usersCount.inactive})`, icon: 'ic-size-14 ic-green fb fal fa-users',
        command: (event) => {
          parent.filterUsers(1);
        }
      },
      {
        label: `${this.translate.instant('all_users')} (${this.usersCount.all})`, icon: 'ic-size-14 ic-red fb fal fa-user-times', command: (event) => {
          parent.filterUsers(2);
        }
      }
    ];
  }

  getAllUsersDataSource() {
    let parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getListOfUsers().subscribe((users: any) => {
      parent.totalUsersDataSource = users;
      const interval2 = setInterval(() => {
        if (!this.translate.translations[this.translate.currentLang]) return;
        clearInterval(interval2);
        this.initTranslationTabs();
        parent.filterUsers(0);
      }, 300);
      parent.mangoAPISrvc.showLoader(false);
      parent.isApiLoaded = true;
    });
  }

  private filterUsers(status) {
    this.activeTab = this.userTabs[status];
    this.filteredUsers = [];
    switch (status) {
      case 0: {
        this.filteredUsers = this.totalUsersDataSource.filter((item) => item.Inactive != true);
        this.searchValue.nativeElement.value = this.searchTextStr = "";
        localStorage.removeItem('usersearchTextStr');
        break;
      }
      case 1: {
        this.filteredUsers = this.totalUsersDataSource.filter((item) => item.Inactive == true);
        this.searchValue.nativeElement.value = this.searchTextStr = "";
        localStorage.removeItem('usersearchTextStr');
        break;
      }
      default: {
        this.filteredUsers = [...this.totalUsersDataSource];
        break;
      }
    }
    setTimeout(() => {
      this.isUserList = true;
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        var event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 100);
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    localStorage.removeItem('usersearchTextStr');
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    localStorage.setItem('usersearchTextStr', obj.filters.global.value);
  }

  redirectToAddUser() {
    if (Number(this.planInfo.NumberofUsers) <= Number(this.planInfo.NoofActiveUsers)) {
      Swal.fire({
        title: this.translate.instant('error'),
        text: this.translate.instant('user_added_exceed_license_message'),
        icon: 'error',
        showCancelButton: true,
        showConfirmButton: this.showAdminButtons, // Only show confirm button if user is admin and superAdmin is true
        confirmButtonText: this.showAdminButtons ? this.translate.instant('add_new_user_license_now') : undefined,
        cancelButtonText: this.translate.instant('cancel')
      }).then((result) => {
        if (result.isConfirmed && this.showAdminButtons) {
          this.promptForLicenses().then((licensesToAdd) => {
            if (licensesToAdd > 0) {
              this.purchaseUserLicenses(licensesToAdd).then(() => {
                this.isUserList = false;
                this.encrDecSrvc.addObject(AppConstants.usersID, -1);
                this.encrDecSrvc.addObject(AppConstants.staffRoleID, "");
                this.encrDecSrvc.addObject(AppConstants.selectedUserName, "");
                this.mangoAPISrvc.fireUserView(true);
              });
            }
          });
        } else {
          this.getAllUsersDataSource();
        }
      });
    } else {
      this.isUserList = false;
      this.encrDecSrvc.addObject(AppConstants.usersID, -1);
      this.encrDecSrvc.addObject(AppConstants.staffRoleID, "");
      this.encrDecSrvc.addObject(AppConstants.selectedUserName, "");
      this.mangoAPISrvc.fireUserView(true);
    }
  }

  addLicense() {
    if (!this.showAdminButtons) return;

    this.promptForLicenses().then((licensesToAdd) => {
      if (licensesToAdd > 0) {
        this.purchaseUserLicenses(licensesToAdd).then(() => {
          this.getAllUsersDataSource();
        });
      }
    });
  }

  promptForLicenses(): Promise<number> {
    return new Promise<number>((resolve) => {
      Swal.fire({
        title: this.translate.instant('add_new_user_license_now'),
        text: this.translate.instant('enter_licenses_to_add'),
        input: 'number',
        inputAttributes: {
          min: '1',
          step: '1'
        },
        showCancelButton: true,
        confirmButtonText: this.translate.instant('purchase_now'),
        cancelButtonText: this.translate.instant('cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          const licensesToAdd = Number(result.value);
          resolve(licensesToAdd);
        } else {
          resolve(0);
        }
      });
    });
  }

  redirectToUserView(userId: number, rowData: any) {
    this.isUserList = false;
    this.encrDecSrvc.addObject(AppConstants.usersID, userId);
    this.encrDecSrvc.addObject(AppConstants.staffRoleID, rowData['StaffRoleID']);
    this.encrDecSrvc.addObject(AppConstants.selectedUserName, rowData['StaffName']);
    this.mangoAPISrvc.fireUserView(true);
  }

  deleteUser(event: any, id, obj) {
    event.stopPropagation();
    if (id == this.resourceID) {
      this.mangoAPISrvc.notify('warning', this.translate.instant('warning'), 'You cannot remove yourself.');
      return false;
    }
    if (obj['Inactive']) {
      Swal.fire({
        title: this.translate.instant('Information'),
        text: this.translate.instant('users.already-inactive'),
        icon: 'info',
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: this.translate.instant('Ok')
      });
      return;
    }
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('make_user_inactive_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_continue'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then((result) => {
      if (result.value) {
        const userObj = {
          Inactive: true,
          StaffName: obj['StaffName']
        };
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.updateUserByAdmin(obj['StaffID'], userObj).subscribe((data) => {
          const selectedStaff = this.totalUsersDataSource.find((staff) => staff.StaffID == obj['StaffID']);
          if (selectedStaff) selectedStaff['Inactive'] = true;
          this.filteredUsers = this.filteredUsers.filter((staff) => staff.StaffID !== obj['StaffID']);
          this.getPlanInfo();
          this.updateUserTabs();
          this.getAllUsersDataSource();
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.updateMsg);
        }, err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
        });
      } else {
        this.getAllUsersDataSource();
      }
    });
  }

  sendEmailToUser(user) {
    window.location.href = "mailto:" + user.Email;
  }

  toggleUserStatus(user) {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: user.Inactive ? this.translate.instant('make_user_active_message') : this.translate.instant('make_user_inactive_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_continue'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        if (user.Inactive && Number(this.planInfo.NumberofUsers) <= Number(this.usersCount.active)) {
          Swal.fire({
            title: this.translate.instant('error'),
            text: this.translate.instant('cannot_activate_user_exceeds_license'),
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('add_new_user_license_now'),
            cancelButtonText: this.translate.instant('cancel')
          }).then((result) => {
            if (result.isConfirmed) {
              this.promptForLicenses().then((licensesToAdd) => {
                if (licensesToAdd > 0) {
                  this.purchaseUserLicenses(licensesToAdd).then(() => {
                    user.Inactive = false;
                    this.mangoAPISrvc.updateUserByAdmin(user.StaffID, user).subscribe(
                      (data) => {
                        this.getPlanInfo();
                        this.updateUserTabs();
                        this.getAllUsersDataSource();
                        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.updateMsg);
                      },
                      (error) => {
                        console.error('Error updating user:', error);
                        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
                      }
                    );
                  });
                }
              });
            } else {
              this.getAllUsersDataSource();
            }
          });
          return;
        }
  
        user.Inactive = !user.Inactive;
        this.mangoAPISrvc.updateUserByAdmin(user.StaffID, user).subscribe(
          (data) => {
            this.getPlanInfo();
            this.updateUserTabs();
            this.getAllUsersDataSource();
            this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.updateMsg);
          },
          (error) => {
            console.error('Error updating user:', error);
            this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
          }
        );
      } else {
        this.getAllUsersDataSource();
      }
    });
  }

  removeUserLicense() {
    if (this.usersCount.active === this.planInfo.NumberofUsers) {
      Swal.fire({
        title: this.translate.instant('error'),
        text: this.translate.instant('cannot_remove_license_no_inactive_users'),
        icon: 'error',
        confirmButtonText: this.translate.instant('ok')
      });
    } else {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        text: this.translate.instant('enter_licenses_to_remove'),
        input: 'number',
        inputAttributes: {
          min: '1',
          step: '1'
        },
        showCancelButton: true,
        confirmButtonText: this.translate.instant('process_now'),
        cancelButtonText: this.translate.instant('cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          const licensesToRemove = Number(result.value);
          if (licensesToRemove > (this.planInfo.NumberofUsers - this.usersCount.active)) {
            Swal.fire({
              title: this.translate.instant('error'),
              text: `The maximum allowed User Licenses that can be removed is ${this.planInfo.NumberofUsers - this.usersCount.active}. Please change the amount of licenses to remove`,
              icon: 'error',
              confirmButtonText: this.translate.instant('ok')
            });
          } else {
            this.processLicenseRemoval(licensesToRemove);
          }
        } else {
          this.getAllUsersDataSource();
        }
      });
    }
  }

  processLicenseRemoval(licensesToRemove: number) {
    this.mangoAPISrvc.deleteUserFromPlanSubscription(licensesToRemove).subscribe(async () => {
      this.planInfo.NumberofUsers -= licensesToRemove;
      const data = {
        NewUserCount: Number(this.planInfo.NumberofUsers),
        TenantId: `${this.companyId}`,
        ProductCode: this.planInfo.SubscriptionLevel === 'SOLO' ? 'Mango Basic' 
          : this.planInfo.SubscriptionLevel === 'PRO' ? 'Mango Plus'
          : this.planInfo.SubscriptionLevel === 'ENTERPRISE' && 'Mango Pro'
      }

      await this.mangoAPISrvc.updateLicenseInfo(data);
      this.updateUserTabs();
      this.getAllUsersDataSource();
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('licenses_removed_successfully'));
    }, (error) => {
      console.error('Error deleting user from plan subscription:', error);
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
    });
  }

  async purchaseUserLicenses(licensesToAdd: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.mangoAPISrvc.addUserToSubscribedPlan({ NumberofUsers: licensesToAdd }).subscribe(async () => {
        this.planInfo.NumberofUsers += licensesToAdd;
        const data = {
          NewUserCount: Number(this.planInfo.NumberofUsers),
          TenantId: `${this.companyId}`,
          ProductCode: this.planInfo.SubscriptionLevel === 'SOLO' ? 'Mango Basic' 
            : this.planInfo.SubscriptionLevel === 'PRO' ? 'Mango Plus'
            : this.planInfo.SubscriptionLevel === 'ENTERPRISE' && 'Mango Pro'
        }
  
        await this.mangoAPISrvc.updateLicenseInfo(data);
        this.updateUserTabs();
        this.getAllUsersDataSource();
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('licenses_purchased_successfully'));
        resolve();
      }, (error) => {
        console.error('Error adding user to subscribed plan:', error);
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
        reject();
      });
    });
  }
}
