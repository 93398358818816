import { Component, NgZone, Compiler, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from './app.menu.service';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, EncrDecrService, MangoApiService, WebsocketService, mangoUtils } from '@app/_services';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AppMainComponent } from './app.main.component';
import { TimerListComponent } from './timer-list.component';
import { NewUpdatesReturn } from './shared/components/new-updates-available/new-updates-available.component';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';

@Component({
	selector: 'app-topbar',
  templateUrl: "./app.topbar.component.html",
	providers: [WebsocketService],
})
export class AppTopBarComponent implements AfterViewInit {

	@ViewChild(TimerListComponent) timerListComponent: TimerListComponent;
	activeItem: number;
	loggedInUser: string;
	imageUrl: string;
	UserLevel: any = 0;
	subscriptionLevel: string;
	imageViewChangeSubscription: Subscription;
	public userDept: any = "";
	timerFlag: boolean = false;
	isMenuHorizontal = false;
	staffIDTemp: any = 0;
	isNewUpdatesAvailable: boolean = false;
	logoLink: any;
	settingsLink: any;

	companyId: number;
	superAdminVisible: number[] = AppConstants.superAdminVisible;
	updateHistoryVisible: number[] = AppConstants.updateHistoryVisible;
	isSuperAdmin = false;
	showLiveChat: boolean = false;
	showTrialCountdown: boolean = false;
	trialDaysLeft: number = 0;

	constructor(
		private zone: NgZone, 
		private menuService: MenuService, 
		public websocketService: WebsocketService, 
		private _compiler: Compiler, 
		public app: AppMainComponent, 
		private _router: Router, 
		private mangoAPISrvc: MangoApiService, 
		private encrDecSrvc: EncrDecrService, 
		public sharedSrvc: SharedComponentsService, 
		private authGuard: AuthGuard, 
		public mangoUtils: mangoUtils,
		public translate: TranslateService
		) {
		this.loggedInUser = this.encrDecSrvc.getObject(AppConstants.userName);
		this.imageUrl = this.encrDecSrvc.getObject(AppConstants.imageUrl);
		const permissionObj = this.encrDecSrvc.getObject(AppConstants.userPermissionObj);
		this.UserLevel = permissionObj.Permission ? parseInt(permissionObj.Permission) : 0;
		this.encrDecSrvc.loadDefaultAPI();
		const staffList = this.encrDecSrvc.getObject(AppConstants.staffList);
		this.staffIDTemp = this.encrDecSrvc.getObject(AppConstants.staffID);
		this.sharedSrvc.sharedCompVisibility.inactivity = true;
		this.sharedSrvc.openInActivityDialog();
		this.sharedSrvc.sharedCompVisibility.upcomingEvents = true;
		this.sharedSrvc.openUpcomingEventsDialogSub();
		this.isSuperAdmin = this.encrDecSrvc.getObject(AppConstants.userPermissions)?.isSuperAdmin;
		for (let i = 0; i < staffList.length; ++i) {
			if (staffList[i]['StaffID'] == this.staffIDTemp) {
				this.userDept = staffList[i]['DeptDescription']
			}
		}

		this.imageViewChangeSubscription = this.mangoAPISrvc.imageViewChange$.subscribe((data) => {
			this.imageUrl = this.encrDecSrvc.getObject(AppConstants.imageUrl);
			this.loggedInUser = this.encrDecSrvc.getObject(AppConstants.userName);
		});

		this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);

		this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);

    websocketService.subject &&
		websocketService.subject.subscribe(msg => {
			if (msg.source === "admin" && msg.content?.toLowerCase().includes("new deployment version")) {
				this.isNewUpdatesAvailable = true;
			}
		});

		this.logoLink = this.subscriptionLevel.toUpperCase() === 'PM' ? 'client/list' : 'dashboard';
		this.settingsLink = this.subscriptionLevel.toUpperCase() === 'PM' ? '/settings/customLists' : '/settings/timeAndExpenses';
	}
	ngAfterViewInit(): void {

		this.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe((data:any) =>{
			//console.log(data);
			if (data[0].SubscriptionLevel === "FREE"){
				this.showTrialCountdown = true;
				this.trialDaysLeft = this.mangoUtils.daysUntilFreeTrial(data[0].DateSubscribed);
				//LOGOUT if trial is expired		
				if (this.trialDaysLeft == 0){
					Swal.fire({
						icon: 'info',
						title: this.translate.instant('trial-expired'),
						html: this.translate.instant('expired-popup-msg'),
						showConfirmButton: true,
						showCancelButton: true,
						confirmButtonText: 'Get Started Now',
						cancelButtonText: 'Cancel',
						allowEscapeKey: false,
						allowEnterKey: false,		
					}).then((result)=>{
						if(result.isConfirmed) {
							this.showSignupDialog(true)
						} else this.logout();
					})
				}
			}
		});
		
		/**code to set the days remaining warning to 15 seconds */
		/*
		const companyData = this.encrDecSrvc.getObject(AppConstants.companySettings);
		const daysRemainingIsAlreadyShown = this.encrDecSrvc.getObject("daysRemainingIsAlreadyShown");
		
		if (companyData.DateSubscribed && !companyData.DatePlanActivated && !daysRemainingIsAlreadyShown) {
			this.showTrialCountdown = true;
			setTimeout(() => {
				this.encrDecSrvc.addObject("daysRemainingIsAlreadyShown", true);
				this.showTrialCountdown = false;
			}, 15000);
		}*/
	}


	appendTasksList(tasksList) {
		if (tasksList.length > 0) {
      const userJustLoggedIn = this.encrDecSrvc.getObject(
        AppConstants.userJustLoggedIn
      );
      if (userJustLoggedIn) {
        Swal.fire({
          title: "Confirmation",
          text: `Mango Billing restored ${
            tasksList.length ?? 0
          } timer(s).  You will need to restart the timer(s).`,
          icon: "info",
          showConfirmButton: true,
          confirmButtonText: "Okay!",
        });
      }
      this.timerFlag = true;
    } else {
      this.timerFlag = false;
    }
    this.encrDecSrvc.addObject(AppConstants.userJustLoggedIn, false);
	}
	mobileMegaMenuItemClick(index) {
		this.app.megaMenuMobileClick = true;
		this.activeItem = this.activeItem === index ? null : index;
	}

	openAndCloseChat() {
		const liveAgentWidget = document
			.querySelector('.embeddedServiceHelpButton') as HTMLElement;
		if (liveAgentWidget.style.display === 'block') {
			liveAgentWidget.style.display = 'none';
		} else {
			liveAgentWidget.style.display = 'block';
		}
	}

	logout() {
		if (this.timerFlag) {
			Swal.fire({
				title: 'Confirmation',
				text: 'There is an active timer(s), do you still want to log out?',
				icon: 'warning',
				showCancelButton: true,
				showDenyButton: true,
				confirmButtonText: 'Yes and delete active timer(s)!',
				denyButtonText: 'Yes!',
				cancelButtonText: 'No!'
			}).then((result) => {
				if (result.isConfirmed) {
					this.encrDecSrvc.addObject(AppConstants.isLoggingUser, "false");
					this.addLogs();
					this.mangoAPISrvc.deleteAllTimers(this.staffIDTemp).subscribe((res) => {
						this._router.navigate(['/login']).then((result) => {
							this.authGuard.logout();
							this._compiler.clearCache();
							setTimeout(() => {
								this.mangoAPISrvc.notifyLogging(false);
							}, 100);
						})
					}, err => {
						this._router.navigate(['/login']).then((result) => {
							this.authGuard.logout();
							this._compiler.clearCache();
							setTimeout(() => {
								this.mangoAPISrvc.notifyLogging(false);
							}, 100);
						})
						console.log(err)
					})
				} else if(result.isDenied) {
					this.encrDecSrvc.addObject(AppConstants.isLoggingUser, "false");
					this.addLogs();
					this.timerListComponent.saveTasksToSession();
					this._router.navigate(['/login']).then((result) => {
						this.authGuard.logout();
						this._compiler.clearCache();
						setTimeout(() => {
							this.mangoAPISrvc.notifyLogging(false);
						}, 100);
					})
				}
			})
		} else {
			this.mangoAPISrvc.showLoader(false);
			this.encrDecSrvc.addObject(AppConstants.isLoggingUser, "false");
			this.addLogs();
			this._router.navigate(['/login']).then((result) => {
				this.authGuard.logout();
				this._compiler.clearCache();
				setTimeout(() => {
					this.mangoAPISrvc.notifyLogging(false);
				}, 100);
			})
		}
	}

	addLogs(){
		const data = {}
		data['Action'] = "User Logout";
		data['Description'] = "User Logout from Mango";
		data['Table'] = "";
		this.mangoAPISrvc.addUserLogs(data).subscribe(()=>{},(err)=>{
			console.log(err);
		});
	}

	redirectClient(type) {
		if (type == 'list') {
			this.encrDecSrvc.addObject(AppConstants.clientID, "");
			this._router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.listRoutePath]);
		} else {
			this.encrDecSrvc.addObject(AppConstants.clientID, -1);
			this.encrDecSrvc.addObject(AppConstants.ClientName, "");
			this._router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath]);
		}

		setTimeout(() => {
			this.mangoAPISrvc.fireClientView(true);
		}, 100);
	}

	redirectUser() {
		this.encrDecSrvc.addObject(AppConstants.usersID, "");
		this._router.navigate([AppConstants.users + '/' + AppConstants.listRoutePath]);
		setTimeout(() => {
			this.mangoAPISrvc.fireUserView(true);
		}, 100);
	}

	openNotesModal() {
		this.app.onLayoutClick()
		const obj = {};
		obj['isFromMenu'] = true;
		obj['isEditNote'] = false;
		obj['isProjectEnable'] = true;
		this.sharedSrvc.openNotesDialog(obj);
	}

	openTimeSheetModal() {
		this.app.onLayoutClick()
		const data = {}
		data['isEditFlow'] = false;
		data['ClientID'] = "Header";
		data['selectedDate'] = new Date();
		this.sharedSrvc.openTimeEntryDialog(data);
	}

	openExpenseModal() {
		this.app.onLayoutClick()
		const data = {}
		data['isEditFlow'] = false;
		data['ClientID'] = "Header";
		data['selectedDate'] = new Date();
		this.sharedSrvc.openExpenseDialog(data);
	}

	openShortCutsModal() {
		this.app.onLayoutClick()
		const obj = {};
		obj['isFromMenu'] = true;
		obj['isEditNote'] = false;
		this.sharedSrvc.openShortCutsDialog(obj);
	}

	openToDoModal() {
		this.app.onLayoutClick()
		const todoObj = {};
		todoObj['isEditFlow'] = false;
		todoObj['isDisableOptions'] = true;
		this.sharedSrvc.openToDoDialog(todoObj);
	}

	openTimerModal() {
		this.app.onLayoutClick();
		this.sharedSrvc.openTimerDialog({ display: true });
	}

	openLanguageKeysModal() {
		this.app.onLayoutClick();
		this.sharedSrvc.openLanguageKeys();
	}

	redirectMyProfile(url) {
		this.app.onLayoutClick()
		this._router.navigate([AppConstants.superAdmin + '/' + url]);
		setTimeout(() => {
			this.mangoAPISrvc.fireProfileView(true);
		}, 100);
	}

	refreshCache() {
		const self = this;
		self.encrDecSrvc.clientList = [];
		self.encrDecSrvc.activitiesList = [];
		self.encrDecSrvc.companyData = [];
		self.encrDecSrvc.termsList = [];
		const staffId = this.encrDecSrvc.getObject(AppConstants.staffID);
		self.mangoAPISrvc.showLoader(true);
		self.encrDecSrvc.removeObject(AppConstants.clientCount);
		self.encrDecSrvc.removeObject(AppConstants.clientGroupCategory);
		self.encrDecSrvc.removeObject(AppConstants.staffList);
		self.encrDecSrvc.removeObject(AppConstants.allStaffList);
		self.encrDecSrvc.removeObject(AppConstants.customerType);
		self.encrDecSrvc.removeObject(AppConstants.shotHands);
		self.encrDecSrvc.removeObject(AppConstants.timeAndExpenses);
		self.encrDecSrvc.removeObject(AppConstants.activityList);
		self.encrDecSrvc.removeObject(AppConstants.expenses);
		self.encrDecSrvc.removeObject(AppConstants.invoiceOptions);
		self.encrDecSrvc.removeObject(AppConstants.noteCategories);
		self.encrDecSrvc.removeObject(AppConstants.planInfo);
		self.encrDecSrvc.removeObject(AppConstants.userPermissionObj);
		self.encrDecSrvc.removeObject(AppConstants.dmsEmailTemplate);
		self.encrDecSrvc.removeObject(AppConstants.workLocations);
		self.encrDecSrvc.removeObject(AppConstants.userPermissions);
		self.encrDecSrvc.loadDefaultAPI();
		self.mangoAPISrvc.loadDefaultAPI(staffId).subscribe((results: any) => {
			self.encrDecSrvc.addObject(AppConstants.clientCount, results[0]);
			self.encrDecSrvc.addObject(AppConstants.clientGroupCategory, results[1]);
			self.encrDecSrvc.addObject(AppConstants.staffList, results[2]);
			self.encrDecSrvc.addObject(AppConstants.allStaffList, results[14]);
			//self.encrDecSrvc.addObject(AppConstants.dmsStaffList, results[3]);
			self.encrDecSrvc.addObject(AppConstants.customerType, results[3]);
			self.encrDecSrvc.addObject(AppConstants.shotHands, results[4]);
			self.encrDecSrvc.addObject(AppConstants.timeAndExpenses, results[5]);
			self.encrDecSrvc.addObject(AppConstants.activityList, results[6]);
			self.encrDecSrvc.addObject(AppConstants.expenses, results[7]);
			self.encrDecSrvc.addObject(AppConstants.invoiceOptions, results[8]);
			self.encrDecSrvc.addObject(AppConstants.noteCategories, results[9]);
			self.encrDecSrvc.addObject(AppConstants.planInfo, results[10]);
			self.encrDecSrvc.addObject(AppConstants.userPermissionObj, results[11]);
			self.encrDecSrvc.addObject(AppConstants.dmsEmailTemplate, results[12]);
			self.encrDecSrvc.addObject(AppConstants.workLocations, results[13]);
			self.encrDecSrvc.addObject(AppConstants.userPermissions, results[15]);

			setTimeout(() => {
				self.mangoAPISrvc.showLoader(false);
			}, 100);

		});
	}

	onClickManageSubscription() {
		Swal.fire({
			icon: 'warning',
			title: 'Manage Subscription',
			text: 'Under Maintenance',
			showConfirmButton: true,
			showCancelButton: false,
		})
	}

	onToggleMenu() {
		this.menuService.onMenuHorizontal();
		// this.isMenuHorizontal = !this.isMenuHorizontal
		// this.menuHorizontal.emit(this.isMenuHorizontal)
		// console.log("Event emitted!", this.isMenuHorizontal)
	}

	testRouter() {
		this._router.navigate(['dms/dmsUIMain']);
	}


	ccardrouter() {
		this._router.navigate(['accounting/cards-view']);
	}

	onNewUpdatesAvailable() {
		if(!this.websocketService.isOpen) {
			Swal.fire({
				icon: 'warning',
				title: 'Warning!',
				text: 'Not connected to websocket server. Please try again after a minute.',
				showConfirmButton: true,
				showCancelButton: false
			})
			return;
		}
		Swal.fire({
			title: 'Confirmation',
			text: 'Are you sure you want to broadcast that there are updates available?',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Yes!',
			cancelButtonText: 'No!'
		}).then((result) => {
			if (result.isConfirmed) {
				const message = {
					source: 'admin',
					content: 'new deployment version',
					companyID: this.encrDecSrvc.getObject(AppConstants.companyID)
				};

				this.websocketService.subject.next(message);
			}
		})
	}

	onSubmitNewUpdates(event: NewUpdatesReturn): void {
    this.websocketService.isNewUpdatesAvailable = false;
		if(event.isLogout) {
			this.logout();
		}
  }

	get isShowSettingsIcon() {
		return (
      this.authGuard.isAllowAccess(8) ||
      this.authGuard.isAllowAccess(7) ||
      this.authGuard.isAllowAccess(9) ||
      this.authGuard.isAllowAccess(31)
    );
	}

	showSignupDialog(isExpired = false) {
		this.mangoAPISrvc.showLoader(true)
		this.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe((response) => {
			const mangoCompanyData = response[0];
			const companysendInBlueObj = {
				sender: { name: "Mango Billing", email: "noreply@mangobilling.com" },
				to: [{ email: "", name: "" }],
				replyTo: { email: "noreply@mangobilling.com" },
				templateId: 73,
				params: {},
			  };
			  const toArr = [{ email: environment.SALES_TEAM_EMAIL, name: 'Sales Team' }];
			  
			  companysendInBlueObj.to = toArr;
		  
			  companysendInBlueObj.params["COMPANYNAME"] = mangoCompanyData.CompanyName;
			  companysendInBlueObj.params["CONTACTNAME"] = mangoCompanyData.CompanyContact;
			  companysendInBlueObj.params["PHONENUMBER"] = mangoCompanyData.Telephone;
			  companysendInBlueObj.params["EMAIL"] = mangoCompanyData.EmailCompany;
			  this.mangoAPISrvc.sendSMTPEmail(companysendInBlueObj).subscribe(
				(data) => {
					this.mangoAPISrvc.showLoader(false);
					Swal.fire({
						title: 'Information',
						text: this.translate.instant('trial.signup_dialog'),
						icon: 'info',
						confirmButtonText: 'OK',
					}).then(() => {
						if(isExpired) this.logout();
					})
				},
				(err) => {
					this.mangoAPISrvc.notify(
						"error",
						'Error',
						this.translate.instant("trial.signup_sales_email_failed")
					);
				  this.mangoAPISrvc.showLoader(false);
				  if(isExpired) this.logout();
				})
		}, err => {
			this.mangoAPISrvc.notify(
				"error",
				'Error',
				this.translate.instant("trial.signup_sales_email_failed")
				);
			  this.mangoAPISrvc.showLoader(false);
			  if(isExpired) this.logout();
		})
	}
}
