<div class="user-list" *ngIf="isUserList">
  <div class="p-d-flex p-jc-between p-ai-center">
    <p-tabMenu [model]="userTabs" [activeItem]="activeTab"></p-tabMenu>
    <div class="p-d-flex p-ai-center">
      <p-button
        pRipple
        label="{{ 'new_user' | translate }}"
        icon="fal fa-plus"
        styleClass="p-button-sm"
        class="p-button-help p-pr-4"
        (click)="redirectToAddUser()"
      ></p-button>
      <div *ngIf="userPermissionType === 'Administrator' && superAdmin" >
        <p-button
          pRipple
          label="Add User License"
          icon="pi pi-plus"
          styleClass="p-button-sm"
          class="p-button-success p-pr-4"
          (click)="addLicense()"
        ></p-button>
        <p-button
          pRipple
          label="Remove User License"
          icon="pi pi-trash"
          styleClass="p-button-sm"
          class="p-button-danger p-pr-4"
          (click)="removeUserLicense()"
        ></p-button>
      </div>
    </div>
  </div>
</div>
<div class="p-px-3">
  <div class="card p-pt-1">
    <p-table
      #dt
      [value]="filteredUsers"
      [rowHover]="true"
      [responsive]="true"
      [rows]="10"
      dataKey="StaffID"
      selectionMode="single"
      [filterDelay]="0"
      [rows]="filteredUsers.length"
      [scrollable]="true"
      [scrollHeight]="'calc(100vh - 350px)'"
      styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
      sortField="StaffName"
      [globalFilterFields]="['StaffName','StaffNumber','TeleOffice','TeleMobile','TeleHome']"
      [sortOrder]="1"
      [resizableColumns]="true"
      [(selection)]="selectedUser"
      (onFilter)="onFilter($event)"
    >
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="p-d-flex">
            <span class="p-input-icon-left">
              <i class="fal fa-search"></i>
              <input
                #searchValue
                pInputText
                type="text"
                (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="{{ 'globalsearch' | translate }}"
              />
            </span>
            <span>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="dt.reset();clearSearchFilter()"
                tooltipPosition="top"
                pTooltip="{{ 'clear' | translate }}"
              ></button>
            </span>
            <p-message
              *ngIf="filteredItemsSize > -1"
              severity="success"
              text="{{filteredItemsSize}} {{'records_found' | translate}}."
              styleClass="p-ml-2"
            ></p-message>
            <div class="profile p-ml-auto" style="text-align: center;">
              <i
                class="fal fa-users ic-black"
                aria-hidden="true"
                style="font-size: 20px;"
              ></i>
              <div
                class="p-text-center"
                style="font-size: 12px;font-weight: 500;"
              >
                Active Users: {{ this.usersCount.active }} Licensed Users: {{ this.planInfo && this.planInfo.NumberofUsers || 0 }}
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center width-10p">Inactive</th>
          <th class="p-text-center width-6p" pResizableColumn>
            {{ 'Actions' | translate }}
          </th>
          <th class="p-text-left width-20p" pSortableColumn="StaffName" pResizableColumn>
            {{ 'user.name_profile' | translate }}
            <p-sortIcon field="StaffName"></p-sortIcon>
          </th>
          <th class="p-text-left width-15p" pSortableColumn="StaffNumber" pResizableColumn>
            {{ 'user.user_initials' | translate }}
            <p-sortIcon field="StaffNumber"></p-sortIcon>
          </th>
          <th class="p-text-left width-15p" pSortableColumn="Email" pResizableColumn>
            {{ 'email' | translate }}
            <p-sortIcon field="Email"></p-sortIcon>
          </th>
          <th class="p-text-left width-10p" pSortableColumn="TeleOffice" pResizableColumn>
            {{ 'user.office_phone' | translate }}
            <p-sortIcon field="TeleOffice"></p-sortIcon>
          </th>
          <th class="p-text-left width-10p" pSortableColumn="TeleMobile" pResizableColumn>
            {{ 'user.mobile_phone' | translate }}
            <p-sortIcon field="TeleMobile"></p-sortIcon>
          </th>
          <th class="p-text-left width-10p" pSortableColumn="TeleHome" pResizableColumn>
            {{ 'user.home_phone' | translate }}
            <p-sortIcon field="TeleHome"></p-sortIcon>
          </th>
          <th class="p-text-center width-7p">
            {{ 'user.calendar_resource' | translate }}
          </th>
          <th pResizableColumn class="p-text-center width-6p">
            {{ 'email' | translate }}
          </th>
          <th class="p-text-left width-5p" pSortableColumn="StaffID" pResizableColumn>
            {{ 'user.Staff-ID' | translate }}
            <p-sortIcon field="StaffID"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr class="cursor-hand">
          <td class="width-10p p-text-center">
            <input
              type="checkbox"
              [checked]="rowData.Inactive"
              (change)="toggleUserStatus(rowData)"
            />
          </td>
          <td class="width-6p p-text-center">
            <i
              pRipple
              class="fal fa-pencil p-mr-1 ic-green"
              (click)="redirectToUserView(rowData.StaffID,rowData);"
              pTooltip="{{'Edit' | translate}}"
              tooltipPosition="top"
            ></i>
            <i
              pRipple
              (click)="deleteUser($event,rowData.StaffID,rowData)"
              class="fal fa-trash-alt p-mr-1 ic-red"
              style="padding-left:8px;"
              pTooltip="{{'mgmt-delete' | translate}}"
              tooltipPosition="top"
            ></i>
          </td>
          <td
            class="width-20p p-text-left users-list-profile-col"
            (click)="redirectToUserView(rowData.StaffID,rowData);"
            pTooltip="{{rowData.StaffName}}"
            showDelay="800"
            tooltipPosition="top"
            tooltipStyleClass="tooltip-no-wrap"
          >
            <div class="user-list-profile-col">
              <div class="p-mr-3">
                <img
                  class="user-list-profile-img"
                  [attr.src]="rowData['ProfilePic']? rowData['ProfilePic'] : 'assets/img/userForTable.png'"
                >
              </div>
              <div>
                <strong>
                  {{ rowData.StaffName }}
                </strong>
                <p class="p-mb-0">
                  {{ rowData['PermissionDescription'] }}
                </p>
              </div>
            </div>
          </td>
          <td
            class="width-15p p-text-left p-text-truncate"
            (click)="redirectToUserView(rowData.StaffID,rowData);"
            pTooltip="{{rowData.StaffNumber}}"
            tooltipPosition="top"
            showDelay="800"
            tooltipStyleClass="tooltip-no-wrap"
          >
            {{ rowData.StaffNumber }}
          </td>
          <td
            class="width-15p p-text-left p-text-truncate"
            (click)="redirectToUserView(rowData.StaffID,rowData);"
            pTooltip="{{rowData.Email}}"
            tooltipPosition="top"
            showDelay="800"
            tooltipStyleClass="tooltip-no-wrap"
          >
            {{ rowData.Email }}
          </td>
          <td
            class="width-10p p-text-left"
            (click)="redirectToUserView(rowData.StaffID,rowData);"
          >
            {{ rowData.TeleOffice }}
          </td>
          <td
            class="width-10p p-text-left"
            (click)="redirectToUserView(rowData.StaffID,rowData);"
          >
            {{ rowData.TeleMobile }}
          </td>
          <td
            class="width-10p p-text-left"
            (click)="redirectToUserView(rowData.StaffID,rowData);"
          >
            {{ rowData.TeleHome }}
          </td>
          <td
            class="width-7p p-text-center"
            (click)="redirectToUserView(rowData.StaffID,rowData);"
          >
            <i
              *ngIf="rowData.BitAddCalendarResource"
              class="pi pi-check ic-green"
            ></i>
          </td>
          <td class="width-6p p-text-center">
            <i
              class="far fa-envelope ic-red"
              (click)="sendEmailToUser(rowData);"
              tooltipPosition="top"
              pTooltip="Email User"
            ></i>
          </td>
          <td
            class="width-5p p-text-left"
            (click)="redirectToUserView(rowData.StaffID,rowData);"
          >
            {{ rowData.StaffID }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">
            <p-message
              severity="info"
              text="{{ 'data_not_found' | translate }}."
              styleClass="p-px-2"
            ></p-message>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
