import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  NgZone,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {
  EncrDecrService,
  MangoApiService,
  BreadcrumbService,
  AuthGuard,
  mangoUtils,
  RepeatEnum
} from '@app/_services';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Table } from 'primeng/table';
import { BehaviorSubject, forkJoin, timer } from 'rxjs';
import swal from 'sweetalert2';
import { MenuItem, SelectItem } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import FileSaver from 'file-saver';
import * as xlsx from 'xlsx';
import { RRule } from 'rrule';
import {
  CARET_DAY_CARDINAL,
  CARET_DAY_ORDINAL,
  CARET_MONTH,
  CARET_QUARTER_CARDINAL,
  CARET_QUARTER_ORDINAL,
  CARET_YEAR
} from '../../project-details/constants';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SharedComponentsService } from '@app/shared/components/shared-components-service';
import { RruleGeneratorComponent } from '../../../../shared/components/rrule-generator/rrule-generator.component';
import { Paginator } from 'primeng/paginator';

export enum TagSwitch {
  ADD = 'Add Only',
  REPLACE = 'Replace Only'
}
@Component({
  selector: 'app-project-list-projects',
  templateUrl: './project-list-projects.component.html'
})
export class ProjectListProjectsComponent implements OnInit, OnDestroy {
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt', { static: false }) dt: Table;
  @ViewChild(RruleGeneratorComponent) rruleComponent;
  @ViewChild('p', { static: false }) paginator: Paginator;
  public selClient: any = { ClientID: null, ClientName: 'All' };
  public filteredClients: any[];
  public clientListDatasource: any = [];
  public clientsDataSource: any = [];
  public projectsList: any = [{ label: 'All', value: null }];
  public companyId;
  tagSwitchLabel: TagSwitch = TagSwitch.ADD;
  forbiddenWildcards = {
    YEAR: [
      CARET_QUARTER_ORDINAL,
      CARET_QUARTER_CARDINAL,
      CARET_MONTH,
      CARET_DAY_ORDINAL,
      CARET_DAY_CARDINAL
    ],
    QUARTER: [CARET_MONTH, CARET_DAY_ORDINAL, CARET_DAY_CARDINAL],
    MONTH: [CARET_DAY_ORDINAL, CARET_DAY_CARDINAL]
  };

  allowedWildcards = {
    YEAR: [CARET_YEAR],
    QUARTER: [CARET_QUARTER_ORDINAL, CARET_QUARTER_CARDINAL, CARET_YEAR],
    MONTH: [CARET_MONTH, CARET_YEAR, CARET_QUARTER_ORDINAL, CARET_QUARTER_CARDINAL],
    DAY: [CARET_DAY_ORDINAL, CARET_DAY_CARDINAL]
  };
  subscriptionLevel;
  public projectObj = {
    TemplateName: null,
    tagID: null,
    tagIDs: null,
    dueDateID: null,
    statusID: null,
    statusIDs: null,
    staffId: null,
    managerId: null,
    clientGroup: null,
    dateRange: null,
    repeatValues: null,
    completionDate: null,
    filterDateField: null,
    redirectStatus: null,
    filterStatus: null
  };

  public projectParamsObj = {
    TemplateName: null,
    tagID: null,
    tagIDs: null,
    dueDateID: null,
    statusID: null,
    statusIDs: null,
    staffId: null,
    managerId: null,
    clientGroup: null,
    dateRange: null,
    repeatValues: null,
    completionDate: null,
    filterDateField: null,
    redirectStatus: null,
    filterStatus: null
  };

  DateFrom: any = new Date();
  DateTo: any = new Date();
  repeatDueDate: any = new Date();

  public ddmSettingsObj: any = {};
  public selectedClientsItems: any = [];
  public isApiloaded: boolean = false;
  public isTagscolvisible: boolean = false;
  public tagsList: any = [];
  public tagsListForFilters: any = [];
  public AllStaffsTypes: any = [{ label: 'All', value: null }];
  public staffListBatchUpdate: any = [];
  public companyTags: any = [];
  public todayDate = moment().format('YYYY-MM-DD');
  public dueDateList: any = [];
  public statusList: any = [];
  public clientsCols: any[];
  public dateRangeList: any = [];
  public dateToFilterList: any = [];
  public DateToFilter: any = null;
  public completionDateList: any = [];
  isLoadings: boolean = false;

  pdfReportProjectList = [];
  selectedValue: string;
  searchTextStr: any = '';
  buttonsDeleteList: MenuItem[];
  filteredItemsSize = -1;
  intervalid: any;
  isFormValid: boolean = false;
  filteredData: any;

  showAssignBudgetDialog = false;
  selectedProjects = [];

  resourceId;
  showBatchUpdateProjForm: boolean = false;
  public batchUpdateForm: FormGroup;
  public batchFormSub: any;
  @ViewChild('formDirective') public formDirective: NgForm;

  public filterProjectListEventData: any = {};
  public displayFilterOption: any = {};
  public filterProjectListDialog: any;

  cols: any[];
  _selectedColumns: any[];
  public userSelectedFilters: any[];
  public staffID: any;
  groupList: SelectItem[];
  public globalFilterColumns: any = [];
  defaultProjectList = false;
  ProjectListCols: Array<string> = [];
  getProjectListUrlParams = null;
  isManaging: any;
  selectedRowsPerPage = 10;
  isShowRequestSidebar: boolean = false;
  selectedRowOnTasks: any;

  copySelectedColunms = [];

  UrlParamsProjectList: boolean = false;
  ParamsFromUrl: any;
  hasTagIds: boolean = false;

  automaticComputeDuedate: any;
  totalProcessLen: any;
  sortField: any[];

  public userSettingLoadingStatus: boolean = false;
  public userSettingLoadedStatus: boolean = false;

  public projectListLoadingStatus: boolean = false;
  public projectListLoadedStatus: boolean = false;

  public projectListPageIndex: number = 1;
  public projectListNextPage: number = 0;
  public projectListPageSize: number = 10;

  public projectListTotalCount: number = 0;

  public projectListSearchQuery: string = '';
  public projectListSearchQueryCopy: string = '';
  public projectListSortQuery: string = '';

  public projectListSortColumnMap: object = {
    ClientName: 'ClientName',
    TemplateName: 'TemplateName',
    Tags: 'Tags',
    TaskProgress: 'TaskProgress',
    ProjectMemo: 'ProjectMemo',
    TotalNotes: 'TotalNotes',
    DateReceived: 'DateReceived',
    FinishDate: 'FinishDate',
    SortableDueDate: 'SortableDueDate',
    Status: 'Status',
    GroupDescriptionValues: 'GroupDescriptionIDArray',
    Repeat: 'Repeat',
    TaskDescription: 'TaskDescription',
    StaffNames: 'UserAssignedIDArray',
    CompletionDate: 'CompletionDate',
    username: 'username',
    managername: 'managername',
    CompanyLocation: 'CompanyLocation',
    EngagementName: 'EngagementName'
  };

  searchInputTimeout = undefined;

  public taskListDataSource: any = [];
  public projectTaskList: any = [];

  listOptionsFilterSelected: { type: any; value: any }[] = [];
  projectListFiltered = [];

  isAllSelectedExport: boolean = false;
  isCSVExportSelected: boolean = false;
  isExport: boolean = false;

  public projectTaskListTotalCount: number = 0;

  public taskListLoadingStatus: boolean = false;
  public taskListLoadedStatus: boolean = false;

  public taskListPaginateStatus: boolean = false;
  public taskListPageIndex: number = 1;
  public taskListNextPage: number = 0;
  public taskListPageSize: number = 10;

  public activeProjectHeaderID: string = '';

  doWhenProjectListLoadedTimeout: any | undefined = undefined;
  doWhenInitializeDoneTimeout: any | undefined = undefined;

  private searchValueSubject = new BehaviorSubject<any>(null);
  searchValue$ = this.searchValueSubject.asObservable();
  public engagementListItems = [];
  rowsPerPageOptions: number[] = [];
  copySelectedColumns: any[] = [];
  columnsToBind: any[] = [];
  isNoduedate: boolean = false;

  constructor(
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef,
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private _fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private sharedService: SharedComponentsService,
    private auth: AuthGuard,
    public mangoUtils: mangoUtils
  ) {
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.getProjectNames(this.companyId);

    const queryParameterData = this.route.snapshot.queryParams || undefined;

    if (queryParameterData) {
      if (queryParameterData['StaffID']) {
        this.projectObj.staffId = this.projectParamsObj.staffId = parseInt(
          queryParameterData['StaffID']
        );

        if (this.projectParamsObj.staffId)
          this.projectObj.managerId = this.projectParamsObj.staffId;

        this.projectParamsObj.managerId = queryParameterData.from
          ? parseInt(queryParameterData['StaffID'])
          : null;

        this.defaultProjectList = true;

        this.getProjectListUrlParams = 'redirectedFromDashboard=true';
      }

      if (queryParameterData['Status']) {
        this.projectParamsObj.statusID = queryParameterData['Status'];
      }

      if (queryParameterData['tag']) {
        this.defaultProjectList = true;

        this.projectParamsObj.tagID = queryParameterData['tag'];
      }

      if (queryParameterData['Due']) {
        this.projectParamsObj.dueDateID = queryParameterData['Due'];
      }

      if (queryParameterData['DateRange']) {
        this.projectParamsObj.dateRange = queryParameterData['DateRange'];
      }

      if (queryParameterData['filterDateField']) {
        this.projectParamsObj.filterDateField = queryParameterData['filterDateField'];
      }

      if (
        'redirectStatus' in queryParameterData === true &&
        (queryParameterData.redirectStatus === true || queryParameterData.redirectStatus === 'true')
      ) {
        this.projectParamsObj.redirectStatus = true;
      }

      if (
        'filterStatus' in queryParameterData === true &&
        (queryParameterData.filterStatus === false || queryParameterData.filterStatus === 'false')
      ) {
        this.projectParamsObj.filterStatus = false;
      }
    }

    this.searchTextStr = this.encrDecSrvc.getObject(
      'projListProjects_' + AppConstants.SearchString
    );
    this.filterDataTable();
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.fetchDdmSettings();
    this.getAllStaffList();

    this.resourceId = this.encrDecSrvc.getObject(AppConstants.resourceID);

    this.DateTo.setDate(this.DateTo.getDate() + 1); //date tomorrow

    this.dateToFilterList = [
      { label: 'Due Date', value: 'DueDate' },
      { label: 'Extension Date', value: 'ExtensionDate' },
      { label: 'Out Date', value: 'FinishDate' }
    ];

    this.DateToFilter = 'DueDate';

    this.buttonsDeleteList = [
      {
        label: 'Delete Selected Items',
        icon: 'fal fa-trash-alt',
        command: () => {
          this.deleteItems();
        }
      },
      {
        label: 'Complete Selected Items',
        icon: 'fal fa-check',
        disabled: this.selectedClientsItems.length > 1,
        command: () => {
          this.completeItems();
        }
      },
      {
        label: 'Batch Update Projects',
        icon: 'fal fa-pencil',
        disabled: this.selectedClientsItems.length > 1,
        command: () => {
          this.onShowBatchUpdateProjForm();
        }
      }
    ];

    if (this.subscriptionLevel == 'ENTERPRISE' || this.subscriptionLevel == 'FREE') {
      this.buttonsDeleteList.push({
        label: 'Batch Assign Budgets',
        icon: 'fal fa-ballot',
        disabled: this.selectedClientsItems.length > 1,
        command: () => {
          this.onShowBudgetAssign();
        }
      });
    }

    this.initializeForm();
    this.getGroups();
  }

  calculateRowsPerPageOptions() {
    if (this.projectListTotalCount > 50) {
      this.rowsPerPageOptions = [10, 25, 50];
    } else if (this.projectListTotalCount > 25) {
      this.rowsPerPageOptions = [10, 25, this.projectListTotalCount];
    } else if (this.projectListTotalCount > 10) {
      this.rowsPerPageOptions = [10, this.projectListTotalCount];
    } else {
      this.rowsPerPageOptions = [this.projectListTotalCount];
    }
  }

  getGroups() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getGroups().subscribe(
      (data: any) => {
        this.groupList = data.map(group => {
          return {
            label: group.GroupDescription,
            value: group.CustomerGroupCategoryID
          };
        });
        this.groupList.unshift({ label: 'All', value: null });
        this.mangoAPISrvc.showLoader(false);
      },
      error => {
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  onColReorder(event) {
    this.saveSelectedCols(event.columns);
  }

  onChangeSelectedCols(event) {
    this.mangoAPISrvc.showLoader(true);
    let columnsToSave = '';
    event.value.map((cols, index) => {
      if (index > 0) columnsToSave += `, ${cols.field}`;
      else columnsToSave += cols.field;
    });
    const objToSave = { ProjectListCols: `{${columnsToSave}}` };
    if (columnsToSave.indexOf('Tags') > -1) {
      this.isTagscolvisible = true;
    } else {
      this.isTagscolvisible = false;
    }
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        data => {
          this.initializeColumns();
          this.dt.reset();
          this.mangoAPISrvc.showLoader(false);
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
        }
      );
  }

  saveSelectedCols(list: Array<any>): void {
    let columnsToSave = '';
    list.map((col, index) => {
      if (index > 0) columnsToSave += `, ${col.field}`;
      else columnsToSave += col.field;
    });
    const objToSave = { ProjectListCols: `{${columnsToSave}}` };
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        (data: any) => {
          this.ProjectListCols = data.ProjectListCols;
        },
        err => {}
      );
  }

  initializeColumns() {
    const self = this;
    this.isLoadings = true;
    let selectedCols = [];
  
    const allCols = [
      { field: 'Tags', header: this.translate.instant('Tags'), rowClass: 'width-6p p-text-left', resizable: true },
  
      {
        field: 'TaskProgress',
        header: this.translate.instant('Tasks'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'ProjectMemo',
        header: this.translate.instant('memo'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'TotalNotes',
        header: this.translate.instant('notes'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
  
      {
        field: 'DateReceived',
        header: this.translate.instant('In-Date'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'FinishDate',
        header: this.translate.instant('Out-Date'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'SortableDueDate',
        header: this.translate.instant('due-date'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
  
      {
        field: 'Status',
        header: this.translate.instant('status'),
        rowClass: 'width-5p p-text-center p-text-nowrap p-text-truncate',
        resizable: true
      },
  
      {
        field: 'GroupDescriptionValues',
        header: this.translate.instant('Client_Groups'),
        rowClass: 'width-6p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
  
      {
        field: 'Repeat',
        header: this.translate.instant('repeat'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'TaskDescription',
        header: this.translate.instant('next-task'),
        rowClass: 'width-8p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'StaffNames',
        header: this.translate.instant('Assigned'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'CompletionDate',
        header: this.translate.instant('Completion-Date'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'username',
        header: this.translate.instant('manager'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'managername',
        header: this.translate.instant('partner'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'CompanyLocation',
        header: this.translate.instant('company_location'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'EngagementName',
        header: this.translate.instant('engagement'),
        rowClass: 'width-5p p-text-left',
        resizable: true
      }
    ];
  
    const defaultCols = [
      { field: 'Tags', header: this.translate.instant('Tags'), rowClass: 'width-6p p-text-left', resizable: true },
  
      {
        field: 'TaskProgress',
        header: this.translate.instant('Tasks'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'ProjectMemo',
        header: this.translate.instant('memo'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'TotalNotes',
        header: this.translate.instant('notes'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
  
      {
        field: 'DateReceived',
        header: this.translate.instant('In-Date'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'FinishDate',
        header: this.translate.instant('Out-Date'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
      {
        field: 'SortableDueDate',
        header: this.translate.instant('due-date'),
        rowClass: 'width-5p p-text-center',
        resizable: true
      },
  
      {
        field: 'Status',
        header: this.translate.instant('status'),
        rowClass: 'width-5p p-text-center p-text-nowrap p-text-truncate',
        resizable: true
      },
  
      {
        field: 'GroupDescriptionValues',
        header: this.translate.instant('Client_Groups'),
        rowClass: 'width-6p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
  
      {
        field: 'Repeat',
        header: this.translate.instant('repeat'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
  
      {
        field: 'TaskDescription',
        header: this.translate.instant('next-task'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'StaffNames',
        header: this.translate.instant('Assigned'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'username',
        header: this.translate.instant('manager'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'managername',
        header: this.translate.instant('partner'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'CompanyLocation',
        header: this.translate.instant('company_location'),
        rowClass: 'width-5p p-text-left p-text-nowrap p-text-truncate',
        resizable: true
      },
      {
        field: 'EngagementName',
        header: this.translate.instant('engagement'),
        rowClass: 'width-5p p-text-left',
        resizable: true
      }
    ];
  
    this.cols = [...allCols].filter(columnData => {
      if (!this.ddmSettingsObj.isBudgetingProjectLevel && columnData.field === 'EngagementName') {
        return false;
      }
  
      return true;
    });
  
    if (this.projectObj.statusID == 'Completed') {
      this.cols.push({
        field: 'Completion',
        header: this.translate.instant('Completion'),
        rowClass: 'p-text-center cursor-hand',
        resizable: true
      });
    }
  
    this.userSettingLoadingStatus = true;
    this.userSettingLoadedStatus = false;
  
    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe(
        (data: any) => {
          if (data.ProjectListCols?.length > 0) {
            selectedCols = allCols.filter(col => data.ProjectListCols.includes(col.field));
  
            this.ProjectListCols = data.ProjectListCols;
          } else {
            selectedCols = [...defaultCols];
          }
  
          this.selectedRowsPerPage = data.ProjectListDefaultPagination || 10;
          this.projectListPageSize = this.selectedRowsPerPage;
  
          this._selectedColumns = selectedCols.filter(columnData => {
            if (
              !self.ddmSettingsObj.isBudgetingProjectLevel &&
              columnData.field === 'EngagementName'
            ) {
              return false;
            }
  
            return true;
          });
  
          if (selectedCols.find(columnData => columnData.field == 'Tags')) {
            this.isTagscolvisible = true;
          }
  
          this.globalFilterColumns = [
            ...['ClientName', 'TemplateName'],
  
            ...this._selectedColumns.map(columnData => columnData.field)
          ];
  
          if (this.ProjectListCols?.length > 0) {
            this._selectedColumns = this.mangoUtils.mapOrder(
              this._selectedColumns,
              this.ProjectListCols,
              'field'
            );
          }
          this.updateColumnsToBind();
          this.userSettingLoadingStatus = false;
          this.userSettingLoadedStatus = true;
          this.isLoadings = false;
        },
        error => {
          selectedCols = [...defaultCols].filter(columnData => {
            if (
              !self.ddmSettingsObj.isBudgetingProjectLevel &&
              columnData.field === 'EngagementName'
            ) {
              this.isLoadings = false;
              return false;
            }
            this.isLoadings = false;
            return true;
          });
  
          this.globalFilterColumns = [
            ...['ClientName', 'TemplateName'],
  
            ...selectedCols.map(columnData => columnData.field)
          ];
          this.selectedColumns = this.selectedColumns.filter(col => col.field !== 'Tags');
          this.updateColumnsToBind();
          this.userSettingLoadingStatus = false;
          this.userSettingLoadedStatus = true;
        }
      );
  }  

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  updateColumnsToBind() {
    this.columnsToBind = this.copySelectedColumns.length > 0 ? this.copySelectedColumns : this.selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    let arr = val.map(col => col.field);
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
    this.globalFilterColumns = [
      ...['ClientName', 'TemplateName'],
      ...this._selectedColumns.map(col => col.field)
    ];
    this._selectedColumns = this.mangoUtils.mapOrder(
      this._selectedColumns,
      this.ProjectListCols,
      'field'
    );

    this.saveSelectedCols(this._selectedColumns);
  }

  getLabel(type, val) {
    if (type == 'DueDate') {
      return this.dueDateList.find(x => x.value == val)?.label ?? val;
    }
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management') },
      { label: this.translate.instant('Project-List') },
      { label: this.translate.instant('Projects'), icon: 'ic-red' }
    ]);
    this.dueDateList = [
      { label: this.translate.instant('all'), value: null },
      { label: this.translate.instant('no_due_date'), value: 'noduedate' },
      { label: this.translate.instant('past_due'), value: 'pastdue' },
      { label: this.translate.instant('Due-Today'), value: 'duetoday' },
      { label: this.translate.instant('due_tomorrow'), value: 'duetomorrow' },
      { label: this.translate.instant('Due-This-Week'), value: 'duethisWeek' },
      { label: this.translate.instant('Due-Next-Week'), value: 'duetNextWeek' },
      { label: this.translate.instant('due_this_month'), value: 'duethismonth' },
      { label: this.translate.instant('due_next_month'), value: 'duetNextMonth' },
      { label: this.translate.instant('due_this_year'), value: 'duethisyear' },
      { label: this.translate.instant('Custom'), value: 'custom' }
    ];
    this.statusList = [
      // { label: this.translate.instant('all_active'), value: null, color: null },
      { label: this.translate.instant('Pending'), value: 'Pending', color: 'Pending' },
      {
        label: this.translate.instant('mgmt-inprogress'),
        value: 'In Progress',
        color: 'InProgress'
      },
      {
        label: this.translate.instant('Ready-for-Review'),
        value: 'Ready for Review',
        color: 'review'
      },
      { label: this.translate.instant('completed'), value: 'Completed', color: 'Completed' }
    ];

    this.dateRangeList = [
      { label: this.translate.instant('none'), value: 'none' },
      { label: this.translate.instant('yearly'), value: 'year' },
      { label: this.translate.instant('quarterly'), value: 'quarterly' },
      { label: this.translate.instant('user.monthly'), value: 'monthly' },
      { label: this.translate.instant('semi_monthly'), value: 'semi-monthly' },
      { label: this.translate.instant('user.weekly'), value: 'week' },
      { label: this.translate.instant('semi_weekly'), value: 'semi-weekly' },
      { label: this.translate.instant('user.daily'), value: 'daily' },
      { label: this.translate.instant('Custom'), value: 'custom' }
    ];

    this.completionDateList = [
      { label: this.translate.instant('all'), value: null },
      { label: this.translate.instant('This-Week'), value: 'this-week' },
      { label: this.translate.instant('This-Month'), value: 'this-month' },
      { label: this.translate.instant('This-Quarter'), value: 'this-quarter' },
      { label: this.translate.instant('This-Year'), value: 'this-year' },
      { label: this.translate.instant('Last-Week'), value: 'last-week' },
      { label: this.translate.instant('Last-Month'), value: 'last-month' },
      { label: this.translate.instant('Last-Quarter'), value: 'last-quarter' },
      { label: this.translate.instant('Last-Year'), value: 'last-year' }
    ];
    this.initializeColumns();
    this.initializeFilterValues();
  }

  async ngOnInit() {
    this.filterProjectListDialog = this.sharedService.filterProjectListDialog.subscribe(
      eventData => {
        this.filterProjectListEventData = eventData;
        this.displayFilterOption = eventData;
      }
    );

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);

    this.isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
    this.sortField = this.encrDecSrvc.getObject('projListProjects_sortField') ?? [
      { field: 'ClientName', order: 1 }
    ];
  }
  
  isParamsEmpty(): boolean {
    const obj = this.projectParamsObj;

    this.ParamsFromUrl = obj;
    if (this.ParamsFromUrl.tagID) {
      this.hasTagIds = true;
    } else {
      this.hasTagIds = false;
    }

    return !(
      obj.managerId ||
      obj.staffId ||
      obj.statusID ||
      obj.tagID ||
      obj.dueDateID ||
      obj.dateRange
    );
  }

  initializeFilterValues() {
    if (!this.isParamsEmpty()) {
      this.doWhenProjectListLoaded(() => {
        this.getProjectList(false, true, null);
      });
    } else {
      this.doWhenProjectListLoaded(() => {
        this.getProjectList(false, false, null);
      });
    }

    if (
      this.projectParamsObj.redirectStatus === true &&
      this.projectParamsObj.filterStatus === false
    ) {
      // this.projectObj.managerId = null;
      // this.projectObj.staffId = null;
      this.projectObj.statusID = null;
      this.projectObj.statusIDs = null;
      this.projectObj.tagID = null;
      this.projectObj.tagIDs = null;
      // this.projectObj.dueDateID = null;
      this.projectObj.clientGroup = null;
      this.projectObj.repeatValues = null;

      this.sharedService.triggerFilterProjectListDialog({
        filterStatus: !this.isParamsEmpty() ? true : false,
        filterState: 'disabled'
      });

      this.userSelectedFilters = [
        this.projectObj.tagID,
        this.projectObj.dueDateID,
        this.projectObj.statusID,
        this.projectObj.staffId,
        this.projectObj.managerId,
        this.projectObj.clientGroup,

        moment.utc(this.DateFrom).format('YYYY-MM-DD'),
        moment.utc(this.DateTo).format('YYYY-MM-DD'),

        this.DateToFilter,

        this.projectObj.repeatValues,
        this.projectObj.completionDate
      ];

      this.doWhenProjectListLoaded(() => {
        this.getProjectList(false, null);
      });

      return;
    } else {
      this.sharedService.triggerFilterProjectListDialog({
        filterState: 'enabled'
      });
    }

    this.mangoAPISrvc
      .getUsrSelFiltersByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe((data: any) => {
        const filterList = Array.from(data.PMProjectFilters || []);
        filterList?.splice(6, 2);

        this.isNoduedate = filterList.includes('noduedate');
        
        if (filterList.filter(Boolean)?.length > 0) {
          const filters = data.PMProjectFilters;

          this.projectObj.tagIDs = filters[0]
            ? filters[0].replaceAll(/[\[\]']+/g, '').split('-')
            : null;
          this.projectObj.dueDateID = filters[1] ? filters[1] : null;
          this.projectObj.statusIDs = filters[2]
            ? filters[2].replaceAll(/[\[\]']+/g, '').split('-')
            : null;
          this.projectObj.staffId = filters[3] ? parseInt(filters[3]) : null;
          this.projectObj.managerId = filters[4] ? parseInt(filters[4]) : null;
          this.projectObj.clientGroup = filters[5] ? parseInt(filters[5]) : null;

          this.DateFrom = filters[6] ? this.mangoUtils.addOffset(filters[6]) : new Date();
          this.DateTo = filters[7] ? this.mangoUtils.addOffset(filters[7]) : new Date();
          this.DateToFilter = filters[8] ? filters[8] : null;

          this.projectObj.repeatValues = filters[9]
            ? filters[9].replaceAll(/[\[\]']+/g, '').split('|')
            : null;
          this.projectObj.completionDate = filters[10] ? filters[10] : null;

          this.sharedService.triggerFilterProjectListDialog({ filterStatus: true });
        } else {
          this.projectObj.managerId = null;
          this.projectObj.staffId = null;
          this.projectObj.statusID = null;
          this.projectObj.statusIDs = null;
          this.projectObj.tagID = null;
          this.projectObj.tagIDs = null;
          this.projectObj.dueDateID = null;
          this.projectObj.clientGroup = null;
          this.projectObj.repeatValues = null;
          this.sharedService.triggerFilterProjectListDialog({ filterStatus: false });
        }

        this.userSelectedFilters = [
          this.projectObj.tagID,
          this.projectObj.dueDateID,
          this.projectObj.statusID,
          this.projectObj.staffId,
          this.projectObj.managerId,
          this.projectObj.clientGroup,

          moment.utc(this.DateFrom).format('YYYY-MM-DD'),
          moment.utc(this.DateTo).format('YYYY-MM-DD'),

          this.DateToFilter,

          this.projectObj.repeatValues,
          this.projectObj.completionDate
        ];
        this.listOptionsFilterSelected = this.mapValuesToFilters(this.userSelectedFilters);
        if (this.isParamsEmpty()) {
          this.doWhenProjectListLoaded(() => {
            this.getProjectList(false, undefined, true, null);
          });
        }
      });
  }
  getStatusValues(value: string): string[] {
    if (!value) {
      return [];
    }
    const optionsString = value.replace(/\[|\]/g, ''); // Remove square brackets
    return optionsString.split('-').map(status => status.trim());
  }
  mapValuesToFilters(values: any[]): { type: any; value: any }[] {
    const filters: { type: any; value: any }[] = [
      { type: 'tag', value: values[0] },
      { type: 'correlativodueDate', value: values[1] },
      { type: 'status', value: values[2] ?? [] },
      { type: 'manager', value: values[3] },
      { type: 'partner', value: values[4] },
      { type: 'clientGroup', value: values[5] },
      { type: 'dateFrom', value: values[6] },
      { type: 'dateTo', value: values[7] },
      { type: 'dateToFilter', value: values[8] },
      { type: 'repeat', value: values[9] },
      { type: 'completionDate', value: values[10] }
    ];

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].value === null) {
        filters[i].type = null;
      }
    }
    return filters.filter(filter => filter.type !== null);
  }

  savePMFilters() {
    const arrToSave = this.userSelectedFilters.map(filter => (filter ? filter : 'null'));

    const objToSave = {
      PMProjectFilters: `{${arrToSave}}`
    };

    this.mangoAPISrvc
      .updateUsrSelFiltersByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        data => {},
        error => {}
      );
  }

  updatePMFilters(value, index, type?) {
    if ([0, 2].includes(index)) {
      if (value && value.length > 0) value = `[${value.join('-')}]`;
      else value = null;
    } else if (index == 9) {
      if (value && value.length > 0) value = `[${value.join('|')}]`;
      else value = null;
    }
    if (index == 2) {
      const newStatus = this.getStatusValues(value);
      this.addOrReplaceValue(type, newStatus);
    } else {
      this.addOrReplaceValue(type, value);
    }
    this.userSelectedFilters[index] = value;
    this.savePMFilters();
  }

  addOrReplaceValue(type, value) {
    let found = false;
    for (let i = 0; i < this.listOptionsFilterSelected.length; i++) {
      if (this.listOptionsFilterSelected[i].type === type) {
        this.listOptionsFilterSelected[i].value = value;
        found = true;
        break;
      }
    }
    // If the value doesn't exist, add the new value to the array
    if (!found) {
      this.listOptionsFilterSelected.push({ type, value });
    }
  }

  onChangeFilters(event, type: string) {
    this.isNoduedate = event.value === 'noduedate';
    switch (type) {
      case 'tag':
        this.updatePMFilters(event.value, 0, type);
        break;
      case 'dueDate':
        this.updatePMFilters(event.value, 1, type);
        break;
      case 'status':
        if (this.projectObj['statusIDs'] && this.projectObj['statusIDs'].length > 0) {
          this.projectObj['statusIDs'] = this.projectObj['statusIDs'].map(item =>
            item['value'] ? item['value'] : item
          );
        }
        this.updatePMFilters(this.projectObj['statusIDs'], 2, type);
        break;
      case 'manager':
        this.updatePMFilters(event.value, 3, type);
        break;
      case 'partner':
        this.updatePMFilters(event.value, 4, type);
        break;
      case 'clientGroup':
        this.updatePMFilters(event.value, 5, type);
        break;
      case 'dateFrom':
        this.updatePMFilters(moment.utc(this.DateFrom).format('YYYY-MM-DD'), 6, type);
        break;
      case 'dateTo':
        this.updatePMFilters(moment.utc(this.DateTo).format('YYYY-MM-DD'), 7, type);
        break;
      case 'dateToFilter':
        this.updatePMFilters(event.value, 8, type);
        break;
      case 'repeat':
        if (this.projectObj['repeatValues'] && this.projectObj['repeatValues'].length > 0) {
          this.projectObj['repeatValues'] = this.projectObj['repeatValues'].map(item =>
            item['value'] ? item['value'] : item
          );
        }
        this.updatePMFilters(this.projectObj['repeatValues'], 9, type);
        break;
      case 'completionDate':
        this.updatePMFilters(event.value, 10, type);
        break;
      default:
        break;
    }
  }

  fetchData() {
    this.doWhenProjectListLoaded(() => {
      this.getProjectList(false, null, null, true);
    });

    this.sharedService.triggerFilterProjectListDialog({ filterStatus: true });
    this.onCloseFilter();
  }

  onCloseFilter(): void {
    this.sharedService.triggerFilterProjectListDialog({ dialogStatus: false });
  }

  onResetFilters() {
    this.router.navigate([], {
      queryParams: {
        query: null
      }
    });

    this.projectParamsObj = {
      TemplateName: null,
      tagID: null,
      tagIDs: null,
      dueDateID: null,
      statusID: null,
      statusIDs: null,
      staffId: null,
      managerId: null,
      clientGroup: null,
      dateRange: null,
      repeatValues: null,
      completionDate: null,
      filterDateField: null,
      redirectStatus: null,
      filterStatus: null
    };
    this.defaultProjectList = false;

    this.selClient = { ClientID: null, ClientName: 'All' };

    this.projectObj.tagID = null;
    this.projectObj.tagIDs = null;
    this.projectObj.dueDateID = null;
    this.projectObj.statusID = null;
    this.projectObj.statusIDs = null;
    this.projectObj.staffId = null;
    this.projectObj.managerId = null;
    this.projectObj.clientGroup = null;
    this.projectObj.repeatValues = null;
    this.projectObj.completionDate = null;

    this.DateFrom = new Date();
    this.DateTo = new Date();
    this.DateToFilter = null;

    this.userSelectedFilters = [
      this.projectObj.tagIDs,
      this.projectObj.dueDateID,
      this.projectObj.statusIDs,
      this.projectObj.staffId,
      this.projectObj.managerId,
      this.projectObj.clientGroup,
      this.DateFrom,
      this.DateTo,
      this.DateToFilter,
      this.projectObj.repeatValues,
      this.projectObj.completionDate
    ];

    this.savePMFilters();

    this.sharedService.triggerFilterProjectListDialog({ filterStatus: false });

    this.listOptionsFilterSelected = [];
    this.projectListFiltered = [];
    this.pdfReportProjectList = [];

    this.doWhenProjectListLoaded(() => {
      this.getProjectList(false);
    });

    this.sharedService.triggerFilterProjectListDialog({ dialogStatus: false });
  }

  async getAllProjectListReport(isFilter?) {
    let argsObj = {
      TemplateName: null,
      tagID: null,
      tagIDs: null,
      dueDateID: null,
      statusID: null,
      statusIDs: null,
      staffId: null,
      managerId: null,
      clientGroup: null,
      dateRange: null,
      repeatValues: null,
      completionDate: null,
      filterDateField: null
    };

    if (!this.isParamsEmpty()) {
      argsObj = {
        ...this.projectParamsObj
      };
    } else {
      argsObj = {
        ...this.projectObj
      };

      if (argsObj.dueDateID == 'custom') {
        argsObj.dueDateID = `${moment
          .utc(this.DateFrom)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss')}|${moment
          .utc(this.DateTo)
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss')}`;
      }
    }

    argsObj['tagIDs'] = argsObj['tagIDs'] ? `[${argsObj['tagIDs'].join('-')}]` : null;

    argsObj['statusIDs'] = argsObj['statusIDs'] ? `[${argsObj['statusIDs'].join('-')}]` : null;

    argsObj['repeatValues'] = argsObj['repeatValues']
      ? `[${argsObj['repeatValues'].join('|')}]`
      : null;

    const filterDateField = this.DateToFilter || argsObj.filterDateField;
    const responseData: any = await this.mangoAPISrvc.asyncGetProjectList(
      argsObj.dueDateID,
      !this.isParamsEmpty() ? this.ParamsFromUrl.statusID : argsObj.statusID,
      !this.isParamsEmpty() ? this.ParamsFromUrl.staffId : argsObj.staffId,
      !this.isParamsEmpty() ? this.ParamsFromUrl.staffId : argsObj.managerId,
      this.selClient.ClientID,
      argsObj.TemplateName,
      argsObj.tagID,
      this.companyId,
      argsObj.clientGroup,
      this.defaultProjectList,
      argsObj.dateRange,
      filterDateField,
      argsObj.tagIDs,
      !this.isParamsEmpty()
        ? this.hasTagIds
          ? null
          : argsObj.statusID
          ? [argsObj.statusID]
          : null
        : argsObj.statusIDs,
      argsObj.repeatValues,
      null,
      this.projectListSearchQueryCopy ? { searchQuery: this.projectListSearchQueryCopy } : null,
      this.getProjectListUrlParams,
      argsObj.completionDate
    );

    let projectList = responseData.projectList;

    projectList.map(item => {
      this.getStaffNames(item['UserAssignedIDArray'], item);
      item['ExtensionDate'] = moment.utc(item['ExtensionDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      item['compareDueDate'] = moment.utc(item['ActualDueDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      item['compareExtensionDate'] = moment
        .utc(item['ExtensionDate'], 'YYYY-MM-DD')
        .format('YYYY-MM-DD');
      item['ActualDueDate'] = moment.utc(item['ActualDueDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      item['DateReceived'] = item['DateReceived']
        ? moment.utc(item['DateReceived'], 'YYYY-MM-DD').format('YYYY-MM-DD')
        : null;
      item['CompletionDate'] = moment
        .utc(item['CompletionDate'], 'YYYY-MM-DD')
        .format('YYYY-MM-DD');
      item['DueDate'] = moment.utc(item['DueDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      item['ExtensionDate'] = moment.utc(item['ExtensionDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      item['FinishDate'] = moment.utc(item['FinishDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      item['NextDueDate'] = moment.utc(item['NextDueDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');

      let tagDesc = '';
      try {
        tagDesc = item['Tags'].map(tag => this.findTagByValue(tag, 'label'));
        item['TagDescriptions'] = tagDesc.toString();
      } catch (error) {
        item['TagDescriptions'] = '';
      }

      item.managername = item.ManagerID == -1 ? 'Unassigned' : item.managername;

      item.username = item.UserAssignedID == -1 ? 'Unassigned' : item.username;

      item.SortableDueDate = item.DueDate;

      switch (item.Status) {
        case 'Pending':
          item.statusClass = 'pending';
          break;

        case 'In Progress':
          item.statusClass = 'inprogress';
          break;

        case 'Ready for Review':
          item.statusClass = 'review';
          break;

        case 'Completed':
          item.statusClass = 'completed';
          break;
      }

      if (item['GroupDescriptionIDArray'] && item['GroupDescriptionIDArray'].length > 0) {
        item['GroupDescriptionValues'] = this.groupList
          ?.filter(group => {
            item['GroupDescriptionIDArray'].includes(group['value']);
          })
          .map(group => group['label'])
          .join(', ');
      }

      if (item['TaskProgress'] === 'null/null') {
        item['TaskProgress'] = '0/0';
      }
      item['TasksCompleted'] = item['TasksCompleted'] ?? '0';
      item['TotalTasks'] = item['TotalTasks'] ?? '0';

      if (this.isCSVExportSelected) {
        item['TaskProgress'] = `="${item['TasksCompleted'] + '/' + item['TotalTasks']}"`;
      } else {
        item['TaskProgress'] = `${item['TasksCompleted'] + '/' + item['TotalTasks']}`;
      }
    });

    if (isFilter && this.listOptionsFilterSelected) {
      projectList = this.projectListFiltered;
    }

    this.pdfReportProjectList = projectList;
  }

  private filterProjectsByFilters(projects: any[], selectedFilters: any[]) {
    return projects.filter((project: any) => {
      return selectedFilters.some(filter => {
        return this.doesProjectMatchFilter(project, filter);
      });
    });
  }
  private doesProjectMatchFilter(project: any, filter: any): boolean {
    const filterType = filter.type.toLowerCase();
    switch (filterType) {
      case 'manager':
        return project.UserAssignedID === filter.value;
      case 'partner':
        return project.ManagerId === filter.value;
      case 'duedate':
        return moment
          .utc(project.SortableDueDate, 'YYYY-MM-DD')
          .isSameOrAfter(moment.utc(filter.value, 'YYYY-MM-DD'), 'day');
      case 'status':
        return filter.value.includes(project.Status);
      case 'datefrom':
        return false;
      case 'dateto':
        return false;
      default:
        return false;
    }
  }

  async getProjectList(
    onlyFiltered = false,
    isLoading = false,
    filterType = null,
    isFiltered = false
  ): Promise<any> {
    try {
      // Check if user settings are still loading or not loaded yet
      if (this.userSettingLoadingStatus || !this.userSettingLoadedStatus) {
        this.doWhenInitializeDone(() => {
          this.getProjectList(onlyFiltered, isLoading, filterType, isFiltered);
        });
        return;
      }

      // Check if a project list is already being loaded
      if (this.projectListLoadingStatus) {
        return;
      }

      const queryParameterData = this.route?.snapshot?.queryParams || {};

      // Set loading status
      if (!onlyFiltered) {
        this.projectListLoadingStatus = true;
        this.projectListLoadedStatus = false;
      }

      this.pdfReportProjectList = [];
      this.mangoAPISrvc.showLoader(true);
      let argsObj = {
        TemplateName: null,
        tagID: null,
        tagIDs: null,
        dueDateID: null,
        statusID: null,
        statusIDs: null,
        staffId: null,
        managerId: null,
        clientGroup: null,
        dateRange: null,
        repeatValues: null,
        completionDate: null,
        filterDateField: null
      };

      // Set arguments object based on parameters
      if (!this.isParamsEmpty() && !isFiltered) {
        argsObj = {
          ...this.projectParamsObj,
          managerId: this.projectParamsObj.staffId
        };
      } else {
        argsObj = {
          ...this.projectObj
        };
        // Handle custom due date
        if (argsObj.dueDateID === 'custom' && this.DateFrom && this.DateTo) {
          argsObj.dueDateID = `${moment
            .utc(this.DateFrom)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss')}|${moment
            .utc(this.DateTo)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss')}`;
        }
      }

      // Add 'Completion' column if status is 'Completed'
      if (argsObj.statusID === 'Completed') {
        this.cols?.splice(5, 0, {
          field: 'Completion',
          header: this.translate.instant('Completion'),
          rowClass: 'p-text-center cursor-hand'
        });
      } else {
        this.cols = this.cols?.filter(col => col.field !== 'Completion');
        this._selectedColumns = this._selectedColumns?.filter(col => col.field !== 'Completion');
      }

      // Filter columns based on filter type
      if (filterType) {
        const temp = this._selectedColumns.map(col => col.field);

        switch (filterType) {
          case 'DueDate':
            temp.push('SortableDueDate');
            break;
          case 'Status':
            temp.push('Status');
            break;
          case 'Manager':
            temp.push('username');
            break;
          case 'Partner':
            temp.push('managername');
            break;
        }

        this._selectedColumns = this.cols.filter(col => temp.includes(col.field));
      }

      this.isApiloaded = false;

      // Reset data sources if not only filtered
      if (!onlyFiltered) {
        this.clientsDataSource = [];
        this.filteredItemsSize = -1;
        this.selectedClientsItems = [];
      }

      this.selClient = this.selClient || { ClientID: null };

      // Format arguments for API call
      argsObj['tagIDs'] = argsObj['tagIDs'] ? `[${argsObj['tagIDs'].join('-')}]` : null;
      argsObj['statusIDs'] = argsObj['statusIDs'] ? `[${argsObj['statusIDs'].join('-')}]` : null;
      argsObj['repeatValues'] = argsObj['repeatValues']
        ? `[${argsObj['repeatValues'].join('|')}]`
        : null;

      return new Promise((resolve, reject) => {
        // Set an explicit timeout
        const timeout = setTimeout(() => {
          reject(new Error('Request timed out'));
        }, 10000); // 10 seconds timeout

        this.mangoAPISrvc
          .getProjectList(
            !this.isParamsEmpty()
              ? argsObj.dueDateID ?? this.ParamsFromUrl.dueDateID
              : argsObj.dueDateID,
            !this.isParamsEmpty()
              ? argsObj.statusID ?? this.ParamsFromUrl.statusID
              : argsObj.statusID,
            !this.isParamsEmpty() ? argsObj.staffId ?? this.ParamsFromUrl.staffId : argsObj.staffId,
            !this.isParamsEmpty()
              ? argsObj.managerId ?? this.ParamsFromUrl.staffId
              : argsObj.managerId,
            this.selClient.ClientID,
            argsObj.TemplateName,
            argsObj.tagID,
            this.companyId,
            argsObj.clientGroup,
            this.defaultProjectList,
            !this.isParamsEmpty()
              ? argsObj.dateRange ?? this.ParamsFromUrl.dateRange
              : argsObj.dateRange,
            !this.isParamsEmpty() ? this.ParamsFromUrl.filterDateField : argsObj.filterDateField,
            argsObj.tagIDs,
            !this.isParamsEmpty()
              ? this.hasTagIds
                ? null
                : argsObj.statusID
                ? [argsObj.statusID]
                : argsObj.statusIDs ?? null
              : argsObj.statusIDs,
            argsObj.repeatValues,
            null,
            !onlyFiltered
              ? {
                  pageIndex: this.projectListPageIndex,
                  nextPage: this.projectListNextPage,
                  pageSize: this.projectListPageSize,
                  _slicePaginationStatus: false,
                  _sqlPaginationStatus: true,
                  searchQuery: this.projectListSearchQuery,
                  sortQuery: this.projectListSortQuery
                }
              : this.isExport
              ? {
                  sortQuery: this.projectListSortQuery
                }
              : this.projectListSearchQueryCopy
              ? { searchQuery: this.projectListSearchQuery }
              : null,
            this.getProjectListUrlParams,
            argsObj.completionDate
          )
          .subscribe(
            (responseData: any) => {
              clearTimeout(timeout); // Clear timeout if response is received in time

              // Process response data
              responseData?.projectList?.forEach(item => {
                this.getStaffNames(item['UserAssignedIDArray'], item);

                item['ExtensionDate'] = item['ExtensionDate']
                  ? moment.utc(item['ExtensionDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['compareDueDate'] = item['ActualDueDate']
                  ? moment.utc(item['ActualDueDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['compareExtensionDate'] = item['ExtensionDate']
                  ? moment.utc(item['ExtensionDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['ActualDueDate'] = item['ActualDueDate']
                  ? moment.utc(item['ActualDueDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['DateReceived'] = item['DateReceived']
                  ? moment.utc(item['DateReceived'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['CompletionDate'] = item['CompletionDate']
                  ? moment.utc(item['CompletionDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['DueDate'] = item['DueDate']
                  ? moment.utc(item['DueDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['ExtensionDate'] = item['ExtensionDate']
                  ? moment.utc(item['ExtensionDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['FinishDate'] = item['FinishDate']
                  ? moment.utc(item['FinishDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['NextDueDate'] = item['NextDueDate']
                  ? moment.utc(item['NextDueDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                  : null;
                item['Tags'] = item['Tags'] ? item['Tags'] : [];
                item['TaskDescription'] = item['TaskDescription'] ? item['TaskDescription'] : '';

                const tagDesc = item['Tags'].map(tag => this.findTagByValue(tag, 'label'));
                item['TagDescriptions'] = tagDesc.toString();

                item.managername = item.ManagerID === -1 ? 'Unassigned' : item.managername;
                item.username = item.UserAssignedID === -1 ? 'Unassigned' : item.username;
                item.SortableDueDate = item.ExtensionDate || item.DueDate;
                // Set status class based on item status
                switch (item.Status) {
                  case 'Pending':
                    item.statusClass = 'pending';
                    break;
                  case 'In Progress':
                    item.statusClass = 'inprogress';
                    break;
                  case 'Ready for Review':
                    item.statusClass = 'review';
                    break;
                  case 'Completed':
                    item.statusClass = 'completed';
                    break;
                }

                // Set group description values
                if (item['GroupDescriptionIDArray'] && item['GroupDescriptionIDArray'].length > 0) {
                  item['GroupDescriptionValues'] = this.groupList
                    ?.filter(group => item['GroupDescriptionIDArray'].includes(group['value']))
                    .map(group => group['label'])
                    .join(', ');
                }
                if (item['TaskProgress'] === 'null/null') {
                  item['TaskProgress'] = '0/0';
                }
                item['TasksCompleted'] = item['TasksCompleted'] ?? '0';
                item['TotalTasks'] = item['TotalTasks'] ?? '0';
                if (this.isCSVExportSelected) {
                  item['TaskProgress'] = `="${item['TasksCompleted'] + '/' + item['TotalTasks']}"`;
                } else {
                  item['TaskProgress'] = `${item['TasksCompleted'] + '/' + item['TotalTasks']}`;
                }
              });
              this.projectListFiltered = responseData.projectList;
              this.pdfReportProjectList = this.projectListFiltered;
              let projectList = responseData.projectList;
              const totalCount = responseData.totalCount;
              this.projectListTotalCount = totalCount;
              if(this.isNoduedate) {
                projectList = projectList.filter(project => {
                  return project.DueDate == null ? true : false;
                });
              }
              this.calculateRowsPerPageOptions();
              if (this.projectListSearchQuery?.length > 0) {
                this.filteredItemsSize = this.projectListTotalCount;
              }

              if (projectList?.length <= 0) {
                this.resetPagination();
              }

              this.isApiloaded = projectList?.length === 0;

              this.clientsDataSource = projectList;
              // Get total and completed tasks
              this.mangoAPISrvc.getTotalAndCompletedTasks().subscribe((list: any) => {
                this.clientsDataSource = this.clientsDataSource.map(item => {
                  const res = list.find(task => task.ProjectHeaderID == item.ProjectHeaderID);
                  return {
                    ...item,
                    TasksCompleted: res?.TasksCompleted || 0,
                    TotalTasks: res?.TotalTasks || 0,
                    Tasks: `${res?.TasksCompleted || 0}/${res?.TotalTasks || 0}`
                  };
                });
              });
              // Handle loading state
              if (isLoading) {
                setTimeout(() => {
                  this.filterDataTable(() => {
                    this.projectListLoadingStatus = false;
                    this.projectListLoadedStatus = true;
                    this.mangoAPISrvc.showLoader(false);
                  });
                }, 100);
              } else {
                this.projectListLoadingStatus = false;
                this.projectListLoadedStatus = true;
                this.mangoAPISrvc.showLoader(false);
              }

              this.mangoAPISrvc.showLoader(false);
              resolve(projectList);
            },
            error => {
              clearTimeout(timeout); // Clear timeout on error
              console.error(['cannot request get project list;', error]);
              this.projectListLoadingStatus = false;
              this.projectListLoadedStatus = false;
              this.mangoAPISrvc.showLoader(false);
              reject(error);
            }
          );
      });
    } catch (error) {
      console.error(['cannot get project list;', error]);
      this.projectListLoadingStatus = false;
      this.projectListLoadedStatus = false;
      this.mangoAPISrvc.showLoader(false);
      return Promise.reject(error);
    }
  }

  filterDataTable(callback?) {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || '';
      const searchValue = localStorage.getItem('searchValue');
      if (searchValue !== null && searchValue.trim() !== '') {
        this.ngZone.run(() => {
          this.searchValue.nativeElement.value = searchValue;
          this.onSearchInput(null, true, searchValue);
          this.cdRef.detectChanges();
        });
      }

      this.dt.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          bubbles: true,
          cancelable: true
        });

        this.searchValue.nativeElement.dispatchEvent(event);
        /*;
                @note:
                  This is calling the get task list twice
                  because we've already dispatched the event
                  then you select the value again.
                @note;
              */
        //this.searchValue.nativeElement.select( );
      } else {
        this.searchValue.nativeElement.focus();
      }

      this.filteredItemsSize = -1;

      if (typeof callback == 'function') {
        callback();
      }
    }, 50);
  }

  onSearchInput($event, isSearchValue?, value?) {
    if (typeof this.searchInputTimeout != 'undefined') {
      clearTimeout(this.searchInputTimeout);

      this.searchInputTimeout = undefined;
    }

    this.searchInputTimeout = setTimeout(() => {
      const searchQuery = (isSearchValue ? value : $event.target.value) || '';

      this.projectListSearchQuery = searchQuery;
      this.projectListSearchQueryCopy = searchQuery;

      this.searchTextStr = searchQuery;

      if (searchQuery.length <= 0) {
        this.clearSearchFilter();
      } else {
        this.doWhenProjectListLoaded(() => {
          this.getProjectList();
        });
      }

      if (typeof this.searchInputTimeout != 'undefined') {
        clearTimeout(this.searchInputTimeout);

        this.searchInputTimeout = undefined;
      }
    }, 1000);

    if (isSearchValue) {
      localStorage.removeItem('searchValue');
    }
  }

  onSort($event) {
    if (this.projectListPageIndex > 1) this.resetPagination();
    this.sortField = $event.multisortmeta;
    this.encrDecSrvc.addObject('projListProjects_sortField', this.sortField);
    if (this.sortField.length > 0) {
      const sortQuery = this.sortField
        .reduce((sortQueryList, sortData) => {
          const order = sortData.order > 0 ? '+' : '-';
          const field = this.projectListSortColumnMap[sortData.field];
          sortQueryList.push(`${order}${field}`);
          return sortQueryList;
        }, [])
        .join(',');
      if (sortQuery === this.projectListSortQuery) {
        return;
      }
      this.projectListSortQuery = sortQuery;
    } else {
      this.projectListSortQuery = undefined;
      this.resetPagination();
    }
    this.doWhenProjectListLoaded(() => {
      this.getProjectList();
    });
  }

  onTablePageChange($event) {
    this.dt.reset();
    this.projectListPageIndex = $event.page + 1;
    this.projectListNextPage = $event.first;
    this.projectListPageSize = $event.rows;
    if (this.selectedRowsPerPage !== $event.rows) {
      this.mangoAPISrvc
        .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), {
          ProjectListDefaultPagination: $event.rows
        })
        .subscribe(
          (data: any) => {},
          error => {}
        );
    }
    if (this.projectListSearchQuery?.length > 0) {
      this.filterDataTable();
    }
    {
      this.doWhenProjectListLoaded(() => {
        this.getProjectList();
      });
    }
  }

  clearSearchFilter() {
    this.projectListSearchQuery = '';
    this.projectListSearchQueryCopy = '';
    this.searchTextStr = '';
    this.searchValue.nativeElement.value = '';

    this.filteredData = [];
    this.filteredItemsSize = -1;

    this.encrDecSrvc.removeObject('projListProjects_' + AppConstants.SearchString);

    this.resetPagination();

    this.doWhenProjectListLoaded(() => {
      this.getProjectList();
    });
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;

    this.filteredData = obj.filteredValue;

    this.encrDecSrvc.addObject(
      'projListProjects_' + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  resetPagination() {
    this.projectListPageIndex = 1;
    this.projectListNextPage = 0;
    this.projectListPageSize = this.selectedRowsPerPage;
    this.dt?.reset();
    setTimeout(() => this.paginator?.changePage(0));
  }

  doWhenInitializeDone(callback?) {
    if (this.userSettingLoadingStatus === true || this.userSettingLoadedStatus === false) {
      if (typeof this.doWhenInitializeDoneTimeout != 'undefined') {
        clearTimeout(this.doWhenInitializeDoneTimeout);
        this.doWhenInitializeDoneTimeout = undefined;
      }

      this.doWhenInitializeDoneTimeout = setTimeout(() => {
        this.doWhenInitializeDone(callback);
      }, 1000);
    } else if (this.userSettingLoadedStatus === true || this.userSettingLoadingStatus === false) {
      if (typeof callback == 'function') {
        callback();
      }

      if (typeof this.doWhenInitializeDoneTimeout != 'undefined') {
        clearTimeout(this.doWhenInitializeDoneTimeout);
        this.doWhenInitializeDoneTimeout = undefined;
      }
    } else {
      if (typeof this.doWhenInitializeDoneTimeout != 'undefined') {
        clearTimeout(this.doWhenInitializeDoneTimeout);
        this.doWhenInitializeDoneTimeout = undefined;
      }
    }
  }

  doWhenProjectListLoaded(callback?) {
    if (this.projectListLoadingStatus === true) {
      if (typeof this.doWhenProjectListLoadedTimeout != 'undefined') {
        clearTimeout(this.doWhenProjectListLoadedTimeout);
        this.doWhenProjectListLoadedTimeout = undefined;
      }

      this.doWhenProjectListLoadedTimeout = setTimeout(() => {
        this.doWhenProjectListLoaded(callback);
      }, 1000);
    } else if (this.projectListLoadedStatus === true || this.projectListLoadingStatus === false) {
      if (typeof callback == 'function') {
        callback();
      }

      if (typeof this.doWhenProjectListLoadedTimeout != 'undefined') {
        clearTimeout(this.doWhenProjectListLoadedTimeout);
        this.doWhenProjectListLoadedTimeout = undefined;
      }
    } else {
      if (typeof this.doWhenProjectListLoadedTimeout != 'undefined') {
        clearTimeout(this.doWhenProjectListLoadedTimeout);
        this.doWhenProjectListLoadedTimeout = undefined;
      }
    }
  }

  initializeForm() {
    this.batchUpdateForm = this._fb.group({
      dueDate: [null],
      isUpdateDueDate: [false],
      receivedDate: [null],
      isUpdateRecDate: [false],
      extensionDate: [null],
      isUpdateExtDate: [false],
      isUpdateTemplateWildcards: [false],
      isUpdatePreviousYearPolicy: [false],
      partner: [this.resourceId],
      isUpdatePartner: [false],
      manager: [this.resourceId],
      isUpdateManager: [false],
      repeat: [''],
      isUpdateRepeat: [false],
      tags: [],
      isUpdateTags: [false],
      requireFinalReview: [false],
      isRequireFinalReview: [false],
      completeInOrder: [false],
      isCompleteInOrder: [false],
      nextDueDate: [null],
      ruleString: [''],
      TemplateWildcards: [''],
      isPreviousPeriodYearPolicy: [false],
      isAddOnly: [true]
    });

    this.batchUpdateForm.get('TemplateWildcards').disable();
    this.batchUpdateForm.valueChanges.subscribe(data => {
      this.validateForm();
    });
  }

  onShowBatchUpdateProjForm() {
    this.showBatchUpdateProjForm = true;
  }

  validateEnteredWildcards(str: string, repeat: RepeatEnum): boolean {
    switch (repeat) {
      case RepeatEnum.YEAR:
        if (this.forbiddenWildcards.YEAR.some(v => str.includes(v))) {
          this.showSwalForbiddenWildcards(this.allowedWildcards.YEAR, repeat);
          return false;
        }
        return true;
      case RepeatEnum.QUARTERLY:
        if (this.forbiddenWildcards.QUARTER.some(v => str.includes(v))) {
          this.showSwalForbiddenWildcards(this.allowedWildcards.QUARTER, repeat);
          return false;
        }
        return true;
      case RepeatEnum.MONTHLY:
      case RepeatEnum.SEMIMONTHLY:
        if (this.forbiddenWildcards.MONTH.some(v => str.includes(v))) {
          this.showSwalForbiddenWildcards(this.allowedWildcards.MONTH, repeat);
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  showSwalForbiddenWildcards(allowedWildcardsArr: string[], repeat: RepeatEnum) {
    swal.fire({
      title: this.translate.instant('Warning'),
      text: `The repeat value is ${repeat} and you can only use these wildcards: ${allowedWildcardsArr.join(
        ', '
      )}`,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonText: this.translate.instant('Ok')
    });
  }

  removeForbiddenWildcards(str: string, repeat: RepeatEnum): string {
    let word = str;
    switch (repeat) {
      case RepeatEnum.YEAR:
        this.forbiddenWildcards.YEAR.forEach(val => {
          word = this.mangoUtils.replaceAll(word, val, '');
        });
        return word;
      case RepeatEnum.QUARTERLY:
        this.forbiddenWildcards.QUARTER.forEach(val => {
          word = this.mangoUtils.replaceAll(word, val, '');
        });
        return word;
      case RepeatEnum.MONTHLY:
      case RepeatEnum.SEMIMONTHLY:
        this.forbiddenWildcards.MONTH.forEach(val => {
          word = this.mangoUtils.replaceAll(word, val, '');
        });
        return word;
      default:
        return word;
    }
  }

  onBatchUpdate() {
    let countChangedWilcards = 0;
    let countWilcardsFailed = 0;
    const formValues = this.batchUpdateForm.value;
    if (formValues.isUpdateTemplateWildcards && formValues.isUpdateRepeat) {
      const isValid = this.validateEnteredWildcards(
        formValues.TemplateWildcards,
        formValues['Repeat'] as RepeatEnum
      );
      if (!isValid) {
        return;
      }
    }
    this.selectedClientsItems.forEach(item => {
      item.IsCompleteInOrder = formValues.isCompleteInOrder
        ? formValues.completeInOrder
        : item.IsCompleteInOrder;

      item.isReviewRequired = formValues.isRequireFinalReview
        ? formValues.requireFinalReview
        : item.isReviewRequired;

      item.DueDate = formValues.isUpdateDueDate
        ? moment.utc(formValues.dueDate).format('YYYY-MM-DD')
        : item.DueDate;

      item.ExtensionDate = item.ActualDueDate = formValues.isUpdateExtDate
        ? moment.utc(formValues.extensionDate).format('YYYY-MM-DD')
        : item.ExtensionDate;

      if (formValues.isUpdateExtDate) {
        if (item.Tags?.length > 0) item.Tags.push(2); //Add Extension Tag to this project
        else item.Tags = [2]; //Add Extension Tag to this project
      }

      item.DateReceived = formValues.isUpdateRecDate
        ? moment.utc(formValues.receivedDate).format('YYYY-MM-DD')
        : item.DateReceived;

      if (item.TurnAround) {
        item.FinishDate = formValues.isUpdateRecDate
          ? moment.utc(formValues.receivedDate).add(item.TurnAround, 'days').format('YYYY-MM-DD')
          : item.FinishDate;
      }
      item.ManagerID = formValues.isUpdatePartner ? formValues.partner : item.ManagerID;

      item.UserAssignedID = formValues.isUpdateManager ? formValues.manager : item.UserAssignedID;

      item.Repeat = formValues.isUpdateRepeat ? formValues.repeat : item.Repeat;

      if (formValues.isUpdateRepeat && item.Repeat == 'custom' && item.DueDate) {
        item.DueDate = this.repeatDueDate;
        item.NextDueDate = formValues.nextDueDate;
        item.RuleString = formValues.ruleString;
      }

      if (formValues.isUpdateTags) {
        if (!formValues.isAddOnly) {
          item.Tags = formValues.tags?.map(item => Number(item));
        } else {
          if (formValues.tags && formValues.tags.length > 0) {
            item.Tags = item.Tags ? item.Tags : [];
            formValues.tags.forEach(tag => {
              if (!item.Tags.includes(Number(tag))) {
                item.Tags.push(Number(tag));
              }
            });
          }
        }
      }

      item.isReviewRequired = formValues.isRequireFinalReview
        ? formValues.requireFinalReview
        : item.isReviewRequired;

      item.TemplateWildcards = formValues.isUpdateTemplateWildcards
        ? formValues.TemplateWildcards
        : item.TemplateWildcards;

      item.isPreviousPeriodYearPolicy = formValues.isUpdatePreviousYearPolicy
        ? formValues.isPreviousPeriodYearPolicy
        : item.isPreviousPeriodYearPolicy;

      if (
        (formValues.isUpdateTemplateWildcards && formValues.TemplateWildcards) ||
        (formValues.isUpdateRepeat && item.TemplateWildcards) ||
        (formValues.isUpdateDueDate && item.TemplateWildcards) ||
        (formValues.isUpdatePreviousYearPolicy && item.TemplateWildcards)
      ) {
        if (
          item.Repeat &&
          item.Repeat !== null &&
          item.Repeat !== 'none' &&
          item.Repeat !== 'custom'
        ) {
          const newWildcards = this.removeForbiddenWildcards(item.TemplateWildcards, item.Repeat);

          if (newWildcards !== item['TemplateWildcards']) countChangedWilcards++;

          item['TemplateWildcards'] = newWildcards;

          if (item['isPreviousPeriodYearPolicy']) {
            item['TemplateName'] = newWildcards
              ? this.mangoUtils.replacePMCaretTemplate(
                  newWildcards,
                  item.DueDate,
                  item.isPreviousPeriodYearPolicy,
                  item.Repeat
                )
              : item['TemplateName'];
          } else {
            item['TemplateName'] = newWildcards
              ? this.mangoUtils.replacePMCaretTemplate(newWildcards, item.DueDate)
              : item['TemplateName'];
          }
        } else countWilcardsFailed++;
      }
    });
    this.mangoAPISrvc.showLoader(true);
    this.batchUpdateProjects(this.selectedClientsItems, countChangedWilcards, countWilcardsFailed);
  }

  batchUpdateProjects(data, countChangedWilcards: number, countWilcardsFailed: number) {
    let observableBatch = [];
    let tempData = JSON.parse(JSON.stringify(data));
    tempData.forEach((selectedItem, key) => {
      if (selectedItem['Tags'].length == 0) {
        delete selectedItem['Tags'];
      }
      observableBatch.push(selectedItem);
    });
    this.mangoAPISrvc.bulkUpdateBulkProjectHeader({ observableBatch }).subscribe(
      (result: any) => {
        this.mangoAPISrvc.showLoader(false);
        const count = result.data;

        let logdata = {};
        logdata['Action'] = 'Batch Update Project/s';
        logdata['Description'] =
          'ProjectID: ' +
          tempData
            .map(x => {
              return x.ProjectHeaderID;
            })
            .join(', ') +
          ' Client(s): ' +
          tempData
            .map(x => {
              return x.ClientName;
            })
            .join(', ');
        logdata['Table'] = '';
        if (!this.isManaging) {
          this.mangoAPISrvc.addUserLogs(logdata).subscribe(
            res => {},
            err => {
              console.log(err);
            }
          );
        }

        swal.fire({
          icon: observableBatch?.length > countWilcardsFailed && count > 0 ? 'success' : 'warning',
          title:
            observableBatch?.length > countWilcardsFailed && count > 0
              ? `${this.translate.instant('Success')}!`
              : 'Warning',
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: 'OK',
          text: `${
            observableBatch?.length > countWilcardsFailed && count > 0
              ? count + ' project(s) got updated!'
              : ''
          } ${
            countChangedWilcards > 0
              ? ' The system automatically updated the template name and template wildcards of ' +
                countChangedWilcards +
                " project(s) to remove incompatible wildcards with each project's Repeat value."
              : ''
          }${
            countWilcardsFailed > 0
              ? ' Failed to update the template name of ' +
                countWilcardsFailed +
                ' project(s) due to having Repeat value equal to None or Custom!'
              : ''
          }`
        });
        this.closeBatchFormModal();

        this.doWhenProjectListLoaded(() => {
          this.getProjectList();
        });
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.closeBatchFormModal();

        this.doWhenProjectListLoaded(() => {
          this.getProjectList();
        });
      }
    );
  }

  closeBatchFormModal() {
    this.batchUpdateForm.reset();
    this.formDirective.resetForm();
    this.batchUpdateForm.controls['isAddOnly'].setValue(true);
    this.showBatchUpdateProjForm = false;
  }

  validateForm(isChanged?: boolean) {
    var isInValidData = false;
    var istouchedData = false;
    const dueDateControl = this.batchUpdateForm.get('dueDate');
    const isUpdateDueDateControl = this.batchUpdateForm.get('isUpdateDueDate');
    const extensionDateControl = this.batchUpdateForm.get('extensionDate');
    const isUpdateExtDateControl = this.batchUpdateForm.get('isUpdateExtDate');
    const receivedDateControl = this.batchUpdateForm.get('receivedDate');
    const isUpdateRecDateControl = this.batchUpdateForm.get('isUpdateRecDate');

    const tagsControl = this.batchUpdateForm.get('tags');
    const isUpdateTagsControl = this.batchUpdateForm.get('isUpdateTags');

    const partnerControl = this.batchUpdateForm.get('partner');
    const isUpdatePartnerControl = this.batchUpdateForm.get('isUpdatePartner');

    const managerControl = this.batchUpdateForm.get('manager');
    const isUpdateManagerControl = this.batchUpdateForm.get('isUpdateManager');

    const repeatControl = this.batchUpdateForm.get('repeat');
    const isUpdateRepeatControl = this.batchUpdateForm.get('isUpdateRepeat');

    const TemplateWildcardsControl = this.batchUpdateForm.get('TemplateWildcards');
    const isUpdateTemplateWildcardsControl = this.batchUpdateForm.get('isUpdateTemplateWildcards');

    Object.keys(this.batchUpdateForm.controls).forEach(key => {
      if (this.batchUpdateForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.batchUpdateForm.get(key).dirty) {
        istouchedData = true;
      }
    });

    istouchedData = istouchedData ? istouchedData : isChanged ? isChanged : false;

    if (isUpdateDueDateControl.value && !dueDateControl.value) {
      this.isFormValid = false;
    } else if (isUpdateTemplateWildcardsControl.value && !TemplateWildcardsControl.value) {
      this.isFormValid = false;
    } else if (isUpdatePartnerControl.value && !partnerControl.value) {
      this.isFormValid = false;
    } else if (isUpdateTagsControl.value && !tagsControl.value) {
      this.isFormValid = false;
    } else if (isUpdateRepeatControl.value && !repeatControl.value) {
      this.isFormValid = false;
    } else if (isUpdateManagerControl.value && !managerControl.value) {
      this.isFormValid = false;
    } else if (isUpdateRecDateControl.value && !receivedDateControl.value) {
      this.isFormValid = false;
    } else if (isUpdateExtDateControl.value && !extensionDateControl.value) {
      this.isFormValid = false;
    } else if (!isInValidData && istouchedData) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  completeItems() {
    let self = this;
    self.searchTextStr = this.encrDecSrvc.getObject(
      'projListProjects_' + AppConstants.SearchString
    );
    event.stopPropagation();
    if (self.selectedClientsItems.length > 15) {
      swal.fire({
        icon: 'warning',
        title: `${self.translate.instant('warning')}!`,
        text: self.translate.instant('pm.only-15-records'),
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok'
      });
    } else {
      swal
        .fire({
          title: self.translate.instant('confirmation'),
          text: `${self.translate.instant('pm.complete-selected-record')}?`,
          icon: 'warning',
          showCancelButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: `${self.translate.instant('yes_complete')}!`,
          cancelButtonText: self.translate.instant('no_cancel')
        })
        .then(result => {
          if (result.isConfirmed) {
            for (let i = 0; i < self.selectedClientsItems.length; i++) {
              const selectedOneObj = self.selectedClientsItems[i];

              (function (index, selectedOneObj, size) {
                setTimeout(() => {
                  self.radioClicked(selectedOneObj, size, true);
                }, i * 10);
                if (index == size) {
                  setTimeout(() => {
                    swal
                      .fire({
                        icon: 'success',
                        title: `${self.translate.instant('Information')}!`,
                        text: `${self.translate.instant('Projects-completed-successfully')}!`,
                        showConfirmButton: true,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        confirmButtonText: 'Ok'
                      })
                      .then(result => {
                        if (result.value) {
                          let logdata = {};
                          logdata['Action'] = 'Completed Project/s';
                          logdata['Description'] =
                            'ProjectID: ' +
                            self.selectedClientsItems
                              .map(x => {
                                return x.ProjectHeaderID;
                              })
                              .join(', ') +
                            ' Client(s): ' +
                            self.selectedClientsItems
                              .map(x => {
                                return x.ClientName;
                              })
                              .join(', ');
                          logdata['Table'] = '';
                          if (!self.isManaging) {
                            self.mangoAPISrvc.addUserLogs(logdata).subscribe(
                              res => {},
                              err => {
                                console.log(err);
                              }
                            );
                          }
                          for (
                            let deleteIndex = 0;
                            deleteIndex < self.selectedClientsItems.length;
                            deleteIndex++
                          ) {
                            const element =
                              self.selectedClientsItems[deleteIndex]['ProjectHeaderID'];
                            let index = self.clientsDataSource.findIndex(
                              item => item.ProjectHeaderID == element
                            );
                            if (self.clientsDataSource[index].Status == 'Completed')
                              self.clientsDataSource.splice(index, 1);
                          }
                          self.filterDataTable();
                          self.selectedClientsItems = [];
                        }
                      });
                  }, 1000);
                }
              })(i, selectedOneObj, self.selectedClientsItems.length - 1);
            }
          }
        });
    }
  }

  deleteItems() {
    let self = this;
    event.stopPropagation();
    swal
      .fire({
        title: self.translate.instant('confirmation'),
        text: self.translate.instant('pm.do-you-want-to-delete-this-record'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `${self.translate.instant('yes_delete')}!`,
        cancelButtonText: self.translate.instant('no_delete')
      })
      .then(result => {
        if (result.value) {
          self.bulkDelete();
        }
      });
  }

  bulkDelete() {
    let observableBatch = [];
    let parent = this;
    parent.searchTextStr = this.encrDecSrvc.getObject(
      'projListProjects_' + AppConstants.SearchString
    );
    parent.selectedClientsItems.forEach((selectedItem, key) => {
      observableBatch.push(selectedItem.ProjectHeaderID);
    });
    var obj = {};
    obj['selectedDeletedItems'] = observableBatch.toString();
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.deleteBulkProjectHeader(obj).subscribe(
      Item => {
        for (let deleteIndex = 0; deleteIndex < observableBatch.length; deleteIndex++) {
          let logdata = {};
          const element = observableBatch[deleteIndex];
          let index = parent.clientsDataSource.findIndex(item => item.ProjectHeaderID == element);
          let data = parent.clientsDataSource.filter(item => item.ProjectHeaderID == element)[0];

          logdata['Action'] = 'Delete Project';
          logdata['Description'] =
            'ProjectID: ' +
            observableBatch[deleteIndex] +
            ' - ' +
            data.TemplateName +
            ` --${data['ClientName']}`;
          logdata['Table'] = '';
          let isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
          if (!isManaging) {
            this.mangoAPISrvc.addUserLogs(logdata).subscribe(
              res => {
                console.log(res);
              },
              err => {
                console.log(err);
              }
            );
          }

          parent.clientsDataSource.splice(index, 1);
        }
        parent.searchTextStr = '';
        this.encrDecSrvc.addObject('projListProjects_' + AppConstants.SearchString, '');
        parent.filterDataTable();
        parent.selectedClientsItems = [];
        parent.mangoAPISrvc.notify('success', 'Deleted!', 'Success fully deleted');
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getAllStaffList() {
    let parent = this;
    let item = [];
    if (parent.auth.isAllowAccess(23)) {
      item = parent.encrDecSrvc.getObject(AppConstants.allStaffList);
    } else {
      item = parent.encrDecSrvc.getObject(AppConstants.staffList);
      item = item?.filter(
        staff => staff?.StaffID == parent.encrDecSrvc.getObject(AppConstants.staffID)
      );
    }
    this.AllStaffsTypes.unshift({ label: 'Unassigned', value: -1, StaffID: null, Email: null });
    this.staffListBatchUpdate.unshift({
      label: 'Unassigned',
      value: -1,
      StaffID: null,
      Email: null
    });
    for (let i = 0; i < item.length; ++i) {
      if (item[i].Inactive) continue;

      let obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      obj['Email'] = item[i]['Email'];
      this.AllStaffsTypes.push(obj);
      this.staffListBatchUpdate.push(obj);
    }
  }

  checkDueDate(obj) {
    let dueDate = obj.ExtensionDate || obj.DueDate;
    return dueDate && dueDate < this.todayDate;
  }

  getToolTip(obj) {
    if (obj.ExtensionDate) return 'Due Date : ' + moment.utc(obj.DueDate).format('MM/DD/YYYY');

    if (obj.SortableDueDate)
      return 'Next Due Date : ' + moment.utc(obj.SortableDueDate).format('MM/DD/YYYY');

    return obj.NextDueDate
      ? 'Next Due Date : ' + moment.utc(obj.NextDueDate).format('MM/DD/YYYY')
      : '';
  }

  findTagByValue(tagValue, property) {
    let temp = this.tagsList.filter(item => item['value'] == tagValue);
    return temp[0] ? temp[0][property] : '';
  }

  fetchDdmSettings() {
    let parent = this;
    parent.companyTags = parent.encrDecSrvc.getObject(AppConstants.companyTags);
    if (!parent.companyTags) {
      // parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.fetchDDMSettings(parent.companyId).subscribe(
        (res: any) => {
          if (res.length > 0) {
            parent.companyTags = res[0];
          } else {
            parent.companyTags = {
              CompanyID: '',
              IsEmailProjectAssigned: false,
              IsEmailTaskAssigned: false,
              IsEmailTaskReady: false,
              IsManagerProjectInProgress: false,
              IsManagerReadyReview: false,
              IsProjectRejected: false,
              Tag1: 'Available for Work',
              Tag2: 'Extended',
              Tag3: 'Urgent',
              Tag4: 'Missing Information',
              Tag5: 'Waiting on Client',
              Tag6: 'Notice',
              Tag7: 'Telephone Call',
              Tag8: 'Tag 8',
              Tag9: 'Tag 9 ',
              ddmSettingsID: null,
              DefaultUserID: null,
              DefaultManagerID: null,
              TagArray2: [],
              isCapacityPlanningEnabled: false,
              isTaskBudgetEnabled: false
            };
          }

          parent.encrDecSrvc.addObject(AppConstants.companyTags, parent.companyTags);
          // parent.mangoAPISrvc.showLoader(false);
        },
        error => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
    parent.ddmSettingsObj = parent.companyTags;
    if (parent.ddmSettingsObj && Object.keys(parent.ddmSettingsObj).length > 0) {
      let allTags = parent.ddmSettingsObj.TagArray2;
      allTags?.map((item, i) => {
        if (item[0] != '') {
          let obj = {};
          obj['label'] = item[0];
          obj['value'] = (i + 1).toString();
          obj['color'] = item[1];
          obj['textColor'] =
            item[1] != ''
              ? this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb(item[1]))
              : this.mangoUtils.setTextColor(this.mangoUtils.hexToRgb('#ffffff'));
          parent.tagsList.push(obj);
          parent.tagsListForFilters.push(obj);
        }
      });
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      let query = event.query;
      for (let i = 0; i < this.clientListDatasource.length; i++) {
        let client = this.clientListDatasource[i];
        if (
          client['ClientName'].toLowerCase().indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] == false
        ) {
          filtered.push(client);
        } else if (
          client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] != true
        ) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  fetchClients() {
    if (this.clientListDatasource.length == 0) {
      let list = this.encrDecSrvc.clientList;
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        this.clientListDatasource.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  handleSelectClick(event) {
    this.selClient = event;
  }

  getProjectNames(cId) {
    // this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getProjectNames(cId).subscribe(
      (data: any) => {
        for (let i = 0; i < data.length; ++i) {
          let obj = {};
          obj['label'] = data[i]['TemplateName'];
          obj['value'] = data[i]['TemplateName'];
          this.projectsList.push(obj);
        }
        // this.mangoAPISrvc.showLoader(false);
      },
      error => this.mangoAPISrvc.showLoader(false)
    );
  }

  redirectToProjectDetails(item) {
    this.saveCurrentSearchValue(this.searchTextStr);
    this.encrDecSrvc.addObject(AppConstants.ddmProjectDetails, JSON.stringify(item));
    this.router.navigate([`/project-management/${item.ProjectHeaderID}/projectDetails`]);
  }

  saveCurrentSearchValue(value: any) {
    localStorage.setItem('searchValue', value);
  }

  displayNotAllowedSwal() {
    swal.fire({
      icon: 'warning',
      title: `${this.translate.instant('Warning')}!`,
      showCancelButton: false,
      allowEscapeKey: true,
      allowEnterKey: true,
      confirmButtonText: 'OK',
      text: `You are not allowed to complete a project on the project list!`
    });
  }

  radioClicked(item, size, isMultiple) {
    if (!this.auth.isAllowAccess(22)) {
      this.displayNotAllowedSwal();
      return;
    }
    if (
      !isMultiple &&
      item.TotalTasks !== 0 &&
      item.TotalTasks !== item.TasksCompleted &&
      item.Status !== 'Ready for Review'
    ) {
      swal
        .fire({
          title: this.translate.instant('confirmation'),
          text: `${this.translate.instant('pm.complete-selected-record-inc-tasks')}`,
          icon: 'warning',
          showCancelButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: `${this.translate.instant('complete_project')}!`,
          cancelButtonText: this.translate.instant('no_cancel')
        })
        .then(result => {
          if (result.value) {
            this.continueCompleteProject(item, size, isMultiple);
          }
        });
    } else {
      this.continueCompleteProject(item, size, isMultiple);
    }
  }

  continueCompleteProject(item, size, isMultiple) {
    let parent = this;
    item.isReviewRequired = item.isReviewRequired ? item.isReviewRequired : false;
    if (item.Status == 'Ready for Review') {
      const confirm = () => {
        item.Status = 'Completed';
        item.statusClass = 'completed';
        item.isCompleted = true;
        item.CompletionDate = moment().format('MM/DD/YYYY HH:mm:ss');
        delete item.completedcount;
        delete item.detailcount;
        // delete item.ClientName;
        // delete item.StaffName;
        item['TasksCompleted'] = item['TotalTasks'];
        item['TotalTasks'] = item['TotalTasks'];
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.updateProjectHeader(item.ProjectHeaderID, item).subscribe(
          data => {
            parent.mangoAPISrvc.fetchprojectDetailsByHeaderId(item.ProjectHeaderID).subscribe(
              (responseData: any) => {
                const taskList = responseData.taskList;
                const data = taskList;

                if (data.length > 0) {
                  data.map(function (lineObj, index) {
                    //@NOTE: Should not override tasks' Complete Date that have already been completed
                    if (!lineObj.IsCompleted) {
                      lineObj.CompletedByStaffID = parent.staffID;
                      lineObj.IsCompleted = true;
                      lineObj.CompletionDate = moment().format('MM/DD/YYYY HH:mm:ss');
                    }
                  });

                  let logdata = {};
                  logdata['Action'] = 'Completed Project';
                  logdata['Description'] =
                    'ProjectID: ' + item['ProjectHeaderID'] + ` --${item['ClientName']}`;
                  logdata['Table'] = '';
                  if (!parent.isManaging) {
                    parent.mangoAPISrvc.addUserLogs(logdata).subscribe(
                      res => {},
                      err => {
                        console.log(err);
                      }
                    );
                  }

                  parent.saveAllProjectDetails(data, item, size);
                } else {
                  parent.saveAllProjectDetails(data, item, size);
                  parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);

                  let index = parent.clientsDataSource.findIndex(
                    loopitem => loopitem.ProjectHeaderID == item['ProjectHeaderID']
                  );
                  if (parent.clientsDataSource[index].Status == 'Completed')
                    parent.clientsDataSource.splice(index, 1);
                  parent.filterDataTable();
                }
              },
              err => {
                parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
              }
            );
            parent.mangoAPISrvc.showLoader(false);
          },
          err => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      };

      //skip confirmation if multiple projects have been selected
      if (isMultiple) confirm();
      else {
        swal
          .fire({
            text: parent.translate.instant('pm.complete-selected-record-ready-for-review'),
            icon: 'question',
            showCancelButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            confirmButtonText: parent.translate.instant('yes_complete'),
            cancelButtonText: parent.translate.instant('no_cancel')
          })
          .then(result => {
            if (result.isConfirmed) {
              confirm();
            } else {
              parent.selectedValue = null;
            }
          });
      }
    } else if (item.isReviewRequired == false) {
      item.Status = 'Completed';
      item.statusClass = 'completed';
      item.isCompleted = true;
      item.CompletionDate = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
      delete item.completedcount;
      delete item.detailcount;
      // delete item.ClientName;
      // delete item.StaffName;
      item['TasksCompleted'] = item['TotalTasks'];
      item['TotalTasks'] = item['TotalTasks'];
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.updateProjectHeader(item.ProjectHeaderID, item).subscribe(
        data => {
          //this API call is not returning anything
          parent.mangoAPISrvc.fetchprojectDetailsByHeaderId(item.ProjectHeaderID).subscribe(
            (responseData: any) => {
              const taskList = responseData.taskList;
              const data = taskList;

              if (data.length > 0) {
                data.map(function (lineObj, index) {
                  //@NOTE: Should not override tasks' Complete Date that have already been completed
                  if (!lineObj.IsCompleted) {
                    lineObj.CompletedByStaffID = parent.staffID;
                    lineObj.IsCompleted = true;
                    lineObj.CompletionDate = moment().format('MM/DD/YYYY HH:mm:ss');
                  }
                });

                let logdata = {};
                logdata['Action'] = 'Completed Project';
                logdata['Description'] =
                  'ProjectID: ' + item['ProjectHeaderID'] + ` --${item['ClientName']}`;
                logdata['Table'] = '';
                if (!parent.isManaging) {
                  parent.mangoAPISrvc.addUserLogs(logdata).subscribe(
                    res => {},
                    err => {
                      console.log(err);
                    }
                  );
                }

                parent.saveAllProjectDetails(data, item, size);
              } else {
                parent.saveAllProjectDetails(data, item, size);
                parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);

                let index = parent.clientsDataSource.findIndex(
                  loopitem => loopitem.ProjectHeaderID == item['ProjectHeaderID']
                );
                if (parent.clientsDataSource[index].Status == 'Completed')
                  parent.clientsDataSource.splice(index, 1);
                parent.filterDataTable();
              }
              parent.mangoAPISrvc.showLoader(false);
            },
            error => {
              parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
              parent.mangoAPISrvc.showLoader(false);
            }
          );
        },
        error => {
          parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      // if (isMultiple) {
      parent.markAllCompleted(item, size, true);
      // } else {
      //   swal
      //     .fire({
      //       text: parent.translate.instant('pm.do-you-want-to-complete-this-project'),
      //       icon: "question",
      //       showCancelButton: true,
      //       allowEscapeKey: false,
      //       allowOutsideClick: false,
      //       confirmButtonText: parent.translate.instant('complete-it'),
      //       cancelButtonText: parent.translate.instant('no_cancel'),
      //     })
      //     .then((result) => {
      //       if (result.value) {
      //         parent.markAllCompleted(item, size, true);
      //       } else {
      //         parent.selectedValue = null;
      //       }
      //     });
      // }
    }
  }

  markAllCompleted(item, size, isMultiple) {
    let parent = this;
    item.detailcount = item.detailcount ? parseInt(item.detailcount) : 0;
    item.completedcount = item.completedcount ? parseInt(item.completedcount) : 0;
    if (isMultiple) {
      item.Status = 'Completed';
      item.statusClass = 'completed';
      item.isCompleted = true;
      item.CompletionDate = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
      item['TasksCompleted'] = item['TotalTasks'];
      item['TotalTasks'] = item['TotalTasks'];
      delete item.completedcount;
      delete item.detailcount;
      // delete item.ClientName;
      // delete item.StaffName;
    } else {
      item.Status = 'Ready for Review';
      item.statusClass = 'review';
      item.isCompleted = false;
    }
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateProjectHeader(item.ProjectHeaderID, item).subscribe(
      (data: any) => {
        parent.mangoAPISrvc.fetchprojectDetailsByHeaderId(item.ProjectHeaderID).subscribe(
          (responseData: any) => {
            const taskList = responseData.taskList;
            const data = taskList;

            if (data.length > 0) {
              data.map(function (lineObj, index) {
                //@NOTE: Should not override tasks' Complete Date that have already been completed
                if (!lineObj.IsCompleted) {
                  lineObj.IsCompleted = true;
                  lineObj.CompletionDate = moment().format('MM/DD/YYYY HH:mm:ss');
                }
              });

              let logdata = {};
              logdata['Action'] = 'Completed Project';
              logdata['Description'] =
                'ProjectID: ' + item['ProjectHeaderID'] + ` --${item['ClientName']}`;
              logdata['Table'] = '';
              if (!parent.isManaging) {
                parent.mangoAPISrvc.addUserLogs(logdata).subscribe(
                  res => {},
                  err => {
                    console.log(err);
                  }
                );
              }

              parent.saveAllProjectDetails(data, item, size);
            } else {
              parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);

              this.doWhenProjectListLoaded(() => {
                this.getProjectList();
              });
            }
            parent.mangoAPISrvc.showLoader(false);
          },
          error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      },
      error => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  saveAllProjectDetails(dataList, rowObj, size) {
    let parent = this;
    if (dataList.length > 0) {
      parent.batchSaveAllTasks(dataList).subscribe(data => {
        if (
          rowObj.Repeat != null &&
          rowObj.Repeat != '' &&
          rowObj.Repeat != 'none' &&
          rowObj.Status == 'Completed'
        ) {
          parent.createAllProjectDetails(dataList, rowObj);
        } else {
          let index = parent.clientsDataSource.findIndex(
            item => item.ProjectHeaderID == rowObj['ProjectHeaderID']
          );
          if (parent.clientsDataSource[index].Status == 'Completed')
            parent.clientsDataSource.splice(index, 1);
          parent.filterDataTable();
        }
        parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
      });
    } else {
      if (
        rowObj.Repeat != null &&
        rowObj.Repeat != '' &&
        rowObj.Repeat != 'none' &&
        rowObj.Status == 'Completed'
      ) {
        parent.createAllProjectDetails(dataList, rowObj);
      } else {
        let index = parent.clientsDataSource.findIndex(
          item => item.ProjectHeaderID == rowObj['ProjectHeaderID']
        );
        if (parent.clientsDataSource[index].Status == 'Completed')
          parent.clientsDataSource.splice(index, 1);
        parent.filterDataTable();
      }
      parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
    }
  }

  calculateTaskRepeatData(dueDate, repeat) {
    let newDate = null;
    dueDate = moment(dueDate).format('DD-MM-YYYY HH:mm:ss');
    if (repeat != 'none') {
      if (repeat == 'year') {
        newDate = moment.utc(dueDate, 'DD-MM-YYYY').add(1, 'years').utc().toDate();
      } else if (repeat == 'quarterly') {
        newDate = moment.utc(dueDate, 'DD-MM-YYYY').add(3, 'months').utc().toDate();
      } else if (repeat == 'monthly') {
        newDate = moment.utc(dueDate, 'DD-MM-YYYY').add(1, 'months').utc().toDate();
      } else if (repeat == 'onetime') {
        newDate = moment.utc(dueDate, 'DD-MM-YYYY').add(1, 'days').utc().toDate();
      } else if (repeat == 'week') {
        newDate = moment.utc(dueDate, 'DD-MM-YYYY').add(7, 'days').toDate();
      } else if (repeat == 'semi-weekly') {
        newDate = moment.utc(dueDate, 'DD-MM-YYYY').add(14, 'days').toDate();
      } else if (repeat == 'semi-monthly') {
        newDate = moment.utc(dueDate, 'DD-MM-YYYY').add(15, 'days').utc().toDate();
      } else if (repeat == 'daily') {
        newDate = moment.utc(dueDate, 'DD-MM-YYYY').add(1, 'days').toDate();
      }
    }

    return newDate;
  }

  calculateRepeatData(item) {
    let newDate = null;
    delete item.dueDate;
    item['DueDate'] = item.NextDueDate;
    item['ActualDueDate'] = item['DueDate'];
    if (item.Repeat != 'none' && item.Repeat != null && item.Repeat != '') {
      if (item.Repeat == 'year') {
        newDate = moment.utc(item['DueDate']).add(1, 'years').utc().toDate();
      } else if (item.Repeat == 'quarterly') {
        newDate = moment.utc(item['DueDate']).add(3, 'months').utc().toDate();
      } else if (item.Repeat == 'monthly') {
        newDate = moment.utc(item['DueDate']).add(1, 'months').utc().toDate();
      } else if (item.Repeat == 'onetime') {
        newDate = moment.utc(item['DueDate']).add(1, 'days').utc().toDate();
      } else if (item.Repeat == 'week') {
        newDate = moment.utc(item['DueDate']).add(7, 'days').toDate();
      } else if (item.Repeat == 'daily') {
        newDate = moment.utc(item['DueDate']).add(1, 'days').toDate();
      } else if (item.Repeat == 'semi-weekly') {
        newDate = moment.utc(item['DueDate']).add(14, 'days').toDate();
      } else if (item.Repeat == 'semi-monthly') {
        newDate = moment.utc(item['DueDate']).add(15, 'days').utc().toDate();
      }
    }
    item['NextDueDate'] = newDate;
    item['CompletionDate'] = null;
    item['ExtensionDate'] = null;
    item['DateReceived'] = null;
    item['isCompleted'] = false;
    item['Status'] = 'Pending';
    item['statusClass'] = 'pending';
    item['Tags'] = null;
  }

  generateRules(date: any, currentRuleStr: string, isNextDueDate?: boolean) {
    let ruledateSource = null;
    let ruleTwodateSource = null;
    let rule = null;
    if (currentRuleStr == '' || !currentRuleStr) {
      ruledateSource = null;
      ruleTwodateSource = null;
      return false;
    }
    const dateStart: any = date ? moment(date).format('YYYYMMDDThhmmss') : null;

    rule = RRule.fromString(dateStart ? `${currentRuleStr};DTSTART=${dateStart}` : currentRuleStr);
    rule.noCache = true;
    if (!isNextDueDate || this.compareDates(date, rule.all()[0])) {
      ruleTwodateSource = rule.all()[1] ? this.convertUTCDate(rule.all()[1]) : null;
      ruleTwodateSource = ruleTwodateSource
        ? this.getNearestWeekday(ruleTwodateSource, currentRuleStr)
        : null;
    } else {
      ruleTwodateSource = rule.all()[0] ? this.convertUTCDate(rule.all()[0]) : null;
      ruleTwodateSource = ruleTwodateSource
        ? this.getNearestWeekday(ruleTwodateSource, currentRuleStr)
        : null;
    }

    return ruleTwodateSource ? new Date(ruleTwodateSource) : null;
  }

  compareDates(date1, date2) {
    return moment(date1).format('YYYY/MM/DD') == moment(date2).format('YYYY/MM/DD');
  }

  getNearestWeekday(dateString: string, currentRuleStr: string) {
    const dateMoment = moment(dateString?.substring(0, 8));
    if (
      (currentRuleStr?.toLowerCase()?.indexOf('freq=yearly') == -1 &&
        currentRuleStr?.toLowerCase()?.indexOf('freq=monthly') == -1) ||
      currentRuleStr?.toLowerCase()?.indexOf('byday=') > -1
    )
      return dateMoment.format('YYYY/MM/DD');

    if (dateMoment.format('dddd') === 'Sunday' || dateMoment.format('dddd') === 'Saturday') {
      dateMoment.add(1, 'days');
      return this.getNearestWeekday(dateMoment.format('YYYYMMDD'), currentRuleStr);
    } else return dateMoment.format('YYYY/MM/DD');
  }

  convertUTCDate(date) {
    var check = moment(date, 'YYYY/MM/DD');
    var month = parseInt(check.format('M'));
    var day = parseInt(check.format('D'));
    var year = parseInt(check.format('YYYY'));
    return new RRule({
      dtstart: new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
    })
      .toString()
      .split('DTSTART:')[1];
  }

  createAllProjectDetails(dataList, rowObj) {
    let parent = this;
    // create project Header and project details
    const oldProjectHeaderID = rowObj['ProjectHeaderID'];
    delete rowObj['ProjectHeaderID'];
    rowObj['PreviousDueDate'] = rowObj['DueDate'];

    rowObj['NextDueDateForTemplate'] = rowObj.NextDueDate;
    parent.calculateRepeatData(rowObj);

    if (
      rowObj.Repeat !== null &&
      rowObj.Repeat !== '' &&
      rowObj.Repeat !== 'none' &&
      rowObj.Repeat !== 'custom'
    ) {
      if (rowObj['isPreviousPeriodYearPolicy']) {
        rowObj['TemplateName'] =
          rowObj['TemplateWildcards'] && rowObj.PreviousDueDate
            ? parent.mangoUtils.replacePMCaretTemplate(
                rowObj['TemplateWildcards'],
                rowObj.DueDate,
                rowObj.isPreviousPeriodYearPolicy,
                rowObj.Repeat
              )
            : rowObj['TemplateName'];
      } else {
        rowObj['TemplateName'] =
          rowObj['TemplateWildcards'] && rowObj.NextDueDateForTemplate
            ? parent.mangoUtils.replacePMCaretTemplate(
                rowObj['TemplateWildcards'],
                rowObj.NextDueDateForTemplate
              )
            : rowObj['TemplateName'];
      }
    }

    rowObj['TasksCompleted'] = 0;
    rowObj['FinishDate'] = null;
    rowObj['TotalNotes'] = 0;
    rowObj['TotalTasks'] = rowObj['TotalTasks'];

    if (rowObj.Repeat == 'custom' && rowObj.RuleString) {
      rowObj['NextDueDate'] = parent.generateRules(rowObj['DueDate'], rowObj.RuleString, true);

      if (!rowObj['NextDueDate'] || !rowObj['DueDate']) {
        rowObj['NextDueDate'] = null;
        rowObj['Repeat'] = null;
        rowObj['RuleString'] = null;
      }
    }
    rowObj['ExtensionDate'] = null;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.createProjectHeader(rowObj).subscribe(
      (Item: any) => {
        parent.fetchData();
        if (!dataList || dataList.length == 0) {
          parent.mangoAPISrvc
            .copyBudgetsToNewProject({
              oldProjectHeaderID: oldProjectHeaderID,
              newProjectHeaderID: Item.data['ProjectHeaderID'],
              companyID: parent.companyId,
              newProjectYear: moment.utc(Item.data['DueDate']).format('YYYY')
            })
            .subscribe(
              result => {},
              err => {}
            );
        }
        rowObj['ProjectHeaderID'] = Item.data['ProjectHeaderID'];
        //todo - call api to copy budget to new ProjectHeaderID
        if (dataList.length == 0) {
          let index = parent.clientsDataSource.findIndex(
            loopitem => loopitem.ProjectHeaderID == rowObj['ProjectHeaderID']
          );
          if (parent.clientsDataSource[index].Status == 'Completed')
            parent.clientsDataSource.splice(index, 1);
          parent.filterDataTable();
        }
        parent.batchCreateAllTasks(dataList, rowObj).subscribe(data => {
          if (dataList?.length > 0) {
            parent.mangoAPISrvc
              .copyBudgetsToNewProject({
                oldProjectHeaderID: oldProjectHeaderID,
                newProjectHeaderID: Item.data['ProjectHeaderID'],
                companyID: parent.companyId,
                newProjectYear: moment.utc(Item.data['DueDate']).format('YYYY')
              })
              .subscribe(
                result => {},
                err => {}
              );
          }

          rowObj['TotalTasks'] = data.length;

          let index = parent.clientsDataSource.findIndex(
            loopitem => loopitem.ProjectHeaderID == rowObj['ProjectHeaderID']
          );

          if (parent.clientsDataSource[index].Status == 'Completed')
            parent.clientsDataSource.splice(index, 1);
          parent.filterDataTable();
          parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        });
        parent.mangoAPISrvc.showLoader(false);
      },
      error => parent.mangoAPISrvc.showLoader(false)
    );
  }

  batchCreateAllTasks(dataList, rowObj) {
    let observableBatch = [];
    let parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    const repeat = rowObj['Repeat'] ? rowObj['Repeat'] : null;
    dataList.forEach((selectedItem, key) => {
      if (selectedItem['isReviewTask']) return;
      if (selectedItem['IsRepeatTask']) {
        selectedItem['DueDate'] = selectedItem['DueDate']
          ? parent.calculateTaskRepeatData(selectedItem['DueDate'], repeat)
          : parent.calculateTaskRepeatData(moment().format('MM/DD/YYYY HH:mm:ss'), repeat);
      } else {
        selectedItem['DueDate'] = null;
      }

      if (selectedItem?.TaskRow !== 1) selectedItem['IsTaskReady'] = false;
      else selectedItem['IsTaskReady'] = true;

      selectedItem['IsRepeatTask'] = false;
      selectedItem['IsCompleted'] = false;
      selectedItem['CompletionDate'] = null;
      selectedItem['CompanyID'] = rowObj.CompanyID;
      selectedItem['ClientID'] = rowObj.ClientID;
      selectedItem['ProjectHeaderID'] = rowObj.ProjectHeaderID;
      // selectedItem["DueDate"] = null;
      selectedItem['IsTaskAssignedSent'] = false;
      delete selectedItem.ProjectDetailsID;

      observableBatch.push(
        parent.http.post(`${environment.API_URL}/ddm/projectDetails/create`, selectedItem, {
          headers: headers,
          withCredentials: true
        })
        // .map((res: Response) => res.json())
      );
    });
    return forkJoin(observableBatch);
  }

  batchSaveAllTasks(data) {
    let observableBatch = [];
    let parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    data.forEach((selectedItem, key) => {
      observableBatch.push(
        parent.http.put(
          `${environment.API_URL}/ddm/projectDetails/update/${selectedItem['ProjectDetailsID']}`,
          selectedItem,
          { headers: headers, withCredentials: true }
        )
        // .map((res: Response) => res.json())
      );
    });
    return forkJoin(observableBatch);
  }

  async generateDataForExport(reportType: string) {
    /**
     * This function is to modify the data to display all the necessary details, which cannot just be access via key-value pair.
     * e.g. "Tags"
     * Tags is an array of "ids/numbers" which gets the label at "findTagByValue" function
     */

    if (reportType === 'all' && this.pdfReportProjectList.length === 0) {
      if (this.listOptionsFilterSelected.length > 0) {
        this.isExport = true;
        await this.getProjectList(true);
      } else {
        await this.getAllProjectListReport();
      } // Await the result of getProjectList
    }

    let source;
    if (reportType === 'current') {
      source = this.filteredData?.length > 0 ? this.filteredData : this.clientsDataSource;
    } else if (reportType === 'all') {
      source = this.pdfReportProjectList;
    }
    if (!this.isCSVExportSelected) {
      source.forEach(value => {
        if (value['TaskProgress'] === 'null/null') {
          value['TaskProgress'] = '0/0';
        }
        value['TasksCompleted'] = value['TasksCompleted'] ?? '0';
        value['TotalTasks'] = value['TotalTasks'] ?? '0';
        value['TaskProgress'] = `${value['TasksCompleted'] + '/' + value['TotalTasks']}`;
      });
    }
    // Modify the selectedColFields array to include 'Assigned' field
    const selectedColFields = this.selectedColumns.map(col => col.field);
    // selectedColFields.unshift('TemplateName');
    // selectedColFields.unshift('ClientName');
    // // Add 'Assigned' field to the selected columns if not already included
    // if (!selectedColFields.includes('Assigned')) {
    //   selectedColFields.unshift('Assigned');
    //   }

    const filteredArray = [];
    source.map(obj => {
      const filteredObj = {};
      selectedColFields.map(col => {
        if (Object.keys(obj).includes(col)) {
          filteredObj[col] = obj[col];
        }
      });
      filteredArray.push(filteredObj);
    });

    const body = filteredArray.map(data => {
      const dataTags = data.Tags || [];
      const tags = dataTags.map(tag => this.findTagByValue(tag, 'label'));

      let dueDate = '-';

      if (data.compareDueDate !== null) {
        dueDate = data.compareExtensionDate ? data.compareExtensionDate : data.compareDueDate;
      }

      return {
        ...data,
        Tags: tags.join(', '),
        dueDate
      };
    });
    this.pdfReportProjectList = [];
    this.mangoAPISrvc.showLoader(false);
    return body;
  }

  async exportExcel() {
    const selectedColFields = this.selectedColumns.map(col => col.header);
    selectedColFields.unshift('Project');
    selectedColFields.unshift('Client Name');
    const data = await this.generateDataForExport('all');
    const worksheet = xlsx.utils.json_to_sheet(data);
    const range = xlsx.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.r; C <= range.e.r; ++C) {
      const address = xlsx.utils.encode_col(C) + '1'; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = selectedColFields[C]; // Change column headers to selectedColFields's header value
    }
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Projects');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  async exportPdf(reportType: string) {
    this.isCSVExportSelected = false;
    const doc: any = new jsPDF('l', 'pt');

    // var columns = [
    //   { title: 'Client', dataKey: 'ClientName' },
    //   { title: 'Project', dataKey: 'TemplateName' },
    //   { title: 'Tags', dataKey: 'Tags' },
    //   { title: 'Tasks', dataKey: 'TaskProgress' },
    //   { title: 'Memo', dataKey: 'ProjectMemo' },
    //   { title: 'In Date', dataKey: 'DateReceived' },
    //   { title: 'Due Date', dataKey: 'SortableDueDate' },
    //   { title: 'Status', dataKey: 'Status' },

    //   { title: 'Next Task', dataKey: 'TaskDescription' },
    //   { title: 'Assigned', dataKey: 'StaffNames' },
    //   { title: 'Tasks', dataKey: 'Tasks' },
    //   { title: 'Completed Tasks', dataKey: 'TasksCompleted' },
    //   { title: 'Total Tasks', dataKey: 'TotalTasks' },
    //   { title: 'Notes', dataKey: 'TotalNotes' },
    //   { title: 'Out Date', dataKey: 'FinishDate' },
    //   { title: 'Completion', dataKey: 'CompletionDate' },
    //   { title: 'Repeat', dataKey: 'Repeat' },
    //   { title: 'Manager', dataKey: 'username' },
    //   { title: 'Partner', dataKey: 'managername' },
    //   { title: 'Company Location', dataKey: 'CompanyLocation' }
    // ]
    this.selectedColumns.unshift({
      field: 'TemplateName',
      header: this.translate.instant('project'),
      rowClass: 'width-9p p-text-left'
    });
    this.selectedColumns.unshift({
      field: 'ClientName',
      header: this.translate.instant('Client'),
      rowClass: 'width-9p p-text-left'
    });

    let columns = this.selectedColumns.map(col => {
      return { title: col.header, dataKey: col.field };
    });
    const body = await this.generateDataForExport(reportType);
    doc.autoTable(columns, body);
    doc.save(`Projects_export_${new Date().getTime()}.pdf`);

    let notIncluded = ['ClientName', 'TemplateName'];
    this.selectedColumns = this.selectedColumns.filter(
      item => !notIncluded.includes(item['field'])
    );
  }

  getSortValues() {
    if (!this.sortField) return '';

    const getColumn = field => {
      return this.selectedColumns.find(x => x.field == field)?.header ?? field;
    };

    return `Sorted by ${this.sortField
      ?.map(x => {
        return `${getColumn(x.field)} ${
          x.order == 1
            ? '<i class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></i>'
            : '<i class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"></i>'
        }`;
      })
      .join(', ')}`;
  }

  async exportCSVFile(reportType: string) {
    this.isCSVExportSelected = true;
    this.mangoAPISrvc.showLoader(true);
    try {
      let originalData = this.dt.value.map(value => {
        if (value['TaskProgress'] === 'null/null') {
          value['TaskProgress'] = '0/0';
        }
        value['TasksCompleted'] = value['TasksCompleted'] ?? '0';
        value['TotalTasks'] = value['TotalTasks'] ?? '0';
        return {
          ...value,
          TaskProgress: `="${value['TasksCompleted'] + '/' + value['TotalTasks']}"`
        };
      });

      if (reportType === 'all' /*&& this.pdfReportProjectList.length === 0*/) {
        try {
          if (this.listOptionsFilterSelected.length > 0) {
            await this.getProjectList(true);
          } else {
            await this.getAllProjectListReport();
          }
        } catch (error) {
          console.error('Error fetching project list:', error);
        }
      }

      const updatedData = reportType === 'all' ? this.pdfReportProjectList : originalData;
      this.updateColumns();
      this.addGroupDescriptionValues();

      setTimeout(() => {
        this.finalizeCSVExport(updatedData, originalData);
      }, 2000);
    } catch (error) {
      console.error('Error during CSV export:', error);
      this.mangoAPISrvc.showLoader(false);
    }
  }

  private updateColumns() {
    this.selectedColumns.push({
      field: 'TagDescriptions',
      header: 'TagDescriptions',
      rowClass: 'width-9p p-text-left'
    });

    this.copySelectedColunms = this.selectedColumns;

    const seen = new Set();
    const filteredArray = this.selectedColumns.filter(item => {
      if (item.field == 'TaskDescription') {
        item.header = 'Next Task';
      }
      if (item.field === 'Tags') {
        return false;
      }
      if (seen.has(item.field)) {
        return false;
      }
      seen.add(item.field);
      return true;
    });
    this.selectedColumns = filteredArray;
    const clientNameColumn = {
      field: 'ClientName',
      header: 'Client',
      rowClass: 'width-9p p-text-left'
    };
    const projectColumn = {
      field: 'TemplateName',
      header: 'Project',
      rowClass: 'width-9p p-text-left'
    };
    const tagsDescriptionColumn = {
      field: 'TagDescriptions',
      header: 'Tags',
      rowClass: 'width-9p p-text-left'
    };
    this.selectedColumns.unshift(clientNameColumn);
    const clientNameIndex = this.selectedColumns.indexOf(clientNameColumn);
    this.selectedColumns.splice(clientNameIndex + 1, 0, projectColumn);
    this.selectedColumns.splice(clientNameIndex + 2, 0, tagsDescriptionColumn);
  }

  private addGroupDescriptionValues() {
    this.pdfReportProjectList.forEach(item => {
      if (item['GroupDescriptionIDArray']?.length) {
        item['GroupDescriptionValues'] = this.groupList
          .filter(group => item['GroupDescriptionIDArray'].includes(group['value']))
          .map(group => group['label'])
          .join(', ');
      }
    });
  }

  private finalizeCSVExport(updatedData, originalData) {
    this.dt.value = updatedData;
    this.dt.exportCSV();
    this.selectedColumns = this.copySelectedColunms;
    this.copySelectedColunms = [];
    this.dt.value = originalData;
    this.pdfReportProjectList = [];
    this.mangoAPISrvc.showLoader(false);
    this.isTagscolvisible = true;
  }

  onShowBudgetAssign() {
    this.selectedProjects = this.selectedClientsItems.map(project => ({
      ...project,
      ddmProjectHeaderID: project.ProjectHeaderID
    }));
    this.showAssignBudgetDialog = true;
  }

  onAssignBudgetsCompleted(event) {
    if (event) {
      this.selectedProjects = [];
      this.selectedClientsItems = [];
    }

    this.showAssignBudgetDialog = false;
  }

  onCompleteRruleDialog(event) {
    if (event.isCompleted) {
      if (this.batchUpdateForm.value['isUpdateDueDate']) {
        this.batchUpdateForm.controls['dueDate'].setValue(event.dueDate);
      }

      this.repeatDueDate = event.dueDate;

      this.batchUpdateForm.controls['nextDueDate'].setValue(event.nextDueDate);

      this.batchUpdateForm.controls['ruleString'].setValue(event.rrule);
    }
  }

  onChangeRepeat() {
    if (this.batchUpdateForm.value['repeat'] == 'custom') {
      // this.isShowRruleDialog = true
      this.rruleComponent.isDisplayRuleDialog = true;
      this.rruleComponent.ngOnInit();
    }
  }

  openTasksSideBar(row) {
    this.selectedRowOnTasks = row;

    this.taskListDataSource = [];
    this.projectTaskList = [];

    this.mangoAPISrvc.showLoader(true);

    this.getProjectDetailsByHeaderId(row.ProjectHeaderID);

    this.activeProjectHeaderID = row.ProjectDetailsID;
  }

  onCompleteTaskRow(rowData) {
    const objToSave = {
      IsCompleted: rowData.IsCompleted
    };

    let nextTask = this.projectTaskList.find(next => next['TaskRow'] == rowData['TaskRow'] + 1);

    this.projectTaskList = this.projectTaskList.map(item => {
      if (item.ProjectDetailsID === rowData.ProjectDetailsID) {
        return {
          ...item,
          IsCompleted: rowData.IsCompleted,
          CompletionDate: rowData.IsCompleted ? new Date() : null,
          CompletedByStaffID: rowData.IsCompleted ? this.staffID : null
        };
      } else if (
        rowData.IsCompleted &&
        nextTask &&
        !nextTask.IsCompleted &&
        !nextTask.IsTaskReady &&
        nextTask.ProjectDetailsID === item.ProjectDetailsID
      ) {
        return {
          ...item,
          IsTaskReady: true
        };
      } else if (
        !rowData.IsCompleted &&
        nextTask &&
        !nextTask.IsCompleted &&
        nextTask.IsTaskReady &&
        nextTask.ProjectDetailsID === item.ProjectDetailsID
      ) {
        return {
          ...item,
          IsTaskReady: false
        };
      } else {
        return item;
      }
    });

    this.mangoAPISrvc.showLoader(true);

    this.batchSaveAllTasks(this.projectTaskList).subscribe(data => {
      this.mangoAPISrvc.showLoader(false);
      this.totalProcessLen = this.projectTaskList.filter(
        item => item['IsCompleted'] == true
      ).length;
      this.selectedRowOnTasks['TasksCompleted'] = this.totalProcessLen;
      if (
        this.projectTaskList.filter(task => task.IsCompleted).length === this.projectTaskList.length
      ) {
        this.continueCompleteProject(this.selectedRowOnTasks, 0, false);
        this.isShowRequestSidebar = false;
      } else {
        if (
          this.selectedRowOnTasks.isCompleted ||
          this.selectedRowOnTasks.CompletionDate ||
          this.selectedRowOnTasks.Status === 'Completed'
        ) {
          //@NOTE: Make the project incomplete.
          const tasksCompleted = this.projectTaskList?.filter(task => task.IsCompleted)?.length;
          this.mangoAPISrvc.showLoader(true);
          this.mangoAPISrvc
            .updateProjectHeader(this.selectedRowOnTasks.ProjectHeaderID, {
              isCompleted: false,
              CompletionDate: null,
              Status: 'In Progress',
              TasksCompleted: tasksCompleted || 0
            })
            .subscribe(
              result => {
                this.mangoAPISrvc.showLoader(false);
                this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
              },
              err => {
                this.mangoAPISrvc.showLoader(false);
                this.mangoAPISrvc.notify('error', 'Error', AppConstants.updateErrorMsg);
              }
            );
        }
      }
    });
  }

  getProjectDetailsByHeaderId(ProjectHeaderID) {
    this.taskListLoadingStatus = true;
    this.taskListLoadedStatus = false;

    this.mangoAPISrvc.showLoader(true);

    let parent = this;

    this.mangoAPISrvc
      .fetchprojectDetailsByHeaderId(ProjectHeaderID, {
        pageIndex: this.taskListPageIndex,

        nextPage: this.taskListNextPage,

        pageSize: this.taskListPageSize,

        _slicePaginationStatus: false,

        _sqlPaginationStatus: false,

        taskListPaginateStatus: this.taskListPaginateStatus
      })
      .subscribe(
        (responseData: any) => {
          const taskList = responseData.taskList;

          const totalCount = responseData.totalCount;

          this.projectTaskListTotalCount = totalCount;

          if (taskList.length <= 0) {
            this.resetProjectTaskListPagination();
          }

          taskList.map(function (lineObj, index) {
            parent.getStaffNames(lineObj['UserAssignedIDArray'], lineObj);
          });

          taskList.sort(function (a, b) {
            return a.TaskRow - b.TaskRow;
          });

          let totalReadyTask = taskList.filter(item => item['IsTaskReady'] == true);

          if (taskList.length > 1) {
            if (totalReadyTask.length == 0) {
              taskList[0]['IsTaskReady'] = true;
            }
          }

          this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);

          /*
            this.taskListDataSource = (
              data
            );
            */

          this.projectTaskList = taskList;

          this.totalProcessLen = this.projectTaskList.filter(
            item => item['IsCompleted'] == true
          ).length;

          this.mangoAPISrvc.showLoader(false);

          this.isShowRequestSidebar = true;

          this.taskListLoadingStatus = false;
          this.taskListLoadedStatus = true;
        },
        error => {
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), error.message);

          this.mangoAPISrvc.showLoader(false);
        }
      );
  }

  onProjectTaskListPageChange($event?) {
    this.taskListPageIndex = $event.page + 1;

    this.taskListNextPage = $event.first;

    this.taskListPageSize = $event.rows;

    this.getProjectDetailsByHeaderId(this.activeProjectHeaderID);
  }

  resetProjectTaskListPagination() {
    this.taskListPageIndex = 1;
    this.taskListNextPage = 0;
    this.taskListPageSize = this.selectedRowsPerPage;
  }

  getStaffNames(ids, data) {
    if (!ids || ids.length == 0) {
      data['StaffNames'] = 'Unassigned';
      return;
    }
    const allList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
    const labels = allList
      ?.filter(item => ids.includes(item['StaffID']))
      .map(item => (item.Inactive ? item['StaffName'] + ' (Inactive User)' : item['StaffName']));
    if (labels.length == 0) {
      // data['UserAssignedIDArray'] = null;
      data['StaffNames'] = 'Unassigned';
      return;
    }
    data['StaffNames'] = labels.join(', ');
  }

  get isAllowFilterOtherUser() {
    return this.auth.isAllowAccess(23);
  }

  onChangeUpdateWildcards(event) {
    if (event.checked) this.batchUpdateForm.get('TemplateWildcards').enable();
    else this.batchUpdateForm.get('TemplateWildcards').disable();
  }

  onChangeTagSwitch() {
    const form = this.batchUpdateForm.value;
    if (form.isAddOnly) this.tagSwitchLabel = TagSwitch.ADD;
    else this.tagSwitchLabel = TagSwitch.REPLACE;

    return this.tagSwitchLabel;
  }

  ngOnDestroy(): void {
    this.filterProjectListDialog.unsubscribe();
  }

  openModalBulkBudgetForProjects() {
    swal
      .fire({
        title: this.translate.instant('confirmation'),
        text: `${this.translate.instant('batch-capacity-planning-msg')}?`,
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: `${this.translate.instant('Yes')}!`,
        cancelButtonText: this.translate.instant('no_cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          if (this.selectedClientsItems.length == 0) {
            swal.fire({
              icon: 'warning',
              title: `${this.translate.instant('warning')}!`,
              text: this.translate.instant('no-records-selected'),
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: 'Ok'
            });
          } else {
            let noEngagement: boolean = false;
            for (let i = 0; i < this.selectedClientsItems.length; i++) {
              if (
                this.selectedClientsItems[i].ClientID == null ||
                this.selectedClientsItems[i].ClientID == undefined ||
                this.selectedClientsItems[i].ClientID < 0
              ) {
                noEngagement = true;
                break;
              }
            }
            if (noEngagement) {
              swal
                .fire({
                  title: this.translate.instant('confirmation'),
                  text: this.translate.instant('run-Client-Budget-Utility'),
                  icon: 'warning',
                  showCancelButton: true,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  confirmButtonText: `${this.translate.instant('Yes')}!`,
                  cancelButtonText: this.translate.instant('no_cancel')
                })
                .then(async result => {
                  if (result.isConfirmed) {
                    this.mangoAPISrvc.showLoader(true);
                    this.router.navigate([AppConstants.settingsAssignmentsRoutePath]);
                    this.mangoAPISrvc.showLoader(false);
                  }
                });
            } else {
              //all is ok to proceed
              let currentDate: Date = new Date();
              let currentYear: any = currentDate.getFullYear();
              for (let i = 0; i < this.selectedClientsItems.length; i++) {
                let project = {
                  Year: currentYear,
                  CompanyID: this.selectedClientsItems[i].CompanyID,
                  ClientID: this.selectedClientsItems[i].ClientID,
                  ProjectMasterID: this.selectedClientsItems[i].ProjectMasterID,
                  ddmProjectHeaderID: this.selectedClientsItems[i].ProjectHeaderID,
                  RevenueAmount: 0,
                  TimeAmount: 0,
                  isTaskBudgetEnabled: this.companyTags.isTaskBudgetEnabled
                };
                if (project.ClientID && project.ddmProjectHeaderID) {
                  let responseUnaTask: any = await this.mangoAPISrvc.addAutomaticBudget(project);
                  if (responseUnaTask.message == 'Budget upated.') {
                    this.mangoAPISrvc.notify(
                      'success',
                      'Success!',
                      `Project ${this.selectedClientsItems[i].TemplateName} updated`
                    );
                  }
                } else {
                  this.mangoAPISrvc.notify(
                    'error',
                    'error',
                    `Project ${this.selectedClientsItems[i].TemplateName}  doesn't have client assigned.`
                  );
                }
              }
            }
          }
        }
      });
  }

  async getClientsFromCompany() {
    this.mangoAPISrvc.getContactsByCompanyId(this.companyId).subscribe((data: any) => {
      console.log(data,);
    });
  }

  async getEngagements(ClientID) {
    this.engagementListItems = [];

    this.mangoAPISrvc.getProjectsByClientId(ClientID).subscribe((data: any) => {
      this.engagementListItems = data
        .filter(item => item.Inactive == false)
        .map(item => ({
          label: item.EngagementName,
          value: item.ProjectMasterID
        }));
    });
  }
}